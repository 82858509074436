<script>
export default {
  props: {
    size: Number
  }
}
</script>

<template>
  <div
    class="dot"
    :style="{ width: (size || 6) + 'px', height: (size || 6) + 'px' }"
  />
</template>

<style lang="less">
.dot {
  position: relative;
  border-radius: 50%;
  background: #ffa07a;
}

.dot::after {
  content: "";
  position: absolute;
  left: 0px;
  top: 0px;
  height: 100%;
  width: 100%;
  border-radius: 50%;
  background: #ffa07a;
  display: block;
  animation: pulse 2s ease 0s infinite;
}

.dot::before {
  content: "";
  position: absolute;
  left: 0px;
  top: 0px;
  height: 100%;
  width: 100%;
  border-radius: 50%;
  background: #ffa07a;
  display: block;
  animation: pulse2 2s ease 0s infinite;
}

@keyframes pulse {
  0% {
    opacity: 1;
    transform: scale(1);
  }
  80% {
    opacity: 0;
    transform: scale(2.5);
  }
  100% {
    opacity: 0;
    transform: scale(3);
  }
}

@keyframes pulse2 {
  0% {
    opacity: 1;
    transform: scale(1);
  }
  30% {
    opacity: 1;
    transform: scale(1);
  }
  100% {
    opacity: 0;
    transform: scale(2.5);
  }
}
</style>
