export type UseCaseTrackName = typeof useCaseButtons[number]['trackName']
export type RemovedUseCaseTrackName = typeof removedUseCaseTrackNames[number]
export type UseCaseLabel = typeof useCaseButtons[number]['label']
export type UserTypeLabel = typeof userTypeButtons[number]['label']

export interface AnswerButton {
  label: string
  icon: string
  goodLeadAnswer: boolean
  salesy?: boolean
  trackName?: string
  showOtherInput?: boolean
}

// ? If we ever remove a use case, we should add it here.
// ? This is to ensure that we still handle old questionaire data properly.
// ? If the same happens with user types, we should add a similar list for that.
export const removedUseCaseTrackNames = [
  'Finde leads der matcher mine nuværende kunder',
] as const

export const useCaseButtons = [
  {
    trackName: 'Fylde min pipeline op med flere leads',
    label: 'Fylde min pipeline op med leads der matcher mit segment og kunder',
    icon: 'trending_up_outline_rounded',
    goodLeadAnswer: true,
    salesy: true,
  },
  {
    trackName: 'Finde og styre investeringer via performance og nøgletal',
    label: 'Finde og styre investeringer via performance og nøgletal',
    icon: 'supervisor_account_outline_rounded',
    goodLeadAnswer: true,
    salesy: true
  },
  {
    trackName: 'Udforske relationer, netværk og nyheder',
    label: 'Udforske relationer, netværk og nyheder',
    icon: 'person_search_outline_rounded',
    goodLeadAnswer: true,
    salesy: true
  },
  {
    trackName: 'Få tillid til potentielle samarbejdspartnere',
    label: 'Screene potentielle kunder og samarbejdspartnere',
    icon: 'bar_chart_4_bars',
    goodLeadAnswer: true,
    salesy: true
  },
  {
    trackName: 'Researche på personer og firmaer af nysgerrighed',
    label: 'Researche på personer og firmaer af nysgerrighed',
    icon: 'search_outline_rounded',
    goodLeadAnswer: false,
    salesy: false

  },
  {
    trackName: 'Andet',
    label: 'Andet',
    icon: 'quiz_outline_rounded',
    goodLeadAnswer: false,
    showOtherInput: true,
    salesy: false
  },
] as const

export const userTypeButtons = [
  {
    label: 'Salg & marketing',
    icon: 'work_outline_rounded',
    goodLeadAnswer: true,
    salesy: true
  },
  {
    label: 'Ledelse',
    icon: 'apartment_outline',
    goodLeadAnswer: true,
    salesy: true
  },
  {
    label: 'Finans & regnskab',
    icon: 'finance_outline_rounded',
    goodLeadAnswer: true,
  },
  {
    label: 'Research & analyse',
    icon: 'search_outline_rounded',
    goodLeadAnswer: true,
  },
  {
    label: 'Selvstændig eller konsulent',
    icon: 'person_outline_rounded',
    goodLeadAnswer: false,
  },
  {
    label: 'Privat',
    icon: 'cottage_outline_rounded',
    goodLeadAnswer: false,
  },
  {
    label: 'Andet',
    icon: 'quiz_outline_rounded',
    goodLeadAnswer: false,
    showOtherInput: true
  },
] as const
