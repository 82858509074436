<script>
import { Tags } from '@/assets/js/helpers.js'
import TagManager from '@/components/TagManager.vue'
export default {
  name: 'TagModal',
  props: {
    tags: { type: Array, required: true },
    entities: { type: Array, required: true }
  },
  data () {
    return {
      addMode: false,
      loading: false
    }
  },
  computed: {
    entityTypeSingleOrPlural () {
      return this.entities[0].entityType.toLowerCase() === 'company'
        ? this.$options.filters.pluralize(this.entities.length, 'the-companies', 'the-company', 'the-companies', true, this.translate)
        : this.$options.filters.pluralize(this.entities.length, 'the-people', 'the-person', 'the-people', true, this.translate)
    }
  },
  components: {
    TagManager
  },
  async beforeMount () {
    this.loading = true
    let associations = await Tags.lookup(this.entities.map(e => e.lassoId))
    this.entities.forEach(e => {
      this.$set(e, 'tags', (associations[e.lassoId.toLowerCase()] || []).map(t => t.id))
    })

    if (this.entities.length === 1 && !this.entities[0].tags.length) {
      this.addMode = true
    }
    this.loading = false
  }
}
</script>

<template>
  <div
    id="tagModal"
    v-if="!loading"
  >
    <div class="modal-top">
      <h1>{{ addMode ? translate('add-to-lists') : translate('edit-lists') }}</h1>
      <p>
        <span v-if="addMode">{{ translate('select-lists-to-add', {entityType: entityTypeSingleOrPlural}) }}</span>
        <span v-else>{{ translate('select-lists-to-add-remove', {entityType: entityTypeSingleOrPlural}) }}</span>
      </p>
    </div>

    <div class="modal-center">
      <tag-manager
        :inPopup="true"
        :tags="tags"
        :entities="entities"
        @cancel="$emit('close')"
        @done="changes => $emit('done', changes)"
      />
    </div>
  </div>
</template>

<style lang="less" scoped>
  #tagModal {
    .modal-center {
      padding: 0;
    }
  }
</style>
