import Address from '@shared/types/virk/Address'
import buildQueryString from '@/util/buildQueryString'

const cache = new Map<string, Promise<DAWAAutocompleteSuggestion[]>>()

export function loadSuggestions (options: {
  searchString: string
  caretPos?: number
  accessAddressId?: string
}) {
  const searchString = options.searchString
  const caretPos = options.caretPos ?? 0
  const accessAddressId = options.accessAddressId ?? null

  const cacheKey = `${searchString}_${caretPos}_${accessAddressId}`

  let promise = cache.get(cacheKey)

  if (!promise) {
    promise = fetch('https://api.dataforsyningen.dk/autocomplete' + buildQueryString([
      [ 'type', 'adresse' ],
      [ 'q', searchString ],
      [ 'caretpos', caretPos ],
      [ 'adgangsadresseid', accessAddressId ],
    ])).then(response => response.json())

    cache.set(cacheKey, promise)
  }

  return promise
}

export function createAddressFromAddressSuggestion (suggestion: DAWAAutocompleteAddressSuggestion) {
  function parseIntOrNull (input: string | null) {
    if (!input) return null
    const int = parseInt(input)
    if (isNaN(int)) return null
    return int
  }

  let houseNumber: number | null = null
  let houseLetter: string | null = null

  if (suggestion.data.husnr) {
    const matches = suggestion.data.husnr.match(/^([0-9]*)([A-z]*)$/)
    if (matches) {
      houseNumber = parseIntOrNull(matches[1])
      houseLetter = matches[2]
    }
  }

  return new Address({
    coName: null,

    streetName: suggestion.data.vejnavn,

    houseNumberFrom: houseNumber,
    houseLetterFrom: houseLetter,
    houseNumberTo: null,
    houseLetterTo: null,

    floor: suggestion.data.etage,
    sideDoor: suggestion.data.dør,

    // cityName: suggestion.data.supplerendebynavn,

    postalCode: parseIntOrNull(suggestion.data.postnr),
    postalDistrict: suggestion.data.postnrnavn,

    coordinates: {
      latitude: suggestion.data.y.toString(),
      longitude: suggestion.data.x.toString(),
    },
  })
}

export interface DAWAAutocompleteSuggestionBase {
  type: string
  tekst: string
  forslagstekst: string
  caretpos: number
}

export interface DAWAAutocompleteStreetNameSuggestion extends DAWAAutocompleteSuggestionBase {
  type: 'vejnavn'
}

export interface DAWAAutocompleteAddress {
  id: string
  vejnavn: string | null
  husnr: string | null
  etage: string | null
  dør: string | null
  supplerendebynavn: string | null
  postnr: string | null
  postnrnavn: string | null
  x: number
  y: number
}

export interface DAWAAutocompleteAccessAddressSuggestion extends DAWAAutocompleteSuggestionBase {
  type: 'adgangsadresse'
  data: DAWAAutocompleteAddress
}

export interface DAWAAutocompleteAddressSuggestion extends DAWAAutocompleteSuggestionBase {
  type: 'adresse'
  data: DAWAAutocompleteAddress
}

export type DAWAAutocompleteSuggestion = (
  | DAWAAutocompleteStreetNameSuggestion
  | DAWAAutocompleteAccessAddressSuggestion
  | DAWAAutocompleteAddressSuggestion
)
