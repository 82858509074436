import { EventBus, Cookie, apiUrl, signOut } from '@/assets/js/helpers'
import EnvironmentUtils from '@shared/utilities/environment-utils.ts'

const state = {
	token: import.meta.env.NODE_ENV === 'production' ? null : import.meta.env.VITE_APP_TOKEN,
	monitoringTokenChanges: false
}
export default {
	setToken(token, notify) {
		let oldToken = state.token
		let newToken = token
		if (newToken !== oldToken) {
			state.token = newToken
			if (notify) EventBus.$emit('token-changed', { newToken, oldToken })
		}
	},
	getToken() {
		return state.token
	},
	async loginWithParameters(userAccessKey, loginToken, puid) {
		let loginMethod
		const model = (() => {
			if (userAccessKey) { loginMethod = 'UAK'; return { userAccessKey } }
			else if (loginToken) { loginMethod = 'loginToken'; return { loginToken } }
			else if (puid) {
				loginMethod = 'puid'
				let splitPUID = decodeURIComponent(puid).split('|') // decode from URI just in case
				if (puid && splitPUID.length < 3) throw new Error('Invalid PUID provided')
				return { product: splitPUID[0], productOrgId: splitPUID[1], productUserId: splitPUID[2] }
			}
      else { return {} }
		})()
    try {
      const { token, user, apps, features, claims } = await fetch(apiUrl + '/login', { body: JSON.stringify(model), method: 'POST', credentials: 'include', headers: { 'Content-Type': 'application/json' } })
      .then(async response => {
        if (response.ok) return await response.json()
        else if (response.status === 401) {
          const reason = await response.json()
          if (loginMethod === "puid" && reason.errorCode === 42) { // Organization not found, redirect to product auth
            switch (model.product) {
              case 'jp':
              case 'finans':
                window.location.replace(`${EnvironmentUtils.GetAppsUrl()}/public/apps/product-auth/#/product/${model.product}?returnUrl=${encodeURIComponent(window.location.href)}&puid=${puid}`)
                return
              default:
                break
            }
          }
          signOut(state.user, state.readOnly)
          throw new Error('Lasso Unauthorized')
        }
        signOut()
        // throw new Error('Login failed')
      })

      // make sure users without portal access can't access the portal
      if (claims.portalAccess === false) signOut()

      this.setToken(token, false)
      return { user, apps, features, loginMethod, claims, token }
    } catch (reason) {
      if (reason.message !== 'Lasso Unauthorized') {
        console.error('Login failed:', reason)
      }
      throw reason
    }
	},
	monitorTokenChanges () {
		// Listen for differences between the token in the cookie and the state.
		// This could indicate that the user signed out elsewhere (cleared cookies), and must be signed out.
		state.monitoringTokenChanges = true
		setInterval(() => {
			try {
				let currentToken = Cookie.get('lasso')
				let oldToken = state.token
				if (oldToken !== currentToken) {
					console.log('API token has changed')
					state.token = currentToken
					EventBus.$emit('token-changed', { oldToken, newToken: currentToken })
				}
			} catch {}
		}, 1000)
	}
}