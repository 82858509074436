const getAppIcon = (appUniqueName: string) => iconMap.get(appUniqueName) ?? 'widgets_outline_rounded'
export default getAppIcon

const iconMap = new Map<string, Record<'company' | 'person', string>>([
  ['riskassessment', { company: 'error_outline_rounded', person: 'error_outline_rounded' }],
  ['dashboard', { company: 'space_dashboard_outline_rounded', person: 'space_dashboard_outline_rounded' }],
  ['monitoring', { company: 'rss_feed_outline_rounded', person: 'rss_feed_outline_rounded' }],
  ['benchmarking', { company: 'leaderboard_outline_rounded', person: 'leaderboard_outline_rounded' }],
  ['information', { company: 'company_info_outline_rounded', person: 'person_info_outline_rounded' }],
  ['information-limited', { company: 'company_info_outline_rounded', person: 'person_info_outline_rounded' }],
  ['sales', { company: 'savings_outline_rounded', person: 'savings_outline_rounded' }],
  ['salesportal', { company: 'savings_outline_rounded', person: 'savings_outline_rounded' }],
  ['ownershipdiagram', { company: 'account_tree_outline_rounded', person: 'account_tree_outline_rounded' }],
  ['news', { company: 'menu_book_outline_rounded', person: 'menu_book_outline_rounded' }],
  ['integrations-api', { company: 'compare_arrows_outline_rounded', person: 'compare_arrows_outline_rounded' }],
  ['search', { company: 'manage_search_outline_rounded', person: 'manage_search_outline_rounded' }],
  ['cvrkeyfigures', { company: 'keyfigures', person: 'keyfigures' }],
  ['cvrnetwork', { company: 'share_outline_rounded', person: 'share_outline_rounded' }],
  ['companyinsight', { company: 'insights_outline_rounded', person: 'insights_outline_rounded' }],
  ['exporter', { company: 'download_outline_rounded', person: 'download_outline_rounded' }],
  ['twinfinder', { company: 'group_outline_rounded', person: 'group_outline_rounded' }],
  ['history', { company: 'work_history_outline_rounded', person: 'work_history_outline_rounded' }],
  ['rating', { company: 'speed_outline_rounded', person: 'speed_outline_rounded' }],
  ['residence', { company: 'home_work_outline_rounded', person: 'home_work_outline_rounded' }],
  ['kam', { company: 'patient_list_outline_rounded', person: 'patient_list_outline_rounded' }],
  ['reporting', { company: 'task_outline_rounded', person: 'task_outline_rounded' }],
  ['finance', { company: 'account_balance_outline_rounded', person: 'account_balance_outline_rounded' }],
  ['accountant', { company: 'edit_square_outline_rounded', person: 'edit_square_outline_rounded' }],
  ['trueowners', { company: 'key_outline_rounded', person: 'key_outline_rounded' }],
  ['tinglysning', { company: 'import_contacts_outline_rounded', person: 'import_contacts_outline_rounded' }],
  ['analysis', { company: 'show_chart_outline_rounded', person: 'show_chart_outline_rounded' }],
  ['contacts', { company: 'badge_outline_rounded', person: 'badge_outline_rounded' }],
  ['properties', { company: 'home_outline_rounded', person: 'home_outline_rounded' }],
  ['lassousersettings', { company: 'account_circle_rounded', person: 'account_circle_rounded' }],
  ['lassosettings', { company: 'settings_outline_rounded', person: 'settings_outline_rounded' }],
  ['labelmaker', { company: 'sell_outline_rounded', person: 'sell_outline_rounded' }],
  ['prospecting', { company: 'auto_fix_rounded', person: 'auto_fix_rounded' }],
  ['internal-tasks', { company: 'integration_instructions_outline_rounded', person: 'integration_instructions_outline_rounded' }],
])
