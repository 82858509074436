export default {
    'cancel': 'Cancel',
    'confirm': 'Confirm',
    'save': 'Save',
    'companies': 'companies',
    'company': 'company',
    'the-company': 'the company',
    'the-companies': 'the companies',
    'people': 'people',
    'person': 'person',
    'the-person': 'the person',
    'the-people': 'the people',
    'companies-and-people': 'companies and people',
    'loading': 'Loading',
    'loading-home': 'Loading your news feed',
    'home-page': 'home page',
    'welcome': 'Welcome, {name}!',
    'welcome-no-name': 'Welcome!',
    'welcome-back': 'Welcome back, {name}!',
    'welcome-back-no-name': 'Welcome back!',
    'welcome-text': 'Since your last visit, here\'s what\'s happened for the companies and people that you monitor.',
    'welcome-no-followed': 'Monitor people and companies to have them shown in your personalized newsfeed below',
    'lead-of-the-day': 'Lead of the day',
    'lead-of-the-day-finding': 'Finding lead of the day',
    'please-wait': 'Please wait',
    'employees': 'employees',
    'add-to-list': 'Add to list',
    'add-to-the-list': 'Add to the list',
    'add-to-lists': 'Add to lists',
    'edit-lists': 'Edit lists',
    'remove': 'Remove',
    'latest-account': 'Latest account',
    'no-recent-news': 'The latest account will be presented here - but there are no recent accounts in your news feed',
    'currently-no-accounts': 'Currently no accounts',
    'not-satisfying': 'Not satisfying',
    'satisfying': 'Satisfying',
    'good': 'Good',
    'not-available': 'Not available',
    'download-report': 'Download report',
    'network': 'network',
    'not-related-network': '{name} is not related to other people through roles in CVR',
    'selected-person': 'Selected person from the most recent news story',
    'connections-with': '{connections} connections with',
    'worked-with': 'Worked together from {year} in',
    'follow': 'Monitor',
    'following': 'Monitoring',
    'not-following': 'Not monitoring',
    'roi': 'ROI',
    'solidity': 'Solidity',
    'revenue': 'Revenue',
    'grossProfitLoss': 'Gross result',
    'profitLossFromOrdinaryActivitiesBeforeTax': 'Result bf. taxes',
    'profitLoss': 'Result',
    'equity': 'Equity',
    'liabilitiesAndEquity': 'Balance',
    'company-status-warning': 'NOTICE! THE CURRENT STATUS OF THE COMPANY IS',
    'share-with-others': 'SHARE WITH OTHERS',
    'select-lists-to-add': 'Select the lists that the {entityType} should be added to.',
    'select-lists-to-add-remove': 'select the lists that the {entityType} should be added to or removed from.',
    'edit-list-name': 'Edit list name',
    'edit-list-name-description': 'Rename the list by entering a name below.',
    'list-name': 'Name of the list',
    'enter-a-name': 'Enter a name',
    'news-about': 'News about {name}',
    'no-news-about': 'No news about {name}',
    'loading-more-news': 'Loading more news',
    'no-more-news': 'No more news',
    'show-more-news': 'Show more news',
    'receive-notifications': 'Receive notifications',
    'receive-emails': 'Receive e-mails',
    'list-monitor-description': 'Receive notifications and e-mails when an event occurs for {entityType} on this list. Select the desired events below.',
    'list-monitor-additional-actions': 'Additional actions are configured for this list using ',
    'list-no-additional-actions': 'Set up additional e-mails, webhooks and integrations to your CRM- og dialer system using ',
    'additional-actions': 'Addition actions',
    'webhooks-and-integrations': 'Webhooks and integrations',
    'monitoring-teaser': 'Get more options',
    'the-monitor-module': 'the overvågning module',
    'try-monitoring': 'Get more events and add webhooks and integrations to your CRM or dialer systems using the Overvågnings module. Give us a call on 71747812 for more information.',
    'follow-list': 'Monitor list',
    'follow-company-list-description': 'Monitor this list of companies and be notified when related changes are registered in CVR.',
    'follow-person-list-description': 'Monitor this liste of people and be notified when changes to related companies are registered in CVR.',
    'receive-notifications-description': 'You will receive notifications about the {entityType} on the list, and will see news about them on the ',
    'import-modal-description': 'We found {count} in you file(s). Add to the list \'{name}\' now?',
    'upload-failed': 'Upload did not succeed',
    'import-description': 'Upload one or more files containing CVR-numbers. The column with CVR-numbers must be named \'cvr\'. ',
    'release-to-upload': 'Release to upload',
    'drag-files-here': 'Drag files here or',
    'or': 'or',
    'select-files': 'Select files',
    'file': 'file',
    'files': 'files',
    'file-no-cvrs': 'The selected {fileOrFiles} does not contain any CVR-numbers.',
    'list-limit-reached': 'List limit reached',
    'list-limit-description': 'The selected files contain so many {entityType} that the combined list size will exceed the limit of {limit} {entityType}.',
    'import-confirm-description-single-file': 'The selected file contains {count} {entityType}. Add to the list \'{listname}\' now?',
    'import-confirm-description-multiple-files': 'The selected files contain {count} {entityType}. Add to the list \'{listname}\' now?',
    'missing-cvr-column': 'The selected file is missing the column \'cvr\'',
    'file-not-valid-single': 'The selected file was not valid.',
    'file-not-valid-multiple': 'One of the selected files was not valid.',
    'news': 'News',
    'follow-tag-follow-description': 'This list contains the {entityType} that you monitor. When you click "Monitor" on {entityType2} in Lasso, they will get added to this list.',
    'follow-tag-share-description': 'This list contains the {entityType} that you monitor, and cannot be shared. Create another list if you wish to share it with your colleagues.',
    'not-shared': 'Not shared',
    'shared-list': 'Shared list',
    'share-list': 'Share list',
    'share-list-description': 'Your colleagues will now be able to see and edit the list. Are you sure?',
    'stop-sharing': 'Stop sharing',
    'stop-sharing-description': 'Your colleagues will no longer be able to see and edit the list. are you sure?',
    'edit': 'Edit',
    'import': 'Import',
    'export': 'Export',
    'export-description': 'Select the export type you wish to do for the list. The export will be sent to {email}.',
    'no-person-exports': 'You have no exports available that work for people.',
    'rename-list': 'Rename list',
    'export-list': 'Export list',
    'export-list-description': 'You are about to export the list \'{listname}\'. An export of the type \'{exportname}\' will be sent to your e-mail.',
    'delete-list': 'Delete list',
    'delete-list-description': 'You are about to delete the list \'{listname}\'. This action cannot be undone.',
    'clear-list': 'Clear list',
    'clear-list-description': 'You are about to remove all entities from the list \'{listname}\'. This action cannot be undone.',
    'list-deleted': 'The list was deleted',
    'empty-list': 'The list is empty',
    'notifications': 'Notifications',
    'settings': 'Settings',
    'settings-saved': 'Your settings were saved',
    'no-more-notifications': 'No more notifications',
    'more': 'more',
    'searching-companies': 'Searching for companies',
    'searching-people': 'Searching for people',
    'no-results': 'No results',
    'unknown-address': 'Unknown address',
    'search-lasso-long': 'Search Lasso for companies or people',
    'search-lasso-short': 'Search Lasso',
    'adding': 'Adding',
    'removing': 'Removing',
    'followed-companies': 'Monitored companies',
    'followed-people': 'Monitored people',
    'export-starting': 'Starting export..',
    'export-initializing': 'Preparing export..',
    'export-in-progress': 'Export in progress..',
    'export-finishing': 'Finishing..',
    'export-finished': 'Export done. <b>Click to open!</b>',
    'export-failed': 'Export failed',
    'export-completion': 'Your export is {percentage}% completed',
    'export-cancelled': 'Cancelled',
    'export-cancelling': 'Cancelling..',
    'no-lists': 'You have no {entityType} lists',
    'enter-list-name': 'Enter name',
    'create-new-list': 'Create new list',
    'create-list': 'Create list',
    'create': 'Create',
    'search-create-list': 'Search for or create new list',
    'creating': 'Creating',
    'save-changes': 'Save changes',
    'remove-from-all-tags': 'Remove from all lists',
    'remove-from-all-tags-description': 'This will remove the selected {entityType} from all lists. This action cannot be undone.',
    'changes-saved': 'Your changes were saved',
    'no-lists-with-text': 'No lists with a name containing \'{text}\'',
    'enter-list-name-to-create': 'Enter a navn above to create list',
    'share-tag-title': 'Share the list with others in your organization',
    'share-with-organization': 'Share with organization',
    'list-visible': 'The list will be visible for your colleagues',
    'shared-by-other-user': 'The list is shared by {name}, who can change this setting',
    'share': 'Share',
    'shared': 'Shared',
    'another-user': 'another user',
    'visible-to-you': 'The list is only visible to you',
    'visible-to-colleagues': 'The list is visible to your colleagues',
    'address-copied': 'The address was copied to you clip board',
    'read-only': 'Your user has limited access and cannot make changes in Lasso.',
    'direct-link': 'You are viewing a compact version of Lasso. Open the portal to see lists and more.',
    'direct-link-read-only': 'You are logged in anonymously with limited access. Log in with a e-mail to get full access.',
    'open-portal': 'Go to portal',
    'log-in': 'Log in',
    'profile': 'Profile',
    'account-settings': 'Account settings',
    'sign-out': 'Sign out',
    'sign-out-description': 'Sign out of Lasso?',
    'sign-in-description': 'Sign in with e-mail?',
    'signed-out': 'You have been signed out',
    'signed-out-description': 'You have been logged out of Lasso through another window. Please log in again.',
    'sign-in-other-user': 'Logged in with other user',
    'sign-in-other-user-description': 'You have logged into Lasso in another window with another user. Click "Continue" to use Lasso with the new user.',
    'update-window': 'Update window',
    'companies-were-added': '{count} companies were added',
    'company-was-added': '{count} company was added',
    'export-queued': 'Export queued',
    'export-queued-description': 'The export will be begin momentarily..',
    'list-shared': 'The list is now shared',
    'list-not-shared': 'The list is no longer shared',
    'now-following': 'Now monitoring {name}',
    'no-longer-following-list': 'No longer monitoring list',
    'now-following-list': 'Now folling list',
    'list-renamed': 'The list was renamed',
    'remove-from-following': 'Remove from monitoring',
    'logged-in-elsewhere': 'You have been logged out',
    'logged-in-elsewhere-description': 'Your Lasso user is in use from another location, and you are therefore being logged out.<br><br>Contact Lasso on 71 74 78 12 if you need more licenses or multi-session support.',
    'close-window': 'Close window',
    'log-in-again': 'Log in again',
    'list-not-found': 'List not found',
    'see-example': 'See example.',
    'could-not-be-found': '{entity} could not be found',
    'actions': 'Actions',
    'remove-from-list': 'Remove from list',
    'remove-from-list-description': 'Are you sure that you want to remove {count} {entityType} from the list?',
    'expired': 'Your subscription has expired',
    'expired-description': 'Your {subscriptionType} expired {timesince}. Activate your subscription to continue using Lasso.',
    'expiring': 'Expiring in {days}',
    'expiring-description': 'Your {subscriptionType} expires in {days}. <a>Activate your subscription</a> to avoid loosing access to Lasso.',
    'second': 'second',
    'seconds': 'seconds',
    'minute': 'minute',
    'minutes': 'minutes',
    'hour': 'hour',
    'hours': 'hours',
    'day': 'day',
    'days': 'days',
    'month': 'month',
    'months': 'months',
    'year': 'year',
    'years': 'years',
    'yesterday': 'Yesterday',
    'ago': 'ago',
    'trial': 'trial',
    'subscription': 'subscription',
    'activate-now': 'Activate now',
    'import-error': 'Import failed',
    'import-error-description-message': 'The import did not succeed. The following error occured: "{error}"',
    'invalid-number-provided': 'The following CVR-numbers are not valid: {numbers}',
    'edit-notifications': 'Edit notifications',
    'no-account-reported': 'The report is not submitted digitally. Download the report to see the key figures.',
    'dpa-title': 'Accept your data processing agreement',
    'dpa-description': 'In order for you and your colleagues to maintain access to the Lasso portal, where you can make lists of customers and monitor people and companies, you are required to accept our data processing agreement. This i required by European regulations, also known as the General Data Protection Regulation - <b>GDPR</b>. You can read our general terms <a href="https://apps.lassox.com/assets/docs/terms_conditions.pdf" target="_blank">here</a> (in Danish). If you have any questions, do not hesitate to contact us at <a href="mailto:contact@lassox.com" target="_blank">contact@lassox.com</a>.',
    'i-accept-dpa-terms': 'I have read and accept the above agreement',
    'save-accept': 'Save accept',
    'ask-later': 'Ask me later',
    'thank-you-terms': 'Thank you - your accept has been saved!',
    'list-edit-disabled-description': "Select {entityType} to monitor or edit their lists",
    'use-search-bar': 'Use the search bar to search for {entityTypes}',
    'enter-email': 'Enter e-mail',
    'receive-email-notifications': 'Receive notifications by e-mail',
    'enter-email-description': 'When following people and companies, you can get notified when updates occur. Enter your e-mail to be notified about change of adress, ownership changes, yearly reports and much more.'
}
