<!-- A modified version of the CompanyField.vue from. Main change is this one uses the local portal TextField to preserve it's look, and that you can prefill the @Model input if the user already has a company. -->
<script lang="ts">
import { cacheName, names } from '@/util/names'
import { unwatchRect, watchRect } from '@/util/watch-rect'
import getViewport from '@shared/vue-lasso-core/viewport'
import axios from '@/axios'
import { Component, Model, Prop, Ref, Vue, Watch } from 'vue-property-decorator'
import TextInput from '@shared/vue-lasso-core/components/TextInput.vue'

interface CompanyMeta {
  continuationToken: string
  hasNextPage: boolean
  totalPages: number
  results: SearchCompany[]
  page: number
  pageSize: number
  resultsFound: number
  resultsReturned: number
}

export interface SearchCompany {
  foundByName: string
  status: string
  lassoId: string
  name: string
  entityType: string
  address1: string
  postalCode: number
  city: string
  country: string
  score: number
}

const searchCompanies = async (query: string) => {
  const response = await axios.get<{ companies: CompanyMeta, people: unknown }>(`data/cvr/search?query=${encodeURIComponent(query)}`)
  return response.data
}

const getSuggestions = async (query: string) => {
  const results = await searchCompanies(query)
  return results.companies.results.sort((a, b) => b.score - a.score)
}

@Component({
  components: {
    TextInput
  },
})

export default class CompanyField extends Vue {
  @Prop(String)
  readonly placeholder!: string

  @Prop(Boolean)
  readonly autofocus!: boolean

  @Prop(String)
  readonly label?: string

  @Prop(Boolean)
  readonly required!: boolean

  @Prop(String)
  readonly name?: string

  @Model('input', { type: Object })
  readonly selectedCompany?: SearchCompany

  @Prop(String)
  readonly helperText?: string

  @Prop(String)
  readonly errorText?: string

  @Ref()
  readonly textField!: TextInput

  @Ref()
  readonly suggestionsEl!: HTMLElement

  $el!: HTMLElement

  viewportRect: DOMRect | null = null
  inputRect: DOMRect | null = null

  inputText = ''
  focused = false

  showSuggestions = false
  suggestionsPromise: Promise<SearchCompany[]> | null = null
  suggestions: SearchCompany[] | null = null
  activeSuggestionIndex = 0

  get suggestionsStyle () {
    const { viewportRect, inputRect } = this

    const totalBorder = 2
    return {
      width: `${(inputRect?.width ?? 0) + totalBorder}px`,
      maxHeight: `${(viewportRect?.bottom ?? 0) - (inputRect?.bottom ?? 0) - 32}px`,
      transform: [
        `translateX(${inputRect?.left ?? 0}px)`,
        `translateY(${inputRect?.bottom ?? 0}px)`,
      ].join(' '),
    }
  }

  get activeSuggestion () {
    return this.suggestions?.[this.activeSuggestionIndex] ?? null
  }

  created () {
    this.resetInputText()
  }

  mounted () {
    this.setViewportRect()
    window.addEventListener('resize', this.setViewportRect)
  }

  beforeDestroy () {
    this.showSuggestions = false
    window.removeEventListener('resize', this.setViewportRect)
  }

  setCompany (company: SearchCompany | null) {
    if (this.selectedCompany?.lassoId != company?.lassoId) this.$emit('input', company)
    this.$nextTick(this.resetInputText)
  }

  resetInputText () {
    this.inputText = this.selectedCompany && this.selectedCompany.name || ''
  }

  selectSuggestion (suggestion: SearchCompany) {
    this.setCompany(suggestion)
    this.showSuggestions = false
  }

  setViewportRect () {
    this.viewportRect = getViewport()
  }

  setInputRect (rect: DOMRect) {
    this.inputRect = rect
  }

  onMouseDown () {
    if (this.focused && this.inputText) this.showSuggestions = true
  }

  onFocus () {
    this.focused = true
    if (this.inputText) this.showSuggestions = true
  }

  onBlur (event: FocusEvent) {
    // Ignore if focus moved to input or suggestions
    if (event.relatedTarget instanceof HTMLElement) {
      if (this.textField.input.contains(event.relatedTarget)) return
      if (this.suggestionsEl.contains(event.relatedTarget)) return
    }

    this.focused = false
    this.showSuggestions = false

    if (!this.inputText) this.setCompany(null)
    else this.resetInputText()
  }

  onKeyDown (e: KeyboardEvent) {
    if (!this.showSuggestions) return

    const key = e.key || e.code

    switch (key) {
      case 'ArrowUp':
      case 'Up':
      case 'ArrowDown':
      case 'Down': {
        e.preventDefault()

        const min = 0
        const max = Math.max((this.suggestions?.length ?? 0) - 1, 0)

        let newIndex = this.activeSuggestionIndex
        if (key.endsWith('Up')) newIndex--
        else newIndex++

        if (newIndex < min) newIndex = max
        else if (newIndex > max) newIndex = min

        this.activeSuggestionIndex = newIndex
        break
      }

      case 'Tab':
      case 'Enter': {
        if (!this.activeSuggestion) return

        // Ignore Shift + Tab
        if (e.shiftKey && e.code === 'Tab') break

        e.preventDefault()
        this.selectSuggestion(this.activeSuggestion)
        break
      }
    }
  }

  onInput () {
    this.activeSuggestionIndex = 0
    this.showSuggestions = !!this.inputText && this.focused
  }

  @Watch('inputText', { immediate: true })
  onInputTextChanged (value: string) {
    if (!value) {
      this.suggestionsPromise = null
      this.suggestions = null
      return
    }

    const promise = this.suggestionsPromise = getSuggestions(value)

    promise.then(suggestions => {
      if (this.suggestionsPromise != promise) return

      this.suggestionsPromise = null
      this.suggestions = suggestions
      this.activeSuggestionIndex = 0
    })
  }

  @Watch('showSuggestions')
  onShowSuggestionsChanged () {
    unwatchRect(this.textField.input, this.setInputRect)
    if (this.showSuggestions) watchRect(this.textField.input, this.setInputRect)
  }

  @Watch('activeSuggestion')
  onActiveSuggestionChanged () {
    if (!this.activeSuggestion) return

    const index = this.activeSuggestionIndex
    const els = [ ...this.suggestionsEl.querySelectorAll('.suggestion') ]
    const el = els[index]

    if (!el) return

    const rect = el.getBoundingClientRect()
    const parentRect = this.suggestionsEl.getBoundingClientRect()

    if (rect.top < parentRect.top) {
      this.suggestionsEl.scrollTop -= parentRect.top - rect.top
    } else if (rect.bottom > parentRect.bottom) {
      this.suggestionsEl.scrollTop -= parentRect.bottom - rect.bottom
    }
  }
}
</script>

<template>
  <div class="company-field">
    <TextInput
      ref="textField"
      :class="{ 'match-suggestions': showSuggestions, 'input-component': true }"
      :placeholder="placeholder"
      :autofocus="autofocus"
      :label="label"
      :required="required"
      :name="name"
      autocomplete="off"
      v-model="inputText"
      :helperText="helperText"
      :errorText="errorText"
      @mousedown="onMouseDown"
      @focus="onFocus"
      @blur="onBlur"
      @keydown="onKeyDown"
      @input="onInput"
    />

    <div
      ref="suggestionsEl"
      class="suggestions"
      tabindex="-1"
      :style="[
        suggestionsStyle,
        { visibility: showSuggestions ? 'visible' : 'hidden' },
      ]"
      @blur="onBlur"
    >
      <template v-if="suggestions && suggestions.length">
        <button
          v-for="(suggestion, i) in suggestions"
          :key="i"
          class="suggestion"
          :class="[
            'suggestion',
            i == activeSuggestionIndex && 'is-active',
          ]"
          type="button"
          @mouseover="() => activeSuggestionIndex = i"
          @click="() => selectSuggestion(suggestion)"
        >
          <div class="text">
            {{ suggestion.name }}
          </div>
        </button>
      </template>

      <p
        v-else
        style="padding: 10px 12px; text-align: center"
      >
        <template v-if="suggestionsPromise">
          Finder firmaer...
        </template>

        <template v-else>
          Ingen firmaer fundet
        </template>
      </p>
    </div>
  </div>
</template>

<style lang="scss" scoped>
.company-field {

  .suggestions {
    position: fixed;
    top: 0;
    /* Border compensation */
    left: -1px;
    z-index: 1000;
    font-size: 12px;
    line-height: 16px;
    background-color: white;
    overflow-x: hidden;
    overflow-y: auto;
    border: 1px solid #F1F1F1;
    border-top-width: 0px;
    border-bottom-right-radius: 8px;
    border-bottom-left-radius: 8px;
    box-shadow: 0px 0px 16px 0px #0000000A;

    .suggestion {
      display: flex;
      align-items: center;
      width: 100%;
      padding: 10px 12px;
      transition: background-color 100ms;
      .text {
        width: 100%;
      }
      &.is-active {
        background: #F9F9F9;
      }

      &:active {
        background: #F9F9F9;
      }
      &:focus {
        outline: 0;
      }

    }
  }

  &::v-deep .match-suggestions {
    position: relative;
    z-index: 1010;

    .input-box {
      border-bottom-color: white;

      border-bottom-right-radius: 0px;
      border-bottom-left-radius: 0px;

    }
  }
}
</style>
