<script lang="ts">
import { Component, Prop, Vue } from 'vue-property-decorator'
import getFirstCharacter from '@/util/getFirstCharacter'
import Icon from '@/components/Icon.vue'
import Tag from '@/types/Tag'
import emojiRegex from 'emoji-regex'

@Component({
  components: {
    Icon,
  },
})

export default class TagIcon extends Vue {
  @Prop({ type: Object })
  readonly tag?: Tag

  @Prop({ type: String, default: 'small' })
  readonly size!: 'small' | 'large'

  @Prop({ type: String, default: 'company' })
  readonly type!: 'company' | 'person'

  @Prop({ type: Boolean, default: false })
  readonly hideBorder!: boolean

  get isCompanyTag () { return this.type === 'company' }
  get isPersonTag () { return !this.isCompanyTag }

  get label () { return (this.tag && getFirstCharacter(this.tag.name.trim()))?.toUpperCase() ?? '' }
  get isEmoji () { return emojiRegex().test(this.label) }

  get isFollowTag () { return this.tag?.followTag ?? false }
}
</script>

<template>
  <div
    :class="[
      'lassox-portal__TagIcon',
      !hideBorder && !isEmoji && !isFollowTag && 'lassox-portal__TagIcon--with-circle',
      `lassox-portal__TagIcon-${size}`,
      isEmoji && 'lassox-portal__TagIcon--emoji',
    ]"
  >
    <Icon
      v-if="isFollowTag"
      :icon="isCompanyTag ? 'company_rss_outline_rounded' : 'person_rss_outline_rounded'"
      :size="size === 'small' ? 'small' : 'normal'"
    />

    <!-- <img
      v-else
      class="lassox-portal__TagIcon-custom-icon"
      :src="'https://ui-avatars.com/api/?type=svg&background=DFDFDF&color=616161&rounded=true&size=20&font-size=0.6&length=1&name=' + tag.name"
    > -->

    <span
      v-else
      class="lassox-portal__TagIcon-custom-icon"
      v-text="label"
    />
  </div>
</template>

<style lang="scss">
.lassox-portal__TagIcon {
  border-radius: 25%;
  font-weight: 500;
  font-size: 18px;
  display: flex;
  justify-content: center;
  align-items: center;
  font-family: -apple-system,BlinkMacSystemFont,"Segoe UI",Roboto,"Helvetica Neue",Arial,sans-serif,"Apple Color Emoji","Segoe UI Emoji","Segoe UI Symbol";

  &-small {
    height: 18px;
    width: 18px;
    border-width: 1px;
    font-weight: 700;
  }

  &-large {
    height: 20px;
    width: 20px;
    border-width: 2px;
    font-size: 16px;
    font-weight: 500;
  }

  &--with-circle {
    border-color: #B7B7B7;
    border-style: solid;

    &.lassox-portal__TagIcon-small {
      font-size: 10px;
      line-height: 18px;
    }
    &.lassox-portal__TagIcon-large {
      font-size: 10px;
    }
  }

  // &-custom-icon {
  //   height: 20px;
  //   width: 20px;
  // }
}
</style>
