import axios from '@/axios'
import type { User } from '@/types/User'

export const requestContact = async (user: User, lassoId: string) => {
  try {
    await axios.post(`contact/request-contact?name=${user.name}&phone=${user.organization.contactPhone}&email=${user.email}&lassoId=${lassoId}`)
  } catch (error) {
    alert(error)
  }
}

export default { requestContact }