<script>
export default {
  name: 'ConfirmModal',
  data () {
    return {
    }
  },
  props: {
    config: { type: Object, required: false },
    confirmTitle: { type: String, required: true },
    confirmDescription: { type: String, required: false },
    confirmButtonText: { type: String },
    cancelButtonText: { type: String },
    showCancelButton: { type: Boolean, default: true }
  },
  computed: {
    hideCancelButton () {
      return this.config && this.config.hideCancelButton
    }
  },
  methods: {
  }
}
</script>

<template>
  <div id="confirmModal">
    <div class="modal-top">
      <!-- <i class="fa fa-exclamation-triangle"></i> -->
      <h1 v-html="confirmTitle" />
      <p
        v-show="confirmDescription"
        v-html="confirmDescription"
      />
    </div>
    <div class="modal-bottom center">
      <button
        class="btn btn-default"
        @click="$emit('cancel')"
        v-if="!hideCancelButton"
      >
        {{ cancelButtonText || translate('cancel') }}
      </button>
      <button
        class="btn btn-primary"
        @click="$emit('confirm')"
      >
        {{ confirmButtonText || translate('confirm') }}
      </button>
    </div>
    <!-- <div class="header">{{confirmText}}</div>
    <div class="button" @click="$emit('confirm')">{{confirmButtonText}}</div>
    <div class="button" @click="$emit('cancel')" v-if="showCancelButton">{{cancelButtonText}}</div> -->
  </div>
</template>

<style lang="less" scoped>
  #confirmModal {
    .modal-top {
      i {
        margin-bottom: 25px;
        font-size: 36px;
        color: #ffa07a;
      }
      text-align: center;
    }

    .modal-bottom {
      display: flex;
      align-items: stretch;
    }

    button {
      flex: 1;
      max-width: 50%;
    }
  }
</style>
