const watchedProps = [ 'top', 'left', 'right', 'bottom', 'width', 'height' ] as const

type WatchedProps = typeof watchedProps[number]

export type RectDiff = { [Prop in WatchedProps]?: DOMRect[Prop] }

export type Callback = (rect: DOMRect, oldRect: DOMRect | null, diff: RectDiff) => void

interface WatcherState {
  rect: DOMRect
  callbacks: Set<Callback>
}

const watchers = new Map<HTMLElement, WatcherState>()

let rafId: number | null = null

function start () {
  if (rafId != null) {
    for (const [ element, state ] of watchers.entries()) {
      const rect = element.getBoundingClientRect()
      const oldRect = state.rect

      const { changed, diff } = compareRects(rect, oldRect)

      if (changed) {
        state.rect = rect

        for (const callback of state.callbacks) {
          callback(rect, oldRect, diff)
        }
      }
    }
  }

  rafId = requestAnimationFrame(start)
}

function stop () {
  if (rafId != null) {
    cancelAnimationFrame(rafId)
    rafId = null
  }
}

function compareRects (rect: DOMRect, oldRect: DOMRect | null) {
  let changed = false
  const diff: RectDiff = {}

  for (const key of watchedProps) {
    if (!oldRect || rect[key] != oldRect[key]) {
      changed = true
      diff[key] = rect[key]
    }
  }

  return {
    changed,
    diff,
  }
}

export function watchRect (element: HTMLElement, callback: Callback) {
  let state = watchers.get(element)
  if (!state) {
    state = {
      rect: element.getBoundingClientRect(),
      callbacks: new Set,
    }

    watchers.set(element, state)
  }

  callback(state.rect, null, {})

  state.callbacks.add(callback)

  if (rafId == null) start()

  return {
    unwatch: () => unwatchRect(element, callback),
  }
}

export function unwatchRect (element: HTMLElement, callback: Callback) {
  const state = watchers.get(element)
  if (!state) return

  state.callbacks.delete(callback)

  if (!state.callbacks.size) {
    watchers.delete(element)
  }

  if (!watchers.size) {
    stop()
  }
}
