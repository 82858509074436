<script lang="ts">
import { Vue, Component, InjectReactive, Prop, Ref } from 'vue-property-decorator'
import Icon from '@/components/Icon.vue'
import LassoSettings from '../util/LassoSettings'
import { actions, Action } from '@/types/Actions'
import ResultType from '@/types/ResultType'
import DropdownButton from '@/components/dropdown/DropdownButton.vue'
import windowSize from '@/window-size'
import BaseButton from '@/components/BaseButton.vue'

@Component({
  components: {
    DropdownButton,
    BaseButton,
    Icon,
  }
})
export default class Actions extends Vue {
  @InjectReactive()
  readonly lassoSettings!: LassoSettings

  @Prop(String)
  readonly type!: 'company' | 'person' | 'address'

  @Prop(Object)
  readonly result!: ResultType

  @Prop({ type: Number, default: () => 0 })
  readonly maxVisibleActions!: number

  @Ref('actionsEl')
  readonly actionsEl!: HTMLDivElement

  loading = false

  get isMobile () {
    return [ 'xxs', 'xs', 'sm' ].includes(windowSize.size)
  }

  get width () {
    return this.$store.getters.actionsElWidth
  }

  set width (value: number) {
    this.$store.state.actionsElWidth = value
  }

  get actionsOrder () {
    return this.lassoSettings.settings.portal.actionsOrder
  }

  get groupedActions () {
    if (!this.type) throw 'Result has no type'
    const groupedActions: Record<'visible' | 'dropdown', { action: Action, disabled: boolean, title: string, tooltip: string, icon: string | null }[]> = { visible: [], dropdown: [] }
    const actionsOrder = this.actionsOrder[this.type]
    const vm = this
    for (const category of [ 'visible', 'dropdown' ] as const) {
      for (const slug of actionsOrder[category]) {
        const action = actions.get(slug)
        const result = this.result
        if (action) groupedActions[category].push({
          action: action,
          get disabled () { return action.isDisabled?.(result) ?? false },
          get title () { return vm.isMobile ? action.getTitle(result) : action.name },
          get tooltip () { return action.getTitle(result) },
          get icon () { return action.icon && action.getIcon ? action.getIcon(result) : typeof action.icon === 'string' ? action.icon : action.icon.company },
        })
      }
    }
    return groupedActions
  }

  get visibleActions () {
    let preferredVisibleActions = this.groupedActions.visible.length
    if (this.groupedActions.dropdown.length) preferredVisibleActions++

    let maxVisibleActions = this.maxVisibleActions
    if (maxVisibleActions < preferredVisibleActions) maxVisibleActions--

    maxVisibleActions = Math.max(maxVisibleActions, 0)
    return this.groupedActions.visible.slice(0, maxVisibleActions)
  }

  get dropdownActions () {
    const dropdownActions: { action: Action, disabled: boolean, title: string, tooltip: string, icon: string | null }[] = []

    const getIcon = (a: Action) => {
      if (a.getIcon) return a.getIcon(this.result)
      return (a.dropdownIcon || a.icon) ?? null
    }

    if (this.visibleActions.length < this.groupedActions.visible.length) {
      dropdownActions.push(...this.groupedActions.visible.slice(this.visibleActions.length - this.groupedActions.visible.length))
    }

    dropdownActions.push(...this.groupedActions.dropdown)

    const mappedDropdownActions = dropdownActions.map(a => {
      return {
        label: a.action.name,
        icon: getIcon(a.action),
        onClick: () => a.action.handle(this.result),
        disabled: a.action.isDisabled && a.action.isDisabled(this.result),
        title: { text: a.tooltip, placement: 'left' },
      }
    })
    return mappedDropdownActions
  }
}
</script>

<template>
  <div
    class="lassox-portal__Actions"
    ref="actionsEl"
  >
    <div
      class="lassox-portal__Actions-wrapper"
      v-for="(obj,i) in visibleActions"
      :key="obj.action.slug"
      v-tooltip="{ text: obj.tooltip, placement: 'top' }"
    >
      <span
        v-if="obj.action.getBadge && !!obj.action.getBadge(result)"
        class="lassox-portal__Actions-action-badge"
        v-text="obj.action.getBadge(result)"
      />
      <BaseButton
        class="lassox-portal__Actions-action"
        buttonColor="peach"
        :disabled="obj.disabled"
        @click.stop.prevent="!obj.disabled && obj.action.handle(result)"
      >
        <Icon
          :icon="obj.icon"
          size="small"
        />
      </BaseButton>
    </div>

    <!-- :dropdownSpacing="4" -->
    <DropdownButton
      v-if="dropdownActions.length"
      :menuItems="dropdownActions"
      :borderRadiusEffect="false"
      preferHAlign="left"
    >
      <template #default="{ isOpen, onClick }">
        <BaseButton
          class="lassox-portal__Actions-action-dropdown"
          buttonColor="peach"
          @click.stop.prevent="onClick"
        >
          <Icon
            :icon="isOpen ? 'expand_less' : 'expand_more'"
            size="small"
          />
        </BaseButton>
      </template>
    </DropdownButton>
  </div>
</template>

<style lang="scss">
.lassox-portal__Actions {
  flex: 1 1 auto;
  display: inline-flex;
  justify-content: flex-end;
  min-width: 0;
  width: 100%;
  gap: 16px;

  &-action-dropdown {
    .md-icon {
      margin-right: 0;
    }
  }

  &-wrapper {
    position: relative;

    .lassox-portal__Actions-action-badge {
      position: absolute;
      min-width: 16px;
      height: 16px;
      border-radius: 8px;
      top: -8px;
      right: -8px;
      background-color: #616161;
      color: #FFF;
      z-index: 2;
      font-size: 10px;
      line-height: 16px;
      text-align: center;
    }
  }


  &-action,
  &-action-dropdown {
    width: 36px;
    min-width: 36px;
    height: 36px;
    min-height: 36px;
    border-radius: 8px;
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 0;
    appearance: none;
    border: 1px solid #B7B7B7;
    font-size: 12px;
    line-height: 14px;
    font-weight: 500;

    &:focus, &:active {
      outline: none;
    }

    &:disabled {
      opacity: 0.5;
      pointer-events: none;
    }
  }
}
</style>
