<script setup lang="ts">
import { store } from '@/assets/js/store'
import { computed, inject, onMounted } from 'vue'
import TrialOverlay from './TrialOverlay.vue'
import PremiumFeatureExpiredTrialOverlay from './PremiumFeatureExpiredTrialOverlay.vue'
import { EventBus } from '@/assets/js/helpers'
import { overlayInjectionKey } from '@/util/overlay'

const canCreateTrial = computed(() => store.state.user.organization?.canCreateTrial)
onMounted(() => {
  const config = inject(overlayInjectionKey)
  if (config?.value.source)
    EventBus.$emit('june:trackEvent', { event: 'Premium feature triggered', traits: { trigger: config.source, trialExpired: !canCreateTrial.value } })
})

const emit = defineEmits([ 'close' ])

</script>

<template>
  <TrialOverlay
    v-if="canCreateTrial"
    @close="emit('close')"
  />
  <PremiumFeatureExpiredTrialOverlay
    v-else
    @close="emit('close')"
  />
</template>
