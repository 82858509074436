<script>
// eslint-disable-next-line @typescript-eslint/no-var-requires
import allowedEvents from '@oldShared/lasso/data/allowedEvents.json'
// eslint-disable-next-line @typescript-eslint/no-var-requires
import allEvents from '@oldShared/lasso/data/availableEvents.json'

import { EventBus } from '@/assets/js/helpers.js'
import EventPickerGrouped from '@oldShared/lasso/vue-components/EventPickerGrouped'
import PremiumFeatureOverlay from './overlays/PremiumFeatureTrialOverlay.vue'
import { showTabOverlay } from '@/util/overlay.ts'

export default {
  props: {
    tag: { type: Object, required: true },
    hasMonitoring: { type: Boolean, required: false },
    isFreemium: Boolean
  },
  data () {
    return {
      loading: false,
      allowedEvents: allowedEvents,
      allEvents: allEvents,
      availableEventTypes: [],
      updating: false,
      monitor: null
    }
  },
  computed: {
    availableEvents () {
      return this.allEvents.filter(e => this.availableEventTypes.indexOf(e.name) > -1)
    }
  },
  methods: {
    async onEventsUpdated (actionType, events) {
      this.updating = true
      this.monitor[actionType + 'Events'] = events
      await this.$store.dispatch('updateTagMonitor', { tagId: this.tag.id, monitor: this.monitor })
      this.updating = false
    },
    loadMonitor (tag) {
      // get the monitor
      this.loading = true
      Promise.all([
        this.$store.dispatch('getAvailableNewsTypes'),
        this.$store.dispatch('getTagMonitor', tag.id)
      ]).then(results => {
        this.availableEventTypes = results[0]
        this.monitor = results[1]
        this.loading = false
      })
    },
    showBookDemoPopup (event) {
      if (this.isFreemium) {
        event.stopPropagation()
        event.preventDefault()

        showTabOverlay({ component: PremiumFeatureOverlay, source: 'FollowEvent' })
      }
    }
  },
  components: {
    EventPickerGrouped
  },
  created () {
    this.loadMonitor(this.tag)
  }
}
</script>

<template>
  <div id="followListDropdown">
    <!-- <div class="dropdown-description with-icon">
      <i class="fa fa-info-circle"></i>
      <span v-lang.list-monitor-description="{ 'entityType': tag.tagType === 'Company' ? translate('companies') : translate('people') }"></span>
    </div>
    <span class="dropdown-divider"></span> -->
    <div
      v-if="loading"
      class="text-center text-muted padding-top-bottom-1"
    >
      {{ translate('loading') }}..
    </div>
    <div
      v-else
      class="actions"
    >
      <div
        v-if="$store.state.productAccess"
        class="padding-top-1 text-muted"
      >
        Vælg hvilke hændelser du ønsker at modtage opdateringer for.
        <event-picker-grouped
          :availableEvents="availableEvents"
          :allowedEvents="allowedEvents"
          :monitorType="'Tag'"
          :entityType="tag.type"
          :actionType="'Email'"
          :events="monitor.emailEvents"
          @events-updated="events => onEventsUpdated('email', events)"
          class="d-block margin-top-1"
          @premiumEventClicked="showBookDemoPopup"
        />
      </div>
      <template v-else>
        <div class="section-header">
          <img
            src="@/assets/img/notification_active.png"
            height="17"
            width="17"
          >
          <span v-lang.receive-notifications />
          <i
            class="fa fa-info-circle width-200"
            style="color: #8f979c"
            :tooltip="translate('list-monitor-description', { 'entityType': tag.type === 'Company' ? translate('companies') : translate('people') })"
            tooltip-placement="left"
          />
        </div>
        <event-picker-grouped
          :availableEvents="availableEvents"
          :allowedEvents="allowedEvents"
          :monitorType="'Tag'"
          :entityType="tag.type"
          :actionType="'Notification'"
          :events="monitor.notificationEvents"
          @events-updated="events => onEventsUpdated('notification', events)"
          class="d-block"
          @premiumEventClicked="showBookDemoPopup"
        />
        <div @click="($event) => showBookDemoPopup($event)">
          <div class="section-header">
            <img
              src="@/assets/img/email_active.png"
              height="12"
              width="16"
            >
            <span v-lang.receive-emails />
          </div>
          <event-picker-grouped
            :availableEvents="availableEvents"
            :allowedEvents="allowedEvents"
            :monitorType="'Tag'"
            :entityType="tag.type"
            :actionType="'Email'"
            :events="monitor.emailEvents"
            :conditionalToggle="!isFreemium"
            @events-updated="events => onEventsUpdated('email', events)"
            class="d-block"
            @premiumEventClicked="showBookDemoPopup"
          />
        </div>
      </template>

      <template v-if="hasMonitoring && monitor.additionalActions">
        <div
          class="section-header"
          style="margin-bottom: 8px"
        >
          <img
            src="@/assets/img/integration_active.png"
            height="18"
            width="18"
          >
          <span v-lang.additional-actions />
        </div>
        <div>
          <span
            v-if="monitor && monitor.additionalActions"
            v-lang.list-monitor-additional-actions
          />
          <span
            v-else
            v-lang.list-no-additional-actions
          /><a
            href=""
            @click.stop.prevent="$store.dispatch('openNewTab', $router.resolve({ name: 'app', params: { appName: 'monitoring' } }).route)"
            v-lang.the-monitor-module
          />
        </div>
      </template>
    </div>
    <div
      v-if="!hasMonitoring && $store.getters.isAdmin && !$store.state.productAccess"
      class="monitor-teaser text-muted"
    >
      <div
        class="text-semibold"
        style="margin-bottom: 8px"
        v-lang.monitoring-teaser
      />
      <div v-lang.try-monitoring />
    </div>
    <!-- <div class="setting-toggle">
      <div class="row">
          <div class="col">
          <div class="text-semibold" v-lang.follow-list></div>
          <span class="text-muted" v-lang.receive-notifications></span>
          </div>
          <div class="col-auto d-flex align-items-center"><toggle-button :value="tag.followed" @toggled="$store.dispatch('updateTagFollowed', { tagId: tag.id, followState: !tag.followed })"></toggle-button></div>
      </div>
      <div v-if="tag.followed" class="description text-muted row align-items-center">
        <i class="fa fa-info-circle col-auto" style="padding-right: 0"></i>
        <span class="col">
          <span><span v-lang.receive-notifications-description="{entityType: tag.tagType === 'Company' ? translate('companies') : translate('people')}"></span> <router-link :to="{ name: 'home' }" v-lang.home-page></router-link></span>
        </span>
      </div>
    </div>
    <span class="dropdown-divider"></span>
      <div class="dropdown-item" style="margin-top: 8px" @click="$router.push({ name: 'app', params: { appName: 'lassousersettings' } })" v-lang.edit-notifications></div> -->
  </div>
</template>

<style lang="less">
#followListDropdown {
  .section-header {
    margin-top: 30px;
    margin-bottom: 15px;
    display: flex;
    align-items: center;
    justify-items: center;
    span {
      flex-grow: 1;
      margin: 0 15px;
      font-weight: 500;
    }

    i {
      position: relative;
      top: -2px;
    }

    hr {
      flex: 1 1 auto;
      margin: 0;
      border-color: #D8D8D8;
      // margin-right: 30px;
    }
  }

  .actions {
    padding: 0 30px 30px 30px;
    .btn {
      padding: 6px 15px;
      line-height: 1.5em;
      font-size: 14px;
      font-weight: 400;
      text-align: left;
      display: flex;
      justify-content: space-between;
      align-items: center;
      border-color: #3D464A;
      color: #222;
      &:hover {
        background-color: initial;
      }
    }

    .menu {
      width: 100%;
    }
  }

  .monitor-teaser {
    border-top: 1px dashed #dfdfdf;
    background-color: #f8f8f8;
    padding: 30px;
  }

  .setting-toggle {
    padding: 10px 20px;
    .description {
      margin-top: 15px;
      line-height: 22px;
      font-style: italic;
    }
  }
}
</style>
