<script lang="ts">
import { Vue, Component, Prop, Inject } from 'vue-property-decorator'
import BaseButton from '@/components/BaseButton.vue'
import DropdownMenu from './DropdownMenu.vue'
import { TooltipOptions } from '@/tooltips'

@Component({
  inheritAttrs: false,

  components: {
    BaseButton,
  },
})

export default class DropdownMenuItem extends Vue {
  @Inject()
  readonly dropdownMenuVm!: DropdownMenu

  @Prop(String)
  readonly icon?: string

  @Prop({ type: String, required: true })
  readonly label!: string

  @Prop({ type: [ String, Object ], required: false })
  readonly title?: string | TooltipOptions

  @Prop(String)
  readonly trailingIcon?: string

  @Prop({ type: Boolean, default: false })
  readonly disabled!: boolean

  @Prop({ type: Boolean, default: true })
  readonly closeMenuOnClick!: boolean

  isMousedOver = false
  longMouseOverPromise: Promise<void> | null = null

  onClick (event: MouseEvent) {
    this.$emit('click', event)
    if (this.closeMenuOnClick) {
      this.dropdownMenuVm.close?.()

      for (const menu of this.dropdownMenuVm.ancestorDropdownMenus) {
        if (!menu.close) break
        menu.close()
      }
    }
  }

  onMouseOver (event: MouseEvent) {
    this.$emit('mouseover', event)
    this.isMousedOver = true

    const promise = this.longMouseOverPromise = new Promise(resolve => setTimeout(resolve, 250))

    promise.then(() => {
      if (this.longMouseOverPromise !== promise) return
      this.longMouseOverPromise = null
      if (this.isMousedOver) this.$emit('longmouseover')
    })
  }

  onMouseOut (event: MouseEvent) {
    this.$emit('mouseout', event)
    this.isMousedOver = false
  }
}
</script>

<template>
  <div
    class="lassox-portal__DropdownMenuItem"
    v-tooltip="title"
  >
    <BaseButton
      class="lassox-portal__DropdownMenuItem-button"
      :icon="icon"
      :label="label"
      :trailingIcon="trailingIcon"
      :disabled="disabled"
      tabindex="0"
      v-bind="$attrs"
      v-on="{
        ...$listeners,
        click: onClick,
        mouseover: onMouseOver,
        mouseout: onMouseOut,
      }"
    />
  </div>
</template>

<style lang="scss">
.lassox-portal__DropdownMenuItem {
  display: flex;
  justify-content: flex-start;
  width: 100%;
  height: 32px;

  &-button {
    justify-content: flex-start;
    width: 100%;
    height: 100%;
    padding: 0 16px;

    .lassox-portal__BaseButton_leading-icon {
      margin-left: -4px;
    }

    .lassox-portal__BaseButton_trailing-icon {
      margin-left: auto;
      margin-right: -4px;
    }

    .lassox-portal__BaseButton_leading-icon + .lassox-portal__BaseButton_label,
    .lassox-portal__BaseButton_label + .lassox-portal__BaseButton_trailing-icon {
      padding-left: 12px;
      margin-left: 0;
    }
  }
}
</style>
