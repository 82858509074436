import Vue from 'vue'
import { forEach, uniqBy } from 'lodash'
import EnvironmentUtils from '@shared/utilities/environment-utils.ts'

// local mobile develop
// export const apiUrl = 'https://staging.api.lassox.com'

export const apiUrl = EnvironmentUtils.GetApiUrl()
export const newsUrl = (window.location.host.indexOf('dev.portal') > -1 || window.location.host.indexOf('localhost') > -1) ? 'http://localhost:1338/api' : window.location.host.indexOf('staging') > -1 ? 'https://staging.lasso.dk/api' : 'https://lasso.dk/api'

let linkify = function (text, doNotCreateLinks, truncate) {
  if (text) {
    var regex = /{([^}]*)}/g
    var regex2 = /\{([^}]+)\}/
    // find all links, take unique
    var links = text.match(regex)
    // for each, generate html or simple text depending on doNotCreateLinks param
    if (links) {
      links.forEach(function (link) {
        var content = link.match(regex2)[1].split('|')
        if (content.length === 2) {
          if (doNotCreateLinks) text = text.replace(link, content[0])
          else text = text.replace(link, '<router-link exact :to="{path: \'/' + content[1] + '\'}">' + content[0] + '</router-link>')
        }
      })
    }
    return truncate ? Vue.filter('truncateAlways')(text, truncate) : text
  }
}

export const EventBus = new Vue()
export let Tags = {
  getAll: () => Vue.http.get(apiUrl + '/tags').then(result => result.body),
  get: (tagId) => Vue.http.get(apiUrl + '/tags/' + tagId).then(result => result.body),
  getEntities: (tagId, skip = 0, take = 1000) => Vue.http.get(apiUrl + `/tags/${tagId}/entities?skip=${skip}&take=${take}`).then(result => result.body),
  create: (tagName, tagType) => Vue.http.post(apiUrl + '/tags?name=' + encodeURIComponent(tagName) + '&type=' + tagType).then(result => result.body),
  del: (tagId) => Vue.http.delete(apiUrl + '/tags/' + tagId),
  clear: (tagId) => Vue.http.put(apiUrl + '/tags/' + tagId + '/clear'),
  getTagACL: (tagId) => Vue.http.put(apiUrl + '/tags/' + tagId + '/sharing' ).then(result => result.body),
  updateOrgACL: (tagId, tagAccessObj) => Vue.http.put(apiUrl + '/tags/' + tagId + '/sharing/organization', tagAccessObj).then(result => result.body),
  updateUserACL: (tagId, lassoUserId, tagAccessObj) => Vue.http.put(`${apiUrl}/tags/${tagId}/sharing/users/${lassoUserId}`, tagAccessObj).then(result => result.body),
  removeUserACL: (tagId, lassoUserId) => Vue.http.put(`${apiUrl}/tags/${tagId}/sharing/users/${lassoUserId}`, { canView: false, canEdit: false, canTag: false, canDelete: false }).then(result => result.body),
  updateFollowed: (tagId, followed) => Vue.http.put(apiUrl + '/tags/' + tagId + '/followed/' + followed).then(result => result.body),
  update: tag => Vue.http.put(apiUrl + '/tags/' + tag.id, tag),
  updateEntityTags: changes => Vue.http.post(apiUrl + '/tags/change', changes).then(result => result.body),
  updateMonitor: (tagId, monitor) => Vue.http.put(apiUrl + '/tags/' + tagId + '/monitor', monitor),
  getMonitor: (tagId) => Vue.http.get(apiUrl + '/tags/' + tagId + '/monitor').then(result => result.body),
    getNews: (listId, page = 1, pageSize = 12, orderBy = 'publishTime') => Vue.http.get(apiUrl + `/tags/${listId}/news?page=${page}&limit=${pageSize}&orderBy=${orderBy}`).then(result => result.body.map(Story.enrichStory)),
  lookup: (lassoIds) => Vue.http.post(apiUrl + '/tags/lookup', lassoIds).then(result => result.body)
}

export let Entities = {
  get: (lassoId) => Vue.http.get(apiUrl + '/v2/' + lassoId).then(result => result.body)
}

export let PEP = {
  get: () => Vue.http.get(apiUrl + '/data/finanstilsynet/peplist').then(result => {
    return result.body
  }, error => {
    // eslint-disable-next-line no-console
    console.log(error)
  })
}

export let Users = {
  getCurrent: () => Vue.http.get(apiUrl + '/users/me').then(result => result.body),
  get: id => Vue.http.get(apiUrl + '/users/' + id).then(result => result.body),
  update: user => Vue.http.put(apiUrl + '/users/' + user.id, user).then(result => result.body),
  updateEmail: email => Vue.http.put(apiUrl + '/users/me/email', JSON.stringify(email)).then(result => result.body),
  getAll: () => Vue.http.get(apiUrl + '/organizations/me/users/simple').then(result => result.body),
  search: filter => Vue.http.get(apiUrl + `/organizations/me/users/simple?nameFilter=${filter}`).then(result => result.body),
  getApps: () => Vue.http.get(apiUrl + '/users/me/apps').then(result => result.body),
  getFollowed: () => Vue.http.get(apiUrl + '/tags/monitored?skip=0&take=10000').then(result => result.body.results),
  updateSettings: settings => Vue.http.put(apiUrl + '/users/me/settings', settings).then(result => result.body),
  switchOrganizationGetToken: orgId => Vue.http.post(apiUrl + `/login/switch-organization/${orgId}/token`, null, { credentials: true }).then(result => result.body),
  switchOrganization: orgId => Vue.http.post(apiUrl + `/login/switch-organization/${orgId}`, null, { credentials: true }).then(result => result.body),
  changeImpersonation: (orgId, userId) => Vue.http.post(apiUrl + '/organizations/' + orgId + '/users/' + userId + '/impersonate/change', null, { credentials: true }).then(result => result.body, err => alert(err.body.errorMessage)),
  stopImpersonating: () => Vue.http.post(apiUrl + '/users/me/stopimpersonating', null, { credentials: true }),
  getAvailableNewsTypes: () => Vue.http.get(apiUrl + '/users/me/availableNewsTypes')
}

export let DataExports = {
  get: () => { return Vue.http.get(apiUrl + '/organizations/me/subscriptions/exports').then(result => result.body) },
  schedule: exportSettings => { return Vue.http.post(apiUrl + '/apps/exporter/exports', exportSettings) }
}

export let Exports = {
  getCurrent: () => { return Vue.http.get(apiUrl + '/apps/exporter/exports/current').then(result => result.body) },
}

export let Notifications = {
  get: async () => { return Vue.http.get(apiUrl + '/users/me/notifications?count=10').then(result => result.body) },
  getBefore: timestamp => { return Vue.http.get(apiUrl + '/users/me/notifications?count=5&before=' + new Date(timestamp).toISOString()).then(result => result.body) },
  listen: (socket, listenerIds, eventHandlers) => {
    socket.on('connect', () => {
      listenerIds.forEach(listenerId => { socket.emit('join', listenerId) })
    })
    Object.keys(eventHandlers).forEach(key => socket.on(key.toLowerCase(), payload => eventHandlers[key](JSON.parse(payload))))
    // eslint-disable-next-line @typescript-eslint/no-empty-function
    socket.on('disconnect', () => {})
  },
  convertNotification: notification => {
    notification.message = notification.message || {}
    return {
      headline: notification.message.headline,
      time: notification.timestamp,
      id: notification.id,
      url: notification.message.url,
      type: notification.type,
      pinned: notification.pinned,
      opened: notification.message.opened,
      title: Notifications.getNotificationTitle(notification),
      description: Notifications.getDescription(notification),
      meta: notification.meta,
      onClick: Notifications.getClickAction(notification),
      icon: Notifications.getIcon(notification)
    }
  },
  getIcon: notification => {
    switch (notification.type) {
      case 'Announcement': return 'announcement'
      case 'ExportCompletion': return 'export-completion'
      case 'News': return 'news'
      case 'ServiceMessage': return 'service-message'
      case 'PatchNote': return 'patch-note' // what's this?
      default: return 'Announcement'
    }
  },
  getNotificationTitle: notification => {
    switch (notification.type) {
      case 'Announcement': return notification.message.headline
      case 'ExportCompletion': return 'Eksport'
      case 'News': return 'Nyhed'
      default: return notification.type
    }
  },
  getClickAction: notification => {
    let openInWindow = () => {
      if (notification.message && notification.message.url)
        window.open((notification.message.url.toLowerCase().indexOf('http') === -1 ? 'http://' : '') + notification.message.url, '_blank') }
    let openGenericModal = () => EventBus.$emit('modal', { modalType: 'genericNotification', maxWidth: 800, notification, showClose: true })
    switch (notification.type) {
          case 'ExportCompletion': return () => {
        if (notification.message.url) window.open(notification.message.url, 'blank')
        else openGenericModal()
      }
      case 'News': return () =>  {
        if (!notification.message.story) openGenericModal()
        else if (notification.message.story.provider === 'Paqle') openInWindow(notification)
        else Story.get(notification.message.story.uniqueId).then(story => { EventBus.$emit('modal', { modalType: 'story', story: story, entity: story.queryEntity, showClose: true, notification }) })
      }
      case 'Announcement': return () => {
        if (notification.message.url) window.open(notification.message.url, 'blank')
        else openGenericModal()
      }
      default: return () => {
        openGenericModal()
      }
      // default: return openInWindow
    }
  },
  getDescription: notification => {
    switch (notification.type) {
      case 'Announcement': return notification.message.content
      case 'News': return linkify(notification.message.headline, true)
      case 'ExportCompletion':
        switch (notification.message.status) {
          case 'Completed': return 'translate-export-finished'
          case 'Cancelled': return 'translate-export-cancelled'
          case 'Failed': return 'translate-export-failed'
        }
        break
      default: return notification.message.headline
    }
  }
}

export let Organization = {
  acceptTerms: () => { return Vue.http.post(apiUrl + '/organizations/me/acceptterms') }
}

export let Story = {
  get: storyId => Vue.http.get(apiUrl + '/news/' + storyId).then(result => Story.enrichStory(result.body)),
  getByLassoIds: (lassoIds, page = 1, pageSize = 12, types = null, orderBy = 'publishTime') => Vue.http.post(apiUrl + '/news?page=' + page + '&limit=' + pageSize + (orderBy ? '&orderBy=' + orderBy : '') + (!types ? '' : '&types=' + types.join(',')), lassoIds).then(result => result.body.map(Story.enrichStory)),
  getList: (listName, page, pageSize, orderBy, types) => Vue.http.get(apiUrl + '/modules/news/lists/' + listName + '?page=' + page + '&limit=' + pageSize + (orderBy ? '&orderBy=' + orderBy : '') + (types ? '&types=' + types.join(',') : '')).then(result => result.body.map(Story.enrichStory)),
  enrichStory: story => {
    if (story) {
      story.imageId = 'https://lasso.blob.core.windows.net/lasso-news-images/' + story.imageId
      story.prettyHeadline = story.headline ? Vue.filter('linkify')(story.headline, true) : ''
      story.prettyTagline = story.tagLine ? Vue.filter('linkify')(story.tagLine, true) : ''
      story.slugTitle = story.prettyHeadline.toLowerCase().replace(/[^\w ]+/g, '').replace(/ +/g, '-')
      for (var i = 0; i < story.lassoIds.length; i++) {
        var entity = story.lassoIds[i]
        if (entity.isMainId) story.mainEntity = { lassoId: entity.lassoId, id: entity.lassoId.substring(6), name: entity.relatedLassoIdName, type: entity.lassoId.indexOf('CVR-1') === 0 ? 'company' : 'person' }
        if (entity.inQuery) story.queryEntity = { lassoId: entity.lassoId, id: entity.lassoId.substring(6), name: entity.relatedLassoIdName, type: entity.lassoId.indexOf('CVR-1') === 0 ? 'company' : 'person' }
      }
      if (!story.queryEntity && story.mainEntity) story.queryEntity = story.mainEntity
    }
    return story
  }
}

export let signOut = async function (user, skipIpCheck) {
  await fetch(apiUrl + '/logout', { credentials: 'include' })
  var product = user && user.organization && user.organization.currentSolution && user.organization.currentSolution.productUniqueName ? user.organization.currentSolution.productUniqueName : null
  window.location.href = EnvironmentUtils.GetAppsUrl() + '/login/' + (product || '') + '?returnUrl=' + encodeURIComponent(EnvironmentUtils.GetPortalUrl()) + (skipIpCheck ? '&skipIpCheck=true' : '')
}

export let getEnvironment = function () {
  return EnvironmentUtils.Environment()
}

export let Cookie = {
  set (name, value, days = 31) {
    var expires = ''
    if (days) {
      var date = new Date()
      date.setTime(date.getTime() + (days * 24 * 60 * 60 * 1000))
      expires = '; expires=' + date.toUTCString()
    }
    document.cookie = name + '=' + (value || '') + expires + '; domain=.lassox.com; path=/'
  },
  get (name) {
    var nameEQ = name + '='
    var ca = document.cookie.split(';')
    for (var i = 0; i < ca.length; i++) {
      var c = ca[i]
      while (c.charAt(0) === ' ') c = c.substring(1, c.length)
      if (c.indexOf(nameEQ) === 0) return c.substring(nameEQ.length, c.length)
    }
    return null
  },
  remove (name) {
    this.set(name, '', -1)
  }
}

export let Helpdesk = {
  search: (query, tags, categoryId) => Vue.http.get(`${apiUrl}/helpdesk/search?query=${encodeURIComponent(query)}&tags=${encodeURIComponent(tags)}${categoryId ? '&categoryId=' + categoryId : ''}`).then(result => result.body)
}

export let WebsiteScanner = {
  mergePartialResults: (previous, update) => {
    if (!previous.data) previous.data = { contacts: [], emails: [], phoneNumbers: [] }
    return {
      ...update,
      data: !update.data // if crawl on page failed, no data is given.
        ? previous.data
        : {
        contacts: uniqBy((previous.data.contacts || []).concat(update.data.contacts), c => c.id),
        emails: uniqBy((previous.data.emails || []).concat(update.data.emails), c => c.email),
        phoneNumbers: uniqBy((previous.data.phoneNumbers || []).concat(update.data.phoneNumbers), c => c.phoneNumber)
      },
      pagesCrawled: (previous.pagesCrawled || 0) + 1
    }
  }
}

export let isWorkingHours = (date) => {
  // Check if it's a weekend (Saturday or Sunday)
  if (date.getDay() === 0 || date.getDay() === 6) return false

  // Check if it's within the working hours (9 AM to 5 PM)
  var hours = date.getHours()
  return hours >= 8 && hours < 17
}

export let GenerateRandomString = () => (Math.random().toString(36).substring(2, 15) + Math.random().toString(36).substring(2, 15))
