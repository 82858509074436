<script>
import { Helpdesk, EventBus, Users } from '@/assets/js/helpers'
import Dropdown from '@oldShared/lasso/vue-components/Dropdown'
import NotificationPulse from '@/components/NotificationPulse'
import IconButton from '@/components/IconButton.vue'
export default {
  data () {
    return {
      EventBus: EventBus,
      initialized: false,
      open: false,
      relevantArticleTagsByRoute: {
        // standalone moduler
        '/app/search': [ 'målgruppesøgning' ],
        '/app/monitoring': [ 'overvågning' ],
        '/app/ejendomme': [ 'ejendomme' ],
        '/app/reporting': [ 'rapportering' ],
        '/app/exporter': [ 'dataudtræk' ],
        '/app/lassosettings': [ 'kontoindstillinger' ],
        '/app/lassousersettings': [ 'min profil' ],

        // firma- og personmoduler
        '/{lassoId}/dashboard': [ 'overblik' ],
        '/{lassoId}/information': [ 'stamoplysninger' ],
        '/{lassoId}/cvrkeyfigures': [ 'regnskaber' ],
        '/{lassoId}/ownershipdiagram': [ 'ejerdiagram' ],
        '/{lassoId}/news': [ 'nyheder' ],
        '/{lassoId}/history': [ 'historik' ],
        '/{lassoId}/twinfinder': [ 'tvilling' ],
        '/{lassoId}/rating': [ 'rating' ],
        '/{lassoId}/kam': [ 'kam' ],
        '/{lassoId}/companyinsight': [ 'firmaindsigt' ],
        '/{lassoId}/tinglysning': [ 'tinglysning' ],
        '/{lassoId}/accountant': [ 'revision' ],
        '/{lassoId}/contacts': [ 'kontaktpersoner' ],
        '/{lassoId}/trueowners': [ 'reelle ejere' ],
        '/{lassoId}/residence': [ 'bopæl' ],

        // generelle ting
        '/': [ 'kom godt igang' ],
        '/list/{listId}': [ 'lister' ]
      },
      relevantArticles: [],
      loadingArticles: false,
      productUpdates: [],
      loadingProductUpdates: false,
      productUpdatesLoaded: false,
    }
  },
  computed: {
    user () {
      return this.$store.state.user
    },
    readOnly () {
      return this.$store.state.readOnly
    },
    lastProducUpdateReadTime () {
      return new Date(this.user ? this.user.settings.lastProductUpdateCheck || new Date(this.user.created) : new Date(2099, 1, 1))
    },
    unreadProductUpdates () {
      return this.productUpdates.filter(update => this.lastProducUpdateReadTime < new Date(update.created_at))
    },
    hasUnreadProductUpdates () {
      return this.unreadProductUpdates.length > 0
    }
  },
  watch: {
    'user': {
      immediate: true,
      handler (user) {
        if (!user || !window.groove || this.productUpdatesLoaded) return

        // Initialize right away so groove can be used from Notifications
        this.initialize()
        // get latest product updates relevant for either sales, finans or accounting
        const solutionName = user.organization.currentSolution.productName.toLowerCase()
        let solutionType = solutionName.indexOf('salg') > -1 ? 'salgsløsning' : solutionName.indexOf('finans') > -1 ? 'finansløsning' : solutionName.indexOf('markedsdata') ? 'markedsdataløsning' : solutionName.indexOf('revision') > -1 ? 'revisionsløsning' : solutionName.indexOf('everything') > -1 ? 'revisionsløsning,salgsløsning,finansløsning,gratisløsning' : 'salgsløsning'
        if (this.$store.getters.isFreemium) solutionType += ',gratisløsning'
        if (solutionType) {
          this.loadingProductUpdates = true
          Helpdesk.search('*', solutionType, 1589190320).then(articles => {
            this.productUpdates = articles.reverse()
            this.loadingProductUpdates = false
            this.productUpdatesLoaded = true

            // popup latest product update if it's less than a month ago.
            let aMonthAgo = new Date()
            aMonthAgo.setMonth(aMonthAgo.getMonth() - 1)
            let latestProductUpdate = this.unreadProductUpdates.length ? this.unreadProductUpdates[0] : null
            if (latestProductUpdate && (aMonthAgo < new Date(latestProductUpdate.created_at))) {
              setTimeout(() => {
                // wait a bit, since the helpdesk must be initialized
                this.goToUrl('/kb/articles/' + latestProductUpdate.id)
              }, 5000)
              // update notifications timestamp, to no longer nudge the user to open the updates dropdown.
              this.resetLastProductCheck()
            }
          })
        }
      }
    }
  },
  mounted () {
    EventBus.$on('open-helpdesk',(url,event)=> this.goToUrl(url,event))
  },
  methods: {
    initialize () {
      if (!this.initialized) {
        window.groove.widget.init("b5740159-9356-4f22-aab2-61b4029ce192", { preferLocal: true })
        this.initialized = true
      }
    },
    async onOpen () {
      this.initialize()

      this.open = true

      this.relevantArticles = []
      let route = this.$route.path.replace(/(CVR|VIRK)-\d{1,2}-[^/.]+/, '{lassoId}').replace(/list\/[^/.]+/, 'list/{listId}')

      let relevantArticleTags = this.relevantArticleTagsByRoute[route]
      if (relevantArticleTags) {
        this.loadingArticles = true
        this.relevantArticles = await Helpdesk.search('*', relevantArticleTags.join(','))
        this.loadingArticles = false
      }
    },
    onClosed () {
      if (this.hasUnreadProductUpdates) {
        this.resetLastProductCheck()
      }
      this.open = false
    },
    resetLastProductCheck () {
      Users.updateSettings({ lastProductUpdateCheck: new Date() })
    },
    goToUrl (url, event) {
      let widget = window.groove ? Object.values(window.groove.loadedWidgets)[0] : null
      if (!widget) return
      if (this.user && !this.readOnly) widget.identifyContact('contact_email', this.user.email)
      widget.replaceRoute(url)
      if (!widget.isOpen) widget.open()
      this.$refs.hcDropdown.open = false
      EventBus.$emit('june:trackEvent', { event: 'Open help desk article', traits: { url } })
    },
  },
  components: {
    Dropdown,
    NotificationPulse,
    IconButton,
  }
}
</script>

<template>
  <dropdown
    ref="hcDropdown"
    class="hc-dropdown"
    left
    :minWidth="220"
    :tabIndex="3"
    :EventBus="EventBus"
    @opened="onOpen()"
    @closed="onClosed"
  >
    <div
      slot="toggle"
      class="d-flex align-items-center justify-content-center"
      style="width: 40px; height: 40px; position: relative;"
    >
      <notification-pulse
        v-if="hasUnreadProductUpdates"
        :size="8"
        style="position: absolute; top: 10px; right: 10px"
      />

      <!-- <img src="../assets/img/svg/help-center.svg" height="24" width="24" /> -->

      <IconButton
        :class="[
          'toolbar-icon-button',
          open && 'toolbar-icon-button--peach',
        ]"
        icon="message_question_outline_rounded_custom"
        size="normal"
        :buttonColor="open ? 'peach' : undefined"
        style="pointer-events: auto;"
      />
    </div>

    <div>
      <template v-if="relevantArticles.length > 0 || loadingArticles">
        <div
          class="dropdown-description"
          style="cursor: initial; font-weight: 500; padding-top: 0"
        >
          Hjælp til denne side
        </div>

        <div class="articles">
          <div
            class="dropdown-item text-muted"
            v-if="loadingArticles"
          >
            Indlæser..
          </div>

          <div
            v-for="article in relevantArticles"
            :key="article.id"
            class="dropdown-item text-truncate d-flex align-items-center"
            :title="article.title"
            @click="goToUrl('/kb/articles/' + article.id)"
          >
            <svg
              xmlns="http://www.w3.org/2000/svg"
              height="18px"
              viewBox="0 0 24 24"
              width="18px"
              fill="#777"
              style="margin-right: 12px; flex-shrink:0"
            >
              <g>
                <rect
                  fill="none"
                  height="24"
                  width="24"
                />
                <path d="M14.17,5L19,9.83V19H5V5L14.17,5L14.17,5 M14.17,3H5C3.9,3,3,3.9,3,5v14c0,1.1,0.9,2,2,2h14c1.1,0,2-0.9,2-2V9.83 c0-0.53-0.21-1.04-0.59-1.41l-4.83-4.83C15.21,3.21,14.7,3,14.17,3L14.17,3z M7,15h10v2H7V15z M7,11h10v2H7V11z M7,7h7v2H7V7z" />
              </g>
            </svg>

            <div class="text-truncate">
              {{ article.title }}
            </div>
          </div>
        </div>

        <span class="dropdown-divider" />
      </template>

      <dropdown
        v-if="productUpdates.length > 0"
        ref="productUpdateDropdown"
        class="dropdown-item"
        left
        :minWidth="300"
      >
        <template #toggle>
          <div
            class="d-flex align-items-center"
          >
            <svg
              v-if="!hasUnreadProductUpdates"
              xmlns="http://www.w3.org/2000/svg"
              viewBox="0 0 24 24"
              width="18px"
              height="18px"
              fill="#777"
              style="margin-right: 12px"
            >
              <path
                d="M0 0h24v24H0V0z"
                fill="none"
              />
              <path d="M12 2l-5.5 9h11L12 2zm0 3.84L13.93 9h-3.87L12 5.84zM17.5 13c-2.49 0-4.5 2.01-4.5 4.5s2.01 4.5 4.5 4.5 4.5-2.01 4.5-4.5-2.01-4.5-4.5-4.5zm0 7c-1.38 0-2.5-1.12-2.5-2.5s1.12-2.5 2.5-2.5 2.5 1.12 2.5 2.5-1.12 2.5-2.5 2.5zM3 21.5h8v-8H3v8zm2-6h4v4H5v-4z" />
            </svg>

            <notification-pulse
              v-else
              :size="8"
              style="margin-left: 5px; margin-right: 12px"
            />

            <template>Produktnyheder</template>

            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="18px"
              height="18px"
              viewBox="0 0 24 24"
              fill="#777"
              style="margin-left: auto;"
            >
              <path
                d="M0 0h24v24H0V0z"
                fill="none"
              />
              <path d="M10 6L8.59 7.41 13.17 12l-4.58 4.59L10 18l6-6-6-6z" />
            </svg>
          </div>
        </template>

        <template #default>
          <div
            v-for="article in productUpdates"
            :key="article.id"
            class="dropdown-item text-truncate d-flex align-items-center"
            :title="article.title"
            @click="goToUrl('/kb/articles/' + article.id, $event)"
          >
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="18px"
              height="18px"
              viewBox="0 0 24 24"
              fill="#777"
              style="margin-right: 12px; flex-shrink:0"
            >
              <g>
                <rect
                  fill="none"
                  height="24"
                  width="24"
                />
                <path d="M14.17,5L19,9.83V19H5V5L14.17,5L14.17,5 M14.17,3H5C3.9,3,3,3.9,3,5v14c0,1.1,0.9,2,2,2h14c1.1,0,2-0.9,2-2V9.83 c0-0.53-0.21-1.04-0.59-1.41l-4.83-4.83C15.21,3.21,14.7,3,14.17,3L14.17,3z M7,15h10v2H7V15z M7,11h10v2H7V11z M7,7h7v2H7V7z" />
              </g>
            </svg>

            <div class="text-truncate">
              {{ article.title }}
            </div>
          </div>
        </template>
      </dropdown>

      <div
        class="dropdown-item d-flex align-items-center"
        @click="goToUrl('/contact')"
      >
        <svg
          xmlns="http://www.w3.org/2000/svg"
          width="18px"
          height="18px"
          viewBox="0 0 24 24"
          fill="#777"
          style="margin-right:12px"
        >
          <path
            d="M0 0h24v24H0V0z"
            fill="none"
          />
          <path d="M22 6c0-1.1-.9-2-2-2H4c-1.1 0-2 .9-2 2v12c0 1.1.9 2 2 2h16c1.1 0 2-.9 2-2V6zm-2 0l-8 5-8-5h16zm0 12H4V8l8 5 8-5v10z" />
        </svg>
        Skriv til kundeservice
      </div>

      <div
        class="dropdown-item d-flex align-items-center"
        @click="goToUrl('/kb')"
      >
        <svg
          xmlns="http://www.w3.org/2000/svg"
          width="18px"
          height="18px"
          viewBox="0 0 24 24"
          fill="#777"
          style="margin-right:12px"
        >
          <path
            d="M0 0h24v24H0V0z"
            fill="none"
          />
          <path d="M15.5 14h-.79l-.28-.27C15.41 12.59 16 11.11 16 9.5 16 5.91 13.09 3 9.5 3S3 5.91 3 9.5 5.91 16 9.5 16c1.61 0 3.09-.59 4.23-1.57l.27.28v.79l5 4.99L20.49 19l-4.99-5zm-6 0C7.01 14 5 11.99 5 9.5S7.01 5 9.5 5 14 7.01 14 9.5 11.99 14 9.5 14z" />
        </svg>
        Søg i Hjælp til Lasso
      </div>
    </div>
  </dropdown>
</template>

<style lang="less">
.hc-dropdown {
  > span.toggle {
    cursor: default;
    pointer-events: none;
  }

  .menu {
    border-radius: 8px;

    &.left {
      right: -96px;
    }

    .toolbar-icon-button {
      &--open .md-icon {
        color: #FFA07A;
      }
    }

    .dropdown .menu.left {
      right: auto;
      left: 0;
    }

    @media only screen and (min-width: 768px) {
      &.left {
        right: 0;
        left: auto;
        margin-top: 9px;  
      }

      .dropdown .menu.left {
        right: 100%;
        left: auto;
        top: 0;
        margin-top: 0;
      }
    }
  }

  .articles {
    max-height: 200px;
    overflow-y: auto;

    .dropdown-item {
      width: 300px;
    }
  }

  .notification-indicator {
    position: absolute;
    top: 5px;
    right: 5px;
    width: 12px;
    height: 12px;
    background: #cd4b3d;
    border-radius: 15px;
  }
}
</style>
