<!-- A new (as of 2024 and Fenge) style of pop-up that fills the apps area rather than the center, and doesn't darken the app, it's ~definitely~ not a pop-up -->
<script setup lang="ts">
import { OverlayConfig } from '@/util/overlay'
import { defineEmits, defineExpose, defineProps, onMounted, ref } from 'vue'
import IconButton from './IconButton.vue'
import { EventBus } from '@/assets/js/helpers'

const props = defineProps<{ config?: OverlayConfig, classes?: string }>()

const visible = ref(false)

const dialog = ref<HTMLDialogElement | null>(null)

const emit = defineEmits(['close'])

const close = (returnVal?: string): void => {
  visible.value = false
  emit('close')
  dialog.value?.close(returnVal)
}

const show = () => {
  dialog.value?.show()
  visible.value = true
}

defineExpose({
  show,
  close,
  visible,
})

document.addEventListener('keydown', (event) => {
  if (event.key === 'Escape') close()
})
</script>

<template>
  <!-- Inspired by https://dev.to/blainesensei/using-the-new-native-dialog-api-as-a-reusable-component-in-vue-3-4aoc -->
  <dialog
    ref="dialog"
    class="tabsViewOverlay design2024"
    :style="{
      // transform: `translateY(${visible ? 0 : 100}%)`,
      opacity: visible ? 1 : 0,
      pointerEvents: visible ? 'inherit' : 'none'
    }"
    @close="close"
  >
    <!-- Ensures the component isn't mounted before it's shown -->
    <div
      v-if="visible && config"
      class="slot-container "
      :class="props.classes && { [props.classes]: props.classes }"
    >
      <div class="closeButtonWrapper">
        <!-- Submit closes the form as per dialog spec, so no @click necessary  -->
        <IconButton
          icon="close"
          :buttonSize="36"
          :iconSize="24"
          class="closeButton"
          buttonColor="peach"
          @click="close"
          type="solid"
          style="width: 36px; height: 36px;"
        />
      </div>

      <slot>
        <component
          @close="close"
          :is="config.component"
        />
      </slot>
    </div>
  </dialog>
</template>

<style lang="scss" scoped>
.tabsViewOverlay {
  z-index: 100;
  width: 100%;
  height: 100%;
  position: absolute;
  transition: opacity .3s ease-out;
}

.design2024 {
  font-family: Poppins;
  font-size: 13px;
}

.tabsViewOverlay:focus {
  outline: none;
}

.closeButtonWrapper {
  position: sticky;
  z-index: 10;
  top: 0;
  left: 0;
  right: 0;
  display: flex;
  height: 0;
}

.closeButton {
  margin-top: 48px;
  margin-right: 48px;
  margin-left: auto;
}

.slot-container {
  position: relative;
  height: 100%;
  overflow-y: auto
}

@media (max-width: 768px) {
  .closeButton {
    margin-top: 24px;
    margin-right: 24px;
  }
}
</style>
