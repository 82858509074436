<script lang="ts">
import introJs from 'intro.js'
import { store } from './assets/js/store'
import TimeoutPromise from './util/timeout-promise'
import router from './assets/js/router'
import lassoLogoSvg from './assets/img/svg/lasso_logo_black.svg?raw'
import OnboardingQuestionaireModals from './components/modals/OnboardingQuestionaireModals.vue'
import Dialog from './components/Dialog.vue'
import { Vue, Component, Prop } from 'vue-property-decorator'
import Button from './components/Button.vue'
import { Route } from 'vue-router'
import Tab from './types/Tab.ts'
import Icon from '@/components/Icon.vue'

const onboardingQuestionaireDate = new Date('2023-10-23')

@Component({
  components: {
    Dialog,
    OnboardingQuestionaireModals,
    Button,
    Icon,
  },
})
export default class Onboarding extends Vue {
  // We can show the questionare and the dialogs while the portal is stil loading, but not the introjs tour, thus this prop
  @Prop({ type: Boolean, required: true })
  readonly portalLoading!: boolean
  @Prop({ type: Boolean, required: true })
  readonly isMobile!: boolean

  showAskUserAboutQuestionareDialog = false
  showOnboardingQuestionaire = false
  onboardingQuestionaireMode: 'existing-user' | 'new-user' | null = null
  showPortalTourDialog = false
  showPortalTourEndDialog = false
  tourLoading = false
  tourLogo = lassoLogoSvg

  mounted () {
    // You can uncomment one of these for testing
    // this.$watch(() => this.portalLoading, () => !this.portalLoading && this.showProspectingHint(false))
    // this.startTourFlow()
    // this.showOnboardingQuestionaire = true

    // "Fake" users shouldn't be shown anything onboarding related
    if (this.$store.state.readOnly || this.$store.state.direct || this.$store.state.impersonating)
      return

    const user = this.$store.state.user

    if ((this.$store.getters.isFreemium || user.organization.currentSolution.trial) && !user.settings.questionaire?.shown) {
      // the user is applicable to show the onboarding questionaire
      if (new Date(user.created).getTime() > onboardingQuestionaireDate.getTime()) this.onboardingQuestionaireMode = 'new-user'
      else this.onboardingQuestionaireMode = 'existing-user'
      this.showAskUserAboutQuestionareDialog = this.onboardingQuestionaireMode === 'existing-user'
      this.showOnboardingQuestionaire = true
    }

    // Show a tour of the new platform for existing users logging in for the first time
    if (this.$route.name === 'search') {
      const portalReleaseDate = new Date('2023-05-15')

      if ((this.$store.getters.isFreemium || new Date(user.created).getTime() < portalReleaseDate.getTime())
        && !user.settings.onboarding?.portal?.shown
        && !this.showOnboardingQuestionaire
        && !this.showAskUserAboutQuestionareDialog) {
        this.startTourFlow()
      }
      else if (this.$store.getters.isFreemium
        && !user.settings.prospecting?.hideIntro // If the user has already been through the prospecing tour, skip the hint
        && !user.settings.onboarding?.portal?.hideProspectingHint) {
        const unwatchHint = this.$watch(() => this.portalLoading, () => {
          if (!this.portalLoading) {
            this.showProspectingHint(false)
            unwatchHint()
          }
        })
      }

      const unwatchQuestionaire = this.$watch(() => this.showOnboardingQuestionaire, questionaireDone => {
        if (questionaireDone) return
        if (this.$store.getters.isFreemium || new Date(user.created).getTime() < portalReleaseDate.getTime()
          && !user.settings.onboarding?.portal?.shown) {
          this.startTourFlow()
          unwatchQuestionaire()
        }
      })
    }
  }

  async startTourFlow () {
    this.showPortalTourDialog = true

    this.tourLoading = true
    await new Promise<void>(resolve => {
      // Only start after page is done rendering so all the elements exist
      this.$watch(() => this.portalLoading, () => {
        if (!this.portalLoading) resolve()
      }, { immediate: true })
    })
    const mockSearchResult = { foundByName: "MOCKED FOR INTRO TUR", status: "Normal", lassoId: "CVR-1-34580820", name: "LASSO X A/S", entityType: "Company", address1: "Toldbodgade 37B", postalCode: 1253, city: "København K", country: "Danmark", score: 16687.295, websites: ["https://lassox.com"] }
    this.$store.state.lassoSettings.settings.portal.lastVisited.push(mockSearchResult)
    this.$store.state.sidebarPinned = true
    this.$store.dispatch('updateOnboardingTours', { 'portal': { shown: true } })
    // Don't distract with 'ringing' notification till after the tour
    this.$emit('tourStarted') // Emit the tourStarted event
  }

  async startIntroTour (): Promise<void> {

    this.tourLoading = false

    let sidebar: HTMLElement
    this.showPortalTourDialog = false

    let completeResolve: () => void
    const completeTourPromise = new Promise<void>(resolve => { completeResolve = resolve })

    const portalTour = introJs()
      .setOptions({
        tooltipClass: 'onboardingTooltip',
        nextLabel: 'Næste',
        doneLabel: 'Afslut',
        overlayOpacity: 0.2,
        hintButtonLabel: 'Ok',
        exitOnOverlayClick: false
      }).onbeforechange(
        async (element: HTMLElement) => {

          if (!element) return false

          // Section for programmatically triggering changes in the UI to show them off
          const changeName = element.dataset.onbeforechangename
          if (changeName === 'unpinSidebar') {
            store.state.sidebarPinned = false
            portalTour.setOption('helperElementPadding', 0)
            // Updates the size of the tour highlighter to match the changed width of the Sidebar, *after* the transition
            sidebar = element
            await new Promise<void>(resolve => element.addEventListener('transitionend', () => { portalTour.refresh(); resolve() }, { once: true }))
          }
          else if (!store.state.sidebarPinned && sidebar) {
            store.state.sidebarPinned = true
            portalTour.setOption('helperElementPadding', 10)
            // baffling sequence, but any refresh(true) needs to be done after sidebar pinned is rendered, and goToStep helps correct the position
            await new Promise(resolve => { requestAnimationFrame(resolve) })
            portalTour.refresh(true)
            sidebar.addEventListener('transitionend', () => portalTour.goToStep(portalTour.currentStep() + 1), { once: true })
          }

          // Make all past step-indicators orange
          const bullets = [ ...document.querySelectorAll<HTMLElement>('.introjs-bullets li a') ]
          bullets.forEach((bullet, i) => (i - 1) < portalTour.currentStep() ? bullet.dataset.done = 'true' : delete bullet.dataset.done)
          return true
        })
      .oncomplete(() => {
        store.state.sidebarPinned = true
        completeResolve()
      })

    // Ensure everything has had time to render before starting the tour
    await TimeoutPromise(100)
    await portalTour.start()
    await completeTourPromise

    this.showPortalTourEndDialog = true
  }


  endPortalTour () {
    this.showPortalTourEndDialog = false
    this.showProspectingHint(true)
    this.$emit('tourStarted')
    if (this.$store.state.lassoSettings.settings.portal.lastVisited[0]?.foundByName === "MOCKED FOR INTRO TUR")
      this.$store.state.lassoSettings.settings.portal.lastVisited = []
  }

  saveQuestionairePreference () {
    this.$store.dispatch('updateOnboardingQuestionaire', { shown: true })
    this.showOnboardingQuestionaire = false
    this.onboardingQuestionaireMode = null
  }

  showProspectingHint (skipPopup: boolean): void {
    introJs()
      .setOptions({ hintShowButton: true, hintButtonLabel: 'Skjul' })
      .addHints().then(intro => {
        intro.onhintclose(() => {
          store.dispatch('updateOnboardingTours', { 'portal': { hideProspectingHint: true } })
        })
        store.state.sidebarPinned
        if (skipPopup) {
          const hintbutton = document.querySelector<HTMLElement>('.introjs-hint')
          if (hintbutton)
            hintbutton.onclick = () => {
              intro.hideHints()
              store.dispatch('updateOnboardingTours', { 'portal': { hideProspectingHint: true } })
              store.dispatch('openNewTab', router.resolve({ name: 'app', params: { appName: 'prospecting' } }).route)
            }
        }

        // Hint is absolutely positioned, so it's looks ugly with the sidebar  pinned, so I remove it
        this.$watch(() => this.$store.state.sidebarPinned, (sidebarPinned: boolean) => {
          if (!sidebarPinned) {
            intro.hideHints()
          }
        })
        this.$watch(() => this.$store.state.modulesOpen, (modulesOpen: boolean) => {
          if (!modulesOpen) {
            intro.hideHints()
          }
        }, { immediate: true })
        // Hide the hint if they navigate to prospecting
        this.$watch(() => this.$store.state.activeTab, (activeTab: Tab) => {
          if (activeTab?.route?.params.appName === 'prospecting') {
            intro.hideHints()
            store.dispatch('updateOnboardingTours', { 'portal': { hideProspectingHint: true } })
          }
        }, { immediate: true })
      })
  }
}
</script>

<template>
  <div>
    <OnboardingQuestionaireModals
      v-if="showOnboardingQuestionaire"
      :mode="onboardingQuestionaireMode"
      :shouldAskUser="showAskUserAboutQuestionareDialog"
      @hideQuestionaire="saveQuestionairePreference"
      @continueQuestionaire="() => showAskUserAboutQuestionareDialog = false"
    />

    <Dialog
      v-if="showPortalTourDialog"
      title="This text will be replaced by the title slot below, but still needs to be truthy"
      :size="750"
      class="lassox-portal__tour-dialog"
      :onClose="() => showPortalTourDialog = false"
    >
      <template #title>
        <div
          class="lassox-portal__lassox-logo"
          v-html="tourLogo"
        />
        <h1 class="lassox-portal__tour-title">
          {{ $store.getters.isFreemium ? 'Velkommen til Lasso' : 'Velkommen til en ny og forbedret platform' }}
        </h1>
      </template>
      <template
        #content
        v-if="$store.getters.isFreemium"
      >
        Vi vil gerne hjælpe dig godt i gang. Derfor får du denne korte guide, som giver en kort intro til dine nye
        muligheder og navigationen herinde.
        <br><br>
        God fornøjelse.
      </template>
      <template
        #content
        v-else
      >
        Alt ser ikke helt ud, som det plejer... Vi har nemlig opgraderet platformen for at spare dig tid, give dig bedre
        overblik og mere fleksibilitet. Udover et nyt design får du fra i dag også:
        <br>
        <br>
        <ul>
          <li>
            <Icon
              :icon="import('@shared/icons/search.svg')"
              class="lassox-portal__tour-icon"
            />
            <b>En ny startside:</b> Et centralt søgefelt, så du kan komme hurtigt i gang
          </li><br>
          <li>
            <Icon
              :icon="import('@shared/icons/tab.svg')"
              class="lassox-portal__tour-icon"
            />
            <b>Faner:</b> Giver dig mulighed for at have flere forskellige sider åbne samtidig
          </li><br>
          <li>
            <Icon
              :icon="import('@shared/icons/left_click.svg')"
              class="lassox-portal__tour-icon"
            />
            <b>Nye genveje:</b> Find specifik viden med et klik direkte fra søgningen
          </li><br>
          <li>
            <Icon
              :icon="import('@shared/icons/person.svg')"
              class="lassox-portal__tour-icon"
            />
            <b>Personliggør siden: </b> Tilpas bl.a. genveje og værktøjer efter dit behov
          </li>
        </ul>
      </template>
      <template #actions>
        <Button
          :label="(portalLoading && tourLoading) ? 'Indlæser...' : isMobile ? 'Ok' : 'Se intro'"
          type="primary"
          :disabled="portalLoading && tourLoading"
          @click="isMobile ? (showPortalTourDialog = false, endPortalTour()) : startIntroTour();"
        />
      </template>
    </Dialog>
    <Dialog
      v-if="showPortalTourEndDialog"
      title="This text will be replaced by the title slot below, but still needs to be truthy"
      :size="650"
      dismissible
      class="lassox-portal__tour-end-dialog"
      :onClose="endPortalTour"
    >
      <template #title>
        <h1 class="lassox-portal__end-tour-title">
          Det var det!
        </h1>
      </template>
      <template #content>
        {{ $store.getters.isFreemium
          ? 'Vi håber, denne korte intro gav dig lidt overblik. Nu kan du bare slå dig løs og bruge platformen som du vil. Og bare rolig - du kan ikke gøre noget forkert, og hvis du har brug for hjælp, står vi selvfølgelig klar til at hjælpe og lytte til feedback.'
          : 'Vi håber, denne korte intro gav dig lidt overblik. Skulle du have brug for yderligere hjælp, til at komme godt i gang med vores nye funktioner, kan du se mere herunder.'
        }}
        <br>
        <br>
        God fornøjelse 🎉
        <div class="lassox-portal__further-help">
          <div>
            <Icon
              :icon="import('@shared/icons/description.svg')"
              size="small"
              class="lassox-portal__end-tour-icon"
            />
            Se <a
              :href="$store.getters.isFreemium
                ? 'https://support.lassox.com/help/lassos-gratis-losning'
                : 'https://support.lassox.com/help/den-nye-og-forbedrede-lasso-platform'"
              target="_blank"
            >hjælpemateriale</a>
          </div>
          <div>
            <Icon
              :icon="import('@shared/icons/email_outlined.svg')"
              size="small"
              class="lassox-portal__end-tour-icon"
            />
            Kontakt <a @click="$emit('open-helpdesk', '/contact')">support</a> eller <a href="tel:71747812">ring til os</a>
          </div>
        </div>
      </template>
      <template #actions>
        <Button
          label="Afslut introduktion"
          type="primary"
          class="lassox-portal__end-tour-button"
          @click="endPortalTour()"
        />
      </template>
    </Dialog>
  </div>
</template>


<style lang="less">
.lassox-portal__tour-dialog,
.lassox-portal__tour-end-dialog {
  font-size: 16px;
  font-weight: 400;
  line-height: 24px;

  b {
    font-weight: 600;
  }

  .lassox-portal__Dialog-box {
    background-color: #FEF9F8;

    & * {
      z-index: 1;
    }

    &::after {
      background: url('./assets/img/freemium-blob.png') no-repeat;
      content: "";
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      z-index: 0;
      background-size: 100%;
      background-position: top left;
      opacity: 0.6;
    }
  }

  .lassox-portal__lassox-logo {
    height: 20px;
    color: black;

    >svg {
      width: 100%;
      height: 100%;
    }
  }

  .lassox-portal__Dialog-header {
    padding: 0;
  }

  .lassox-portal__Dialog-title {
    margin: 22px 0 22px 0;
    font-size: 22px;
    white-space: normal;

    .lassox-portal__tour-title {
      text-align: center;
    }

    .lassox-portal__end-tour-title {
      text-align: left;
    }

    @media only screen and (min-width: 768px) {
      .lassox-portal__tour-title {
        margin: 52px 0 32px 0;
      }
    }
  }

  .lassox-portal__Dialog-box {
    padding: 32px;
  }

  @media only screen and (min-width: 768px) {
    &:not(.lassox-portal__tour-end-dialog) .lassox-portal__Dialog-box {
      padding: 32px 88px 32px 88px;
    }
  }

  .lassox-portal__Dialog-content {
    margin: 0;

    .lassox-portal__Dialog-content-inner {
      padding: 0;
    }
  }

  .lassox-portal__further-help {
    margin-top: 32px;
    font-size: 14px;

    &>div:first-child {
      padding-bottom: 16px;
    }

    a {
      color: #FFA07A;

      &:hover {
        color: #FFA07A;
        text-decoration: underline;
      }
    }
  }

  .lassox-portal__tour-icon {
    margin-right: 12px;
  }

  .lassox-portal__end-tour-icon {
    margin-right: 8px;
    margin-top: 3px;
  }

  .lassox-portal__Dialog-actions:not(:first-child) {
    margin: 0;
  }

  .lassox-portal__Dialog-actions {
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 32px 0 0 0;
    font-weight: 500;
  }

  @media only screen and (min-width: 768px) {
    .lassox-portal__Dialog-actions {
      margin: 32px 0 0 0;
    }
  }
}

.lassox-portal__tour-end-dialog {
  .lassox-portal__Dialog-box::after {
    opacity: 0.3;
    transform: scaleY(-1);
  }

  .lassox-portal__Dialog-box .lassox-portal__Dialog-actions {
    display: flex;
    justify-content: flex-end;
    font-weight: 500;
  }
}


// Tour library styles section, the classes are mostly not defined by us
.introjs-helperLayer {
  // Remove default black border around highlighted element
  box-shadow: rgba(33, 33, 33, 0.2) 0px 0px 0px 5000px !important;

  &.widen-to-plus {
    padding-right: 48px;
  }
}

.introjs-hintReference.introjs-tooltipReferenceLayer {
  .introjs-tooltip {
    .introjs-button {
      // +10 since there's no outer padding here
      padding-bottom: 17px;
    }
  }
}

.introjs-tooltipReferenceLayer .introjs-tooltip {
  border-radius: 16px;
  width: 350px;
  font-family: Roboto !important;
  display: grid !important;
  grid-template-columns: 1fr 1fr;

  &.top10 {
    top: 10px;
  }

  .introjs-skipbutton {
    display: none;
  }

  .introjs-disabled {
    visibility: hidden;
  }

  .introjs-arrow {
    border: 8px solid transparent;

    &.top {
      // -10px normally, but that clashes with our rounder corners
      top: -14px;
      border-bottom-color: white;
    }

    &.left {
      left: -14px;
      border-right-color: white;

    }

    &.right {
      right: -14px;
      border-left-color: white;

    }

    &.bottom,
    &.bottom-middle {
      bottom: -14px;
      border-top-color: white;
    }
  }

  // Compensating for the arrowsmovement above, making it centered on it's target.
  &.introjs-bottom-left-aligned {
    left: -5px !important;
  }

  &.introjs-right {
    top: -5px !important;
  }

  &.introjs-top {
    right: -5px !important;
  }

  &.introjs-right {
    top: -5px !important;
  }

  .introjs-tooltip-header {
    padding: 16px 24px 0 24px;
    grid-column: span 2;

    .introjs-tooltip-title {
      font-weight: 500;
    }
  }

  .introjs-tooltiptext {
    grid-column: span 2;
    padding: 20px 20px 0 20px;
  }

  .introjs-tooltiptext,
  .introjs-button,
  .introjs-tooltip-title {
    font-size: 14px;
    font-family: Roboto, Helvetica, Arial, sans-serif;
  }

  .introjs-button {
    padding: 0;
    border: 0;
    border-radius: 9999px;
    color: #ff8e61;
    background: #fff;
    font-weight: 500;
    box-shadow: 0 0 0 0;

    &.introjs-prevbutton {
      display: none;
    }
    &.introjs-nextbutton {
      padding: 4px 16px;
      margin: 8px 0;
      margin-right: 14px;
      color: white;
      text-shadow: none;
      background: #ff8e61;
      transform: scale(1);
      transition: transform 450ms cubic-bezier(0, 0, 0.2, 1);
      will-change: transform;

      &:hover {
        transform: scale(0.95);
      }
    }
  }

  // Hint tooltip
  .introjs-tooltiptext .introjs-button {
    color: #FFA07A;
    float: right;
    font-family: Roboto, Helvetica, Arial, sans-serif;

  }

  .introjs-tooltipbuttons {
    border: 0;
    // margin-top: -20px;
  }

  .introjs-bullets {
    padding: 0 0 0 20px;
    // margin-top: -20px;
    line-height: 10px;
    align-self: center;
    text-align: left;

    ul li {
      margin: 0 4px;

      a {
        height: 10px;
        width: 10px;
      }

      a.active,
      a[data-done] {
        background: #FFA07A;
      }
    }
  }
}

.introjs-hints .introjs-hint {

  .introjs-hint-pulse,
  .introjs-hint-dot {
    background-color: rgb(255, 160, 122);

    &:hover {
      background-color: #FF8E61;
    }
  }

  &.hide-hint {
    visibility: hidden;
  }
}

@keyframes introjspulse {
  0% {
    transform: scale(.95);
    box-shadow: 0 0 0 0 rgba(255, 160, 122, 7);
  }

  70% {
    transform: scale(1);
    box-shadow: 0 0 0 10px transparent;
  }

  100% {
    transform: scale(.95);
    box-shadow: 0 0 0 0 transparent;
  }
}

.lassox-portal__App {
  &-inner {
    position: relative;
    z-index: 1;
    display: flex;
    width: 100%;
    height: 100%;
    overflow: hidden;
  }

  &-tab-view-wrapper {
    position: relative;
    z-index: 1;
    width: 100%;
    height: 100%;
    min-height: 0;
    background: white;
    overflow: hidden;
    // box-shadow: rgba(0, 0, 0, 0.08) 0px 0px 24px 0px;
  }

  &-dialogs,
  &-menus {
    position: relative;
    z-index: 1;
  }
}</style>