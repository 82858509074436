<template>
  <div class="dropdown" @blur="onBlur" :tabindex="tabIndex">
    <span @click="conditionalToggle ? onToggle() : null" class="toggle">
      <slot name="toggle" :open="open"></slot>
    </span>
    <div
      v-show="open && !disabled" class="menu"
      :class="{ left: left, 'right': right, 'right-top': rightTop, padding: !noPadding }"
      :style="styles">
      <slot :open="open"></slot>
    </div>
  </div>
</template>

<script>
  export default {
    name: 'dropdown',
    props: {
      disabled: { type: Boolean, default: false },
      EventBus: { type: Object },
      text: { type: String, required: false, default: 'Toggle' },
      minWidth: { type: [Number, String] },
      width: { type: [Number, String] },
      conditionalToggle: { type: Boolean, default: true },
      left: Boolean,
      right: Boolean,
      rightTop: Boolean,
      noPadding: Boolean,
      tabIndex: { type: Number, default: 100 },
      isOpen: { type: Boolean, default: false }
    },
    data () {
      return {
        open: false
      }
    },
    computed: {
        styles() {
            let styles = []
            if (this.minWidth) styles.push({ minWidth: this.minWidth + (!isNaN(this.minWidth) ? 'px' : '') })
            if (this.width) styles.push({ width: this.width + (!isNaN(this.width) ? 'px' : '') })
            return styles
        }
    },
    methods: {
      onBlur () {
      },
      onToggle () {
        console.debug('onToggle', this.open)
        this.open = !this.open
      },
      onOutsideClick (event) {
        if (this.open && this.$el && (event.type === 'blur' || !this.$el.contains(event.target))) {
          this.open = false
        }
      }
    },
    watch: {
      open (isOpen) {
        if (isOpen) this.$emit('opened')
        else this.$emit('closed')
      },
      $route () {
        this.open = false
      },
      isOpen: {
        handler (open) {
          this.open = open
        },
        immediate: true
      }
    },
    mounted () {
      if (this.EventBus) this.EventBus.$on('app-click', this.onOutsideClick)
      else {
        if (!this.$root.outsideClickHandler) {
          this.$root.outsideClickHandler = event => this.$root.$emit('window-click', event)
          window.addEventListener('click', this.$root.outsideClickHandler)
        }
        this.$root.$on('window-click', this.onOutsideClick)
      }
      this.$on('close', () => { this.open = false })
    },
    beforeDestroy () {
      if (this.EventBus) this.EventBus.$off('app-click', this.onOutsideClick)
      else this.$root.$off('window-click', this.onOutsideClick)
    }
  }
</script>

<style lang="less">
  .dropdown {
    display: inline-block;
    position: relative;
    &:focus, &:active {
      outline: none;
    }
    .disabled {
      cursor: default !important;
    }
    .toggle {
      cursor: pointer;
      .dropdown-indicator {
        padding-left: 15px;
      }
    }
    .menu {
      box-shadow: 0 5px 10px 1px rgba(50,50,50,.1);
      position: absolute;
      top: 100%;
      left: 0;
      z-index: 999;
      background-color: #fff;
      border-radius: 0 0 2px 2px;
      &.padding {
        padding: 10px 0;
      }
      .dropdown-item {
        font-size: 12px;
        line-height: 32px;
        color: #000;
        padding: 0 16px;
        cursor: pointer;
        &:active {
          background-color: #f8f8f8;
        }
        &.disabled {
          color: #777;
        }
      }

      .dropdown-description {
        padding: 8px 16px;
        font-size: 12px;
        color: #777;
        &.with-icon {
          display: flex;
          align-items: center;
          i {
            padding-right: 15px;
          }
        }
      }

      .dropdown-divider::after {
        content: '';
        height: 1px;
        margin: 10px 0;
        border-top: 1px solid #dfdfdf;
        display: block;
      }

      .dropdown-divider.small::after {
        margin: 0;
      }

      &.left {
        right: 0;
        left: auto;
      }

      &.right {
        left: 100%;
        right: 0;
      }
      &.right-top {
        left: 100%;
        top: 0;
      }

      // .dropdown-item & .dropdown {
      //   position: relative;
      //   .menu {
      //     position: absolute;
      //     right: 100%;
      //     top: 0;
      //   }
      // }
    }
  }
</style>
