<script setup lang="ts">
import { computed, defineProps, ref, watchEffect } from 'vue'
const props = withDefaults(defineProps<{
  size?: 'xs' | 'small' | 'normal' | 'large'| 'larger'
  icon: string
  hoverable?: boolean
}>(), { size: 'normal' })

const icon = ref('')

const icons = import.meta.glob('@shared/icons/*.svg', { as: 'raw', eager: true })

const svg = computed(() => {
  const theKey = Object.keys(icons).find(key => key.endsWith(`/${props.icon}.svg`))
  if (!theKey) return ''
  return icons[theKey]
})
</script>

<template>
  <div
    :class="`md-icon is-${size} ${hoverable ? 'hoverable' : ''}`"
    v-html="svg"
  />
</template>

<style lang="scss" scoped>
@import "@shared/style/global.scss";

$sizes: (
  xs: 14px,
  small: 18px,
  smallish: 20px,
  normal: 24px,
  large: 36px,
  larger: 48px,
);

.md-icon {
  display: inline-block;
  color: getTextColor('medium');
  vertical-align: top;

  &.hoverable:hover {
    color: getTextColor('light');
  }

  &::v-deep > svg {
    vertical-align: top;
    fill: currentColor;
  }

  @each $name, $size in $sizes {
    &.is-#{$name} {
      line-height: $size;

      &::v-deep > svg {
        width: $size;
        height: $size;
      }
    }
  }
}
</style>