<template>
  <div class="spin-loader"></div>
</template>

<script>
  export default {
    
  }
</script>

<style lang="less">
  .spin-loader {
    border: 2px solid #f3f3f3;
    border-top: 2px solid #CD4B3D;
    border-radius: 50%;
    width: 15px;
    height: 15px;
    animation: spin .5s linear infinite;
  }
  @keyframes spin {
    0% { transform: rotate(0deg); }
    100% { transform: rotate(360deg); }
  }
</style>