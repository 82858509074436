<script lang="ts">
import { Vue, Component, Prop } from 'vue-property-decorator'
import Icon from '@/components/Icon.vue'
import Draggables from '@/components/Draggables.vue'
import BaseButton from '@/components/BaseButton.vue'
import TagIcon from '@/components/TagIcon.vue'
import { Group, Item } from '@/models/draggable-items'
import LassoSettings from '../util/LassoSettings'
import Tag from '@/types/Tag'
import stripLeadingEmojiFromText from '@/util/stripLeadingEmojiFromText'

@Component({
  components: {
    Draggables,
    Icon,
    BaseButton,
    TagIcon,
  }
})

export default class DraggableTags extends Vue {
  @Prop({ type: Array, required: true })
  readonly tags!: any[]

  @Prop({ type: Function, required: true })
  readonly onTagClick!: (tag: any) => void

  @Prop({ type: String, required: true })
  readonly tagType!: 'company' | 'person'

  collapsed = true

  itemCache: Partial<Record<string, Item>> = {}

  groups: Group[] = [
    {
      id: 'visible',
      hideLabel: true,
      items: [],
    },
    {
      id: 'hidden',
      text: 'Skjult',
      hidden: true,
      items: [],
    },
  ]

  stripLeadingEmojiFromText = stripLeadingEmojiFromText

  get tagsCacheString () { return JSON.stringify(this.tags.map(tag => tag.id)) }
  get localTagsCacheString () { return JSON.stringify(this.groups.flatMap(group => group.items.map(item => item.id))) }
  get groupsCacheString () { return JSON.stringify(this.groups.map(group => group.items.map(item => item.id))) }

  get tagsById () { return new Map(this.tags.map(tag => [ tag.id, tag ])) }

  get lassoSettings (): LassoSettings { return this.$store.state.lassoSettings }

  get hiddenGroup () { return this.groups[1] }

  get hasHiddenTags () { return !!this.hiddenGroup.items.length }

  getTagItem (tag: Tag) {
    let item = this.itemCache[tag.id]

    if (!item) {
      const { tagType } = this

      item = {
        id: tag.id,
        get icon () { return tag.followTag ? tagType === 'company' ? 'company_rss_outline_rounded' : 'person_rss_outline_rounded' : undefined },
        get text () { return tag.name.trim() },
        deletable: false,
        onClick: () => this.onTagClick(tag),
      }
    }

    return item
  }

  onDragStart () {
    this.collapsed = false
  }

  onDragStop () {
    this.$nextTick(() => {
      if (!this.hasHiddenTags) this.collapsed = true
    })
  }

  getTagById (tagId: string) {
    return this.tagsById.get(tagId)
  }

  mapDraggablesToSettings (draggables: Group[]) {
    const settings: Record<'visible' | 'hidden', Set<string>> = {
      visible: new Set,
      hidden: new Set
    }

    draggables.forEach(g => {
      g.items.forEach(i => settings[g.id as 'visible' | 'hidden'].add(i.id))
    })

    return settings
  }

  created () {
    this.$watch(
      () => this.collapsed,
      collapsed => this.hiddenGroup.hidden = collapsed,
      { immediate: true },
    )

    this.$watch(
      () => this.tags,
      () => {
        const newItemCache: DraggableTags['itemCache'] = {}

        for (const tag of this.tags) {
          newItemCache[tag.id] = this.getTagItem(tag)
        }

        this.itemCache = newItemCache
      },
      { immediate: true },
    )

    this.$watch(
      () => this.tagsCacheString,
      () => {
        if (!this.tags) return
        if (this.localTagsCacheString === this.tagsCacheString) return

        const newGroups: Group[] = []

        for (const group of this.groups) {
          const newItems: Item[] = []

          for (const id of this.lassoSettings.settings.portal.tagsOrder[this.tagType][group.id as 'visible' | 'hidden']) {
            const tag = this.tagsById.get(id)
            if (!tag) continue

            newItems.push(this.getTagItem(tag))
          }

          newGroups.push({ ...group, items: newItems })
        }

        this.groups = newGroups
      },
      { immediate: true },
    )

    this.$watch(
      () => this.groupsCacheString,
      () => {
        this.lassoSettings.settings.portal.tagsOrder[this.tagType] = this.mapDraggablesToSettings(this.groups)
        this.lassoSettings.updateSettings()
      },
    )
  }
}
</script>

<template>
  <div class="lassox-portal__DraggableTags">
    <Draggables
      class="lassox-portal__DraggableTags-draggables"
      v-model="groups"
      :readOnly="$store.state.readOnly"
      :spacing="4"
      :reserveSpaceAfterGroups="false"
      :showOnlyGroupOnDrag="true"
      @dragStart="onDragStart"
      @dragStop="onDragStop"
    >
      <template #leading="props">
        <TagIcon
          :tag="getTagById(props.item.originalItem.id)"
          :type="tagType"
        />
      </template>

      <template #content="value">
        <div
          class="lassox-portal__DraggableTags-draggables-draggable"
          v-text="stripLeadingEmojiFromText(value.text).trim()"
        />
      </template>
    </Draggables>

    <BaseButton
      v-if="hasHiddenTags"
      class="lassox-portal__DraggableTags-show-more-btn"
      @click="collapsed = !collapsed"
    >
      <Icon
        :icon="collapsed ? 'expand_more_rounded' : 'expand_less_rounded'"
        size="small"
      />

      <span v-text="collapsed ? 'Flere' : 'Færre'" />
    </BaseButton>
  </div>
</template>

<style lang="scss">
.lassox-portal__DraggableTags {
  display: flex;
  flex-direction: column;
  align-items: stretch;

  &-draggables {
    .lassox-portal__Draggable-text {
      color: #262626;
      transition: color 100ms;

      &:hover {
        color: #ffa07a;
      }
    }

    &-draggable {
      display: flex;
      align-items: center;
      font-size: 14px;
      line-height: 16px;
      font-weight: 500;

      &-icon {
        display: flex;
        justify-content: center;
        align-items: center;
        height: 18px;
        width: 18px;
        font-size: 18px;

        &-circle {
          font-size: 10px;
          font-weight: 700;
          border: 1px solid #616161;
          border-radius: 50%;
          line-height: 18px;
        }
      }
    }
  }

  &-show-more-btn {
    display: flex;
    justify-content: flex-start;
    align-items: center;
    height: 32px;
    padding: 8px;
    margin: -8px;
    margin-top: 8px + 4px;
    border-radius: 8px;

    > span {
      color: #262626;
      font-weight: 500;
    }

    .md-icon {
      margin-right: 8px;
    }
  }
}
</style>
