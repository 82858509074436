<script lang="ts">
import { Vue, Component, Prop } from 'vue-property-decorator'
import Icon from './Icon.vue'

@Component({
  components: {
    Icon,
  },
})

export default class IconButton extends Vue {
  @Prop(String)
  readonly icon?: string

  @Prop({ type: String, default: 'normal' })
  readonly size!: 'normal' | 'small'

  @Prop(Boolean)
  readonly isLink?: boolean

  @Prop(Boolean)
  readonly isRed?: boolean
}
</script>

<template>
  <component
    :is="isLink ? 'a' : 'button'"
    :class="[
      'icon-button',
      `is-${size}`,
      isRed && 'is-red',
    ]"
    @click="$emit('click', $event)"
  >
    <Icon
      :icon="icon"
      :size="size"
    />
  </component>
</template>

<style lang="scss" scoped>
@import "@shared/style/global.scss";

.icon-button {
  line-height: 1;
  vertical-align: top;
  border-radius: 50%;
  color: getTextColor('medium');
  transition: color 100ms, background-color 100ms, opacity 100ms;
  -webkit-tap-highlight-color: transparent;

  & > .md-icon {
    color: inherit;
  }

  &.is-small {
    padding: 3px;
  }

  &.is-normal {
    padding: 6px;
  }

  &:hover {
    background-color: rgba(black, 0.04);
  }

  &:focus {
    background-color: rgba(black, 0.08);
    outline: none;
  }

  &:active {
    background-color: rgba(black, 0.16)
  }

  &.is-red {
    $color: getTextColor('primary');

    color: $color;

    &:hover {
      background-color: rgba($color, 0.08);
    }

    &:focus {
      background-color: rgba($color, 0.16);
    }

    &:active {
      background-color: rgba($color, 0.32);
    }
  }
}
</style>
