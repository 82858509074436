<script>
import Vue from 'vue'
import lasso from '@oldShared/lasso/sdk/lasso-app-sdk'

function getAttributeNames (node) {
  const attributes = []
  for (const attr of node.attributes) {
    attributes.push(attr)
  }
  return attributes
}

export default Vue.extend({
  functional: true,

  props: {
    text: { type: String },
    html: { type: String },
    createLinks: { type: Boolean, default: true },
    onClickLink: { type: Function, required: false },
  },

  render (createElement, ctx) {
    const createTextVnode = ctx._v

    const createLinkVnode = ({ lassoId, name }) => ctx.props.createLinks
      ? createElement('a', {
        attrs: { href: '#' },
        on: { click: (e) => {
          e.preventDefault()
          if (ctx.props.onClickLink) {
            ctx.props.onClickLink({ lassoId, name })
          } else {
            // @ts-ignore
            if (lassoId.toLowerCase().indexOf('cvr-1-') === 0) lasso.companies.show(lassoId)
            // @ts-ignore
            else if (lassoId.toLowerCase().indexOf('cvr-3-') === 0) lasso.people.show(lassoId)
          }
        } },
      }, name)
      : createTextVnode(name)

    const linkifyString = string => {
      const vnodes = []

      while (true) {
        const match = string.match(/{([^{|]+)\|(CVR-[0-9]-[0-9]+)}/) // Original: {(CVR-[0-9]-[0-9]+)\|([^}]+)}

        if (match === null || match.index === undefined) {
          vnodes.push(createTextVnode(string))
          break
        }

        vnodes.push(createTextVnode(string.substring(0, match.index)))
        vnodes.push(createLinkVnode({ lassoId: match[2], name: match[1] }))
        string = string.substring(match.index + match[0].length)
      }

      return vnodes
    }

    const mapNodes = nodes => nodes.flatMap(node => {
      switch (node.nodeType) {
        case Node.ELEMENT_NODE: {
          return createElement(
            node.tagName,
            { attrs: Object.fromEntries(getAttributeNames(node).map(attr => [ attr.name, node.getAttribute(attr.name) ])) },
            mapNodes([ ...node.childNodes ]),
          )
        }

        case Node.TEXT_NODE: {
          return linkifyString(node.textContent)
        }
      }
    })

    if (ctx.props.text) return linkifyString(ctx.props.text)

    if (ctx.props.html) {
      const div = document.createElement('div')
      div.innerHTML = ctx.props.html
      return mapNodes([ ...div.childNodes ])
    }

    return []
  },
})
</script>
