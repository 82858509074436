<script>
import { Tags, EventBus, signOut } from '@/assets/js/helpers.js'
import Auth from '@/assets/js/auth.js'
import { find } from 'lodash'
import PremiumFeatureOverlay from './overlays/PremiumFeatureTrialOverlay.vue'
import { showTabOverlay } from '@/util/overlay.ts'
export default {
  name: 'AppView',
  data () {
    return {
      appWindow: null,
      currentTrigger: null
    }
  },
  props: {
    appType: {
      type: String,
      required: true
    },
    menu: {
      type: Boolean,
      default: true
    }
  },
  components: {
    PremiumFeatureOverlay
  },
  computed: {
    tab () {
      return this.$parent.tab
    },
    route () {
      return this.tab.route
    },
    changeTrigger () {
      return this.appType === 'standalone' || !this.route.params.lassoId ? this.route.params.appName + (this.route.params.appParams ? JSON.stringify(this.route.params.appParams) : '') : this.route.params.lassoId
    },
    lassoAppConfig () {
      return this.appType === 'standalone' || !this.route.params.lassoId ? this.standaloneConfig : this.entityConfig
    },
    isPlace () {
      return this.route.params.lassoId ? this.route.params.lassoId.toLowerCase().indexOf('cvr-2') === 0 : false
    },
    standaloneConfig () {
      let appParams = {}
      if (this.route.params.appParams) {
        appParams[this.route.params.appName] = { ...this.route.query, ...JSON.parse(this.route.params.appParams) }
      } else {
        appParams[this.route.params.appName] = { ...this.route.query }
      }
      return {
        appType: 'standalone',
        initialApp: this.route.params.appName,
        appParameters: appParams || null,
        appDependencies: { pep: this.$store.state.pep }, // skal være indlæst fra API selvfølgelig
        organizationId: '',
        organizationUrl: '',
        userId: '',
        userCreated: this.$store.state.user.created,
        skipEntityConversion: true,
        mappings: {
          company: [],
          person: []
        },
        padding: false,
        menu: false,
        pushEnabled: !this.$store.state.direct,
        bindings: {
          'list:add': this.$store.state.readOnly || this.isPlace || this.$store.state.productAccess ? false : this.addToList,
          'show:company': this.showEntity,
          'show:person': this.showEntity,
          'create:list': this.$store.state.readOnly || this.$store.state.productAccess ? false : this.createList,
          'search:list': this.searchLists,
          'trigger:refreshList': this.refreshList,
          'trigger:refreshApps': this.refreshApps,
          'trigger:signOut': this.signOut,
          /** @deprecated Use premiumFeature instead.  */
          'trigger:bookDemo': this.premiumFeature,
          'trigger:premiumFeature': this.premiumFeature,
          'trigger:trackEvent': this.trackEvent,
          'trigger:startImpersonation': this.startImpersonation,
          'trigger:refreshPortal': this.refreshPortal,
        },
        userData: {
          user: this.$store.state.user,
          claims: this.$store.state.claims,
          apps: this.$store.state.apps,
          features: this.$store.state.features,
          token: Auth.getToken()
        }
      }
    },
    entityConfig () {
      let appParams = {}
      if (this.route.params.appParams)
        appParams[this.route.params.appName] = { ...this.route.query, ...JSON.parse(this.route.params.appParams) }
      else
        appParams[this.route.params.appName] = { ...this.route.query }
      return {
        appType: this.appType,
        appParameters: appParams,
        appDependencies: { pep: this.$store.state.pep }, // skal være indlæst fra API selvfølgelig
        initialApp: this.route.params.appName || '',
        entityType: this.route.params.lassoId.toLowerCase().indexOf('cvr-1') === 0 ? 'company' : this.isPlace ? 'place' : 'person',
        entityId: this.route.params.lassoId,
        entity: this.$store.state.entities[this.route.params.lassoId] || {},
        lassoId: this.route.params.lassoId,
        organizationId: '',
        organizationUrl: '',
        userId: '',
        userCreated: this.$store.state.user?.created ?? null,
        skipEntityConversion: true,
        // For freemium, show apps in same order on person and company, and disabled/tease apps last
        configurations: this.$store.getters.isFreemium ? { appOrder: [ 'dashboard', 'information', 'cvrkeyfigures', 'news', 'ownershipdiagram', 'history', 'twinfinder', 'cvrnetwork' ] } : null,
        mappings: {
          company: [],
          person: []
        },
        padding: true,
        menu: this.menu,
        pushEnabled: !this.$store.state.direct,
        bindings: {
          'list:add': this.$store.state.readOnly || this.isPlace || this.$store.state.productAccess ? false : this.addToList,
          'show:company': this.showEntity,
          'show:person': this.showEntity,
          'create:list': this.$store.state.readOnly || this.$store.state.productAccess ? false : this.createList,
          'search:list': this.searchLists,
          'trigger:refreshList': this.refreshList,
          'trigger:refreshApps': this.refreshApps,
          'trigger:signOut': this.signOut,
          'trigger:openApp': this.openApp,
          'trigger:searchAddress': this.$store.state.direct && this.route.query.search === undefined ? false : this.searchAddress,
          /** @deprecated trigger:bookDemo, remove if you're sure it's not used in *any* app. */
          'trigger:bookDemo': this.premiumFeature,
          'trigger:premiumFeature': this.premiumFeature,
          'trigger:trackEvent': this.trackEvent,
          'trigger:startImpersonation': this.startImpersonation,
          'trigger:refreshPortal': this.refreshPortal,
        },
        userData: {
          user: this.$store.state.user,
          claims: this.$store.state.claims,
          apps: this.$store.state.apps,
          features: this.$store.state.features,
          token: Auth.getToken()
        }
      }
    }
  },
  methods: {
    bootstrap: function () {
      if (this.appWindow) this.appWindow.update(this.lassoAppConfig)
      else this.appWindow = window.lasso.bootstrap(this.$refs.appWindow, this.lassoAppConfig)
    },
    addToList (respondable) {
      var data = respondable.request.data
      Tags.getAll().then(tags => {
        EventBus.$emit('modal', { modalType: 'tagManager', tagType: data.entityType, entities: [ { lassoId: data.entityId, entityType: data.entityType, tags: tags} ], onDone: () => { respondable.respond() }, onCancel: () => { respondable.fail() } })
      })
    },
    showEntity (respondable) {
      this.$store.dispatch('findMatchingTab', (tab) => (
        tab.route.params?.lassoId === respondable.request.data.id
      )).then(tab => {
        if (tab) {
          this.$store.state.activeTab = tab
        } else {
          this.$store.dispatch('openNewTabRelatively', this.$router.resolve({ name: 'entity', params: { lassoId: respondable.request.data.id, appName: respondable.request.config?.appName || null, appParams: JSON.stringify(respondable.request.config?.appParams || {}) }, query: this.route.query }).route)
        }
      })
    },
    createList (respondable) {
      var list = respondable.request.data.entity
      if (list && list.name && list.name.length > 0) {
        this.$store.dispatch('createTag', { tagName: list.name, tagType: list.entityType }).then(newTag => {
          respondable.respond({ name: newTag.name, id: newTag.id, entityType: newTag.type })
        }, error => respondable.fail(error))
      } else respondable.fail('Invalid list provided')
    },
    searchLists (respondable) {
      if (respondable.request.data && respondable.request.data.query && respondable.request.data.query.entityTypes) {
        var types = respondable.request.data.query.entityTypes.map(t => t.toLowerCase())
        respondable.respond(this.$store.getters.tags.filter(t => !!find(types, type => type === t.type.toLowerCase())).map(t => ({ name: t.name, entityType: t.type.toLowerCase(), id: t.id })))
      } else respondable.fail('Invalid query object provided. Must contain property "entityTypes"')
    },
    refreshList (respondable) {
      this.$store.commit('invalidateTag', respondable.request.data)
    },
    refreshApps (respondable) {
      this.$store.dispatch('updateApps').then(respondable.respond)
    },
    signOut (respondable) {
      signOut(this.$store.state.user, this.$store.state.readOnly)
    },
    openApp (respondable) {
      const route = this.$router.resolve({ name: this.route.name, params: { appName: respondable.request.data }, query: this.route.query }, this.route).route

      if (this.appType === 'standalone') {
        this.$store.dispatch('openNewTabRelatively', route)
      } else {
        if (route.params.appName === this.route.params.appName) return
        this.tab.pushRoute(route)
      }
    },
    searchAddress (respondable) {
      let { street, streetNo, postalCode, city } = respondable.request.data
      this.$store.dispatch('openNewTabRelatively', this.$router.resolve({ name: 'search', params: { address: `street:${street} streetNo:${streetNo} postalCode:${postalCode} city:${city}` } }).route)
    },
    premiumFeature(respondable) {
      showTabOverlay({ component: PremiumFeatureOverlay, ...respondable.request.data })
    },
    trackEvent (respondable) {
      EventBus.$emit('june:trackEvent', respondable.request.data)
    },
    refreshPortal (respondable) {
      this.$store.dispatch('reload', respondable.request.data)
    },
    startImpersonation (respondable) {
      this.$store.dispatch('impersonate', respondable.request.data)
    },
    refreshPage (respondable) {
      document.location.reload()
    }
  },
  watch: {
    'route.params.appName' (appName) {
      // the app has changed, not the entity. Update window. Do nothing if not entityview, e.g. standalone view.
      if (this.appType === 'entityview' && this.route.name === 'entity' && this.appWindow && appName) {
        if (this.route.params.update) this.bootstrap()
        else this.appWindow.navigate(appName)
      }
    },
    'changeTrigger': {
      immediate: true,
      handler (trigger, previousTrigger) {
        this.$nextTick().then(() => {
          if (trigger && trigger === this.currentTrigger) return
          switch (this.appType) {
            case 'standalone':
              if (this.route.name === 'app') {
                this.currentTrigger = trigger
                this.bootstrap()
              }
              break
            case 'entityview':
              if (this.route.name === 'entity') {
                this.currentTrigger = trigger
                this.bootstrap()
              }
              break
          }
        })
      }
    }
  },
  updated () {
    if (this.$store.state.activeTab.id === this.tab.id) this.$nextTick(() => this.bootstrap())
  },
  mounted () {
    // push all push-messages to app window in order for app to listen to them.
    EventBus.$on('push-message', msg => {
      if (this.appWindow) {
        this.appWindow.push(msg)
      }
    })
  }
}
</script>

<template>
  <div class="appView">
    <iframe
      ref="appWindow"
      src=""
      border="0"
      allow="clipboard-write *;"
    />
  </div>
</template>

<style lang="less">
  .appView {
    flex: 1;
    position: relative;
    iframe {
      position: absolute;
      user-select: none;
      display: block;
      margin: 0;
      left: 0;
      right: 0;
      top: 0;
      bottom: 0;
      border: none;
      height: 100%;
      width: 100%;
    }
  }
</style>
