export default class EnvironmentUtils {
    public static Environment (): string {
        switch (window.location.hostname.split('.')[0]) {
            case 'staging': return 'Staging';
            case 'dev1': return 'Dev1';
            case 'dev2': return 'Dev2';
            case 'dev3': return 'Dev3';
            case 'dev':
            case 'localhost':
                return 'Development';
            case 'portal':
            case 'apps':
            default:
                return 'Production';
        }
    };

    public static GetApiUrl (): string {
        switch (EnvironmentUtils.Environment().toLowerCase()) {
            case 'production': return 'https://api.lassox.com';
            case 'staging': return 'https://staging.api.lassox.com';
            case 'dev1': return 'https://dev1.api.lassox.com';
            case 'dev2': return 'https://dev2.api.lassox.com';
            case 'dev3': return 'https://dev3.api.lassox.com';
            case 'dev':
            default:
                return 'https://dev.api.lassox.com:4444';
        }
    }

    public static GetAppsUrl (): string {
        switch (EnvironmentUtils.Environment().toLowerCase()) {
            case 'production': return 'https://apps.lassox.com';
            case 'staging': return 'https://staging.apps.lassox.com';
            case 'dev1': return 'https://dev1.apps.lassox.com';
            case 'dev2': return 'https://dev2.apps.lassox.com';
            case 'dev3': return 'https://dev3.apps.lassox.com';
            case 'dev':
            default:
                return 'https://dev.apps.lassox.com:54311';
        }
    }

    public static GetPortalUrl (): string {
        switch (EnvironmentUtils.Environment().toLowerCase()) {
            case 'production': return 'https://portal.lassox.com';
            case 'staging': return 'https://staging.portal.lassox.com';
            case 'dev1': return 'https://dev1.portal.lassox.com';
            case 'dev2': return 'https://dev2.portal.lassox.com';
            case 'dev3': return 'https://dev3.portal.lassox.com';
            case 'dev':
            default:
                return 'https://dev.portal.lassox.com:65433';
        }
    }
}