<script lang="ts">
import { Component, Prop, Vue } from 'vue-property-decorator'
import Icon from '@/components/Icon.vue'
import IconButton from './IconButton.vue'
import BaseButton from './BaseButton.vue'
import DropdownMenu from './dropdown/DropdownMenu.vue'
import DropdownMenuItem from './dropdown/DropdownMenuItem.vue'
import { Tags } from '@/assets/js/helpers'
// eslint-disable-next-line no-unused-vars
import ACLUser from '@/types/ACLUser'
// eslint-disable-next-line no-unused-vars
import ACLObject from '@/types/ACLObject'
import ACLUserDropdown from './ACLUserDropdown.vue'

@Component({
  components: {
    DropdownMenu,
    DropdownMenuItem,
    Icon,
    IconButton,
    BaseButton,
    ACLUserDropdown,
  }
})
export default class ACLUserListItem extends Vue {
  @Prop({
    type: Object
  })
  readonly user!: ACLUser

  @Prop({
    type: String
  })
  readonly currentUserId!: string

  @Prop({
    type: Object
  })
  readonly tag!: { id: string, owner: string, canEdit: boolean }


  updateACL (newACL: ACLObject) {
    Tags.updateUserACL(this.tag.id, this.user.lassoUserId, newACL).then(() => {
      this.$emit('tagAccessChanged')
    })
  }
}
</script>

<template>
  <div class="acl-user-list-item">
    <span
      class="acl-user-list-item-name"
      :title="user.name"
    >{{ user.name }}</span>

    <div style="width: 16px" />

    <div class="acl-user-list-item-meta d-flex align-items-center">
      <template v-if="user.lassoUserId !== tag.owner && tag.canEdit">
        <ACLUserDropdown
          :tag="tag"
          :user="user"
          type="user"
          :currentUserId="currentUserId"
          @tagAccessChanged="$emit('tagAccessChanged')"
        />

        <template v-if="user.lassoUserId !== currentUserId">
          <div style="width: 16px" />

          <IconButton
            icon="close"
            size="small"
            @click.stop="updateACL({})"
          />
        </template>
      </template>

      <span
        v-else
        class="font-italic"
        style="color: #9E9E9E"
      >
        Ejer af listen
      </span>
    </div>
  </div>
</template>

<style lang="scss" scoped>
  .acl-user-list-item {
    display: flex;
    justify-content: space-between;
    align-items: center;
    min-height: 37px;
    padding: 6px 0;
    border-bottom: 1px solid #f1f1f1;

    &-name {
      width: 1px;
      flex-grow: 1;
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
    }
  }
</style>
