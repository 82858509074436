import axios from '@/axios'
import { actions, initializeActions } from '@/types/Actions'
import App from '@/types/App'
import ResultType from '@/types/ResultType'
import { Types } from '@/types/SearchTypes'
import Tag from '@/types/Tag'
import TimeoutPromise from '@/util/timeout-promise'
import { orderBy } from 'lodash'

interface Settings {
  portal: {
    search: {
      filters: Record<keyof Types, SelectedFilter[]>
    }
    lastVisited: ResultType[]
    actionsOrder: Record<'company' | 'person' | 'address', Record<'visible' | 'dropdown' | 'hidden', Set<string>>>
    appsOrder: Record<'visible' | 'hidden', Set<string>>
    tagsOrder: Record<'company' | 'person', Record<'visible' | 'hidden', Set<string>>>
    brandingBaselineSpring2024Survey: {
      shown: boolean
    }
  }
}

export type SelectedFilter = {
  key: string,
  value: string | undefined,
  type: 'query' | 'append',
  clearOnChange: boolean
}

interface SettingsResponse {
  portal?: {
    search?: {
      filters?: Partial<Record<keyof Types, SelectedFilter[]>>
    }
    lastVisited?: ResultType[]
    actionsOrder?: Partial<Record<'company' | 'person' | 'address', Partial<Record<'visible' | 'dropdown' | 'hidden', string[]>>>>
    appsOrder?: Partial<Record<'visible' | 'hidden', string[]>>
    tagsOrder?: Partial<Record<'company' | 'person', Partial<Record<'visible' | 'hidden', string[]>>>>
    brandingBaselineSpring2024Survey?: {
      shown?: boolean
    }
  }
}

const actionTypes = [ 'company', 'person', 'address' ] as const
const actionOrderCategories = [ 'visible', 'dropdown', 'hidden' ] as const

const isActionSupported = (slug: string, type: typeof actionTypes[number]) => {
  return !!actions.get(slug)?.tags.has(type)
}

const defaultActionsOrder: Record<'company' | 'person' | 'address', Record<'visible' | 'dropdown' | 'hidden', string[]>> = {
  company: {
    visible: [
      'monitor',
      'copy-id',
      'website',
      'cvrkeyfigures',
      'add-tags',
    ],
    dropdown: [],
    hidden: [],
  },
  person: {
    visible: [
      'monitor',
      'information',
      'cvrkeyfigures',
      'add-tags',
    ],
    dropdown: [],
    hidden: [],
  },
  address: {
    visible: [
      'show-people',
      'show-companies',
      'riskassessment',
      'properties',
    ],
    dropdown: [],
    hidden: [],
  },
};

const appOrderCategories = [ 'visible', 'hidden' ] as const;

const tagTypes = [ 'company', 'person' ] as const;
const tagOrderCategories = appOrderCategories;

export default class LassoSettings {
  public Ready: Promise<LassoSettings>

  private updateSettingsSymbol: symbol | null = null
  private apps: App[]
  private tags: Tag[]
  private readOnly: boolean

  settings: Settings = {
    portal: {
      search: {
        filters: {
          companyperson: [],
          company: [],
          person: [],
          address: [],
        }
      },
      lastVisited: [],
      actionsOrder: {
        company: { visible: new Set, dropdown: new Set, hidden: new Set },
        person: { visible: new Set, dropdown: new Set, hidden: new Set },
        address: { visible: new Set, dropdown: new Set, hidden: new Set },
      },
      appsOrder: {
        visible: new Set,
        hidden: new Set,
      },
      tagsOrder: {
        company: { visible: new Set, hidden: new Set },
        person: { visible: new Set, hidden: new Set },
      },
      brandingBaselineSpring2024Survey: {
        shown: false,
      },
    },
  }

  constructor (options: {
    apps: App[]
    tags: Tag[]
    readOnly: boolean
    isFreemium: boolean
  }, initialSettings?: SettingsResponse) {
    this.apps = options.apps
    this.tags = options.tags
    this.readOnly = options.readOnly

    initializeActions()

    this.applyActionDefaults()

    const prospectingApp = this.apps.find(app => app.uniqueName === 'prospecting')
    if (options.isFreemium && prospectingApp) {
      this.apps.unshift(prospectingApp)
    }
    // this.settings.portal.appsOrder.visible.add('news')

    for (const app of this.apps) {
      this.settings.portal.appsOrder.visible.add(app.uniqueName)
    }

    const companyTags = orderBy(this.tags.filter((t) => t.type === 'Company'), [ 'followTag' ], [ 'desc' ])
    const personTags = orderBy(this.tags.filter((t) => t.type === 'Person'), [ 'followTag' ], [ 'desc' ])

    for (const tag of companyTags) {
      this.settings.portal.tagsOrder.company.visible.add(tag.id)
    }

    for (const tag of personTags) {
      this.settings.portal.tagsOrder.person.visible.add(tag.id)
    }

    this.Ready = new Promise(async (resolve, reject) => {
      try {
        let userSettings = initialSettings || (await axios.get<SettingsResponse>('users/me/settings')).data
        this.applySettingsResponse(userSettings)
        resolve(this)
      } catch (reason) {
        reject(reason)
      }
    })
  }

  applyActionDefaults () {
    for (const type of actionTypes) {
      const addedSlugs = new Set<string>()

      for (const category of actionOrderCategories) {
        for (const slug of this.settings.portal.actionsOrder[type][category]) {
          addedSlugs.add(slug)
        }
      }

      for (const category of actionOrderCategories) {
        for (const slug of defaultActionsOrder[type][category]) {
          if (addedSlugs.has(slug)) continue
          if (!isActionSupported(slug, type)) continue

          this.settings.portal.actionsOrder[type][category].add(slug)
          addedSlugs.add(slug)
        }
      }

      for (const action of actions.values()) {
        if (addedSlugs.has(action.slug)) continue
        if (!isActionSupported(action.slug, type)) continue

        this.settings.portal.actionsOrder[type].dropdown.add(action.slug)
        addedSlugs.add(action.slug)
      }
    }
  }

  applySettingsResponse (response: SettingsResponse) {
    const portalSettings = this.settings.portal
    const portalSettingsResponse = response.portal

    // filters
    if (portalSettingsResponse?.search && portalSettingsResponse.search.filters) {
      for (const filters in portalSettingsResponse.search.filters) {
        portalSettings.search.filters[filters as keyof Types] = portalSettingsResponse.search.filters[filters as keyof Types] ?? []
      }
    }

    // lastVisited
    if (portalSettingsResponse?.lastVisited) {
      const lastVisited = portalSettingsResponse.lastVisited
      if (Array.isArray(lastVisited)) portalSettings.lastVisited = lastVisited
    }

    // actionsOrder
    if (portalSettingsResponse?.actionsOrder) {
      for (const type of actionTypes) {
        const typeOrders = portalSettingsResponse.actionsOrder[type]
        if (!typeOrders) continue

        for (const category of actionOrderCategories) portalSettings.actionsOrder[type][category].clear()

        const addedSlugs = new Set<string>()

        for (const category of actionOrderCategories) {
          const categoryActions = typeOrders[category]
          if (!categoryActions) continue

          for (const slug of categoryActions) {
            if (!isActionSupported(slug, type)) continue
            if (addedSlugs.has(slug)) continue
            portalSettings.actionsOrder[type][category].add(slug)
            addedSlugs.add(slug)
          }
        }
      }

      this.applyActionDefaults()
    }

    // appsOrder
    if (portalSettingsResponse?.appsOrder) {
      for (const category of appOrderCategories) portalSettings.appsOrder[category].clear()

      const addedUniqueNames = new Set<string>()

      for (const category of appOrderCategories) {
        const categoryApps = portalSettingsResponse.appsOrder[category]
        if (!categoryApps) continue

        for (const uniqueName of categoryApps) {
          if (addedUniqueNames.has(uniqueName)) continue
          portalSettings.appsOrder[category].add(uniqueName)
          addedUniqueNames.add(uniqueName)
        }
      }

      for (const app of this.apps) {
        if (addedUniqueNames.has(app.uniqueName)) continue
        portalSettings.appsOrder.visible.add(app.uniqueName)
        addedUniqueNames.add(app.uniqueName)
      }
    }

    // tagsOrder
    if (portalSettingsResponse?.tagsOrder) {
      for (const type of tagTypes) {
        const typeOrders = portalSettingsResponse.tagsOrder[type]
        if (!typeOrders) continue

        for (const category of tagOrderCategories) portalSettings.tagsOrder[type][category].clear()

        const addedIds = new Set<string>()

        for (const category of tagOrderCategories) {
          const categoryTags = typeOrders[category]
          if (!categoryTags) continue

          for (const id of categoryTags) {
            if (addedIds.has(id)) continue
            portalSettings.tagsOrder[type][category].add(id)
            addedIds.add(id)
          }
        }

        for (const tag of this.tags) {
          if (addedIds.has(tag.id)) continue
          portalSettings.tagsOrder[type].visible.add(tag.id)
          addedIds.add(tag.id)
        }
      }
    }

    // brandingBaselineSpring2024Survey
    if (portalSettingsResponse?.brandingBaselineSpring2024Survey) {
      const { shown } = portalSettingsResponse.brandingBaselineSpring2024Survey

      if (typeof shown === 'boolean') {
        portalSettings.brandingBaselineSpring2024Survey.shown = shown
      }
    }
  }

  async updateSettings () {
    if (this.readOnly) return

    const symbol = Symbol()
    this.updateSettingsSymbol = symbol

    await TimeoutPromise(1000)

    if (this.updateSettingsSymbol !== symbol) return

    const settingsPayload: SettingsResponse = {}

    const portalSettings = this.settings.portal

    const portalSettingsPayload: Exclude<SettingsResponse['portal'], undefined> = {}

    portalSettingsPayload.lastVisited = portalSettings.lastVisited

    portalSettingsPayload.appsOrder = {
      visible: [ ...portalSettings.appsOrder.visible.values() ],
      hidden: [ ...portalSettings.appsOrder.hidden.values() ],
    }

    portalSettingsPayload.tagsOrder = {
      company: {
        visible: [ ...portalSettings.tagsOrder.company.visible.values() ],
        hidden: [ ...portalSettings.tagsOrder.company.hidden.values() ],
      },
      person: {
        visible: [ ...portalSettings.tagsOrder.person.visible.values() ],
        hidden: [ ...portalSettings.tagsOrder.person.hidden.values() ],
      },
    }

    portalSettingsPayload.actionsOrder = {}

    portalSettingsPayload.search = portalSettings.search

    for (const type of actionTypes) {
      const typeOrders = portalSettings.actionsOrder[type]

      const responseActionsOrder: Partial<Record<typeof actionOrderCategories[number], string[]>> = portalSettingsPayload.actionsOrder[type] = {}

      for (const category of actionOrderCategories) {
        const slugs = typeOrders[category]

        responseActionsOrder[category] = [ ...slugs ]
      }
    }

    portalSettingsPayload.brandingBaselineSpring2024Survey = portalSettings.brandingBaselineSpring2024Survey

    settingsPayload.portal = portalSettingsPayload

    const response = await axios.put<SettingsResponse>('users/me/settings', settingsPayload)

    if (this.updateSettingsSymbol !== symbol) return

    this.applySettingsResponse(response.data)

    this.updateSettingsSymbol = null
  }

  updateActionsOrder (actionsOrder: Partial<Record<typeof actionTypes[number], Record<'visible' | 'dropdown' | 'hidden', string[]>>>) {
    for (const type of actionTypes) {
      const typeOrders = actionsOrder[type]
      if (!typeOrders) continue

      for (const category of actionOrderCategories) {
        this.settings.portal.actionsOrder[type][category] = new Set(typeOrders[category])
      }
    }
  }
}
