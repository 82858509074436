<script lang="ts">
import { Vue, Component, Prop } from 'vue-property-decorator'
import Icon from '@/components/Icon.vue'
import IconButton from '@/components/IconButton.vue'

@Component({
  components: {
    Icon,
    IconButton,
  },
})

export default class Draggable extends Vue {
  @Prop({ type: String, required: true })
  readonly text!: string

  @Prop({ type: Boolean, default: true })
  readonly draggable!: boolean

  @Prop(Boolean)
  readonly clickable?: boolean

  @Prop(Boolean)
  readonly deletable?: boolean

  @Prop({ type: Boolean, default: () => false })
  readonly dragging!: boolean

  @Prop({ type: String, required: false })
  readonly icon?: string

  hovering = false
  @Prop({ type: String, required: true })
  readonly id!: string

  get isPropectingIntro () {
    return this.id === 'item-prospecting' && this.$store.state.user?.settings?.questionaire?.isSalesy
  }
}
</script>

<template>
  <div
    :class="[
      'lassox-portal__Draggable',
      draggable && 'lassox-portal__Draggable--draggable',
      clickable && 'lassox-portal__Draggable--clickable',
      dragging && 'lassox-portal__Draggable--dragging',
    ]"
    @mousedown="e => draggable && !clickable && $emit('mousedown', e)"
    :data-intro="isPropectingIntro ? 'Opret din første salgskampagne og få friske leads hver uge — helt automatisk.' : undefined"
    :data-title="isPropectingIntro ? 'Leder du efter leads?' : undefined"
    :data-step="isPropectingIntro ? 6 : undefined"
    data-position="right"
    :data-hint="isPropectingIntro ? 'Med Prospecting kan du få leveret friske leads hver uge, så snart du har sat din første salgskampagne i gang. Klik for at komme igang.' : undefined"
    >
    <div
      class="lassox-portal__Draggable-drag-handle"
      @mousedown="e => draggable && clickable && $emit('mousedown', e)"
    >
      <div
        v-if="icon || $scopedSlots.leading"
        class="custom-icon"
      >
        <slot
          name="leading"
          v-bind="$props"
        >
          <Icon
            size="small"
            :icon="icon"
          />
        </slot>
      </div>

      <Icon
        class="drag-icon"
        icon="drag_handle"
        size="small"
      />
    </div>

    <button
      :is="clickable ? 'button' : 'div'"
      class="lassox-portal__Draggable-text"
      tabindex="-1"
      @click="e => clickable && $emit('click', e)"
    >
      <slot
        name="content"
        v-bind="$props"
      >
        <template>
          {{ text }}
        </template>
      </slot>
    </button>

    <IconButton
      v-if="deletable"
      class="lassox-portal__Draggable-delete-button"
      icon="close"
      size="small"
      tabindex="-1"
      @mousedown.native.stop="() => {}"
      @click="e => $emit('deleteClicked', e)"
    />
  </div>
</template>

<style lang="scss">
@use 'sass:color';
@use 'sass:math';

@import '@shared/style/global.scss';

.lassox-portal__Draggable {
  position: relative;
  z-index: 1;
  display: flex;
  align-items: center;
  height: 32px;
  padding: 8px;
  border-radius: 8px;
  user-select: none;
  overflow: hidden;
  transition: background-color 100ms, transform 100ms;

  &--dragging {
    z-index: 2;
    background-color: color.mix(white, black, 96%);
    transition: background-color 100ms;

    .lassox-portal__Draggable-drag-handle {
      .custom-icon {
        position: absolute;
        height: 100%;
        width: 100%;
        opacity: 0;
      }

      .drag-icon {
        opacity: 1;
      }
    }
  }

  $iconSize: 18px;
  $desiredIconSize: 18px;
  $iconButtonSize: 24px;
  $iconButtonPadding: math.div($iconButtonSize - $iconSize, 2);
  $iconButtonMargin: math.div($iconButtonSize - $desiredIconSize, -2);
  $iconToTextSpacing: 8px;

  &-drag-handle,
  &-delete-button.icon-button.is-small {
    display: flex;
    padding: $iconButtonPadding;
    margin: $iconButtonMargin;
    flex-shrink: 0;
  }

  &-drag-handle {
    position: relative;
    width: 24px;
    height: 24px;

    .drag-icon,
    .custom-icon {
      position: absolute;
      transition: opacity 250ms;
    }

    .drag-icon {
      opacity: 0;
    }

    .custom-icon {
      opacity: 1;
    }
  }

  &-text {
    @include typography('body2');
    width: 0;
    padding: 8px 4px;
    flex-grow: 1;
    margin: 0 4px;
    border-radius: 2px;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;

    &:last-child {
      margin-right: -4px;
    }

    &:focus {
      outline: hidden;
    }
  }

  &-delete-button.icon-button.is-small {
    opacity: 0;
  }

  &:hover &-delete-button.icon-button.is-small,
  &--dragging &-delete-button.icon-button.is-small {
    opacity: 1;
  }

  &.lassox-portal__Draggable--draggable {
    &:not(.lassox-portal__Draggable--clickable),
    .lassox-portal__Draggable-drag-handle {
      cursor: grab;
    }

    &:not(.lassox-portal__Draggable--clickable):hover .lassox-portal__Draggable-drag-handle,
    .lassox-portal__Draggable-drag-handle:hover,
    .lassox-portal__Draggable--dragging .lassox-portal__Draggable-drag-handle {
      .drag-icon {
        opacity: 1;
      }

      .custom-icon {
        opacity: 0;
      }
    }
  }

  &.lassox-portal__Draggable--dragging {
    &,
    .lassox-portal__Draggable-drag-handle {
      cursor: grabbing;
    }
  }
}
</style>
