<script>
export default {
  name: 'HomeSimple'
}
</script>

<template>
  <div
    class="helper-text"
    v-show="$route.name !== 'entity'"
    v-lang.use-search-bar="{ entityTypes: 'firmaer og personer' }"
  />
</template>

<style lang="less">
  .helper-text {
      flex-grow: 1;
      display: flex;
      align-items: center;
      justify-content: center;
      font-size: 17px;
      color: #9E9E9E;
      font-weight: 300;
    }
</style>
