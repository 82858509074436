<script>
// import { EventBus, Users } from '@/assets/js/helpers.js'
import Icon from '@/components/Icon.vue'
import IconButton from '@/components/IconButton.vue'
import DraggableTags from '@/components/draggables/DraggableTags.vue'
import BaseButton from '@/components/BaseButton.vue'
import LoadingSpinner from './LoadingSpinner.vue'

export default {
  name: 'TagList',
  props: {
    open: { type: Boolean, required: false, default: true },
    tags: { type: Array, required: true },
    title: { type: String },
    maxHeight: { type: String, default: '100px' },
    tagType: { type: String, required: true },
    disableCreate: { type: Boolean, default: false }
  },
  data () {
    return {
      creatingTag: false,
      savingTag: false,
      newTagName: '',
      tagsCollapsed: true,
    }
  },
  computed: {
    lassoSettings: function () {
      return this.$store.state.lassoSettings
    }
  },
  methods: {
    openNewTab (tag) {
      this.$store.dispatch('findMatchingTab', (tab) => tab.route?.name === 'list' && tab.route?.params?.listId == tag.id).then((tab) => {
        if (tab) {
          this.$store.state.activeTab = tab
        } else {
          this.$store.dispatch('openNewTab', this.$router.resolve({ name: 'list', params: { listId: tag.id }, query: this.$route.query }).route)
        }
      })
    },
    showTag (tag) {
      this.$store.dispatch('openNewTab', this.$router.resolve({ name: 'list', params: { listId: tag.id }, query: this.$route.query }).route)
    },
    createTag: function (type) {
      requestAnimationFrame(() => {
        this.newTagName = ''
        this.creatingTag = true
        // EventBus.$emit('modal', { modalType: 'tagManager', tagType: type, tags: this.tags })
      })
    },
    saveTag () {
      if (this.savingTag || !this.newTagName.length) return

      this.savingTag = true
      this.$store.dispatch('createTag', { tagName: this.newTagName, tagType: this.tagType }).then(newTag => {
        this.showTag(newTag)
        this.lassoSettings.settings.portal.tagsOrder[this.tagType].visible.add(newTag.id)
        this.creatingTag = this.savingTag = false
      })
    }
  },
  mounted () {
    this.$root.$el.addEventListener('click', event => {
      if (!this.creatingTag || !event.target) return
      if (this.$refs.create && this.$refs.create.contains(event.target)) return
      if (this.$refs.createButton && this.$refs.createButton.contains(event.target)) return
      this.creatingTag = false
    })
  },
  components: {
    Icon,
    IconButton,
    BaseButton,
    DraggableTags,
    LoadingSpinner,
  }
}
</script>

<template>
  <div
    class="tag-list"
    :class="{ 'open': open }"
  >
    <BaseButton
      class="toggle-btn"
      buttonColor="peach"
      @click="$emit('toggle-open', !open)"
    >
      <span>{{ title }}</span>

      <Icon :icon="open ? 'expand_less_rounded' : 'expand_more_rounded' " />
    </BaseButton>

    <template v-if="open">
      <div
        v-show="!tags.length"
        class="empty-list text-muted"
      >
        <span v-lang.no-lists="{entityType: tagType === 'company' ? translate('company') : translate('person') }" />
      </div>

      <DraggableTags
        :tags="tags"
        :tagType="tagType"
        :onTagClick="openNewTab"
      />

      <template v-if="!disableCreate">
        <div class="lassox-portal__TagList_separator" />

        <BaseButton
          v-if="!creatingTag"
          ref="createButton"
          class="lassox-portal__TagList_create-new-button"
          @click="createTag(tagType)"
        >
          <Icon
            icon="add"
            size="small"
          />

          <span v-lang.create-new-list />
        </BaseButton>

        <div
          v-else
          ref="create"
          class="lassox-portal__TagList_creator"
        >
          <input
            class="lassox-portal__TagList_creator_input"
            type="text"
            :placeholder="translate('enter-list-name')"
            :disabled="savingTag"
            v-model="newTagName"
            v-focus
            @keyup.esc="() => creatingTag = false"
            @keyup.enter="() => saveTag()"
          >

          <Icon
            class="lassox-portal__TagList_creator_leading-icon"
            icon="add"
            size="small"
          />

          <template v-if="!savingTag">
            <IconButton
              v-if="!newTagName.length"
              class="lassox-portal__TagList_creator_trailing-icon lassox-portal__TagList_creator_trailing-icon--cancel-button"
              icon="cancel_outline_rounded"
              :buttonSize="24"
              @click="() => creatingTag = false"
            />

            <IconButton
              v-else
              class="lassox-portal__TagList_creator_trailing-icon lassox-portal__TagList_creator_trailing-icon--save-button"
              icon="check_outline_rounded"
              :buttonSize="24"
              @click="() => saveTag()"
            />
          </template>

          <template v-else>
            <LoadingSpinner
              class="lassox-portal__TagList_creator_trailing-icon lassox-portal__TagList_creator_trailing-icon--loading-spinner"
              preset="sm"
            />
          </template>
        </div>
      </template>
    </template>
  </div>
</template>

<style lang="less">
.tag-list {
  .empty-list {
    padding: (8px - 2px) 0;
    margin-top: 8px + 4px;
    margin-bottom: -20px - 8px;
    font-size: 14px;
    line-height: 20px;
  }

  .lassox-portal__TagList_separator {
    border-top: 1px solid #f1f1f1;
    margin: (8px + 8px) (-16px);
  }

  .lassox-portal__TagList_create-new-button {
    justify-content: flex-start;
    height: 32px;
    padding: 8px;
    margin: -8px;
    border-radius: 8px;

    .md-icon {
      margin-right: 8px;
    }

    span {
      color: #262626;
      font-size: 14px;
      line-height: 16px;
      font-weight: 500;
    }
  }

  .lassox-portal__TagList_creator {
    position: relative;
    margin: -8px;

    &_input {
      display: block;
      box-sizing: border-box;
      width: 100%;
      height: 32px;
      padding: 8px 34px;
      border: 0;
      margin: 0;
      font: inherit;
      font-size: 14px;
      line-height: 16px;
      font-weight: 500;
      color: #262626;
      background-color: transparent;
      border-radius: 8px;
      box-shadow: inset 0 0 0 1px #B7B7B7;
    }

    &_leading-icon,
    &_trailing-icon {
      position: absolute;
      top: (32px / 2) - (18px / 2);
    }

    &_leading-icon {
      left: 8px;
      pointer-events: none;
    }

    &_trailing-icon {
      right: 8px;
    }

    &_trailing-icon--save-button {
      .md-icon {
        color: #81D372;
      }

      .lassox-portal__BaseButton_overlay {
        background-color: rgba(#81D372, 0.2);
      }
    }
  }
}
</style>
