<script>
import { EventBus, apiUrl, Users, Tags } from '@/assets/js/helpers.js'
// import PopupMenu from '@shared/vue-components/popup-menu/PopupMenu'
import Dropdown from '@oldShared/lasso/vue-components/Dropdown'
import NewsFeed from '@/components/news/NewsFeed'
import TagShareSettings from '@/components/TagShareSettings'
import FollowListDropdown from '@/components/FollowListDropdown'
import ImportDropdown from '@/components/ImportDropdown'
import TagManager from '@/components/TagManager.vue'
import Icon from '@/components/Icon.vue'
import formatNumber from '@shared/utilities/formatNumber.ts'
import dots from '@oldShared/lasso/vue-components/loaders/dots.vue'
import Tab from '@/types/Tab.ts'
import VueRouter from 'vue-router'
import windowSize from '@/window-size.ts'
import PremiumFeatureOverlay from './overlays/PremiumFeatureTrialOverlay.vue'
import { showTabOverlay } from '@/util/overlay.ts'

export default {
  // eslint-disable-next-line vue/multi-word-component-names
  name: 'List',
  data () {
    return {
      entities: [],
      totalEntities: 0,
      entitiesMap: new Map(),
      selectedEntities: [],
      loadingTag: false,
      loadingNews: false,
      loadingEntities: false,
      loadingMoreNews: false,
      loadingExports: false,
      loadingTags: false,
      editingTags: false,
      editingTagName: false,
      importerOpen: false,
      tagFollowListOpen: false,
      tagAccess: null,
      showTags: false,
      view: 'list',
      EventBus: EventBus,
      newTagName: '',
      saving: false,
      error: false
    }
  },
  watch: {
    // TODO : Is this necessary?
    'entities' (newEnts, oldEnts) { if (newEnts.length !== oldEnts.length) this.getListNews() },
  },
  computed: {
    route () { return this.$parent.tab.route },
    allIsSelected () { return this.entities && this.entities.length && this.selectedEntities.length === this.entities.length },
    tag () { return this.$store.getters.tag(this.route.params.listId) },
    // entities () { return (this.tag ? this.tag.results : []) || [] },
    news () { return this.entities.length ? this.$store.getters.news(this.route.params.listId) : null },
    thirdPartyExports () { return (this.$store.state.exports || []).filter(e => e.thirdPartyExport).sort((a, b) => a.title.localeCompare(b.title, 'da-DK'))  },
    dataExports () { return this.$store.state.exports || [] },
    excelExports () { return (this.$store.state.exports || []).filter(e => !e.thirdPartyExport).sort((a, b) => a.title.localeCompare(b.title, 'da-DK')) },
    hasMonitoring () { return this.$store.getters.standaloneApps.some(a => a.uniqueName === 'monitoring') },
    isMonitoring () {
      return this.tag.monitors?.some(m => m.lassoUserId === this.$store.state.user.id)
    },
    readOnly () { return this.$store.state.readOnly },
    isNarrow () { return windowSize.width < windowSize.sizes.lg },
    isMobile () { return windowSize.width < windowSize.sizes.sm },
    isFreemium () { return this.$store.getters.isFreemium },
    userCanImport () {
      return !this.$store.state.productAccess && !this.readOnly && this.tag.canTag && this.tag.type === 'Company'
    },
    userCanExport () {
      return this.$store.getters.isFreemium || (!this.$store.state.productAccess && this.$store.getters.standaloneApps.some(a => a.uniqueName === 'exporter') && this.tag.type === 'Company')
    },
    userCanEdit () { return this.tag.canEdit },
    userCanDelete () { return this.tag.canDelete },
    userCanShare () {
      return this.$store.getters.isFreemium ||
        !this.readOnly && !this.$store.state.productAccess
        && (this.$store.getters.isAdmin || this.$store.state.user.lassoOrganizationId !== '819b6c9e-277e-4e13-b999-9f407982224b') // this is a solution for users that test Risikovurdering. Since they are from multiple companies, they must not see who else are in this solution, which can be seen this way
    },
    entityTypeSingleOrPlural () {
      if (this.tag && this.entities) {
        return this.tag.type === 'Company'
          ? this.$options.filters.pluralize(formatNumber(this.totalEntities), 'companies', 'company', 'companies', false, this.translate)
          : this.$options.filters.pluralize(formatNumber(this.totalEntities), 'people', 'person', 'people', false, this.translate)
      } else return null
    },
    tagFollowId () { return this.$store.state.tags?.filter((t) => t.type.toLowerCase() === this.tag.type.toLowerCase() && t.followTag)[0]?.id },
    companyFollowListId () { return this.$store.state.tags.find(t => t.type === 'Company' && t.followTag).id },
    personFollowListId () { return this.$store.state.tags.find(t => t.type === 'Person' && t.followTag).id },
    tagName () {
      return this.$store.state.productAccess && this.tag.followTag
        ? this.tag.type === 'Company' ? this.translate('followed-companies') : this.translate('followed-people')
        : this.tag.name
    },
    typeNamePlural () {
      return this.tag.type === 'Company' ? this.translate('companies') : this.translate('people')
    },
    typeNameSingle () {
      return this.tag.type === 'Company' ? this.translate('the-company') : this.translate('the-person')
    }
  },
  methods: {
    openInNewTab (entity) {
      this.$store.dispatch('findMatchingTab', (tab) => (
        tab.route?.params.lassoId === entity.lassoId
      )).then(tab => {
        if (tab) {
          this.$store.state.activeTab = tab
        } else {
          this.$store.dispatch('openNewTab', this.$router.resolve({ name: "entity", params: { lassoId: entity.lassoId }, query: this.route.query }).route)
        }
      })
    },
    getListNews: function () {
      this.loadingNews = true
      this.$store.dispatch('getListNews', { cacheKey: this.route.params.listId, forceRefresh: false, listId: this.tag.id }).then(() => {
        this.loadingNews = false
      })
    },
    async getList (forceRefresh) {
      this.entities = []
      this.entitiesMap.clear()
      this.selectedEntities = []
      this.loadingTag = true
      this.loadingEntities = false
      // this.$emit('updateList')
      await this.$store.dispatch('getTag', { tagId: this.route.params.listId, forceRefresh: forceRefresh })
      const tasks = [ this.loadEntities() ]
      if (!this.readOnly) tasks.push(this.loadTagAccess())
      await Promise.all(tasks)
      this.getListNews()
      this.loadingTag = false
      this.updateSelectedEntities()
    },
    refreshList: function (tagId, forceRefresh) {
      this.loadingTag = true
      this.$store.dispatch('getTag', { tagId: tagId, forceRefresh: forceRefresh }).then(() => { this.loadingTag = false; this.updateSelectedEntities() })
    },
    onFollowListClose () {
      this.tagFollowListOpen = false
      this.$store.dispatch('getTag', { tagId: this.tag.id, forceRefresh: true })
    },
    deleteTag () {
      if (this.userCanDelete) {
        EventBus.$emit('modal', {
          modalType: 'confirm',
          confirmTitle: this.translate('delete-list')+'?',
          confirmDescription: this.translate('delete-list-description', { listname: this.tag.name }),
          onConfirm: () => {
            this.$store.dispatch('deleteTag', this.tag.id).then(() => {
              EventBus.$emit('tab:close', this.$parent.tab)
            })
          }
        })
      }
    },
    clearTag () {
      if (this.userCanEdit) {
        EventBus.$emit('modal', {
          modalType: 'confirm',
          confirmTitle: this.translate('clear-list')+'?',
          confirmDescription: this.translate('clear-list-description', { listname: this.tag.name }),
          onConfirm: () => {
            this.$store.dispatch('clearTag', this.tag.id).then(() => {
              this.getList(true)
            })
          }
        })
      }
    },
    // eslint-disable-next-line no-unused-vars
    onSave (event) {
      if (this.tag.name !== this.newTagName) {
        const originalName = this.tag.name
        this.tag.name = this.newTagName
        this.saving = true
        this.$store.dispatch('updateTag', this.tag).then(() => {
          this.$notify({ title: this.translate('list-renamed') })
          this.$emit('done')
        }, () => {
          this.tag.name = originalName
          this.error = true
          this.saving = false
        })
      }
      this.editingTagName = false
    },
    editTagName () {
      if (this.userCanEdit) {
        this.editingTagName = true
        this.newTagName = this.tag.name
        this.$nextTick(function () {
          this.$refs['input-edit-name'].focus()
          this.$refs['dd-tag-settings'].open = false
        })
      }
    },
    toggleAll () {
      if (this.allIsSelected) this.entities.forEach(e => { this.$set(e, 'selected', false) })
      else this.entities.forEach(e => { this.$set(e, 'selected', true) })
      this.updateSelectedEntities()
    },
    toggleEntity (entity, doNotUpdateSelected) {
      this.$set(entity, 'selected', !entity.selected)
      if (!doNotUpdateSelected) this.updateSelectedEntities()
    },
    toggleEditTags (state) {
      this.editingTags = state
      this.loadingTags = true
      if (state) {
        const entities = this.selectedEntities.map(e => e.lassoId)
        Tags.lookup(entities).then(result => {
          this.selectedEntities = this.selectedEntities.map(e => {
            e.tags = result[e.lassoId.toLowerCase()].map(t => t.id)
            return e
          })
        })
      }
      this.loadingTags = false
    },
    updateSelectedEntities: function () {
      this.selectedEntities = this.entities ? this.entities.filter(e => e.selected) : []
    },
    doExport (ex) {
      this.$store.state.activeTab.pushRoute(this.$router.resolve({ name: 'app', params: { appName: 'exporter', appParams: JSON.stringify({exportType: ex.type, listId: this.tag.id }) } }).route)
    },
    onEditLists (entities) {
      EventBus.$emit('modal', { modalType: 'tagManager', type: this.tag.type, entities: entities, onDone: () => { this.updateSelectedEntities() } })
    },
    onRemoveFromList (entities) {
      const that = this
      EventBus.$emit('modal', {
        modalType: 'confirm',
        confirmTitle: this.translate('remove-from-list'),
        confirmDescription: this.translate('remove-from-list-description', { count: entities.length, entityType: this.translate(this.tag.type === 'Company' ? (entities.length > 1 ? 'companies' : 'company') : (entities.length > 1 ? 'people' : 'person')) }),
        onConfirm: () => {
          const changes = { entities: entities.map(e => ({lassoId: e.lassoId})), tagsToRemove: [ this.tag.id ], tagsToAdd: [] }
          that.$store.dispatch('updateEntityTags', changes).then(() => {
            this.entities = this.entities.filter(ent => !changes.entities.some(e => e.lassoId === ent.lassoId))
            this.updateSelectedEntities()
          })
        }
      })
    },
    addOrRemoveFollowList (selectedEntities, add) {
      if (this.saving) return
      this.saving = true
      const changes = {
        entities: selectedEntities.map(e => ({lassoId: e.lassoId})),
        tagsToAdd: add ? [ this.tagFollowId ] : [],
        tagsToRemove: add ? [] : [ this.tagFollowId ]
      }
      this.$store.dispatch('updateEntityTags', changes).then(() => {
        this.saving = false
        this.$notify({ title: this.translate('changes-saved') })
        this.$refs['dd-tag-settings'].open = false
      })
    },
    async loadTagAccess () {
      const resp = await this.$http.get(apiUrl + `/tags/${this.tag.id}/sharing`)
      this.tagAccess = resp.body
      this.tagAccess.users = resp.body.users.sort((a, b) => {
        if (a.lassoUserId === this.tag.owner) return -1
        if (b.lassoUserId === this.tag.owner) return 1
        return a.name.localeCompare(b.name)
      })
    },
    onTagAccessChanged () {
      this.loadTagAccess()
    },
    onTagManagerDone (changes) {
      changes.tagsToRemove.forEach(tagId => {
        if (this.tag.id === tagId) {
          this.entities = this.entities.filter(ent => !changes.entities.some(e => e.lassoId === ent.lassoId))
        }
      })
      this.editingTags = false
      this.$refs['dd-tag-settings'].open = false
      this.updateSelectedEntities()
    },
    onLoadMoreNews () {
      this.loadingMoreNews = true
      this.$store.dispatch('getListNews', { cacheKey: this.route.params.listId, forceRefresh: false, listId: this.tag.id, page: this.news.page + 1 }).then(() => { this.loadingMoreNews = false })
    },
    onExportsOpened () {
      if (!this.$store.state.exports) this.$store.dispatch('getExports')
    },
    forceAppClick () {
      EventBus.$emit('app-click', {})
    },
    onOpenTagFollowList () {
      if (!this.$store.state.user.email) {
        EventBus.$emit('modal', {
          modalType: 'userEmail',
          onDone: (email) => {
            Users.updateEmail(email).then(() => {
              this.$store.state.user.email = this.$store.state.user.userName = email
              this.tagFollowListOpen = true
              this.$notify({ title: this.translate('settings-saved') })
            })
          }
        })
      } else this.tagFollowListOpen = true
    },
    async loadEntities () {
      this.loadingEntities = true
      const body = await Tags.getEntities(this.tag.id, this.entities.length, 1000)
      let newEntities = []
      for (const entity of body.results) {
        if (this.entitiesMap.has(entity.lassoId)) continue
        newEntities.push(entity)
        this.entitiesMap.set(entity.lassoId, entity)
      }
      this.totalEntities = body.resultsFound
      if (this.allIsSelected) {
        newEntities = newEntities.map(e => {
          e.selected = true
          return e
        })
        this.selectedEntities.push(...newEntities)
      }
      this.entities.push(...newEntities)
      this.loadingEntities = false
    },
    onScroll (e) {
      if (this.loadingEntities) return
      const { target: { scrollTop, scrollHeight, clientHeight } } = e
      if (Math.ceil(scrollTop) === scrollHeight - clientHeight) this.loadEntities()
    },
    checkIfTrial (event, title, description, context) {
      if (this.isFreemium) {
        event.stopPropagation()
        showTabOverlay({ component: PremiumFeatureOverlay, source: 'tags_' + context })
      }
    }
  },
  async mounted () {
    this.$nextTick(async () => {
      await this.getList()
    })
    this.tagFollowId
  },
  components: {
    NewsFeed,
    // PopupMenu,
    Dropdown,
    TagShareSettings,
    FollowListDropdown,
    ImportDropdown,
    TagManager,
    Icon,
    dots
  }
}
</script>

<template>
  <div
    id="list"
    :class="{ 'read-only': readOnly, 'mobile': isMobile, 'narrow': isNarrow }"
    v-if="$store.state.user"
  >
    <div
      v-if="isMobile"
      id="mobileMenu"
    >
      <div
        class="menu-item"
        :class="{ 'selected': view === 'list' }"
        @click="view = 'list'"
      >
        {{ tag && tag.type === 'Company' ? translate('companies') : translate('people') }}
      </div>
      <div
        class="menu-item"
        :class="{ 'selected': view === 'news' }"
        @click="view = 'news'"
        v-lang.news
      />
    </div>
    <div
      id="listHeader"
      v-show="!isMobile || isMobile && view === 'list'"
    >
      <div
        class="list-title"
        :class="{ 'has-selected': selectedEntities.length }"
      >
        <template v-if="$store.state.productAccess">
          <div class="list-type-toggle" @click="$store.state.activeTab.pushRoute($router.resolve({ name: 'list', params: { listId: companyFollowListId } }).route)" :class="{ active: route.params.listId === companyFollowListId }">Firmaer</div>
          <div class="list-type-toggle" @click="$store.state.activeTab.pushRoute($router.resolve({ name: 'list', params: { listId: personFollowListId } }).route)" :class="{ active: route.params.listId === personFollowListId }">Personer</div>
        </template>
        <template v-else>
          <div
            class="info name ellipsis d-flex justify-content-between align-items-center"
            v-if="tag && !editingTagName"
            @click="editTagName"
          >
            <span class="text-semibold">{{ tagName }}</span>
          </div>
          <div
            class="edit-name d-flex justify-content-between align-items-center"
            v-if="editingTagName"
            style="width: 100%;"
          >
            <input
              ref="input-edit-name"
              class="editing-list-name text-semibold flex-grow-1"
              v-model="newTagName"
              :placeholder="tag.name || translate('enter-a-name')"
              @keyup.enter="onSave($event)"
              @blur="editingTagName = false"
              @keyup.esc="editingTagName = false"
            >
            <i
              class="fa fa-pencil"
              aria-hidden="true"
            />
          </div>
        </template>
      </div>

      <div
        class="actions"
        v-if="tag && $store.state.user"
      >
        <!-- Remove from list button (used in product access only) -->
        <div
          class="button text-red clickable"
          v-if="$store.state.productAccess && selectedEntities.length"
          @click="onRemoveFromList(selectedEntities)"
          v-lang.remove-from-list
        />
        <!--  SETTINGS LIST -->
        <dropdown
          ref="dd-tag-settings"
          v-if="!$store.state.productAccess && !readOnly"
          left
          :minWidth="200"
          :EventBus="EventBus"
        >
          <div
            slot="toggle"
            class="button border-left"
          >
            <img
              v-if="isNarrow"
              style="max-width: 15px"
              src="../assets/img/cog.png"
            >
            <span v-if="!isNarrow">{{ translate('edit') }}</span>
            <i
              v-if="!isNarrow"
              class="fa fa-angle-down fa-xs dropdown-indicator"
            />
          </div>
          <div>
            <div
              class="dropdown-description"
              v-if="!selectedEntities.length && tag.canTag && !isFreemium"
              v-lang.list-edit-disabled-description="{ entityType: tag.type === 'Company' ? translate('companies') : translate('people') }"
            />
            <span
              class="dropdown-divider"
              v-if="tag.canTag && !selectedEntities.length && !isFreemium"
            />
            <dropdown
              class="dropdown-item"
              v-if="tag.canTag"
              :minWidth="220"
              rightTop
              noPadding
              :class="{disabled: !selectedEntities.length}"
              :eventBus="EventBus"
              :conditionalToggle="selectedEntities.length > 0"
              :isOpen="editingTags"
              @opened="toggleEditTags(true)"
              @closed="toggleEditTags(false)"
            >
              <div
                slot="toggle"
                class="d-flex justify-content-between align-items-center"
                :class="{disabled: !selectedEntities.length}"
              >
                <span v-lang.add-to-lists>NESTED</span>
                <svg
                  style="margin-left: 5px"
                  xmlns="http://www.w3.org/2000/svg"
                  height="18px"
                  viewBox="0 0 24 24"
                  width="18px"
                  fill="#777"
                ><path
                  d="M0 0h24v24H0V0z"
                  fill="none"
                /><path d="M10 6L8.59 7.41 13.17 12l-4.58 4.59L10 18l6-6-6-6z" /></svg>
              </div>
              <div class="content shadow">
                <tag-manager
                  v-if="selectedEntities.length && !loadingTags && editingTags"
                  class="inside-dropdown"
                  :tags="$store.state.tags.filter((t) => t.type.toLowerCase() === tag.type.toLowerCase() && !t.followTag)"
                  :entities="selectedEntities"
                  @cancel="toggleEditTags(false)"
                  @done="changes => onTagManagerDone(changes)"
                />
                <div
                  class="dropdown-item"
                  v-else
                >
                  Indlæser<dots />
                </div>
              </div>
            </dropdown>
            <div
              class="dropdown-item"
              v-if="tag.canTag"
              :class="{disabled: !selectedEntities.length}"
              @click="selectedEntities.length > 0 ? onRemoveFromList(selectedEntities) : null"
              v-lang.remove-from-list
            />
            <span
              class="dropdown-divider"
              v-if="tag.canTag"
            />
            <div
              v-if="!isFreemium"
              class="dropdown-item d-flex justify-content-between align-items-center"
              :class="{disabled: !selectedEntities.length || saving}"
              @click="selectedEntities.length > 0 ? addOrRemoveFollowList(selectedEntities, true) : false"
            >
              <div
                class=""
                v-lang.follow
              />
            </div>
            <div
              v-if="!isFreemium"
              class="dropdown-item d-flex justify-content-between align-items-center"
              :class="{disabled: !selectedEntities.length || saving}"
              @click="selectedEntities.length > 0 ? addOrRemoveFollowList(selectedEntities, false) : false"
            >
              <div
                class=""
                v-lang.unfollow
              />
            </div>
            <span
              v-if="!isFreemium"
              class="dropdown-divider"
            />
            <div
              class="dropdown-item"
              :class="{disabled: !userCanEdit}"
              @click="editTagName"
              v-lang.rename-list
            />
            <div
              class="dropdown-item"
              :class="{disabled: !userCanEdit}"
              @click="clearTag"
              v-lang.clear-list
            />
            <div
              class="dropdown-item"
              :class="{disabled: !userCanDelete}"
              @click="deleteTag()"
              v-lang.delete-list
            />
          </div>
        </dropdown>

        <!-- IMPORT COMPANIES -->
        <dropdown
          v-if="userCanImport"
          noPadding
          left
          :minWidth="350"
          @opened="importerOpen = true"
          @closed="importerOpen = false;"
          :EventBus="EventBus"
          :conditionalToggle="!isFreemium"
          @click.native="($event) => checkIfTrial($event, 'Importer filer', 'Med fuld adgang til Lasso X kan du importere alle de lister med firmaer og CVR-numre, du ønsker. Book en demo, få en guidet rundtur og få den fulde oplevelse i 3 dage.', 'import')"
        >
          <div
            slot="toggle"
            class="button border-left"
          >
            <i
              class="fa fa-cloud-upload"
              v-if="isNarrow"
            />
            <span v-else>{{ translate('import') }}</span>
            <i
              v-if="!isNarrow"
              class="fa fa-angle-down fa-xs dropdown-indicator"
            />
          </div>
          <import-dropdown
            :tag="tag"
            :tagEntities="entities"
            :entityType="tag.type"
            :visible="importerOpen"
            @import-done="getList(true)"
          />
        </dropdown>

        <!-- EXPORT COMAPNIES -->
        <dropdown
          v-if="userCanExport"
          left
          :minWidth="250"
          @opened="onExportsOpened"
          :EventBus="EventBus"
        >
          <div
            slot="toggle"
            class="button border-left"
            @click="($event) => checkIfTrial($event, 'Eksporter oplysninger', 'Med fuld adgang til Lasso X kan du eksportere al den data, du ønsker til bl.a. Excel dit CRM-system eller via API. Book en demo, få en guidet rundtur og oplev mulighederne selv.', 'exporter')"
          >
            <i
              v-if="isNarrow"
              class="fa fa-cloud-download"
            />
            <span v-else>{{ translate('export') }}</span>
            <i
              v-if="!isNarrow"
              class="fa fa-angle-down fa-xs dropdown-indicator"
            />
          </div>
          <div
            class="export-wrapper-outer dark"
            v-bar
          >
            <div class="export-wrapper-inner">
              <div class="export-wrapper-max-height">
                <div
                  class="dropdown-description"
                  v-lang.export-description="{email:$store.state.user.email}"
                />
                <span class="dropdown-divider" />
                <div
                  v-show="$store.state.gettingExports"
                  class="text-muted dropdown-description"
                >
                  Indlæser..
                </div>
                <div
                  v-if="!$store.state.gettingExports && tag.type !== 'Company'"
                  class="text-muted dropdown-description"
                >
                  <span v-lang.no-person-exports />
                </div>

                <template v-else>
                  <div
                    v-for="ex in excelExports"
                    :key="ex.name"
                    class="dropdown-item"
                    @click="doExport(ex)"
                  >
                    {{ ex.title }}
                  </div>

                  <template v-if="thirdPartyExports.length">
                    <span class="dropdown-divider" />

                    <div
                      v-for="ex in thirdPartyExports"
                      :key="ex.name"
                      class="dropdown-item"
                      @click="doExport(ex)"
                    >
                      {{ ex.title }}
                    </div>
                  </template>
                </template>
              </div>
            </div>
          </div>
        </dropdown>

        <!-- SHARE LIST -->
        <dropdown
          v-if="userCanShare && !isFreemium"
          left
          :minWidth="500"
          :EventBus="EventBus"
        >
          <div
            slot="toggle"
            class="button border-left text-pink"
          >
            <i
              v-if="!$store.getters.isFreemium"
              class="fa"
              :class="tagAccess && !tagAccess.organization.canView && tagAccess.users.length === 1 ? 'fa-lock' : 'fa-unlock'"
              style="font-size: 16px"
            />
            <span
              v-show="!isNarrow"
              :style="!$store.getters.isFreemium ? 'margin-left: 8px' : ''"
            >{{ tagAccess && !tagAccess.organization.canView && tagAccess.users.length === 1 ? translate('share') : translate('shared') }}</span>
          </div>
          <div
            v-if="tag.followTag"
            class="dropdown-description with-icon"
          >
            <i class="fa fa-info-circle" /><div v-lang.follow-tag-share-description="{ entityType: tag.type === 'Company' ? translate('companies') : translate('people') }" />
          </div>
          <tag-share-settings
            v-else-if="tagAccess"
            :tag="tag"
            :tagAccess="tagAccess"
            :currentUserId="$store.state.user.id"
            @tagAccessChanged="onTagAccessChanged"
          />
        </dropdown>

        <!-- FOLLOW LIST -->
        <dropdown
          v-if="!readOnly"
          left
          noPadding
          :minWidth="300"
          @opened="onOpenTagFollowList"
          @closed="onFollowListClose"
          :EventBus="EventBus"
          :conditionalToggle="!isFreemium || tag.id === tagFollowId"
          @click.native="($event) => tag.id !== tagFollowId && checkIfTrial($event, 'Aktiv overvågning', 'Få de rette opdateringer i realtid og følg bedre med via en fuld adgang til Lasso X. Book en demo, få en guidet rundtur og prøv den fulde oplevelse i 3 dage.', 'monitoring')"
        >
          <div
            slot="toggle"
            class="button border-left ellipsis text-pink"
          >
            <i
              class="fa fa-rss"
              style="font-size: 16px"
            />
            <span
              v-show="!isNarrow"
              style="margin-left: 8px"
            >{{ $store.state.productAccess ? translate('notifications') : isMonitoring ? translate('following') : translate('follow') }}</span>
          </div>
          <follow-list-dropdown
            v-if="tagFollowListOpen"
            :hasMonitoring="hasMonitoring"
            :tag="tag"
            :currentUserId="$store.state.user.id"
            :isFreemium="isFreemium"
          />
        </dropdown>
      </div>
    </div>
    <div id="listContentWrapper">
      <div id="content">
        <div
          id="entityList"
          class="min-max-height dark"
          v-show="!isMobile || isMobile && view === 'list'"
          v-bar
        >
          <div
            class="min-max-height"
            @scroll="onScroll"
          >
            <div v-show="loadingTag">
              <h4 class="text-muted text-thin text-center loading-list">
                <span v-lang.loading />..
              </h4>
            </div>
            <div v-show="!loadingTag">
              <h4
                v-show="entities.length === 0"
                class="text-muted text-thin text-center empty-list"
              >
                <span v-lang.empty-list />
              </h4>
              <!-- all -->
              <div
                class="entity-list-item"
                v-show="entities.length"
                :class="{ disabled: totalEntities > 1000 }"
                tooltip-placement="bottom"
                :tooltip="totalEntities > 1000 ? 'Du kan højest vælge 1.000 ' + this.typeNamePlural : ''"
              >
                <div
                  class="selector"
                  @click="totalEntities <= 1000 && toggleAll()"
                >
                  <div
                    class="check"
                    :class="{ checked: allIsSelected }"
                  >
                    <i class="fa fa-check" />
                  </div>
                </div>
                <div
                  class="info text-bold"
                  @click="totalEntities <= 1000 && toggleAll()"
                >
                  Vælg alle <span
                    class="entity-count text-normal"
                    v-show="!loadingTag"
                  >({{ entityTypeSingleOrPlural }})</span>
                </div>
              </div>

              <div
                class="entity-list-item"
                v-for="entity in entities"
                :key="entity.lassoId"
              >
                <div
                  class="selector"
                  @click="toggleEntity(entity)"
                >
                  <div
                    class="check"
                    :class="{ checked: entity.selected }"
                  >
                    <i class="fa fa-check" />
                  </div>
                </div>
                <div
                  @click="openInNewTab(entity)"
                  class="info"
                >
                  {{ entity.name }}
                </div>
              </div>

              <div />
            </div>
          </div>
        </div>

        <div
          v-show="!isMobile || isMobile && view === 'news'"
          v-bar
          id="informationView"
          class="min-max-height dark dragger-wide"
        >
          <div class="min-max-height">
            <div
              v-if="!news && $store.state.productAccess"
              class="empty-list-description min-max-height"
            >
              <h1>Gem {{ typeNamePlural }} og hold dig opdateret</h1>
              <p class="text-muted">
                Listen er tom. Søg derfor et firma eller en person frem i søgebaren i toppen. Når du har fundet den du søger, klikker du på "Gem" for at tilføje til listen. Klik på "Notifikationer" i højre hjørne for at modtage e-mails når der sker ændringer på de {{ typeNamePlural }} du har gemt.
              </p>
            </div>
            <div
              v-else
              class="news-wrapper"
            >
              <div
                v-show="news && loadingNews || loadingMoreNews"
                class="loading-filter"
              />
              <div
                v-if="totalEntities > 10000"
                class="lassox-portal__Alert alert-type-info"
              >
                <Icon
                  icon="info"
                  size="small"
                />
                <span class="t-body">Vær opmærksom på, der vises kun nyheder for de første 10.000 virksomheder på en liste.</span>
              </div>
              <news-feed
                v-if="news"
                :news="news"
                feedType="list"
                :loading="loadingNews || loadingMoreNews"
                @load-more="onLoadMoreNews"
              />
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<style lang="less" scoped>
@import "@oldShared/lasso/style/base/icons";
@import "@oldShared/lasso/style/constants/colors";

.text-pink {
  color: #FF8191;
}

a.disabled {
  pointer-events: none;
  cursor: default;
  user-select: none;
  color: gray;
}

  .entity-pagination {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 10px 20px;
  }

  #list {
    display: flex;
    flex-direction: column;

    #listHeader {
      height: 48px;
      font-size: 14px;
      background-color: #fff;
      border-bottom: 1px solid #dfdfdf;

      // box-shadow: 0 1px 5px 1px rgba(50,50,50,.2);
      z-index: 10;
      display: flex;
      align-items: center;
      justify-content: space-between;

      .list-title {
        display: flex;
        align-items: center;
        width: 300px;
        min-width: 300px;
        // &.has-selected {
        //   width: 300px;
        //   min-width: 300px;
        // }
        // padding-right: 15px;
        line-height: 48px;
        .selector {
          width: 35px;
          flex: 0 0 35px;
        }

        .list-type-toggle {
          flex-grow: 1;
          text-align: center;
          text-transform: capitalize;
          cursor: pointer;
          &.active {
            border-bottom: 1px solid #ffa07a;
          }
        }

        .info.name {
          font-size: 14px;
          flex: 1 1;
          padding: 0 15px 0 11px;
        }
        .edit-name {
          width: 100%;
          padding-right: 5px;
          i {
            top: -2px;
            color: @text-muted;
          }
          input.editing-list-name {
            opacity: 0.8;
            height: 47px;
            width: 100%;
            border: 0 solid #E5E5E5;
            color: black;
            font-weight: 500;
            padding-left: 11px;
            background-color: #f8f8f8;
          }
        }
      }

      .actions {
        display: flex;
        align-items: center;
        font-weight: 500;
        .export-wrapper {
          &-max-height {
            max-height: 400px;
            margin-right:-17px;
          }
        }
        .dropdown-item {
          // line-height: 20px;
          &:hover {
            background-color: #F8F9FA;
          }
          &.disabled {
            color: #8F979C;
            // opacity: 0.9;
            cursor: default;
            .toggle {
              cursor: default;
            }
          }
          .menu {
            top: 0;
            left: 100%;

          }
        }
        .button {
          &:not(.text-pink) {
            color: #262626;
          }
          white-space: nowrap;
          height: 48px;
          line-height: 48px;
          padding: 0 20px;

          &.border-left {
            border-left: 1px solid #dfdfdf;
          }

          // &.border-right {
          //   border-left: 1px solid #dfdfdf;
          // }
        }

        .close img {
          margin-right: 30px;
        }
      }
    }

    #listContentWrapper {
      width: unset;
      flex-grow: 1;
      position: relative;

      #content {
        position: absolute;
        height: 100%;
        width: 100%;
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
        flex-direction: row;
        display: flex;

        .empty-list-description {
          display: flex;
          flex-direction: column;
          align-items: center;
          justify-content: center;
          h1 {
            font-size: 18px;
            margin-bottom: 20px;
          }
          p {
            max-width: 60%;
          }
        }

        #entityList {
          flex-basis: 300px;
          flex-shrink: 0;
          border-right: 1px solid #dfdfdf;
          max-height: 100%;

          h4.empty-list, h4.loading-list {
            margin-top: 30px;
          }

          .entity-list-item {
            display: flex;
            align-items: center;
            height: 34px;
            cursor: pointer;

            &:first-child {
              padding-top: 2px;
            }

            &:hover {
              background: #f8f8f8;
            }

            .selector {
              height: 34px;
              width: 35px;
              flex: 0 0 35px;
            }

            .info {
              font-size: 13px;
              padding-right: 5px;
              // text-transform: uppercase;
              flex-grow: 1;
              text-overflow: ellipsis;
              overflow: hidden;
              white-space: nowrap;
              .entity-count {
                color: #777;
              }
            }
            &.disabled {
              color: #777;
              .selector {
                &:hover {
                  cursor: default;
                }
              }
              &:hover {
                cursor: default;
              }
            }
          }
        }

        #informationView {
          flex-grow: 1;
          background-color: #f8f8f8;

          .news-wrapper {
            padding: 15px;
            position: relative;

            .lassox-portal__Alert {
              border: 1px solid #E0E0E0;
              border-radius: 2px;
              box-sizing: border-box;
              display: flex;
              justify-content: flex-start;
              align-items: center;
              max-width: 1140px;
              margin: 0 auto;
              color: #616161;
              &.alert-type-info {
                background: white;
                padding:16px 32px;
              }
              .md-icon {
                margin-right: 16px;
              }
            }

            .news-feed:not(.xs) {
              padding-top: 15px;
            }

            .loading-filter {
              background-color: rgba(255, 255, 255, 0.5);
              position: absolute;
              height: 100%;
              width: 100%;
              z-index: 99;
            }
          }
        }
      }
    }

    .min-max-height {
      max-height: 100%;
      height: 100%;
    }

    #mobileMenu {
      display: flex;
      font-size: 14px;
      color: #222;
      text-transform: capitalize;
      background-color: #f8f8f8;
      .menu-item {
        flex: 1;
        line-height: 47px;
        padding: 0 15px;
        cursor: pointer;
        text-align: center;
        border-bottom: 1px solid #dfdfdf;
        &+.menu-item {
          border-left: 1px solid #dfdfdf;
        }

        &.selected {
          border-bottom-color: #ffa07a;
          color: #ffa07a;
        }
      }
    }

    &.narrow {
      #listHeader {
        .list-title {
          width: 250px;
          min-width: 250px;
        }
      }

      #listContentWrapper #content #entityList {
        flex-basis: 250px;
      }
    }

    &.mobile {
      #listHeader {
        .list-title {
          border-right: none;
          width: 100%;
        }
        .actions {
          display: none;
        }
      }

      #listContentWrapper {
        #content {
          display: block;
          #entityList {
            border-right: none;
          }
        }
      }
    }

    &.read-only {
      #listHeader {
        .list-title {
          .selector {
            display: none;
          }
          .name {
            padding-left: 15px;
          }
        }
      }

      #entityList {
        .entity-list-item {
          .selector {
            display: none;
          }
          .info {
            padding-left: 15px;
          }
        }
      }
    }
  }
  .tag-manager .tags-wrapper .tags .selectable-tag {
    font-size: 13px;
  }
</style>
