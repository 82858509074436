<script lang="ts" setup>
import { store } from '@/assets/js/store'
import type Tab from '@/types/Tab.ts'
import { OverlayConfig, onShowTabOverlay, overlayInjectionKey } from '@/util/overlay'
import { ref, watch, computed, Ref, defineProps, provide, onMounted, InjectionKey } from 'vue'
import { EventBus } from '@/assets/js/helpers'
import AppView from './AppView.vue'
import Home from './Home.vue'
import HomeSimple from './HomeSimple.vue'
import List from './List.vue'
import SearchView from './SearchView.vue'
import TabViewOverlay from './TabViewOverlay.vue'
import TrialOverlay from './overlays/TrialOverlay.vue'
import { getEnvironment } from '@/assets/js/helpers'
import TrialExpiringOverlay from './overlays/TrialExpiringOverlay.vue'
import TrialExpiredOverlay from './overlays/TrialExpiredOverlay.vue'
import PremiumFeatureTrialOverlay from './overlays/PremiumFeatureTrialOverlay.vue'
import PremiumFeatureExpiredTrialOverlay from './overlays/PremiumFeatureExpiredTrialOverlay.vue'
import { tabInjectionKey } from '@/types/Tab.ts'

const props = defineProps<{tab: Tab}>()

provide(tabInjectionKey, props.tab)
// For the non-typescripts
provide('tab', props.tab)

const inactiveSize = ref<Record<'width' | 'height',  number> | null>(null)
const direct = computed(() => store.state.direct)
const isActive = computed(() => store.state.activeTab?.id === props.tab.id)

const component = computed(() => {
  if (!props.tab.route) return null
  const route = props.tab.route

  switch (route.name) {
    case 'news':
      return {
        is: (store.state.readOnly && store.state.productAccess)
          ? HomeSimple
          : Home,
      }
    case 'search':
    case 'start-trial':
      return {
        is: SearchView,
      }

    case 'entity':
      return {
        is: AppView,
        props: {
          get menu () { return !direct.value || route.query.menu !== undefined },
          appType: 'entityview',
        },
      }

    case 'list':
      return {
        is: List,
      }

    case 'app':
      return {
        is: AppView,
        props: {
          get menu () { return !direct.value || route.query.menu !== undefined },
          appType: 'standalone',
        },
      }

    // Used by Simon in the newsletter, otherwise, trialOverlay is mostly triggered by non-freemium feature clicks
    default:
      return null
  }
})

const root = ref<HTMLElement | null>(null)

watch(isActive, (isActive) => {
  if (isActive) {
    inactiveSize.value = null
  } else if (root.value){
    const rect = root.value.getBoundingClientRect()
    inactiveSize.value = { width: rect.width, height: rect.height }
  }
}, { immediate: true })


const tabViewOverlay : Ref<typeof TabViewOverlay | undefined> = ref()
const overlayConfig: Ref<OverlayConfig | undefined> = ref()

onShowTabOverlay((config: OverlayConfig) => {
  if (isActive.value) {
    overlayConfig.value = config
    tabViewOverlay.value?.show()
  }
})

provide(overlayInjectionKey, overlayConfig)

onMounted(() => {
  if( props.tab.route?.name === 'start-trial')
  {
    overlayConfig.value = { component: PremiumFeatureTrialOverlay }
    tabViewOverlay.value?.show()

    // It's hard for non-programmers to test the various states of trial so it can can be tested on development envirornments via URL params ?test=expiring / ?test=expired
    const testParam = new URLSearchParams(window.location.search).get('test')
    store.state.user.organization.currentSolution.versionUniqueName

    if (getEnvironment() !== 'Production' && testParam)
      if (testParam === 'signup') {
        store.state.user.organization.currentSolution.versionUniqueName = 'lasso_lite'
        overlayConfig.value = { component: TrialOverlay }
      }
      else if (testParam === 'expiring') {
        store.state.user.organization.currentSolution.versionUniqueName = 'lasso_pro'
        overlayConfig.value = { component: TrialExpiringOverlay }
      }
      else if (testParam === 'expired'){
        store.state.user.organization.currentSolution.versionUniqueName = 'lasso_pro'
        overlayConfig.value = { component: TrialExpiredOverlay }
      }
      else if (testParam === 'premium-expired'){
        store.state.user.organization.currentSolution.versionUniqueName = 'lasso_pro'
        overlayConfig.value = { component: PremiumFeatureExpiredTrialOverlay }
      }
  }

  const config = overlayConfig.value
  if (config?.source)
    EventBus.$emit('june:trackEvent', { event: 'Premium feature triggered', traits: { trigger: config.source, trialExpired: !store.state.user.organization?.canCreateTrial } })
})


</script>

<template>
  <div
    ref="root"
    :class="[
      'lassox-portal__TabView',
      isActive && 'lassox-portal__TabView--active',
    ]"
    :style="inactiveSize ? {
      width: `${inactiveSize.width}px`,
      height: `${inactiveSize.height}px`,
    } : {}"
  >
    <component
      v-if="component"
      :is="component.is"
      class="lassox-portal__TabView-inner"
      v-bind="component.props"
    />

    <TabViewOverlay
      ref="tabViewOverlay"
      :config="overlayConfig"
    />
  </div>
</template>

<style lang="scss">
.lassox-portal__TabView {
  position: absolute;
  top: 0;
  left: 0;
  display: flex;
  width: 100%;
  height: 100%;
  visibility: hidden;
  overflow: hidden;

  &--active {
    position: relative;
    visibility: visible;
  }

  &-inner {
    width: 100%;
    height: 100%;
    min-width: 0;
    min-height: 0;
  }

  .lasso-wrapper {
    flex-grow: 1;
  }
}
</style>
