<script>
import Button from '@/components/Button.vue'
import { EventBus } from '@/assets/js/helpers.js'

export default {
  name: 'BookDemoModal',
  props: {
    config: { type: Object, required: true }
  },
  data () {
    return {
      // loadingForm: true,
      context: null,
      title: null,
      description: null,
      source: null
    }
  },
  computed: {
    lowerImage () {
      switch (this.context) {
        case 'riskreport':
        case 'creditreport':
        case 'contacts':
          return true
        default:
          return false
      }
    },
    showContactUnderButtons () { return this.context !== 'integrations-api' },
    showBottomContact () { return this.context === 'integrations-api' },
    callToActionImage () {
      switch (this.context) {
        case 'exporter':
        case 'import':
        case 'save-to-list':
          return require('@/assets/img/demo/Eksporter_computer_cut.png')
        case 'twinfinder':
          return require('@/assets/img/demo/Tvilling_img_cut.png')
        case 'contacts':
          return require('@/assets/img/demo/Kontaktpersoner_mobil_cut.png')
        case 'monitoring':
          return require('@/assets/img/demo/Overvågning_img_cut.png')
        case 'organizationreport':
        case 'cvrkeyfigures':
          return require('@/assets/img/demo/Koncernregnskab_computer_cut.png')
        case 'google_phonenumbers':
          return require('@/assets/img/demo/Google_telefonnummer_cut.png')
        case 'print':
          return require('@/assets/img/demo/Udskriv_computer_cut.png')
        case 'paqle':
          return require('@/assets/img/demo/Paqle_computer_cut.png')
        case 'ritzau':
          return require('@/assets/img/demo/Ritzau_computer_cut.png')
        case 'search':
          return require('@/assets/img/demo/Målgruppe_img_cut.png')
        case 'phonedata':
          return require('@/assets/img/demo/Teledata_img_cut.png')
        case 'dataexport':
          return require('@/assets/img/demo/Dataudtræk_img_cut.png')
        case 'creditreport':
          return require('@/assets/img/demo/Kreditrapport_mobil_cut.png')
        case 'editableownershipdiagram':
          return require('@/assets/img/demo/Ejerdiagram_computer_cut.png')
        case 'integrations-api':
          return require('@/assets/img/demo/Integrationer_img_cut.png')
        case 'riskreport':
          return require('@/assets/img/demo/Risikorapport_mobil_cut.png')
        default:
          return require('@/assets/img/demo/Eksporter_computer_cut.png')
      }
    },
    blob () {
      switch (this.context) {
        case 'exporter':
          return require('@/assets/img/svg/blobs/peach.svg?inline')
        case 'contacts':
        case 'print':
        case 'editableownershipdiagram':
        case 'monitoring':
        case 'integrations-api':
        case 'save-to-list':
          return require('@/assets/img/svg/blobs/peach_pink.svg?inline')
        case 'phonedata':
        case 'google_phonenumbers':
          return require('@/assets/img/svg/blobs/pink.svg?inline')
        case 'cvrkeyfigures':
        case 'organizationreport':
        case 'creditreport':
        case 'paqle':
        case 'ritzau':
        case 'twinfinder':
        case 'search':
        case 'dataexport':
        case 'import':
          return require('@/assets/img/svg/blobs/violet.svg?inline')
        default:
          return require('@/assets/img/svg/blobs/peach_pink.svg?inline')
      }
    }
  },
  methods: {
    openLink (url, event) {
      EventBus.$emit('june:trackEvent', { event, traits: { trigger: this.source } })
      open(url, '_blank')
    }
  },
  components: {
    Button,
  },
  created () {
    this.title = this.config.title ?? 'Book demo'
    this.description = this.config.description ?? null
    this.context = this.config.context ?? null
    this.source = this.config.source ?? null
    EventBus.$emit('june:trackEvent', { event: 'Book demo modal triggered', traits: { trigger: this.source } })
  }
}
</script>

<template>
  <div
    id="bookDemoModal"
    class="lassox-portal__BookDemoModal"
  >
    <div class="modal-center">
      <div class="lassox-portal__BookDemoModal-content">
        <h1
          class="lassox-portal__BookDemoModal-title"
          v-text="title"
        />
        <p
          class="lassox-portal__BookDemoModal-description"
          v-html="description"
        />
        <div class="lassox-portal__ButtonGroup">
          <!-- Do generalize this if more cases of contextual buttons are added-->
          <Button
            v-if="context === 'integrations-api'"
            class="lassox-portal__Integrations-button"
            type="outlined"
            label="Se alle integrationer"
            @click="openLink('https://lassox.com/integrationer','Book demo modal see all integrations')"
          />
          <Button
            class="lassox-portal__BookDemoModal-button"
            type="primary"
            label="Book demo her"
            @click="openLink('https://lassox.com/book-demo', 'Book demo page opened')"
          />
          <div
            v-if="showContactUnderButtons"
            style="flex-basis: 100%;"
          >
            eller ring <a href="tel:+4571747812">71 74 78 12</a>
          </div>
        </div>
        <a
          v-if="context === 'integrations-api'"
          class="lassox-portal__IntegrationsApi-link"
          href="#"
          @click="openLink('https://docs.lassox.com/', 'Book demo modal see API')"
        >Se API dokumentation</a>
        <div
          v-if="showBottomContact"
          class="lassox-portal__BookDemoModal-contact-text"
        >
          Brug for hjælp? Ring <a href="tel:+4571747812">71 74 78 12</a>
        </div>
      </div>
      <div class="img-container">
        <img
          class="lassox-portal__BookDemoModal-cta-img"
          :style="{
            bottom: lowerImage ? 0 : undefined
          }"
          :src="callToActionImage"
          alt="Demo illustration"
        >
        <img
          class="lassox-portal__BookDemoModal-blob"
          :src="blob"
          alt="Demo Illustration"
        >
      </div>
    </div>
  </div>
</template>

<style lang="less" scoped>
  #bookDemoModal {
    font-family: 'Roboto', sans-serif;
    min-width: 800px;
    position: relative;
    // background: url('../../assets/img/freemium-blob.png') no-repeat;
    background-size: 120%;
    background-position: top left;
    background-color: white;
    overflow: hidden;
    border-radius: 8px;

    &.lassox-portal__BookDemoModal {

      .lassox-portal__BookDemoModal-content {
        .lassox-portal__BookDemoModal-title {
          font-weight: 600;
          font-size: 22px;
          line-height: 28px;
          margin-bottom: 32px;
        }
        .lassox-portal__BookDemoModal-description {
          font-size: 16px;
          line-height: 24px;
          margin-bottom: 32px;
          // Base modal resizes to 95% width below this
          @media (min-width: 577px) {
            width: 360px;
          }
        }
        .lassox-portal__ButtonGroup {
          display: flex;
          gap: 16px 8px;
          flex-wrap: wrap;
        }

        .lassox-portal__BookDemoModal-button {
          padding: 0 24px;
        }

        .lassox-portal__IntegrationsApi-link {
          font-size: 14px;
          margin-top: 16px;
          position: absolute;
        }
        .lassox-portal__BookDemoModal-contact-text {
          position: absolute;
          bottom: 42px;
          left: 42px;
          z-index: 2;

          &, & a {
            color: #fff;
          }
        }
      }

      .img-container {
        position: relative;
        margin: -40px -30px;
        min-height: 300px;
        z-index: 0;
        pointer-events: none;
        .lassox-portal__BookDemoModal-cta-img {
          position: absolute;
          right: 0;
          bottom: 60px;
          width: 400px;
          z-index: 2;
          user-select: none;
        }
        .lassox-portal__BookDemoModal-blob {
          position: absolute;
          right: 0;
          bottom: 0;
          width: 100%;
          z-index: 1;
        }
      }
    }

    // &.lassox-portal__BookDemoModal-sales {

    // }
  }
  @media screen and (max-width: 840px) {
    #bookDemoModal {
      min-width: unset;
      .img-container .lassox-portal__BookDemoModal-cta-img {
        display: none;
      }
    }
  }
</style>
