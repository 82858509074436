<script>
import Vue from 'vue'
import { EventBus } from '@/assets/js/helpers.js'
export default {
  name: 'ExpandableResume',
  props: {
    'resume': {
      type: Object,
      required: true
    },
    'expandable': {
      type: Boolean,
      default: true
    },
    'goToByExpanding': {
      type: Boolean,
      default: false
    }
  },
  data () {
    return {
      loading: false
    }
  },
  computed: {
    resumeComponent: function () {
      return {
        template: '<span>' + (this.resume.content ? Vue.filter('linkify')(this.resume.content, false) : '') + '</span>'
      }
    }
  },
  methods: {
    expand: function () {
      if (this.goToByExpanding) {
        this.$router.push({ name: 'person', params: { id: 4004111600 } })
      } else {
        this.loading = true
        EventBus.$emit('loadLongResume')
      }
    }
  },
  components: {
  }
}
</script>

<template>
  <div class="expandable-resume">
    <p class="text-semimuted resume-text">
      <strong style="margin-right: 10px;">Opdateret {{ new Date().getDate() }}. {{ new Date().getMonth() | monthName }}</strong><dynamic
        :comps="[resumeComponent]"
        :emptyView="{template: '<span></span>'}"
      />
    </p>
    <div
      v-show="resume.shortVersion && expandable"
      class="expander"
    >
      <div class="gradient" />
      <a
        v-show="!loading"
        class="btn btn-md btn-primary"
        @click="expand(), $root.trackEvent({eventCategory:'Click', eventAction: 'Erhvervsresume (læs mere)', eventLabel: $route.fullPath})"
      >VIS MERE</a>
      <div
        v-show="loading"
        class="loader"
      >
        <img src="@/assets/img/preloader.gif">
        <span>Analyserer netværk..</span>
      </div>
    </div>
  </div>
</template>

<style lang="less">
  .expandable-resume {

    p.resume-text {
      line-height: 26px;
      a {
        color: #616161;
      }
    }

    .expander {
      margin-top: -78px;
      .gradient {
        height: 78px;
        position: relative;
        z-index: 1;
        background: -moz-linear-gradient(top, rgba(255,255,255,0) 0%, rgba(255,255,255,1) 100%);
        background: -webkit-linear-gradient(top, rgba(255,255,255,0) 0%,rgba(255,255,255,1) 100%);
        background: linear-gradient(to bottom, rgba(255,255,255,0) 0%,rgba(255,255,255,1) 100%);
        filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#00ffffff', endColorstr='#ffffff',GradientType=0 );
      }
      .btn {
        // margin-top: 15px;
        padding-left: 30px;
        padding-right: 30px;
      }

      .loader {
        height: 51px;
        display: flex;
        padding-top: 5px;
        justify-content: center;
        align-items: center;
        img {
          height: 45px;
          width: 45px;
          margin-right: 12px;
        }
        span {
          font-size: 16px; 
          color: rgba(97,97,97,.7);
        }
      }
    }
  }

  #app.xxs, #app.xs {
    .expander {
      text-align: center;
    }
  }
</style>
