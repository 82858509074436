<script lang="ts">
import { Vue, Component } from 'vue-property-decorator'
import Tooltip from './Tooltip.vue'
import { getStore, TooltipOptions } from '../tooltips'

@Component({
  components: {
    Tooltip,
  },
})

export default class TooltipRoot extends Vue {
  prevTooltips = new Set<TooltipOptions>()
  map = new Map<TooltipOptions, {
    remove: () => void,
  }>()

  activeTooltips: TooltipOptions[] = []

  get store () { return getStore(this.$root) }

  get tooltips () { return this.store.tooltips }

  created () {
    this.$watch(
      () => this.tooltips,
      () => {
        const newMap: this['map'] = new Map

        const tooltips = new Set(this.tooltips)

        for (const [ tooltip, { remove } ] of this.map) {
          if (tooltips.has(tooltip)) continue
          remove()
        }

        for (const tooltip of tooltips) {
          let mapItem = this.map.get(tooltip)

          if (mapItem) {
            newMap.set(tooltip, mapItem)
            continue
          }

          // eslint-disable-next-line @typescript-eslint/no-empty-function
          let unbindMouseEvents = () => {}
          // eslint-disable-next-line @typescript-eslint/no-empty-function
          let unwatchEl = () => {}

          mapItem = {
            remove: () => {
              unbindMouseEvents()
              unwatchEl()
              this.map.delete(tooltip)
            },
          }

          unwatchEl = this.$watch(
            () => tooltip.el,
            el => {
              unbindMouseEvents()

              if (el) {
                let mousedOver = false
                let showing = false

                const show = () => {
                  if (showing) return
                  this.activeTooltips.push(tooltip)
                  showing = true
                }

                const hide = () => {
                  if (!showing) return
                  const index = this.activeTooltips.indexOf(tooltip)
                  if (index !== -1) this.activeTooltips.splice(index, 1)
                  showing = false
                }

                const onMouseEnter = () => {
                  mousedOver = true
                  show()
                }

                const onMouseLeave = () => {
                  mousedOver = false
                  requestAnimationFrame(() => {
                    if (!mousedOver) hide()
                  })
                }

                el.addEventListener('mouseenter', onMouseEnter)
                el.addEventListener('mouseleave', onMouseLeave)

                unbindMouseEvents = () => {
                  el.removeEventListener('mouseenter', onMouseEnter)
                  el.removeEventListener('mouseleave', onMouseLeave)
                  mousedOver = false
                  hide()
                }
              }
            },
            { immediate: true },
          )

          newMap.set(tooltip, mapItem)
        }

        this.prevTooltips = tooltips
        this.map = newMap
      },
      { immediate: true },
    )
  }
}
</script>

<template>
  <div class="lassox-portal__TooltipRoot">
    <Tooltip
      v-for="(tooltip, i) in activeTooltips"
      :key="i"
      v-bind="{
        ...tooltip,
        overrideShow: typeof tooltip.overrideShow === 'boolean'
          ? tooltip.overrideShow
          : true,
      }"
    />
  </div>
</template>

<style lang="scss">
.lassox-portal__TooltipRoot {
  position: fixed;
  z-index: 1;
  top: 0;
  left: 0;
  width: 0;
  height: 0;
}
</style>
