import { DAWAAutocompleteSuggestion } from "@/types/DAWAAutocomplete"
import { CompaniesResult, PeopleResult } from "@/types/EntitySearchResponse"
import ResultType from "@/types/ResultType"

type Lit = string | number | bigint | boolean | symbol | unknown[] | Record<string, unknown> | ((...args: unknown[]) => unknown) | null | undefined

export const isRecord = <K extends string = string> (v: unknown): v is Record<K, unknown> => typeof v == 'object' && v !== null

export const isArray = (v: unknown): v is unknown[] => Array.isArray(v)

export const isString = (v: unknown): v is string => typeof v === 'string'

export const isNumber = (v: unknown): v is number => typeof v === 'number'

export const isBoolean = (v: unknown): v is boolean => typeof v === 'boolean'

export const isNull = (v: unknown): v is null => v === null

export const isUndefined = (v: unknown): v is undefined => v === undefined

export const isNullish = (v: unknown): v is null | undefined => isNull(v) || isUndefined(v)

export const isNonNullish = <T> (v: T | null | undefined): v is T => !isNullish(v)

export const isOneOf = <T extends Lit> (v: unknown, t: T[]): v is T => {
  for (const type of t) if (v === type) return true
  return false
}

export const isOneOfTypes = <G extends (v: unknown) => v is unknown> (v: unknown, guards: G[]): v is G extends (v: unknown) => v is infer T ? T : unknown => {
  for (const guard of guards) {
    if (guard(v)) return true
  }
  return false
}

export function isDAWAResult (result: ResultType): result is DAWAAutocompleteSuggestion {
  return ('forslagstekst' in result)
}
export function isCompaniesResult (result: ResultType): result is CompaniesResult {
  return ('entityType' in result) && result.entityType === 'Company'
}
export function isPeopleResult (result: ResultType): result is PeopleResult {
  return ('entityType' in result) && result.entityType === 'Person'
}
