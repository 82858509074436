<script lang="ts">
  export default {
    name: 'SwitchAccountModal'
  }
</script>
<script setup lang="ts">
import { store } from '@/assets/js/store';
import Icon from '@/components/Icon.vue'
import { Organization } from '@/types/Organization';
import formatDate from '@/util/date-format';
import { computed, onBeforeMount, ref } from 'vue';
import LoadingSpinner from '../LoadingSpinner.vue';

const emit = defineEmits(['close'])

const loadingAvailableOrgs = ref(false)

const organizations = computed<Organization[]>(() => store.state.availableOrganizations)

const switchAccount = (organization: Organization) => {
  store.dispatch('switchOrganization', organization)
  emit('close')
}

onBeforeMount(async () => {
  loadingAvailableOrgs.value = true
  await store.dispatch('getAvailableOrgs')
  loadingAvailableOrgs.value = false
})
</script>
<template>
  <div class="lassox-portal__SwitchAccountModal">
    <div class="modal-center">
      <h1 class="mb-2">Skift konto</h1>
      <p class="mt-2 mb-8">
        Du er tilknyttet flere konti. Vælg en anden konto for at<br>logge ind med denne.
      </p>
      <div v-if="loadingAvailableOrgs" style="display: flex; align-items: center; justify-content: center; gap: 8px; flex-wrap: wrap;">
        <LoadingSpinner preset="lg" style="flex-basis: 100%" />
        <p class="typography-title">Indlæser konti...</p>
      </div>
      <div v-else class="lassox-portal__SwitchAccountModal-organizations">
        <template v-for="(organization, i) in organizations">
          <div
            v-if="i"
            :key="`${organization.lassoOrganizationId}-separator`"
            class="organization-separator"
          />
          <div
            :key="organization.lassoOrganizationId"
            class="lassox-portal__SwitchAccountModal-organization"
            :class="{
              selected: organization.lassoOrganizationId === $store.state.user.organization.lassoOrganizationId,
            }"
            :title="organization.lassoOrganizationId === $store.state.user.organization.lassoOrganizationId ? 'Du er allerede logget ind på denne konto' : ''"
            @click="() => {
              if (organization.lassoOrganizationId !== $store.state.user.organization.lassoOrganizationId) {
                switchAccount(organization)
              }
            }"
          >
            <div class="lassox-portal__SwitchAccountModal-organization-text">
              <div class="lassox-portal__SwitchAccountModal-organization-title">
                {{ organization.name }}
                <small v-if="organization.lassoOrganizationId === $store.state.user.organization.lassoOrganizationId">(nuværende)</small>
              </div>
              <div class="lassox-portal__SwitchAccountModal-organization-subtitle">
                {{ organization.currentSolution?.productName }}, oprettet d. {{ formatDate(organization.created) }}
              </div>
            </div>
            <Icon class="lassox-portal__SwitchAccountModal-organization-trailing-icon" icon="arrow_forward_ios_outline_rounded" />
          </div>
        </template>
      </div>
    </div>
  </div>
</template>
<style lang="scss">
@import "@shared/style/_typography.scss";
.lassox-portal__SwitchAccountModal {
  padding: 0 20px;

  h1 { @include typography('name'); }
  p { @include typography('body'); }

  .lassox-portal__SwitchAccountModal-organizations {
    display: flex;
    flex-direction: column;
    margin: -20px 0;
    margin-top: -4px;

    .lassox-portal__SwitchAccountModal-organization {
      display: flex;
      flex-direction: row;
      align-items: center;
      padding: 20px;
      margin: 0 -20px;
      border-radius: 8px;
      cursor: pointer;

      &.disabled {
        cursor: not-allowed;
        opacity: 0.5;
        user-select: none;

        &:hover {
          background-color: transparent;
        }
      }
  
      &-text {
        display: flex;
        flex-direction: column;
        flex-grow: 1;
        padding-right: 16px;
      }
  
      &-title {
        margin: -4px 0;
        color: #212121;
        font-size: 17px;
        line-height: 24px;
        font-weight: 500;
        display: flex;
        align-items: center;
        gap: 8px;

        small {
          color: #777;
          font-size: 14px;
          line-height: 24px;
          font-weight: 400;
        }
      }
  
      &-subtitle {
        margin: -4px 0;
        margin-top: 8px - 4px;
        font-size: 14px;
        line-height: 24px;
      }
  
      &-trailing-icon {
        display: flex;
        justify-content: center;
        align-items: center;
        width: 18px;
        height: 18px;
        font-size: 18px;
        line-height: 18px;
        text-align: center;
      }
  
      i.fa {
        font-size: 18px;
        color: #616161;
      }
  
      &:hover {
        background-color: #F1F1F1;
      }
  
      &.selected {
        background-color: #E2E2E2;
      }
    }
  }

}
</style>