<script lang="ts">
import { Vue, Component, Prop } from 'vue-property-decorator'
import IconButton from '@shared/vue-lasso-core/components/IconButton.vue'
import Icon from '@/components/Icon.vue'
import { unwatchRect, watchRect } from '@/util/watch-rect'

const sizes = {
  '1x': 320,
  '2x': 360,
  '3x': 400,
  '4x': 440,
  '5x': 480,
}

let idInt = 0

type Tab = { name: string, title: string, icon?: string, onClick: () => void }

@Component({
  inheritAttrs: false,

  components: {
    IconButton,
    Icon,
  },
})

export default class Dialog extends Vue {
  id = idInt++

  @Prop(Boolean)
  readonly dismissible?: boolean

  @Prop(Boolean)
  readonly showCloseButton?: boolean

  @Prop(String)
  readonly title?: string

  @Prop(String)
  readonly content?: string

  @Prop({ type: [ String, Number ], default: '1x' })
  readonly size!: string | number

  @Prop(Function)
  readonly onClose?: () => void

  contentEl: HTMLElement | null = null
  contentInnerEl: HTMLElement | null = null

  activeTab: Tab | null = null

  scrollable = false

  get attrs () {
    const normalizeDynamic = (value: any) => value ? [ value ] : []

    const attrs = {
      ...this.$attrs,
      class: normalizeDynamic(this.$attrs.class),
      style: normalizeDynamic(this.$attrs.style),
    }

    if (this.$vnode.data) {
      const { data } = this.$vnode

      if (data.staticClass) attrs.class.push(data.staticClass)
      if (data.staticStyle) attrs.class.push(data.staticStyle)
      if (data.class) attrs.class.push(data.class)
      if (data.style) attrs.class.push(data.style)
    }

    return attrs
  }

  get maxWidth () {
    if (typeof this.size === 'number') return this.size
    if (this.size in sizes) return sizes[this.size as keyof typeof sizes]
    return sizes['1x']
  }

  get slotProps () {
    return {
      close: this.close,
    }
  }

  mounted () {
    this.updateRefs()

    this.$nextTick(() => this.updateRefs())

    this.$watch(
      () => this.contentInnerEl,
      (contentInnerEl, oldContentInnerEl) => {
        this.scrollable = false
        if (oldContentInnerEl) unwatchRect(oldContentInnerEl, this.onContentInnerElResize)
        if (contentInnerEl) watchRect(contentInnerEl, this.onContentInnerElResize)
      },
      { immediate: true },
    )
  }

  updated () {
    this.updateRefs()
  }

  beforeDestroy () {
    this.contentEl = null

    if (this.contentInnerEl) unwatchRect(this.contentInnerEl, this.onContentInnerElResize)
    this.contentInnerEl = null
  }

  updateRefs () {
    this.contentEl = (this.$refs.contentEl as HTMLElement | undefined) ?? null
    this.contentInnerEl = (this.$refs.contentInnerEl as HTMLElement | undefined) ?? null
  }

  onContentInnerElResize (rect: DOMRect) {
    const { contentEl, contentInnerEl } = this

    if (!contentEl || !contentInnerEl) return
    this.scrollable = rect.height > contentEl.getBoundingClientRect().height
  }

  tabOnClick (tab: Tab) {
    this.activeTab = tab
    tab.onClick()
  }

  close () {
    this.$emit('close')
    this.onClose?.()
  }
}
</script>

<template>
  <portal
    to="dialogs"
    slim
  >
    <div
      :key="id"
      v-bind="attrs"
      :class="[
        'lassox-portal__Dialog',
        scrollable && 'lassox-portal__Dialog--scrollable',
        ...attrs.class,
      ]"
    >
      <div
        class="lassox-portal__Dialog-backdrop"
        @click.self="() => dismissible && close()"
      />

      <div
        class="lassox-portal__Dialog-box"
        :style="{ maxWidth: `${maxWidth}px` }"
        tabindex="-1"
      >
        <slot>
          <div
            v-if="title || dismissible || $slots['header'] || $slots['title']"
            class="lassox-portal__Dialog-header"
          >
            <slot
              name="header"
              v-bind="slotProps"
            >
              <div
                v-if="title"
                class="lassox-portal__Dialog-title"
              >
                <slot
                  name="title"
                  v-bind="slotProps"
                >
                  <div
                    v-if="title"
                    v-text="title"
                  />
                </slot>
              </div>

              <IconButton
                v-if="(dismissible && showCloseButton)"
                class="lassox-portal__Dialog-close-button"
                icon="close"
                size="normal"
                @click="close"
              />
            </slot>
          </div>

          <div
            v-if="content || $slots['content']"
            ref="contentEl"
            class="lassox-portal__Dialog-content"
          >
            <div
              ref="contentInnerEl"
              class="lassox-portal__Dialog-content-inner"
            >
              <slot
                name="content"
                v-bind="slotProps"
              >
                <div
                  v-if="content"
                  v-text="content"
                />
              </slot>
            </div>
          </div>

          <div
            v-if="$slots['actions']"
            class="lassox-portal__Dialog-actions"
          >
            <slot
              name="actions"
              v-bind="slotProps"
            />
          </div>
        </slot>
      </div>
    </div>
  </portal>
</template>

<style lang="scss">
@import '@shared/style/global.scss';

.lassox-portal__Dialog {
  position: fixed;
  z-index: 2;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 32px;
  pointer-events: none;

  &-backdrop {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: rgba(black, 0.32);
    pointer-events: auto;
  }

  &-box {
    position: relative;
    display: flex;
    flex-direction: column;
    width: 100%;
    border-radius: 8px;
    background-color: white;
    overflow: hidden;
    box-shadow: 0 2px 10px 0 rgba(black, 0.2);
    pointer-events: auto;

    &:focus {
      outline: none;
    }
  }

  &-header,
  &-content-inner,
  &-actions {
    padding: 24px;
  }

  &-header,
  &-content,
  &-actions {
    &:not(:first-child) {
      margin-top: -24px;
    }
  }

  &-header {
    display: flex;
    align-items: center;
    flex-shrink: 0;
    flex-grow: 0;
  }

  &-title {
    @include typography('title');
    flex-grow: 1;
    flex-shrink: 1;
    margin-right: auto;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }

  &-title + &-close-button {
    margin-left: 32px;
  }

  &-close-button {
    margin: -6px;
  }

  &-content {
    flex-shrink: 1;
    flex-grow: 1;
    overflow: hidden;
    overflow-y: auto;
  }

  &-actions {
    flex-shrink: 0;
    flex-grow: 0;
  }

  $this: &;

  &--scrollable {
    #{$this}-header,
    #{$this}-content,
    #{$this}-actions {
      margin-top: 0;
    }

    #{$this}-header {
      padding-bottom: 25px;
      box-shadow: inset 0 -1px 0 0 rgba(black, 0.12);
    }

    #{$this}-actions {
      padding-top: 25px;
      box-shadow: inset 0 1px 0 0 rgba(black, 0.12);
    }
  }
}
</style>
