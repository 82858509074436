import Vue from 'vue'
export default {
  number: function test (str) {
    return str.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',')
  },
  timeSince: function (date, translator) {
    var now = new Date()
    var diff = new Date(date).getTime() - now.getTime()
    var seconds = Math.abs(Math.round(diff / 1000))
    if (seconds < 45) return seconds + ' ' + (seconds === 1 ? translator('second') : translator('seconds')) + ' ' + translator('ago')
    var minutes = Math.round(seconds / 60)
    if (minutes < 45) return minutes + ' ' + (minutes === 1 ? translator('minute') : translator('minutes')) + ' ' + translator('ago')
    var hours = Math.round(minutes / 60)
    if (hours < 22) return hours + ' ' + (hours === 1 ? translator('hour') : translator('hours')) + ' ' + translator('ago')
    var days = Math.round(hours / 24)
    if (days < 26) return days === 1 ? translator('yesterday') : (days + ' ' + translator('days') + ' ' + translator('ago'))
    var months = Math.round(days / 31)
    if (months < 11) return months + ' ' + (months === 1 ? translator('month') : translator('months')) + ' ' + translator('ago')
    var years = Math.round(days / 365)
    return years + ' ' + (years === 1 ? translator('year') : translator('years')) + ' ' + translator('ago')
  },
  daysBetween: function (date1, date2) {
    var diff = date1.getTime() - date2.getTime()
    return Math.abs(Math.round(diff / (1000 * 60 * 60 * 24)))
  },
  pluralize: function (count, zeroText, oneText, multipleText, hideCount, translator) {
    var countText = hideCount ? '' : (count + ' ')
    if (count === 0) return countText + translator(zeroText)
    else if (count === 1) return countText + translator(oneText)
    else return countText + translator((multipleText || zeroText))
  },
  truncateAlways: function (text, length) {
    if (text) {
      if (text.length <= length) {
        return text.substring(0, text.length - 4) + ' ...'
      } else {
        return Vue.filter('truncate')(text, length, ' ...', true)
      }
    } else return ''
  },
  monthName: function (month) {
    switch (month) {
      case 0: return 'januar'
      case 1: return 'februar'
      case 2: return 'marts'
      case 3: return 'april'
      case 4: return 'maj'
      case 5: return 'juni'
      case 6: return 'juli'
      case 7: return 'august'
      case 8: return 'september'
      case 9: return 'oktober'
      case 10: return 'november'
      case 11: return 'december'
    }
  },
  possessiveEnding: function (text, language) {
    if (text && language !== 'en') {
      if (text.substring(text.length - 1) === 's') return text + '\''
      else return text + 's'
    } else return text
  },
  capitalize: function (text) {
    return text.substring(0, 1).toUpperCase() + text.substring(1)
  },
  linkify: function (text, doNotCreateLinks, truncate) {
    if (text) {
      var regex = /{([^}]*)}/g
      var regex2 = /\{([^}]+)\}/
      // find all links, take unique
      var links = text.match(regex)
      // for each, generate html or simple text depending on doNotCreateLinks param
      if (links) {
        links.forEach(function (link) {
          var content = link.match(regex2)[1].split('|')
          if (content.length === 2) {
            if (doNotCreateLinks) text = text.replace(link, content[0])
            else text = text.replace(link, '<router-link exact :to="{path: \'/' + content[1] + '\'}">' + content[0] + '</router-link>')
          }
        })
      }
      return truncate ? Vue.filter('truncateAlways')(text, truncate) : text
    }
  }
}
