<template>
  <span id="dots1" class="dots" ref="dots">.</span>
</template>

<script>
export default {
  props: {
    speed: {
      type: Number,
      default: 300
    },
  },
  name: 'dots',
  data () {
    return {}
  },
  computed: {},
  methods: {
  },
  mounted () {
    let dots = this.$refs['dots']
    setInterval(() => {
      if ( dots.innerHTML.length > 2 ) 
        dots.innerHTML = "";
    else 
        dots.innerHTML += ".";
    }, this.speed)
  }
}
</script>

<style lang="less">

</style>


