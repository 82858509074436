<script setup lang="ts">
import { EventBus } from '@/assets/js/helpers'
import { store } from '@/assets/js/store'
import axios from '@/axios'
import Button from '@/components/Button.vue'
import { Organization, User } from '@/types/User'
import windowSize from '@/window-size'
import Icon from '@/components/Icon.vue'
import TextInput from '@shared/vue-lasso-core/components/TextInput.vue'
import { Ref, computed, defineEmits, inject, onMounted, onUnmounted, ref, watch } from 'vue'
import { useRoute } from 'vue-router/composables'
import CompanyField, { SearchCompany } from '../CompanyField.vue'
import filters from '@/assets/js/filters'
import trialApi from '@/util/trialApi'
import { tabInjectionKey } from '@/types/Tab.ts'
import router from '@/assets/js/router'
import { overlayInjectionKey } from '@/util/overlay'

const props = withDefaults(defineProps<{ mode?: 'expiring' | 'expired' | 'signup' }>(), { mode: 'signup' })

type Trial = {
  title: string
  solutionName: string
  priceLink: string
  lengthInDays: number
  features: string[]
}

const config = inject(overlayInjectionKey)

type Trials = Record<'marketData' | 'sales' | 'finance', Trial>

type TrialDecorated = Trial & {recommended?: true, position?: 'left' | 'middle' | 'right'}

const trials = {
  marketData: {
    title: 'Markedsdata',
    solutionName: 'lasso_pro',
    priceLink: 'https://lassox.com/loesninger/markedsdata#priser',
    lengthInDays: 7,
    features: [
      'Stamoplysninger',
      'Nøgletal',
      'Ejerdiagram',
      'Målgruppesøgning',
      'Erhvervshistorik',
    ],
  },
  sales: {
    title: 'Salg & Marketing',
    priceLink: 'https://lassox.com/loesninger/salg-marketing#priser',
    solutionName: 'lasso_sales_marketing_pro',
    lengthInDays: 14,
    features: [
      'Prospecting ',
      'Tvillingesøgning',
      'Kontaktinfo',
      'Salgstrigger',
      'CRM-integration'
    ],

  },
  finance: {
    title: 'Finans',
    priceLink: 'https://lassox.com/loesninger/finans', /* todo: Maybe they get around to defining a finance package? */
    solutionName: 'lasso_finance_pro',
    lengthInDays: 7,
    features: [
      'Ejerdiagram',
      'Nøgletal',
      'Ejendomme',
      'Risikovurdering',
      'Virksomhedsresumé',
    ],
  }
} satisfies Trials

const selectedTrial = ref() satisfies Ref<TrialDecorated | undefined>

const isMobile = computed(() => { return windowSize.width < 700 /* or 700px? */ })

const trialsOrdered = computed((): TrialDecorated[] => {
  const questionaire = store.state.user?.settings?.questionaire

  // As decided in https://linear.app/lassox/issue/DEL-791/freemium-replace-book-demo-with-start-trial-flow-frontend#comment-64ceab55
  const isFinancey = questionaire?.useCase === 'Finde og styre investeringer via performance og nøgletal' || questionaire?.userType === 'Finans & regnskab' || questionaire?.userType === 'Ledelse'

  const trialsDecorated : TrialDecorated[]  =
    questionaire?.isSalesy
      ? [ { ...trials.marketData, position: 'left' }, { ...trials.sales, position: 'middle', recommended: true }, { ...trials.finance, position: 'right' } ]
      : isFinancey
        ? [ { ...trials.marketData, position: 'left' }, { ...trials.finance, position: 'middle', recommended: true }, { ...trials.sales, position: 'right' } ]
        : [ { ...trials.sales, position: 'left' }, { ...trials.marketData, position: 'middle', recommended: true }, { ...trials.finance, position: 'right' } ]

  // Recommended is fist on mobile and middle on desktop, since you can't see all on them on the former
  const trialsSorted = isMobile.value ? trialsDecorated.sort((a, b) => {
    if (a.recommended && !b.recommended) {
      return -1
    } else if (!a.recommended && b.recommended) {
      return 1
    } else {
      return 0
    }
  }) : trialsDecorated

  if (selectedTrial.value) {
    const selectedStyleTrial = trialsSorted.find(({ solutionName }) => solutionName === selectedTrial.value?.solutionName)
    if (selectedStyleTrial)
      return [ selectedStyleTrial ]
  }
  return trialsSorted
})

//#region Signup
const initialSolutionName = props.mode === 'expired' ? store.state.user.organization.previousSolution?.versionUniqueName : store.state.user.organization.currentSolution.versionUniqueName
const initialTrial = trialsOrdered.value.find((trial) => trial.solutionName === initialSolutionName?.toLowerCase())
if(initialTrial)
  selectedTrial.value = initialTrial

const today = new Date()
today.setHours(0, 0, 0, 0)
const expiryDate = store.state.user.organization?.currentSolution?.toDate
const daysToExpiry = expiryDate ? filters.daysBetween(new Date(expiryDate), today) : null

const emit = defineEmits([ 'close' ])

const scrollableElement: Ref<HTMLElement | null> = ref(null)

onMounted(async () => {
  // Neither @scroll event nor ref="" seems to work on TransitionGroup, so we have to select it old-school like
  scrollableElement.value = document.querySelector('.transition-container')

  if (scrollableElement.value) {
    const el = scrollableElement.value
    el.addEventListener('scroll', updateDotsOnScroll)
    onUnmounted(() => {
      el.removeEventListener('scroll', updateDotsOnScroll)
    })
  }
})

const route = useRoute()

const user: Ref<User> = ref(store.state.user)
const searchCompany: Ref<Partial<SearchCompany>> = ref({ lassoId: store.state.user.organization.lassoId })

const finished = ref(false)
const loading = ref(false)

const createTrial = async () => {
  if (!selectedTrial.value) throw new Error('No trial selected')

  const organization: Partial<Organization> = {
    lassoId: searchCompany.value?.lassoId,
    name: searchCompany.value?.name,
    address1: searchCompany.value?.address1,
    zipCode: searchCompany.value?.postalCode + '',
    city: searchCompany.value?.city,
  }

  loading.value = true
  try {
    const promiseResults = await Promise.allSettled([
      axios.put<Organization>('organizations/me', { ...store.state.user.organization, ...organization }),
      !user.value.phoneNumber && axios.put('users/me', { ...user.value, phoneNumber: user.value.organization.contactPhone }),
      axios.post('/apps/settings/me/subscriptions/trial/start?version=' + selectedTrial.value.solutionName)
    ])

    loading.value = false
    const responsesOK = promiseResults.every((result) => result.status === "fulfilled")
    if (!responsesOK) {
      promiseResults.forEach((result) => {
        if (result.status === "rejected")
          alert(result.reason.response?.data?.errorMessage)
      })
      return
    }
    finished.value = true
    // Pretty much whatever the user clicks next, we want to refresh the page to load in the new modules
    watch(route, refresh)
  } catch (error) {
    alert(error)
  }
}
const tab = inject(tabInjectionKey)

const refresh = () => {
  if(tab?.route?.name === 'start-trial' )
    tab.replaceRoute(router.resolve({ path: 'search' })?.route)
  if (finished.value)
    location.reload()
}

onUnmounted(refresh)

const scrollElement: Ref<HTMLElement | null> = ref(null)

const trialElements = ref<HTMLElement[]>([])

const scrollToTrial = (index: number) => {
  if (trialElements.value.length > index)
    trialElements.value[index].scrollIntoView({ behavior: 'smooth', block: 'center', inline: 'center' })
}

const activeDots: Ref<boolean[]> = ref([ true, false, false ])

const updateDotsOnScroll = (event: Event) => {
  const scrollEl = event.target as HTMLElement
  const maxScroll = scrollEl.scrollWidth - scrollEl.offsetWidth
  // Calculate index by splitting scroll position into equal parts
  const index = Math.round((scrollEl.scrollLeft / maxScroll) * (activeDots.value.length - 1))
  activeDots.value = [ false, false, false ]
  activeDots.value[index] = true
}

//#endregion
const showConfirm = ref(false)
const requestContactAndClose = async () => {
  EventBus.$emit('june:trackEvent', { event: 'Contact Me clicked', traits: { trigger: `Trial ${props.mode} reminder` } })
  loading.value = true
  await trialApi.requestContact(store.state.user, store.state.user.organization.lassoId)
  showConfirm.value = true
  loading.value = true
  setTimeout(() => { emit('close') }, 3000)
}
</script>

<template>
  <section
    class="start-trial-root design2024 start-trial"
    :class="isMobile ? 'mobile' : 'desktop'"
  >
    <TransitionGroup
      tag="div"
      class="transition-container"
      :class="trialsOrdered.length === 1 && 'single-trial-layout'"
      ref="scrollElement"
    >
      <div
        v-if="!selectedTrial"
        class="trials-header"
        key="trials-header"
      >
        <h1 class="title typography-title">
          Prøv den fulde løsning gratis
        </h1>
        <p class="typography-headline subtext">
          {{ config?.subtitle || 'Vælg den pakke, der passer til dit behov, og kom i gang nu.'}}
        </p>
      </div>

      <article
        v-for="(trial, i) in trialsOrdered"
        :key="trial.solutionName"
        :class="[
          'trial-card',
          trial.recommended && 'recommended-trial',
          trial.position === 'left' && !isMobile && 'left-card',
          trial.position === 'middle' && !isMobile && 'middle-card',
          trial.position === 'right' && !isMobile && 'right-card',
          selectedTrial && 'selected-trial'
        ]"
        :ref="el => {
          // @ts-ignore
          trialElements[i] = el
        }"
      >
        <div
          class="recommended-banner"
          v-if="trial.recommended"
        >
          Anbefalet til dig
        </div>
        <h2 class="trial-title typography-title">
          {{ trial.title }}
        </h2>
        <ul
          class="feature-list"
        >
          <li
            class="feature-item"
            v-for="feature in trial.features.slice(0, trial.recommended ? 5 : 4)"
            :key="feature + trial.solutionName"
          >
            <Icon
              icon="task_alt"
              :style="{ color: trial.recommended ? 'var(--dark-peach)' : 'black' }"
              :size="trial.recommended || isMobile ? 'normal' : 'smallish'"
            />
            <span class="typography-headline">{{ feature }}</span>
          </li>
        </ul>

        <a
          :href="trial.priceLink"
          class="typography-headline typography-link"
          @click="() => { EventBus.$emit('june:trackEvent', { event: 'View Prices clicked', traits: { solution: trial.solutionName } }) }"
          target="_blank"
        >Se priser</a>
        <div
          class="fade-transition"
          :style="{ opacity: selectedTrial ? 0 : 1 }"
        >
          <Button
            label="Prøv gratis"
            :type="trial.recommended ? 'primary' : 'outlined-primary'"
            class="start-trial-button typography-headline "
            @click.prevent="() => {
              selectedTrial = trial
              EventBus.$emit('june:trackEvent', { event: 'Try Free trial clicked', traits: { solution: trial.solutionName, recommended: trial.recommended } })
            }"
          />
        </div>
        <!-- TODO: Some "back" button? -->
      </article>

      <div
        v-if="selectedTrial"
        key="signup-container"
        class="signup-container fade-transition"
      >
        <Transition mode="out-in">
          <template v-if="mode === 'signup'">
            <form
              class="fade-transition"
              @submit.prevent="createTrial"
              v-if="!finished"
            >
              <h2 class="typography-title">
                Opret prøveperiode
              </h2>
              <p
                style="margin-top: 16px;"
                class="typography-headline"
              >
                Indtast dine oplysninger og få<br v-if="!isMobile"> {{ selectedTrial?.lengthInDays }} dages gratis adgang med det samme
              </p>
              <CompanyField
                v-model="searchCompany"
                style="margin-top: 24px"
                placeholder="Firmanavn"
                name="job-company"
              />
              <TextInput
                placeholder="Telefonnummer"
                style="margin-top: 24px"
                v-model="user.organization.contactPhone"
              />
              <Button
                :label="loading ? 'Opretter...' : 'Opret'"
                :disabled="loading || !user.organization.contactPhone || user.organization.contactPhone.length < 8 || !searchCompany.name ? true : false"
                @click="EventBus.$emit('june:trackEvent', { event: 'Trial Created', traits: { solution: selectedTrial.solutionName, recommended: selectedTrial.recommended ?? false } })"
                type="primary"
                class="signup-button"
              />
            </form>
            <div
              v-else
              class="finished-container fade-transition"
            >
              <h2 class="typography-title">
                Din prøveperiode er aktiveret
              </h2>
              <p
                style="margin-top: 16px;"
                class="typography-headline"
              >
                Klik på knappen og kom i gang med at udforske dine nye muligheder.
              </p>
              <Button
                label="Kom i gang"
                type="primary"
                class="standard-margin-top"
                @click.prevent="() => { refresh() }"
              />
            </div>
          </template>
          <div v-else-if="showConfirm">
            <h2 class="typography-title">
              Tak! Du hører fra os
            </h2>
          </div>
          <div
            v-else-if="mode === 'expiring'"
            style="width: 410px;"
          >
            <h2 class="typography-title">
              Din prøveperiode udløber snart
            </h2>
            <p
              style="margin-top: 16px;"
              class="typography-headline"
            >
              Om {{ daysToExpiry }}  dage vil du derfor miste adgangen til en række værktøjer.</br></br>
              Klik på knappen, hvis du ønsker fortsat at have fuld adgang. Så kontakter vi dig og fortæller om dine muligheder.
            </p>
            <Button
              label="Kontakt mig"
              type="primary"
              class="standard-margin-top"
              :disabled="loading"
              @click.prevent="requestContactAndClose"
            />
            <p class="typography-headline standard-margin-top">
              Du er også velkommen til at ringe til os på
              <a href="tel:+4571747812">71 74 78 12</a>
            </p>
          </div>
          <div
            v-else-if="mode === 'expired'"
            style="width: 410px;"
          >
            <h2 class="typography-title">
              Din prøveperiode er udløbet
            </h2>
            <p
              style="margin-top: 16px;"
              class="typography-headline"
            >
              Du har derfor mistet adgang til en række værktøjer og informationer.
              <br><br>
              Klik på knappen, hvis du ønsker du at få adgang igen. Så kontakter vi dig og fortæller om dine muligheder.
            </p>
            <Button
              label="Kontakt mig"
              type="primary"
              style="margin-top: 32px;"
              class="standard-margin-top"
              :disabled="loading"
              @click.prevent="requestContactAndClose"
            />
            <p class="typography-headline standard-margin-top">
              Du er også velkommen til at ringe til os på
              <a href="tel:+4571747812">71 74 78 12</a>
            </p>
            <p
              style="margin-top: 32px;"
              class="typography-headline"
            >
              Fortsætter du uden abonnement, har du nu adgang til
              den gratis udgave af Lasso,
              hvor du stadig kan gøre brug af flere af vores funktioner, dog med visse begrænsninger.
            </p>
          </div>
        </Transition>
      </div>
      <footer
        class="swipe-dot-container"
        key="swipe-dot-container"
        v-if="!selectedTrial && isMobile"
      >
        <div
          v-for="(trial, index) in [1, 1, 1]"
          :key="'dot' + index"
          class="swipe-dot"
          @click="scrollToTrial(index)"
          :class="{ 'active-dot': activeDots[index] }"
        />
      </footer>
    </TransitionGroup>
  </section>
</template>

<style scoped>
.start-trial-root {
  /* ? maybe this should be generalized in TabViewOverLay, but not sure if there's a good universal mobile breakpoint for all overlays */
  &.mobile {
    height: 100%;
  }

  &.desktop {
    display: flex;
    align-items: center;
    justify-content: center;
    min-height: 100%;
    padding-block: 64px;
  }
}

.title {
  text-align: center;
}

.subtext {
  margin-top: 8px;
  text-align: center;
  margin-bottom: 72px;
}

.mobile {
  .transition-container {
    height: 100%;

    position: relative;
    max-width: 100vw;
    overflow: overlay;
    scrollbar-width: none;
    padding: 92px 32px 32px 32px;
    column-gap: 16px;
    scroll-snap-type: x mandatory;
    --card-column: calc(50% + (286px / 2));
    grid-template-columns: auto auto auto;
  }

  .trials-header,
  .signup-container {
    /* Make the this element stay still despite being in the scrollable container */
    position: sticky;
    max-width: calc(100vw - 64px);
    left: 0;
    text-align: left;
    grid-row: 1;
    grid-column: 1 / -1;
    width: 100%;
  }

  .subtext {
    margin-bottom: 32px;
  }

  .subtext,
  .title {
    text-align: left;
  }

  .trial-card {
    grid-row: 2;
    height: 444px;
  }

  /* The element should appear centered when scrolled all the way to the left or right  */
  --centering-margin: calc(50vw - (286px / 2) - 32px);

  article:nth-of-type(1) {
    margin-left: var(--centering-margin);

    &.v-leave-to {
      transform: translateX(-100vw) !important;
    }
  }

  article:nth-of-type(2) {
    &.v-leave-to {
      transform: translateX(-100vw) !important;
    }

    .selected-trial+&.v-leave-to {
      transform: translateX(100vw) !important;
    }
  }

  article:nth-of-type(3) {
    margin-right: var(--centering-margin);

    &.v-leave-to {
      transform: translateX(100vw) !important;
    }
  }

  article.selected-trial {
    margin-left: var(--centering-margin);
    margin-right: 0;
  }

  .v-move.trial-card.v-leave-active {
    transition: transform var(--transition-length), opacity var(--transition-length);
  }

  .v-leave-to {
    opacity: 0;
  }

  .typography-headline {
    font-size: 16px;
    font-weight: 500;
    line-height: 24px;
  }

  .signup-container {
    margin-left: 0;
    margin-bottom: 32px;
  }

  .swipe-dot-container {
    display: flex;
    justify-content: center;
    gap: 8px;
    position: sticky;
    grid-row: 3;
    grid-column: 1 / -1;
    left: 0;
    margin-top: 32px;
    /* grid-column: ; */
    max-width: calc(100vw - 64px);

    .swipe-dot {
      width: 18px;
      height: 18px;
      border-radius: 100%;
      background-color: var(--dark-peach);
      opacity: 0.2;

      &.active-dot {
        opacity: 1;
      }
    }
  }
}

.transition-container {
  display: grid;
  grid-template-columns: min-content auto auto;
  grid-template-rows: auto auto 1fr;
  /* header "shakes" during transition if width isn't set  */
  width: 750px;
  /* row-gap: 72px; */
}

.trials-header {
  position: relative;
  grid-column: 1 / -1;
}

.recommended-banner {
  position: absolute;
  top: 18px;
  right: -85px;
  font-size: 13px;
  transform: rotate(25deg);
  /* TODO: Fiddle with these values */
  width: 100%;
  color: white;
  background: #FF8E61;
  padding-top: 2px;
  padding-bottom: 2px;
  text-align: center;
}

.trial-card {
  position: relative;
  display: inline-block;
  height: 378px;
  width: 286px;
  padding: 36px 16px;
  border-radius: 16px;
  box-shadow: 0px 0px 16px 0px #00000014;
  display: flex;
  flex-direction: column;
  align-items: center;
  background-color: white;
}

.left-card,
.right-card {
  margin-top: 40px;
  z-index: 0;
  width: 248px;
}

.left-card {
  margin-right: -16px;
}

.right-card {
  margin-left: -16px;
}

.recommended-trial {
  height: 444px;
  z-index: 10;
}

.trial-title {
  margin-left: auto;
  margin-right: auto;
  display: block;
  text-align: center;
}

.feature-list {
  margin-top: 44px;
  display: flex;
  flex-direction: column;
  flex-grow: 1;
  gap: 14px;
}

.feature-item {
  display: flex;
  align-items: center;
  gap: 16px;
}

.start-trial-button {
  margin-top: 20px;
  font-size: 13px;
}

.recommended-trial {
  overflow: hidden;
  padding-top: 56px;

  .typography-headline,
  .typography-link {
    font-size: 16px;
    line-height: 24px;
    font-weight: 500;
  }

  .feature-list {
    gap: 10px;
    margin-top: 36px;
  }
}

.signup-container {
  margin-left: 88px;
  width: 330px;
  margin-top: auto;
  margin-bottom: auto;
  grid-column: 2 / -1;
}

.start-trial-root {
  --transition-length: 750ms;
}

.signup-button {
  margin-top: 32px;
  margin-left: auto;
}

.standard-margin-top {
  margin-top: 32px;
}

/* Transition  */
.v-leave-active.trial-card {
  position: absolute;
}

.v-leave-active.trials-header,
.v-leave-active.signup-container {
  height: 0;
}

.mobile .v-leave-active.swipe-dot-container {
  height: 0;
  margin: 0 !important;
}

.v-move {
  transition: transform var(--transition-length), opacity var(--transition-length);
}

.fade-transition {
  transition: opacity var(--transition-length);
}

.trials-header {
  transition: opacity 300ms, transform 1000ms;
}

.v-leave-to.trials-header {
  opacity: 0;
  transform: translateY(-128px);
}

.mobile .v-leave-to.trials-header {
  opacity: 0;
  transform: translateY(0px);
}

.v-enter.signup-container,
.v-enter.fade-transition,
.v-leave-to.fade-transition,
.v-leave-to.trials-header .v-leave-to.swipe-dot-container {
  opacity: 0;
}

.v-leave-to.trial-card {
  transform: scale(0.7) translateY(-70px);
  z-index: 5;
}

.v-leave-to.trial-card.middle-card {
  transform: scale(0.6);
}

.selected-trial {
  margin: 0;
  z-index: 20;
}
</style>
