<script>
import SelectableTag from '@/components/SelectableTag'
import { EventBus, Tags } from '@/assets/js/helpers.js'
import { orderBy } from 'lodash'
export default {
  name: 'TagManager',
  props: {
    tags: { type: Array, required: true },
    entities: { type: Array, required: true },
    inPopup: { type: Boolean, default: false }
  },
  data () {
    return {
      follow: false,
      tagActions: {},
      tagGroups: {},
      saving: false,
      addMode: false, // this signals that we're adding a single entity that is currently not on any lists
      tagFilter: '',
      creatingTag: false
    }
  },
  watch: {
    entities: {
      immediate: true,
      handler () {
        this.initialize(this.tags, this.entities)
        if (this.entities.length === 1 && this.tags.length > 0) {
          this.addMode = true
        }
      }
    }
  },
  methods: {
    initialize (tags, entities) {
      this.loadingTags = true
      tags.forEach(t => {
        let hasTagCount = 0
        for (const e of entities) {
          if (!e.tags) continue
          if (e.tags.includes(t.id)) hasTagCount++
        }
        this.$set(this.tagGroups, t.id, {
          tag: t,
          id: t.id,
          entitiesWithTag: hasTagCount === 0 ? 'none' : hasTagCount < this.entities.length ? 'some' : 'all',
          toAdd: [],
          toRemove: [],
          action: 'none'
        })
        this.loadingTags = false
      })
    },
    cancel () {
      this.resetActions()
      this.$emit('cancel')
    },
    onTagToggle (tagId) {
      var group = this.tagGroups[tagId]
      switch (group.action) {
        case 'none': group.action = group.entitiesWithTag === 'all' ? 'remove' : 'add'; break
        case 'add': group.action = group.entitiesWithTag === 'none' ? 'none' : 'remove'; break
        case 'remove': group.action = 'none'; break
      }
    },
    resetActions () {
      Object.values(this.tagGroups).forEach(group => {
        group.action = 'none'
      })
    },
    removeFromAllTags () {
      EventBus.$emit('modal', {
        modalType: 'confirm',
        confirmTitle: this.translate('remove-from-all-tags') + '?',
        confirmDescription: this.translate('remove-from-all-tags-description', { entityType: this.tags[0].type === 'Company' ? this.translate('companies') : this.translate('people') }),
        onConfirm: () => {
          Object.keys(this.tagGroups).map(key => {
            if (this.tagGroups[key].entitiesWithTag !== 'none') {
              this.tagGroups[key].action = 'remove'
            }
          })
          this.onSave()
        }
      })
    },
    getTagGroup (tagId) {
      return this.tagGroups[tagId]
    },
    onSave () {
      this.saving = true
      const changes = {
        entities: this.entities.map(e => ({lassoId: e.lassoId})),
        tagsToAdd: Object.keys(this.tagGroups).map(key => this.tagGroups[key]).filter(g => g.action === 'add').map(g => g.id),
        tagsToRemove: Object.keys(this.tagGroups).map(key => this.tagGroups[key]).filter(g => g.action === 'remove').map(g => g.id)
      }
      this.$store.dispatch('updateEntityTags', changes).then(() => {
        this.entities.forEach(e => {
          // removed tags are removed from entities
          e.tags = e.tags.filter(t => changes.tagsToRemove.indexOf(t) === -1)
          // added tags are added to entities if not already there
          changes.tagsToAdd.filter(t => e.tags.indexOf(t) === -1).forEach(t => e.tags.push(t))
        })
        this.saving = false
        this.$notify({ title: this.translate('changes-saved') })
        this.$emit('done', changes)
        this.$emit('close')
      })
    },
    createTag (tagName, tagType) {
      if (this.creatingTag) return
      this.creatingTag = true
      this.$store.dispatch('createTag', { tagName: tagName, tagType: tagType }).then(newTag => {
        this.creatingTag = false
        this.initialize([ newTag ], [])
      })
    },
    refreshScrollbar () {
      window.setTimeout(() => {
        this.$vuebar.destroyScrollbar(this.$refs.scroll)
        this.$nextTick(() => this.$vuebar.initScrollbar(this.$refs.scroll, {}))
      }, 0)
    }
  },
  computed: {
    hasChanges () {
      return Object.keys(this.tagGroups).some(key => this.tagGroups[key].action !== 'none')
    },
    sortedTags () {
      return this.tags.sort((a, b) => a.name.localeCompare(b.name, 'da-DK'))
    },
    filteredTags () {
      return this.sortedTags.filter(t => t.name.toLowerCase().indexOf(this.tagFilter.toLowerCase()) > -1)
    },
    exactMatch () {
      return this.filteredTags.some(t => t.name.toLowerCase() === this.tagFilter.toLowerCase())
    },
    noTagsDescription () {
      if (this.filteredTags.length === 0 && this.tagFilter.length > 0) return this.translate('no-lists-with-text', { text: this.tagFilter })
      else return this.translate('enter-list-name-to-create')
    }
  },
  components: {
    selectableTag: SelectableTag
  }
}
</script>

<template>
  <div
    class="tag-manager"
    :class="{ 'in-popup': inPopup }"
  >
    <div id="tagFilter">
      <a
        v-if="!exactMatch && tagFilter.length > 0"
        href="#"
        @click="createTag(tagFilter, entities[0].entityType)"
      >{{ creatingTag ? translate('creating') + '..' : translate('create-list') }}</a>
      <i
        v-else
        class="fa fa-search text-muted"
      />
      <input
        v-focus
        v-model="tagFilter"
        :placeholder="translate('search-create-list')"
      >
    </div>
    <div
      class="tags-wrapper dark"
      v-bar
      ref="scroll"
    >
      <div class="tags">
        <selectable-tag
          v-for="tag in filteredTags"
          :tagGroup="getTagGroup(tag.id)"
          @onToggle="onTagToggle"
          :key="tag.id"
        />
        <div
          class="padding-left-right-1 padding-top-bottom-2 text-center text-muted no-tags-description"
          v-show="filteredTags.length === 0"
        >
          {{ noTagsDescription }}
        </div>
      </div>
    </div>
    <div
      v-if="!inPopup"
      class="bottom d-flex padding-left-1"
      :class="{'justify-content-end': !hasChanges}"
    >
      <a
        href="#"
        v-show="hasChanges"
        style="margin-right: 15px"
        @click="onSave"
        v-lang.save-changes
      />
      <a
        href="#"
        v-show="hasChanges"
        class="text-muted"
        @click="cancel"
        v-lang.cancel
      />
      <a
        href="#"
        v-show="!hasChanges"
        style="margin-right: 15px"
        @click="removeFromAllTags"
        v-lang.remove-from-all-tags
      />
    </div>
    <div
      v-else
      class="bottom"
    >
      <button
        class="btn btn-default"
        :disabled="saving"
        @click="cancel"
        v-lang.cancel
      />
      <button
        class="btn btn-primary"
        :disabled="!hasChanges || saving"
        @click="onSave"
      >
        {{ addMode ? translate('save') : translate('save-changes') }}
      </button>
    </div>
  </div>
</template>

<style lang="less" scoped>
  .tag-manager {
    line-height: 1.5em;
    flex: 1 1;
    display: flex;
    flex-direction: column;
    height: 300px;

    .top {
      border-bottom: 1px solid #dfdfdf;
      font-size: 12px;
      padding: 8px;
      line-height: 1.5em;
      cursor: initial;
      .header {
        font-weight: 500;
        color: #222;
      }
      a {
        color: #ffa07a;
      }
    }

    #tagFilter {
      line-height: 40px;
      position: relative;

      a, i {
        line-height: 40px;
        position: absolute;
        right: 15px;
        top: 0;
      }

      input {
        width: 100%;
        height: 40px;
        border-width: 0 0 1px 0;
        border-bottom: 1px solid #dfdfdf;
        padding: 0 15px;
      }
    }

    .tags-wrapper {
      flex: 1 1;
      .tags {

        .selectable-tag {
          border: none;
          margin: 0;
          &:not(:first-child) {
            border-top: 1px solid #dfdfdf;
          }
          &:not(.selected):hover {
            background-color: #f8f8f8;
          }
        }
      }
    }

    .bottom {
      border-top: 1px solid #dfdfdf;
      text-align: center;
      line-height: 40px;
    }

    &.in-popup {

      #tagFilter {
        padding: 0 30px;
        a, i {
          right: 45px;
        }
      }

      .tags-wrapper {

        .selectable-tag {
          margin: 0 30px;
        }
      }

      .bottom {
        padding: 15px;
        text-align: right;
        background-color: #f8f8f8;
        border-radius: 0 0 8px 8px;

        display: flex;
        justify-content: flex-end;
        gap: 8px;
      }
    }
  }
  .tag-manager.inside-dropdown {
    .tags-wrapper {
      .tags {
        .selectable-tag {
          font-size: 13px;
          border: 0;
        }
      }
    }
  }
</style>
