<script>
import { EventBus } from '@/assets/js/helpers.js'
export default {
  name: 'DiscoverPreview',
  props: {
    'discoverItem': {
      type: Object,
      required: true
    },
    'sizing': {
      type: String, // tall, narrow
      required: false
    },
    'classes': {
      type: String,
      required: false
    },
    maxWidth: {
      type: String
    }
  },
  data () {
    return {
    }
  },
  computed: {
    backgroundImage: function () {
      // this.$mq.resize
      // var size = this.$mq.size()
      return this.discoverItem.imageId.replace('.jpg', '_768.jpg')
      // return require('../assets/img/discover/' + this.discoverItem.imageId.replace('.jpg', '_768.jpg'))
      // if (size === 'xxs' || this.maxWidth === 'xxs') return 'url(' + this.newsItem.imageId.replace('.jpg', '_480.jpg') + ')'
      // if (size === 'xs' || this.maxWidth === 'xs') return 'url(' + this.newsItem.imageId.replace('.jpg', '_768.jpg') + ')'
      // if (size === 'sm' || this.maxWidth === 'sm') return 'url(' + this.newsItem.imageId.replace('.jpg', '_992.jpg') + ')'
      // if (size === 'md' || this.maxWidth === 'md') return 'url(' + this.newsItem.imageId.replace('.jpg', '_1200.jpg') + ')'
      // if (size === 'lg' || this.maxWidth === 'lg') return 'url(' + this.newsItem.imageId.replace('.jpg', '_1200.jpg') + ')'
    }
  },
  methods: {
    navigate (config) {
      EventBus.$emit('navigate', config)
    }
  }
}
</script>

<template>
  <div
    class="discover-preview"
    :class="classes"
    @click="navigate({ name: 'list', params: { listName: discoverItem.listName }})"
  >
    <template>
      <div style="background-color: #000; border-radius: 3px;">
        <div
          class="image"
          :style="{ backgroundImage: 'url(' + backgroundImage + ')' }"
        />
        <div class="discover-title">
          <h2>{{ discoverItem.title }}</h2>
          <span class="count-label">{{ discoverItem.label }}</span>
        </div>
      </div>
      <div
        class="description text-muted"
        v-html="discoverItem.description"
      />
    </template>
  </div>
</template>

<style lang="less" scoped>
  // rules to keep in mind
  // narrow (1/4 wide or less) can be visible from sm and up

  .content-box {
    padding: 0;
  }

  .discover-preview {
    position: relative;
    cursor: pointer;
    .image {
      background-size: cover;
      background-position: center center;
      cursor: pointer;
      border-radius: 3px;
      opacity: 1;
      background-color: #000;
      position: relative;
      transition: opacity .2s ease-in-out;
      filter: alpha(opacity=60);
      opacity: 0.60;
      // height: 100%;
      // width: 100%;
      // top: 0;
      // left: 0;
      height: 175px;
      display: flex;
      justify-content: center;
      align-items: center;
    }
    .discover-title {
      text-align: center;
      position: absolute;
      width: 100%;
      top: 64px;

      h2 {
        margin: 0 0 10px 0;
        color: white;
        font-size: 25px;
        font-weight: 500;
      }

      .count-label {
        background: #ffa07a;
        padding: 3px 5px;
        color: #fff;
        font-size: 10px;
        font-weight: 700;
        text-transform: uppercase;
      }
    }

    .description {
      text-align: center;
      padding: 24px 30px 15px 30px;
      font-size: 15px;
    }

    &:hover {
      .image {
        filter: alpha(opacity=45);
        opacity: 0.45;
      }
    }
  }
  //   .content {
  //     // min-height: 200px;
  //     padding: 30px 30px;

  //     .info {
  //       font-size: 14px;
  //     }

  //     h3 {
  //      font-size: 24px;
  //      margin: 10px 0 15px 0;
  //      word-break: break-word;
  //      a {
  //        color: inherit;
  //        &:hover {
  //          color: #ffa07a;
  //          text-decoration: none;
  //        }
  //      }
  //     }

  //     p {
  //       margin: 0;
  //       line-height: 26px;
  //     }
  //   }

  //   &.highlight {
  //     .image {
  //       min-height: 200px;
  //     }
  //     .content {
  //       h3 {
  //         font-size: 33px;
  //       }
  //     }
  //   }

  //   &.basic {
  //     .image {
  //       min-height: 200px;
  //     }
  //   }
  // }

  // #app.xxs .news-preview {
  //   .image {
  //     border-radius: 0;
  //   }
  // }

  // #app.min-xs .news-preview {
  //   &.highlight {
  //     .image {
  //       min-height: 200px;
  //     }
  //   }
  // }

  // #app.min-sm .news-preview {
  //   &.highlight {
  //     .image {
  //       min-height: 275px;
  //     }
  //     .content {
  //       h3 {
  //         margin-bottom: 0;
  //       }
  //     }
  //   }

  //   &.basic {
  //     &.narrow {
  //       .image {
  //         min-height: 150px;
  //       }
  //       h3 {
  //         font-size: 20px;
  //       }
  //     }

  //     &.tall {
  //       .image {
  //         min-height: 250px;
  //       }
  //     }
  //   }
  // }

  // #app.min-md .news-preview {
  //   &.highlight {
  //     display: flex;
  //     align-items: stretch;
  //     .image {
  //       width: 50%;
  //       min-height: 200px;
  //       border-radius: 3px 0 0 3px;
  //     }
  //     .content {
  //       flex: 1;
  //       display: flex;
  //       flex-direction: column;
  //       justify-content: center;
  //       h3 {
  //         margin-bottom: inherit
  //       }
  //     }
  //   }

  //   &.basic {
  //     &.narrow {
  //       .image {
  //         min-height: 150px;
  //       }
  //       h3 {
  //         font-size: 20px;
  //       }
  //     }

  //     &.tall {
  //       .image {
  //         min-height: 300px;
  //       }
  //     }
  //   }
  // }

  // #app.lg .news-preview {
  //   &.highlight {
  //     .image {
  //       width: 58.333%;
  //     }
  //   }
  // }
</style>
