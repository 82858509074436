<script lang="ts">
import { Vue, Component } from 'vue-property-decorator'

@Component({})

export default class DialogTransition extends Vue {}
</script>

<template>
  <transition-group
    name="lassox-portal__DialogTransition"
    appear
    :duration="{ enter: 150, leave: 75 }"
  >
    <slot />
  </transition-group>
</template>

<style lang="scss">
.lassox-portal__DialogTransition {
  &-enter-active {
    & > .lassox-portal__Dialog-backdrop {
      transition: opacity 150ms linear;
      opacity: 0;
    }

    & > .lassox-portal__Dialog-box-wrapper {
      transition: opacity 45ms linear, transform 150ms ease-out;
      opacity: 0;
      transform: scale(0.8);
    }
  }

  &-enter-to {
    & > .lassox-portal__Dialog-backdrop {
      opacity: 1;
    }

    & > .lassox-portal__Dialog-box-wrapper {
      opacity: 1;
      transform: scale(1);
    }
  }

  &-leave-active {
    transition: opacity 75ms linear;
    opacity: 1;
  }

  &-leave-to {
    opacity: 0;
  }
}
</style>
