<script>
export default {
  name: 'TagNameModal',
  data () {
    return {
      email: '',
    }
  },
  computed: {
    invalid () {
      return !this.email || !/^[_A-Za-z0-9]+([.-]?[_A-Za-z0-9]+)*@[A-Za-z0-9-]+(\.[A-Za-z0-9-]+)*(\.[A-Za-z]{2,10})$/.test(this.email)
    }
  }
}
</script>

<template>
  <div id="emailModal">
    <div class="modal-top">
      <h1 v-lang.receive-email-notifications />
      <p v-lang.enter-email-description />
    </div>
    <div class="modal-center">
      <div class="input-group">
        <input
          :class="{ error: email && invalid }"
          v-focus
          v-model="email"
          :placeholder="translate('enter-email')"
        >
      </div>
    </div>
    <div class="modal-bottom right">
      <button
        class="btn btn-default"
        @click="$emit('cancel')"
        v-lang.cancel
      />
      <button
        class="btn btn-primary"
        :disabled="invalid"
        @click="$emit('done', email)"
        v-lang.save
      />
    </div>
  </div>
</template>

<style lang="less" scoped>
  #emailModal {
    input.error {
      &:not(:focus)  {
        border-color: #ffa07a;
      }
    }
  }
</style>
