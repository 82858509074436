<script lang="ts">
import ACLObject from '@/types/ACLObject'
import ACLUser from '@/types/ACLUser'
import TagAccess from '@/types/TagAccess'
import DropdownButton from '@/components/dropdown/DropdownButton.vue'
import DropdownMenu from '@/components/dropdown/DropdownMenu.vue'
import DropdownMenuItem from '@/components/dropdown/DropdownMenuItem.vue'
import { Component, Prop, Vue } from 'vue-property-decorator'
import { Tags } from '@/assets/js/helpers'
import BaseButton from '@/components/BaseButton.vue'
import { DropdownMenuItems } from '@/models/dropdown-menu-items'

@Component({
  components: {
    DropdownMenu,
    DropdownMenuItem,
    DropdownButton,
    BaseButton,
  }
})
export default class ACLUserDropdown extends Vue {

  @Prop({
    type: Object
  })
  readonly user?: ACLUser

  @Prop({
    type: Object
  })
  readonly tag!: { id: string, owner: string, canView: boolean, canEdit: boolean, canDelete: boolean, canTag: boolean }

  @Prop({
    type: Object
  })
  readonly tagAccess!: TagAccess

  @Prop({
    type: String,
    default: "user"
  })
  readonly type!: "organization" | "user"

  @Prop({
    type: String
  })
  readonly currentUserId!: string

  showACLDropdown = false

  get access () {
    const target = this.type === "organization" ? this.tagAccess.organization : this.user

    if (target === undefined) throw Error('User is undefined !')

    return Vue.observable({
      canView: target.canView,
      canEdit: target.canEdit,
      canDelete: target.canDelete,
      canTag: target.canTag,
    })
  }

  get ACLMenuItems (): DropdownMenuItems {
    return [
      {
        label: 'Se listen',
        icon: !this.access.canView ? 'check_box' : 'check_box_checked',
        onClick: () => {
          this.updateACL({
            canView: !this.access.canView,
            canEdit: !this.access.canView && this.access.canEdit,
            canDelete: !this.access.canView && this.access.canDelete,
            canTag: !this.access.canView && this.access.canTag,
          })
        },
      },
      {
        label: 'Redigere listen',
        icon: !this.access.canEdit ? 'check_box' : 'check_box_checked',
        onClick: () => {
          this.updateACL({
            canView: this.access.canView || !this.access.canTag,
            canEdit: this.access.canEdit,
            canDelete: this.access.canDelete,
            canTag: !this.access.canTag,
          })
        },
      },
      {
        label: 'Dele listen',
        icon: !this.access.canEdit ? 'check_box' : 'check_box_checked',
        onClick: () => {
          this.updateACL({
            canView: this.access.canView || !this.access.canEdit,
            canEdit: !this.access.canEdit,
            canDelete: this.access.canDelete,
            canTag: this.access.canTag,
          })
        },
      },
      {
        label: 'Slette listen',
        icon: !this.access.canDelete ? 'check_box' : 'check_box_checked',
        onClick: () => {
          this.updateACL({
            canView: this.access.canView || !this.access.canDelete,
            canEdit: this.access.canEdit,
            canDelete: !this.access.canDelete,
            canTag: this.access.canTag,
          })
        },
      },
    ]
  }

  get accessString () {
    const permissions = []
    if (this.access.canView) permissions.push('se')
    if (this.access.canTag) permissions.push('redigere')
    if (this.access.canEdit) permissions.push('dele')
    if (this.access.canDelete) permissions.push('slette')
    if (permissions.length === 0) return 'Ikke delt'
    return (this.type === "organization" ? 'Alle kan ' : 'Kan ') + this.arrayToSentence(permissions)
  }

  arrayToSentence (arr: Array<string>) {
    return arr.slice(0, -2).join(', ') +
      (arr.slice(0, -2).length ? ', ' : '') +
      arr.slice(-2).join(' og ')
  }

  updateACL (newACL: ACLObject) {
    if (newACL.canEdit || newACL.canDelete || newACL.canTag) newACL.canView = true

    if (this.type === "organization") {
      Tags.updateOrgACL(this.tag.id, newACL).then(() => {
        this.$emit('tagAccessChanged')
      })
      return
    }

    if (!this.user) throw Error("User is undefined !")

    Tags.updateUserACL(this.tag.id, this.user.lassoUserId, newACL).then(() => {
      this.$emit('tagAccessChanged')
    })
  }
}
</script>

<template>
  <div class="lassox-portal__ACLUserDropdown">
    <DropdownButton
      v-if="(type === 'organization' || user && user.lassoUserId !== currentUserId) && tag.canEdit"
      :borderRadiusEffect="false"
      :menuItems="ACLMenuItems"
    >
      <template #default="{ onClick, isOpen }">
        <BaseButton
          class="lassox-portal__ACLUserDropdown-button"
          :trailingIcon="isOpen ? 'expand_less' : 'expand_more'"
          :label="accessString"
          @click.stop="onClick"
        />
      </template>
    </DropdownButton>

    <div
      v-else
      class="font-italic"
      style="color: #9E9E9E"
    >
      {{ accessString }}
    </div>

    <!-- <DropdownMenu
      v-if="type === 'organization' || user.lassoUserId !== currentUserId && tag.canEdit"
      :buttonEl="showACLDropdown ? $refs.userACLButton.$el : null"
      :close="() => showACLDropdown = false"
    >

    </DropdownMenu> -->
  </div>
</template>

<style lang="scss">
  .lassox-portal__ACLUserDropdown {
  }
  .lassox-portal__ACLUserDropdown-button {
    border-radius: 2px;
    height: 24px;
    padding-left: 8px;
    padding-right: 4px;
    border: none;

    .lassox-portal__BaseButton_trailing-icon {
      padding-left: 4px;
    }
  }
</style>
