export default class Address {
  coName: string | null

  streetName: string | null

  houseNumberFrom: number | null
  houseLetterFrom: string | null
  houseNumberTo: number | null
  houseLetterTo: string | null

  floor: string | null
  sideDoor: string | null

  postalCode: number | null
  postalDistrict: string | null

  coordinates: { longitude: string, latitude: string } | null | undefined

  constructor (response: Pick<Address, (
    | 'coName'
    | 'streetName'
    | 'houseNumberFrom'
    | 'houseLetterFrom'
    | 'houseNumberTo'
    | 'houseLetterTo'
    | 'floor'
    | 'sideDoor'
    | 'postalCode'
    | 'postalDistrict'
    | 'coordinates'
  )>) {
    this.coName = response.coName

    this.streetName = response.streetName

    this.houseNumberFrom = response.houseNumberFrom
    this.houseLetterFrom = response.houseLetterFrom
    this.houseNumberTo = response.houseNumberTo
    this.houseLetterTo = response.houseLetterTo

    this.floor = response.floor
    this.sideDoor = response.sideDoor

    this.postalCode = response.postalCode
    this.postalDistrict = response.postalDistrict

    this.coordinates = response.coordinates
  }

  get address1 () {
    return seperate(', ', [
      seperate(' ', [
        this.streetName,

        seperate('', [
          seperate('-', [this.houseNumberFrom, this.houseNumberTo]),
          seperate('-', [this.houseLetterFrom, this.houseLetterTo])
        ]),
      ]),

      seperate(' ', [
        this.floor && `${this.floor}.`,
        this.sideDoor,
      ]),
    ])
  }

  get address2 () {
    return seperate(' ', [
      this.postalCode,
      this.postalDistrict,
    ])
  }

  isEqualTo (address: Address) {
    for (const key of [
      'coName',
      'streetName',
      'houseNumberFrom',
      'houseLetterFrom',
      'houseNumberTo',
      'houseLetterTo',
      'floor',
      'sideDoor',
      'postalCode',
      'postalDistrict',
    ] as const) {
      if (this[key] !== address[key]) return false
    }
    return true
  }

  format () {
    return seperate(', ', [
      this.address1,
      this.address2,
    ])
  }

  toString () {
    this.format()
  }
}

const seperate = (seperator: string, items: unknown[]) => (
  items
    .filter(isNotNull)
    .filter(isNotEmptyString)
    .join(seperator)
)

function isNotNull (item: unknown) { return item !== null }

function isNotEmptyString (item: unknown) { return typeof item !== 'string' || !!item }
