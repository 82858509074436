<script lang="ts">
import { Vue, Component, Watch, Prop, Provide, Ref, Model } from 'vue-property-decorator'
import Icon from '@/components/Icon.vue'
import IconButton from '@/components/IconButton.vue'
import { unwatchRect, watchRect } from '@/util/watch-rect'
import BaseButton from '@/components/BaseButton.vue'
import DropdownButton from '@/components/dropdown/DropdownButton.vue'
import DropdownMenu from '@/components/dropdown/DropdownMenu.vue'
import DropdownMenuItem from '@/components/dropdown/DropdownMenuItem.vue'
import type BuiltSuggestion from '@/types/BuiltSuggestion'
import ResultType from '@/types/ResultType'
import { isDAWAResult } from '@/util/type-guards'

@Component({
  components: {
    Icon,
    IconButton,
    BaseButton,
    DropdownMenu,
    DropdownMenuItem,
    DropdownButton,
  },
})

export default class SearchBar<T> extends Vue {
  @Provide()
  get searchBar () { return this }

  @Prop({ type: String, default: 'Søg' })
  readonly placeholder!: string

  @Prop({ type: Boolean, default: false })
  readonly autofocus!: boolean

  @Model('change', { type: String })
  modelInputText!: string

  refs: {
    inputEl: HTMLInputElement
  } | null = null

  boundsRect: DOMRect | null = null
  inputRect: DOMRect | null = null

  focused = false
  focusedInput = false
  showTypeDropdown = false

  inputText = ''

  async mounted () {
    this.updateRefs()

    if (this.autofocus) this.refs?.inputEl.focus()
  }

  updated () {
    this.updateRefs()
  }

  updateRefs () {
    this.refs = {
      inputEl: this.$refs.inputEl as HTMLInputElement,
    }
  }

  getRefs () {
    if (!this.refs) throw Error('getRefs() was called before refs were set')
    return this.refs
  }

  updateInput (result: ResultType) {
    if (isDAWAResult(result)) {
      const { inputEl } = this.getRefs()

      let inputValue = result.tekst

      if (result.type === 'adgangsadresse') inputValue = result.forslagstekst

      inputEl.value = inputValue ?? ''
      this.inputText = inputValue
      inputEl.setSelectionRange(result.caretpos ?? 0, result.caretpos ?? 0)
    }
  }

  onInputElFocus () {
    this.focusedInput = true
  }

  onInputElInput () {
    this.inputText = this.refs?.inputEl.value ?? ''
  }

  @Watch('modelInputText', { immediate: true })
  onModelInputText () {
    this.inputText = this.modelInputText
  }

  @Watch('inputText')
  onInputTextChanged () {
    this.$emit('change', this.inputText)
    this.$emit('input', { inputText: this.inputText, caretPos: this.refs?.inputEl.selectionStart ?? null })
  }

  get introText () {
    return this.$store.state.user?.settings?.questionaire?.isSalesy
      ? 'Brug søgebaren til at finde firmaer, personer og adresser, der er interessante for dig. Fx leads eller kunder, du vil kigge nærmere på.'
      : this.$store.getters.isFreemium
        ? 'Udgangspunktet er, at alting skal være en simpel søgning væk - derfor er den centralt placeret. Vælg selv, hvilke datatyper, du vil søge på.'
        : 'Med søgefeltet som det nye udgangspunkt kan du nå hurtigere frem til den viden, du leder efter - både når det gælder firmaer, personer og adresser.'
  }
}
</script>

<template>
  <div
    class="lassox-portal__SearchBar"
    data-title="Søg"
    data-step="1"
    data-position="right"
    :data-intro="introText"
    tabindex="-1"
    @focusout="() => focused = false"
    @keydown="event => $emit('keydown', event)"
  >
    <div class="lassox-portal__SearchBar-wrapper">
      <div class="lassox-portal__SearchBar-input-box">
        <div class="lassox-portal__SearchBar-type-chooser">
          <slot name="leading-action" />
        </div>

        <input
          ref="inputEl"
          class="lassox-portal__SearchBar-input"
          type="text"
          :placeholder="focusedInput ? undefined : placeholder"
          spellcheck="false"
          v-model="inputText"
          @focus="onInputElFocus"
          @blur="focusedInput = false"
          @input="onInputElInput"
        >

        <div class="lassox-portal__SearchBar-trailing-icons">
          <transition
            name="lassox-portal__SearchBar-trailing-icons-transition"
            mode="out-in"
          >
            <IconButton
              v-if="inputText"
              icon="cancel_outline_rounded"
              size="small"
              @click="() => {
                inputText = ''
                if (refs) refs.inputEl.focus()
              }"
            />
          </transition>
        </div>
      </div>
    </div>
  </div>
</template>

<style lang="scss" scoped>
@import "@shared/style/global.scss";

.lassox-portal__SearchBar {
  width: 100%;
  height: 48px;

  &:focus {
    outline: none;
  }

  &-wrapper {
    position: relative;
    z-index: 1;
    border-radius: 24px;
    background-color: white;
    overflow: hidden;
  }

  &-input-box {
    position: relative;
    transition-property: background-color, box-shadow;
    transition-duration: 100ms;
    display: flex;
    justify-content: center;
    align-items: center;
  }

  &-input-box &-type-chooser {
    position: relative;
    display: flex;
    justify-content: center;
    align-items: center;
    height: 48px;
    box-shadow: 1px 0 0 0 #D4D4D4;

    & .lassox-portal__BaseButton {
      height: 48px;
      padding-left: 24px;
      border-radius: 24px;
      border-top-right-radius: 0;
      border-bottom-right-radius: 0;
    }
  }

  &-input-box &-input {
    font: inherit;
    font-size: 14px;
    line-height: 20px;
    font-weight: 400;
    display: block;
    box-sizing: border-box;
    width: 100%;
    height: 48px;
    padding: ((48px - 20px) / 2) 0;
    padding-left: 16px;
    padding-right: 48px;
    border: 0;
    margin: 0;
    background-color: transparent;

    &::placeholder {
      color: #B7B7B7;
    }

    &:focus {
      outline: none;
    }

    // Remove IE clear button
    &::-ms-clear {
      display: none;
    }
  }

  &-input-box &-trailing-icons {
    position: absolute;
    top: 0;
    right: 0;
    display: flex;
    align-items: center;
    gap: 32px - 18px;
    height: 100%;
    padding: 0 (48px - 18px) / 2;
    pointer-events: none;

    .lassox-portal__IconButton {
      pointer-events: auto;
    }

    &-transition-enter-active,
    &-transition-leave-active {
      transition: opacity 150ms;
    }

    &-transition-enter,
    &-transition-leave-to {
      opacity: 0;
    }
  }

  @mixin compact-search-bar {
    height: 36px;

    .lassox-portal__SearchBar-input-box .lassox-portal__SearchBar-type-chooser {
      height: 36px;

      .lassox-portal__BaseButton {
        height: 36px;
        padding-left: 16px;
        border-radius: 18px;
        border-top-right-radius: 0;
        border-bottom-right-radius: 0;
      }
    }

    .lassox-portal__SearchBar-input-box .lassox-portal__SearchBar-input {
      height: 36px;
      padding: ((36px - 16px) / 2) 0;
      padding-left: 16px;
      padding-right: 36px;
      font-size: 12px;
      line-height: 16px;
    }

    .lassox-portal__SearchBar-input-box .lassox-portal__SearchBar-trailing-icons {
      padding: 0 (36px - 18px) / 2;
    }
  }

  &:is(.lassox-portal__SearchView-header .lassox-portal__SearchBar) {
    .lassox-portal__SearchBar-wrapper {
      box-shadow: 0 0 0 1px #D4D4D4, 0 0 24px rgb(0 0 0 / 0.08);
    }

    &:is(.lassox-portal__SearchView--size-678 .lassox-portal__SearchBar) {
      @include compact-search-bar();
    }
  }

  &:is(.lassox-portal__SearchView-sticky-header .lassox-portal__SearchBar) {
    .lassox-portal__SearchBar-wrapper {
      box-shadow: 0 0 0 1px #D4D4D4, 0 0 8px rgba(0, 0, 0, 0.08);
    }

    @include compact-search-bar();
  }
}
</style>
