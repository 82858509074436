import { EventBus } from '@/assets/js/helpers'
import Vue, { Component, InjectionKey, Ref } from 'vue'


export type OverlayConfig = {
  component: Component
  source?: string
  subtitle?: string
  // These below are not used currently, but trigger:openDemo sends it and could be useful in the future
  title?: string,
  context?: string,
  description?: string,
}

const openTabEvent = 'openOverlay'

export const showTabOverlay = (config: OverlayConfig) => {
  EventBus.$emit(openTabEvent, config)
}
export const onShowTabOverlay = (callback: (config: OverlayConfig) => void) => {
  EventBus.$on(openTabEvent, callback)
}

export const overlayInjectionKey = Symbol() as InjectionKey<Ref<OverlayConfig> | undefined>
