<script lang="ts">
import { Vue, Component, Prop } from 'vue-property-decorator'

const sizes = {
  sm: {
    size: 18
  },
  md: {
    size: 24
  },
  lg: {
    size: 40
  }
}

@Component({})
export default class LoadingSpinner extends Vue {
  @Prop()
  readonly preset?: 'sm' | 'md' | 'lg'

  @Prop()
  readonly size?: number

  @Prop({ type: Boolean, default: true })
  readonly fade!: boolean

  get presetSize () {
    if (this.preset) {
      if (this.preset in sizes) return sizes[this.preset].size
    }
    return sizes.md.size
  }

  get width () {
    if (this.size) return this.size
    return this.presetSize
  }
  get height () {
    if (this.size) return this.size
    return this.presetSize
  }
}
</script>
<template>
  <svg
    class="lassox-portal__LoadingSpinner"
    :class="{ 'lassox-portal__LoadingSpinner-fade': fade }"
    :width="width + 'px'"
    :height="height + 'px'"
    viewBox="0 0 66 66"
    xmlns="http://www.w3.org/2000/svg"
  >
    <circle
      class="path"
      fill="none"
      stroke-width="6"
      stroke-linecap="round"
      cx="33"
      cy="33"
      r="30"
    />
  </svg>
</template>
<style lang="scss">
$offset: 188;
$duration: 1.4s;

.lassox-portal__LoadingSpinner {
  animation: rotator $duration * 1.5 linear infinite;

  &-fade {
    .path {
      animation: colors ($duration * 4) ease-in-out infinite;
    }
  }

  .path {
    stroke: #ff8e61;
    stroke-dasharray: $offset;
    stroke-dashoffset: calc($offset / 4);
    transform-origin: center;
    animation:
      dash $duration ease-in-out infinite,
      dashRotation ($duration * 4) ease-in-out infinite;
  }
}

@keyframes rotator {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}

@keyframes colors {
  0% { stroke: #FAE9C4; }
  25% { stroke: #F09782C4; }
  50% { stroke: #EE8A78; }
  75% { stroke: #AB9EF5; }
  100% { stroke: #FAE9C4; }
}

@keyframes dash {
  0% {
    stroke-dashoffset: $offset;
  }
  50% {
    stroke-dashoffset: calc($offset / 4);
    // transform: rotate(135deg);
  }
  100% {
    stroke-dashoffset: $offset;
    // transform: rotate(450deg);
  }
}

@keyframes dashRotation {
  0% {
    transform: rotate(0deg);
  }
  12.5% {
    transform: rotate(0deg);
  }
  25% {
    transform: rotate(270deg);
  }
  37.5% {
    transform: rotate(270deg);
  }
  50% {
    transform: rotate(270deg * 2);
  }
  62.5% {
    transform: rotate(270deg * 2);
  }
  75% {
    transform: rotate(270deg * 3);
  }
  87.5% {
    transform: rotate(270deg * 3);
  }
  100% {
    transform: rotate(270deg * 4);
  }
}
</style>
