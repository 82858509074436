import type { Route } from 'vue-router'
import { Vue, Component, Prop } from 'vue-property-decorator'
import appHistory from '@/history'
import { InjectionKey } from 'vue'

let idInt = 0

export const tabsById = new Map<number, Tab>()

@Component({})

export default class Tab extends Vue {
  @Prop({ required: true })
  readonly store!: any

  route: Route | null = null

  order: number | null = null

  readonly id = idInt++

  get name () {
    const { store, route } = this

    if (!route) return 'Indlæser...'

    if (route.params.appName) {
      const appName = store.getters.app(route.params.appName)?.name
      const entityName = route.params.lassoId && store.getters.entity(route.params.lassoId)?.name

      if (!entityName && !appName) {
        return store.getters.appFromSolution(route.params.appName)?.name
      }

      return entityName
        ? entityName
        : appName
    }

    if (route.params.listId) {
      return store.getters.tag(route.params.listId)?.name
    }

    switch (route.name) {
      case 'search': return 'Ny søgning'
      case 'news': return 'Nyheder'
      case 'entity': return 'Indlæser...'
      case 'start-trial': return 'Prøveperiode'
      default: return ''
    }
  }

  created () {
    tabsById.set(this.id, this)
  }

  replaceRoute (route: Route) {
    this.route = route
    history.replaceState({
      historyId: appHistory.id,
      tabId: this.id
    }, '', route.fullPath)
  }

  pushRoute (route: Route) {
    this.route = route
    history.pushState({
      historyId: appHistory.id,
      tabId: this.id,
    }, '', route.fullPath)
  }
}

export const createTab = (options: { store: any, route: Route }) => {
  const tab = new Tab({
    propsData: {
      store: options.store,
    },
  })

  tab.route = options.route

  return tab
}

export const tabInjectionKey = Symbol() as InjectionKey<Tab>
