<script lang="ts">
import { Vue, Component, Prop } from 'vue-property-decorator'
import Icon from '@/components/Icon.vue'
import Draggables from '@/components/Draggables.vue'
import BaseButton from '@/components/BaseButton.vue'
import getAppIcon from '@shared/types/IconsMap'
import { Group, Item } from '@/models/draggable-items'
import LassoSettings from '../util/LassoSettings'
import App from '@/types/App'

@Component({
  components: {
    Draggables,
    Icon,
    BaseButton,
  }
})

export default class DraggableApps extends Vue {
  @Prop({ type: Array, required: true })
  readonly apps!: App[]

  @Prop({ type: Function, required: true })
  readonly onAppClick!: (app: App) => void

  collapsed = true

  itemCache: Partial<Record<string, Item>> = {}

  groups: Group[] = [
    {
      id: 'visible',
      hideLabel: true,
      items: [],
    },
    {
      id: 'hidden',
      text: 'Skjult',
      hidden: true,
      items: [],
    },
  ]

  get appsCacheString () { return JSON.stringify(this.apps.map(app => app.uniqueName)) }
  get localAppsCacheString () { return JSON.stringify(this.groups.flatMap(group => group.items.map(item => item.id))) }
  get groupsCacheString () { return JSON.stringify(this.groups.map(group => group.items.map(item => item.id))) }

  get appsByUniqueName () { return new Map(this.apps.map(app => [ app.uniqueName, app ])) }

  get lassoSettings (): LassoSettings { return this.$store.state.lassoSettings }

  get hiddenGroup () { return this.groups[1] }

  get hasHiddenApps () { return !!this.hiddenGroup.items.length }

  getAppItem (app: App) {
    let item = this.itemCache[app.uniqueName]

    if (!item) {
      let icon = getAppIcon(app.uniqueName) || 'widgets_outline_rounded'
      if (typeof icon !== 'string') icon = icon.company

      item = {
        id: app.uniqueName,
        text: app.name,
        deletable: false,
        onClick: () => this.onAppClick(app),
        icon,
      }
    }

    return item
  }

  onDragStart () {
    this.collapsed = false
  }

  onDragStop () {
    this.$nextTick(() => {
      if (!this.hasHiddenApps) this.collapsed = true
    })
  }

  mapDraggablesToSettings (draggables: Group[]) {
    const settings: Record<'visible' | 'hidden', Set<string>> = {
      visible: new Set,
      hidden: new Set
    }

    draggables.forEach(g => {
      g.items.forEach(i => settings[g.id as 'visible' | 'hidden'].add(i.id))
    })

    return settings
  }

  created () {
    this.$watch(
      () => this.collapsed,
      collapsed => this.hiddenGroup.hidden = collapsed,
      { immediate: true },
    )

    this.$watch(
      () => this.apps,
      () => {
        const newItemCache: DraggableApps['itemCache'] = {}

        for (const app of this.apps) {
          newItemCache[app.uniqueName] = this.getAppItem(app)
        }

        this.itemCache = newItemCache
      },
      { immediate: true },
    )

    this.$watch(
      () => this.appsCacheString,
      () => {
        if (!this.apps) return
        if (this.localAppsCacheString === this.appsCacheString) return

        const newGroups: Group[] = []

        for (const group of this.groups) {
          const newItems: Item[] = []

          for (const uniqueName of this.lassoSettings.settings.portal.appsOrder[group.id as 'visible' | 'hidden']) {
            const app = this.appsByUniqueName.get(uniqueName)
            if (!app) continue

            newItems.push(this.getAppItem(app))
          }

          newGroups.push({ ...group, items: newItems })
        }

        this.groups = newGroups
      },
      { immediate: true },
    )

    this.$watch(
      () => this.groupsCacheString,
      () => {
        this.lassoSettings.settings.portal.appsOrder = this.mapDraggablesToSettings(this.groups)
        this.lassoSettings.updateSettings()
      },
    )
  }
}
</script>

<template>
  <div class="lassox-portal__DraggableApps">
    <Draggables
      class="lassox-portal__DraggableApps-draggables"
      v-model="groups"
      :readOnly="$store.state.readOnly || $store.getters.isFreemium"
      :spacing="4"
      :reserveSpaceAfterGroups="false"
      :showOnlyGroupOnDrag="true"
      @dragStart="onDragStart"
      @dragStop="onDragStop"
    >
      <template #content="value">
        <div
          class="lassox-portal__DraggableApps-draggables-draggable"
          v-text="value.text"
        />
      </template>
    </Draggables>

    <BaseButton
      v-if="hasHiddenApps"
      class="lassox-portal__DraggableApps-show-more-btn"
      @click="collapsed = !collapsed"
    >
      <Icon
        :icon="collapsed ? 'expand_more_rounded' : 'expand_less_rounded'"
        size="small"
      />

      <span v-text="collapsed ? 'Flere' : 'Færre'" />
    </BaseButton>
  </div>
</template>

<style lang="scss">
.lassox-portal__DraggableApps {
  display: flex;
  flex-direction: column;
  align-items: stretch;

  &-draggables {
    .lassox-portal__Draggable-text {
      color: #262626;
      transition: color 100ms;

      &:hover {
        color: #ffa07a;
      }
    }

    &-draggable {
      display: flex;
      align-items: center;
      font-size: 14px;
      line-height: 16px;
      font-weight: 500;
    }
  }

  &-show-more-btn {
    display: flex;
    justify-content: flex-start;
    align-items: center;
    height: 32px;
    padding: 8px;
    margin: -8px;
    margin-top: 8px + 4px;
    border-radius: 8px;

    > span {
      color: #262626;
      font-weight: 500;
    }

    .md-icon {
      margin-right: 8px;
    }
  }
}
</style>
