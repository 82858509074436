<script>
import VueDropzone from 'vue2-dropzone'
import { apiUrl, EventBus } from '@/assets/js/helpers.js'
export default {
  name: 'ImportDropdown',
  props: {
    tag: { type: Object, required: true },
    tagEntities: {  type: Array, required: true },
    entityType: { type: String, required: true },
    visible: { type: Boolean, required: false }
  },
  data () {
    return {
      lassoIds: null,
      hover: false,
      errorMessage: '',
      dropzoneOptions: {
        url: apiUrl + '/utilities/cvrsfromfile/' + this.entityType,
        previewTemplate: '<div></div>',
        acceptedFiles: '.xls,.xlsx,.csv',
        uploadMultiple: true
      }
    }
  },
  watch: {
    'visible' (visible) {
      if (visible) {
        this.errorMessage = ''
        this.hover = false
      }
    }
  },
  methods: {
    onUploadSuccess (files, cvrs) {
      if (cvrs.length === 0) this.errorMessage = this.translate('file-no-cvrs', { fileOrFiles: this.translate(files.length > 1 ? 'files' : 'file') })
      else if ((cvrs.length + this.tagEntities.length) > this.$store.state.maxListSize) {
        EventBus.$emit('modal', {
          modalType: 'confirm',
          confirmTitle: this.translate('list-limit-reached'),
          confirmDescription: this.translate('list-limit-description', { limit: this.$store.state.maxListSize, entityType: this.translate('companies') }),
          hideCancelButton: true,
          confirmButtonText: 'OK',
          // eslint-disable-next-line @typescript-eslint/no-empty-function
          onConfirm: () => {}
        })
      } else {
        this.errorMessage = ''
        EventBus.$emit('modal', {
          modalType: 'confirm',
          confirmTitle: this.translate('add-to-the-list') + '?',
          confirmDescription: this.translate(files.length > 1 ? 'import-confirm-description-multiple-files' : 'import-confirm-description-single-file', { count: cvrs.length, entityType: this.translate('companies'), listname: this.tag.name }),
          onConfirm: () => {
            const lassoIds = cvrs.map(cvr => ({lassoId: 'CVR-1-' + cvr}))
            this.$store.dispatch('updateEntityTags', { entities: lassoIds, tagsToAdd: [ this.tag.id ], tagsToRemove: [] }).then(() => {
              this.$emit('import-done')
              this.$notify({ title: this.translate(lassoIds.length === 1 ? 'company-was-added' : 'companies-were-added', { count: lassoIds.length }) })
            }, error => {
              let errorMessage = error.body.errorMessage
              if (errorMessage && errorMessage.indexOf('Invalid number provided:') === 0) errorMessage = this.translate('invalid-number-provided', {numbers: errorMessage.replace('Invalid number provided: ', '')})
              EventBus.$emit('modal', {
                modalType: 'confirm',
                hideCancelButton: true,
                confirmTitle: this.translate('import-error'),
                confirmDescription: this.translate('import-error-description-message', {error: errorMessage}),
                // eslint-disable-next-line @typescript-eslint/no-empty-function
                onConfirm () {},
                // eslint-disable-next-line @typescript-eslint/no-empty-function
                onCancel () {}
              })
            })
          },
          // eslint-disable-next-line @typescript-eslint/no-empty-function
          onCancel () {
          }
        })
      }
    },
    onError (files, message, xhr) {
      if (message && message.errorMessage === 'Missing required column: cvr') this.errorMessage = this.translate('missing-cvr-column')
      else this.errorMessage = this.translate(files.length > 1 ? 'file-not-valid-multiple' : 'file-not-valid-single')
    }
  },
  components: {
    VueDropzone
  }
}
</script>

<template>
  <div id="importDropdown">
    <div
      v-if="errorMessage"
      class="dropdown-description"
    >
      <div
        class="text-semibold text-red"
        v-lang.upload-failed
      />
      <div>
        {{ errorMessage }} <a
          target="_blank"
          href="/static/data/import-example.xlsx"
          v-lang.see-example
        />
      </div>
    </div>
    <div
      v-else
      class="dropdown-description"
      style="padding: 20px;"
    >
      <span v-lang.import-description /> <a
        target="_blank"
        href="/static/data/import-example.xlsx"
        v-lang.see-example
      />
    </div>

    <div
      v-if="lassoIds"
      class="padding-top-bottom-1"
    >
      <button
        class="btn btn-default"
        v-lang.cancel
      />
      <button
        class="btn btn-primary"
        style="margin-left: 10px;"
        v-lang.confirm
      />
    </div>
    <div v-else>
      <div
        class="dropzone-wrapper"
        :class="{ 'hover': hover }"
      >
        <div class="help-text text-center">
          <h2 v-if="hover">
            {{ translate('release-to-upload') }}
          </h2>
          <div v-else>
            <div v-lang.drag-files-here />
            <a><i class="fa fa-cloud-upload" /><span v-lang.select-files /></a>
          </div>
        </div>
        <vue-dropzone
          :includeStyling="false"
          @vdropzone-success-multiple="onUploadSuccess"
          @vdropzone-error-multiple="onError"
          @vdropzone-drag-over="hover = true"
          @vdropzone-drag-leave="hover = false"
          ref="dropZone"
          id="dropzone"
          :options="dropzoneOptions"
        />
      </div>
    </div>
  </div>
</template>

<style lang="less" scoped>
#importDropdown {
  .dropzone-wrapper {
    position: relative;
    #dropzone {
      height: 150px;
      position: relative;
      z-index: 2;
      cursor: pointer;
    }
    .help-text {
      z-index: 1;
      position: absolute;
      left: 0;
      top: 0;
      width: 100%;
      height: 100%;
      font-size: 14px;
      line-height: 30px;
      border-top: 1px dashed #dfdfdf;
      background-color: #f8f8f8;
      display: flex;
      align-items: center;
      justify-content: center;
      // color: #222;
      // h1 {
      //   font-size: 18px;
      //   font-weight: 500;
      //   margin: 0;
      // }
      // .or-text {
      //   font-style: italic;
      //   font-size: 13px;
      //   padding: 10px 0;
      // }
      a {
        color: #ffa07a;
        .fa-cloud-upload {
          font-size: 18px;
          margin-right: 8px;
          position: relative;
          top: 2px;
        }
      }
    }

    &.hover {
      .help-text {
        border-color: #ffa07a;
      }
    }
  }
}
</style>
