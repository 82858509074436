<script lang="ts">
import { Vue, Component, Prop } from 'vue-property-decorator'
import Icon from './Icon.vue'

@Component({
  components: {
    Icon,
  },
})
export default class InputBase extends Vue {
  @Prop({ type: String, required: false }) readonly label: string | undefined
  @Prop({ type: Boolean, required: false }) readonly floatingLabel: boolean | undefined

  @Prop({ type: String, required: false }) readonly leadingIcon: string | undefined
  @Prop({ type: String, required: false }) readonly trailingIcon: string | undefined
  @Prop({ type: Function, required: false }) readonly trailingIconOnClick: Function | undefined

  @Prop({ type: String, required: false }) readonly helperText: string | undefined
  @Prop({ type: String, required: false }) readonly errorText: string | undefined

  @Prop({ type: Boolean, required: false }) readonly isFocused: boolean | undefined
  @Prop({ type: Boolean, required: false }) readonly hasValue: boolean | undefined
}
</script>

<template>
  <div
    :class="{
      'input-base': true,

      'has-leading-icon': leadingIcon,
      'has-trailing-icon': trailingIcon || errorText,
      'has-trailing-icon-on-click': trailingIconOnClick,

      'is-focused': isFocused,
      'has-value': hasValue,
      'has-error': errorText,
    }">
    <label v-if="!floatingLabel" class="label">{{ label }}</label>

    <div class="input-box">
      <div
        v-if="leadingIcon"
        class="leading-icon">
        <Icon :icon="leadingIcon" />
      </div>

      <label v-if="floatingLabel" class="floating-label">
        <div class="label-text">{{ label }}</div>
      </label>

      <slot></slot>

      <div
        v-if="trailingIcon"
        :is="trailingIconOnClick ? 'button' : 'div'"
        class="trailing-icon"
        @click.prevent="() => { trailingIconOnClick && trailingIconOnClick() }">
        <Icon :icon="trailingIcon" />
      </div>

      <div
        v-else-if="errorText"
        class="trailing-icon">
        <Icon icon="error" />
      </div>
    </div>

    <div v-if="helperText && !errorText" class="helper-text">{{ helperText }}</div>
    <div v-else-if="errorText" class="error-text">{{ errorText }}</div>
  </div>
</template>

<style lang="scss" scoped>
@use 'sass:math';

@import "@shared/style/global.scss";

@mixin transition {
  transition-duration: 150ms;
  transition-timing-function: cubic-bezier(0.4, 0.0, 0.2, 1);
}

.input-base {
  .input-box {
    @include transition;
    position: relative;
    display: flex;
    color: getTextColor('medium');
    background-color: #ffffff;
    border-radius: 2px;
    box-shadow: inset 0 0 0 1px getScaleColor('gray', 400);
    transition-property: box-shadow;
  }

  &.is-focused .input-box {
    box-shadow: inset 0 0 0 2px getScaleColor('gray', 700);
  }

  &.has-error .input-box {
    box-shadow: inset 0 0 0 1px getTextColor('primary');
  }

  &.is-focused.has-error .input-box {
    box-shadow: inset 0 0 0 2px getTextColor('primary');
  }

  .leading-icon,
  .trailing-icon {
    @include transition;
    position: absolute;
    top: 6px;
    bottom: 6px;
    padding: 6px;
    pointer-events: none;
    cursor: pointer;
    border-radius: 50%;
    transition-property: background-color;

    &:hover,
    &:focus {
      background-color: getScaleColor('gray', 200);
    }

    &:active {
      background-color: getScaleColor('gray', 300);
    }

    .md-icon {
      @include transition;
      transition-property: color;
    }
  }

  .leading-icon {
    left: 6px;
  }

  .trailing-icon {
    right: 6px;
  }

  &.has-trailing-icon-on-click .trailing-icon {
    pointer-events: all;
  }

  &.has-error .md-icon {
    color: getTextColor('primary')
  }

  .floating-label {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    pointer-events: none;
  }

  .floating-label,
  .input {
    padding: 12px 16px;
  }

  &.has-leading-icon {

    .floating-label,
    .input {
      padding-left: 46px;
    }
  }

  &.has-trailing-icon {

    .floating-label,
    .input {
      padding-right: 46px;
    }
  }

  $labelFontSize: 14px;
  $labelFloatingFontSize: 13px;
  $labelScalingFactor: math.div($labelFloatingFontSize, $labelFontSize);
  $labelPaddingX: math.div(4px, $labelScalingFactor);

  .floating-label .label-text {
    @include transition;
    display: inline-block;
    max-width: calc(100% + #{$labelPaddingX * 2});
    padding: 0 $labelPaddingX;
    margin: 0 (-$labelPaddingX);
    color: getTextColor('light');
    background-color: #ffffff;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    transform-origin: center left;
    transition-property: max-width, color, transform;
  }

  &.is-focused .floating-label .label-text {
    color: getTextColor('dark');
  }

  &.has-error .floating-label .label-text {
    color: getTextColor('primary')
  }

  &.is-focused,
  &.has-value {
    .floating-label .label-text {
      max-width: calc((100% + #{$labelPaddingX * 2}) / #{$labelScalingFactor});
      transform: translateY(-24px) scale($labelScalingFactor);
    }
  }

  &.has-leading-icon {

    &.is-focused,
    &.has-value {
      .floating-label .label-text {
        transform: translateY(-24px) translateX(-30px) scale($labelScalingFactor);
      }
    }
  }

  &.has-leading-icon,
  &.has-trailing-icon {

    &.is-focused,
    &.has-value {
      .floating-label .label-text {
        max-width: calc((100% + #{$labelPaddingX * 2} + 30px) / #{$labelScalingFactor});
      }
    }
  }

  &.has-leading-icon.has-trailing-icon {

    &.is-focused,
    &.has-value {
      .floating-label .label-text {
        max-width: calc((100% + #{$labelPaddingX * 2} + 60px) / #{$labelScalingFactor});
      }
    }
  }

  .input {
    display: block;
    box-sizing: border-box;
    width: 100%;
    min-height: 48px;
    border: 0;
    margin: 0;
    font: inherit;
    color: inherit;
    background-color: transparent;

    &:focus {
      outline: none;
    }

    &::-ms-clear {
      display: none;
    }
  }

  .error-text,
  .helper-text {
    @include typography('group-describe');
    padding: 0 16px;
    margin-top: 4px;
  }

  &.has-error {

    .error-text,
    .helper-text {
      color: getTextColor('primary');
    }
  }
  .design2024 & {
    .input-box {
      border-radius: 8px;
      box-shadow: 0px 0px 16px 0px #0000000A;
      border: 1px solid #F1F1F1;
    }

    .input {
      min-height: 40px;
      height: 40px;
    }

    .floating-label {
      padding-top: 9px;
      padding-bottom: 9px;
    }

    &.is-focused,
    &.has-value {
      .floating-label .label-text {
        transform: translateY(-21px) scale($labelScalingFactor) !important;
      }
    }
  }
}
</style>
