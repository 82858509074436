import { DirectiveOptions } from 'vue/types/options'
import { isRecord } from '../util/type-guards'
import { getStore, isOffset, isPlacement, TooltipOptions } from '../tooltips'

const parseBindingValue = (value: unknown) => {
  let tooltip: TooltipOptions

  if (!value) return null

  if (typeof value === 'string') {
    tooltip = { text: value }
  } else if (isRecord(value)) {
    tooltip = { text: String(value.text) }
    if (isPlacement(value.placement)) tooltip.placement = value.placement
    if (isOffset(value.offset)) tooltip.offset = value.offset
    if (typeof value.overrideShow === 'boolean') tooltip.overrideShow = value.overrideShow
  } else {
    return null
  }

  return tooltip
}

const tooltips = new Map<HTMLElement, TooltipOptions>()

const directive: DirectiveOptions = {
  bind: (el, binding, vnode) => {
    const root = vnode.context?.$root
    if (!root) return

    const parsed = parseBindingValue(binding.value)
    if (!parsed) return

    const tooltip = {
      ...parsed,
      el,
    }

    tooltips.set(el, tooltip)
    getStore(root).add(tooltip)
  },

  update: (el, binding, vnode) => {
    const root = vnode.context?.$root
    if (!root) return

    let tooltip = tooltips.get(el)
    const parsed = parseBindingValue(binding.value)

    if (!parsed) {
      tooltips.delete(el)
      if (tooltip) getStore(root).remove(tooltip)
      return
    }

    if (tooltip) {
      tooltip.text = parsed.text
      tooltip.placement = parsed.placement
      tooltip.offset = parsed.offset
      tooltip.overrideShow = parsed.overrideShow
      return
    }

    tooltip = {
      ...parsed,
      el,
    }

    tooltips.set(el, tooltip)
    getStore(root).add(tooltip)
  },

  unbind: (el, binding, vnode) => {
    const root = vnode.context?.$root
    const tooltip = tooltips.get(el)
    if (!tooltip) return

    tooltips.delete(el)
    if (root) getStore(root).remove(tooltip)
  },
}

export default directive
