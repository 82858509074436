<script>
import { Story } from '@/assets/js/helpers.js'
export default {
  name: 'FeedList',
  props: {
    'listType': {
      type: String,
      required: true
    },
    'count': {
      type: Number,
      required: false
    }
  },
  data () {
    return {
    }
  },
  computed: {
    list: function () {
      return this.$store.state.lists[this.listConfig.saveAs] || { news: { items: [] } }
    },
    items: function () {
      return this.count ? this.list.news.items.slice(0, this.count) : this.list.news.items
    },
    // eslint-disable-next-line vue/return-in-computed-property
    title: function () {
      switch (this.listType) {
        case 'latest-news': return 'Seneste nyheder'
        case 'latest-accounts': return 'Seneste regnskaber'
      }
    },
    // eslint-disable-next-line vue/return-in-computed-property
    listConfig: function () {
      switch (this.listType) {
        case 'latest-news': return { name: 'forsiden', saveAs: 'forsiden-latest', orderBy: 'publishtime' }
        case 'latest-accounts': return { name: 'forsiden', saveAs: 'forsiden-accounts', types: [ 26 ], orderBy: 'publishtime' }
      }
    },
    description: function () {
      return false
    }
  },
  components: {
  },
  mounted: function () {
    this.$store.dispatch('loadList', { listName: this.listConfig.name, saveAs: this.listConfig.saveAs, more: false, count: this.count, types: this.listConfig.types, orderBy: this.listConfig.orderBy })
  }
}
</script>

<template>
  <div class="feed-list content-box">
    <h3>{{ title }}</h3>
    <p v-if="description">
      {{ description }}
    </p>
    <div class="feed-list-items margin-top-2">
      <!-- <div v-if="listType === 'latest-accounts'" class="account-item" v-for="item in items">
        <a :href="item.url" target="_blank"><span class="filetype" :class="{pdf: item.url.indexOf('pdf') > -1, tiff: !item.url.indexOf('pdf') > -1 && item.url.indexOf('tiff') > -1 }">{{item.url.indexOf('pdf') > -1 ? 'PDF' : 'TIFF'}}</span></a>
        <router-link :to="{ name: 'companyStory', params: { id: item.mainEntity.id, storyTitle: item.slugTitle, storyId: item.uniqueId } }">{{item.mainEntity.name}}</router-link>
      </div> -->
      <!-- <div v-if="listType === 'latest-news'" class="news-item" v-for="item in items">
        <div class="time text-semimuted">{{item.time | date('%d-%m-%Y')}}</div><router-link :to="{ name: 'companyStory', params: { id: item.mainEntity.id, storyTitle: item.slugTitle, storyId: item.uniqueId } }">{{item.prettyHeadline}}</router-link>
      </div> -->
    </div>
  </div>
</template>

<style lang="less" scoped>
  .feed-list {
    padding: 30px;

    h3 {
      margin: 0
    }

    .account-item, .news-item {
      margin-top: 8px;
    }

    .time {
      font-size: 14px;
      line-height: 28px;
      display: inline-block;
      margin-right: 15px;
    }
  }
</style>
