<script>
export default {
  // eslint-disable-next-line vue/multi-word-component-names
  name: 'Modal',
  props: {
    config: {
      type: Object,
      required: true
    }
  },
  data () {
    return {
      show: false
    }
  },
  methods: {
    onClose () {
      if (this.config && this.config.requireAction) {
        // don't do anything
      } else this.$emit('close')
    },
    onKeyUp (event) {
      if (event.keyCode === 27) this.onClose()
    }
  },
  computed: {
    defaultWidth () {
      switch (this.config.modalType) {
        case 'confirm': return 300
        case 'tagName': return 380
        case 'story': return 800
        case 'switchAccount': return 800
        case 'terms': return 800
        default: return 450
      }
    }
  },
  created () {
    document.addEventListener('keyup', this.onKeyUp)
  },
  mounted () {
    if (this.config && this.config.requireAction) this.$store.commit('lockedByModal', true)
  },
  beforeDestroy () {
    document.removeEventListener('keyup', this.onKeyUp)
    this.$store.commit('lockedByModal', false)
  }
}
</script>

<template>
  <transition name="modal">
    <div
      class="modal-mask"
      @click.self="onClose"
    >
      <!-- <div class="modal-wrapper" > -->
      <div
        class="modal-container"
        :style="{ 'max-width': (config.maxWidth || defaultWidth) + 'px' }"
      >
        <div
          v-show="config && config.showClose"
          @click="onClose"
          class="close-button"
        >
          <img src="../assets/img/close-icon.png">
        </div>
        <slot />
      </div>
      <!-- </div> -->
    </div>
  </transition>
</template>

<style lang="less">
  .modal-mask {
    position: fixed;
    z-index: 9998;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    max-height: 100%;
    overflow-y: auto;
    background-color: rgba(0, 0, 0, .5);
    display: flex;
    justify-content: center;
    align-items: center;
    transition: opacity .3s ease;
    padding: 30px 0;
  }

  .modal-wrapper {
    flex: 1 0 100%;
    display: flex;
    align-self: flex-start;
    align-items: center;
    justify-content: center;
    padding: 30px 0;
  }

  .modal-container {
    position: relative;
    width: auto;
    @media only screen and (max-width: 576px) {
      width: 95%;
    }
    margin: auto 0;
    // margin: 30px 0;
    background-color: #fff;
    border-radius: 8px;
    font-size: 15px;
    // line-height: 28px;
    line-height: 1.5em;
    box-shadow: 0 2px 8px rgba(0, 0, 0, .33);
    transition: all .3s ease;
    color: #222;

    .close-button {
      padding: 10px;
      position: absolute;
      z-index: 9999;
      right: 20px;
      top: 20px;
      cursor: pointer;
    }

    .modal-top {
      padding: 30px;
      h1, h2, h3 {
        margin: 0;
      }

      h1 {
        font-size: 18px;
        font-weight: 500;
      }

      p {
        margin: 15px 0 0 0;
        color: #777;
        font-size: 14px;
      }
      // background-color: #f8f8f8;
    }

    .modal-center {
      padding: 40px 30px;
      // background-color: #f8f8f8;
    }

    .modal-top + .modal-center {
      padding-top: 0;
    }

    .modal-bottom {
      border-top: 1px solid #dfdfdf;
      background-color: #f8f8f8;
      border-radius: 0 0 8px 8px;
      padding: 15px;
      display: flex;
      justify-content: space-around;
      font-size: 14px;
      .left {
        display: flex;
        flex-grow: 1;
        align-items: center;
      }

      &.right {
        display: flex;
        justify-content: flex-end;
        // align-items: center;
      }

      button + button {
        margin-left: 10px;
      }

      // button {
      //   border-radius: 2px;
      //   background-color: #fff;
      //   padding: 8px 15px;
      //   border: none;
      //   font-size: 14px;
      //   &:not(:disabled) {
      //     cursor: pointer;
      //   }

      //   &:disabled {
      //     filter: opacity(0.5)
      //   }

      //   &.secondary {
      //     border: 1px solid #d1d1d1;
      //   }

      //   &.primary {
      //     background-color: #ffa07a;
      //     color: #fff;
      //   }

      //   &+ button {
      //     margin-left: 10px;
      //   }
      // }

      // a.cancel {
      //   position: relative;
      //   top: 2px;
      //   margin-right: 20px;
      //   color: #777;
      //   cursor: pointer;
      // }
      // // box-shadow: 0 2px 5px -1px rgba(50, 50, 50, 0.2);
      // position: relative;
      // background-color: #f8f8f8;
    }

    .modal-footer {
      // background: linear-gradient(to bottom,rgba(0,0,0,.14),rgba(0,0,0,.03) 3px,transparent);
      background-color: #f8f8f8;
      text-align: center;
      font-size: 13px;
      a {
        text-decoration: underline
      }
    }
  }

  .modal-enter {
    opacity: 0;
  }

  .modal-leave-active {
    opacity: 0;
  }

  .modal-enter .modal-container,
  .modal-leave-active .modal-container {
    -webkit-transform: scale(1.1);
    transform: scale(1.1);
  }

</style>
