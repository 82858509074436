<script lang="ts">
import { Vue, Component } from 'vue-property-decorator'

@Component({})

export default class DropdownMenuDivider extends Vue {}
</script>

<template>
  <div class="lassox-portal__DropdownMenuDivider" />
</template>

<style lang="scss">
.lassox-portal__DropdownMenuDivider {
  height: 1px;
  margin: 8px 0;
  box-shadow: inset 0 1px 0 0 rgba(black, 0.12);
}
</style>
