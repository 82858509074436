<script>
import Notifications from '@/components/Notifications'
import HelpWidget from '@/components/HelpWidget'
import { EventBus, signOut } from '@/assets/js/helpers'
import {store} from '@/assets/js/store.js'
import { createTab } from '@/types/Tab.ts'
import Icon from '@/components/Icon'
import IconButton from '@/components/IconButton'
import VueRouter, { NavigationFailureType } from 'vue-router'
import draggable from 'vuedraggable'
import { watchRect, unwatchRect } from '@/util/watch-rect.ts'
import getViewport from '@shared/vue-lasso-core/viewport.ts'
import { mapState } from 'vuex'
import getAppIcon from '@shared/types/IconsMap.ts'
import DropdownButton from '@/components/dropdown/DropdownButton.vue'
import DropdownMenuItem from '@/components/dropdown/DropdownMenuItem.vue'
import DropdownMenuDivider from '@/components/dropdown/DropdownMenuDivider.vue'
import windowSize from '@/window-size.ts'
import TagIcon from '@/components/TagIcon.vue'
import stripLeadingEmojiFromText from '@/util/stripLeadingEmojiFromText.ts'
import formatDate from '@/util/date-format.ts'

export default {
  // eslint-disable-next-line vue/multi-word-component-names
  name: 'Toolbar',
  props: {
    anonymous: {
      type: Boolean,
      default: false
    },
    stopAnimation: {
      type: Boolean,
      default: false
    },
  },
  data () {
    return {
      notificationsOpen: false,
      EventBus: EventBus,
      tabDrag: false,
      tabsWrapperWidth: 0,
      tabsWrapperEl: null,
      showDropdown: false,
      canCloseTab: true,
      closeTabCooldown: 1000,
      showNotifcationDropdown: false,
      console: console,
      allTabs: [],
      hideTabIcon: false,
      centerCloseIcon: false,
      hoveredTab: null,
      // mobileBehaviour: false
    }
  },
  watch: {
    showDropdown (n) {
      if (n) {
        this.$nextTick(() => {
          this.setDropdownStyle()
        })
      }
    },
    // '$store.state.activeTab' (tab) {
    //   if (this.mobileBehaviour) {
    //     this.$nextTick(() => this.selectTab(tab))
    //   }
    // },
    '$store.state.tabs': {
      immediate: true,
      handler () {
        this.sortTabs()
      },
    },
  },
  beforeRouteUpdate (to, from, next) {
    // since we're not using a route-component for the app-view, the only way to do actions before route-update, is to do it in another route-triggered component, like here.
    // make sure we fetch the entity if the lassoId param changes
    if (to.name === 'entity' && from.name === 'entity' && to.params.lassoId) store.dispatch('getEntity', { lassoId: to.params.lassoId }).then(() => next())
    else next()
  },
  computed: {
    ... mapState({
      readOnly: state => state.readOnly,
      direct: state => state.direct,
      user: state => state.user,
      activeTab: state => state.activeTab,
      tabs: state => state.tabs,
    }),
    userDropdown () {
      return (
        (this.direct || this.readOnly)
        ? [
          !this.readOnly ? [
            {
              label: 'Åbn portalen',
              onClick: this.openPortal,
            },
          ] : null,

          this.readOnly ? [
            {
              label: 'Log ind',
              onClick: this.signIn,
            },
          ] : [
            {
              label: 'Log ud',
              onClick: this.signOut,
            },
          ],
        ]
        : [
          !this.mobileBehaviour ? [
            {
              label: 'Kontoindstillinger',
              icon: 'settings_outline_rounded',
              onClick: () => this.openRouteInNewTab('lassosettings'),
            },
            (this.$store.state.claims.multipleOrganizations ? {
              label: 'Skift konto',
              icon: 'switch_account_outline_rounded',
              closeMenuOnClick: false,
              onClick: async () => {
                EventBus.$emit('modal', {
                  modalType: 'switchAccount',
                })
              },
            } : null),
          ] : null,

          [
            {
              label: 'Log ud',
              icon: 'logout_outline_rounded',
              onClick: () => this.signOut(),
            },
          ],
        ]
      ).filter(Boolean)
    },
    dragOptions () {
      return {
        animation: 200,
        group: 'tabs',
        draggable: '.draggable-tabs .tab.draggable-tab',
        disabled: false,
        ghostClass: 'ghost',
        direction: 'horizontal',
        sort: false,
        // forceFallback: true,
        fallbackTolerance: 3,
        dragClass: 'tab-drag'
      }
    },
    sidebarWidth () {
      return this.$store.state.sidebarPinned ? 300 : 0
    },
    helpdeskAvailable () {
      return !!window.groove
    },
    mobileBehaviour () {
      return windowSize.width < windowSize.sizes.sm
    },
    hasTabsToShow () { return !!this.allTabs.length },
    isNarrow () { return windowSize.width < windowSize.sizes.md },
    hasActiveJobs () {
      return (Object.keys(this.$store.state.updates).length + Object.values(this.$store.state.websiteScans).filter(scan => scan.status !== 'finished').length) > 0
    },
    unreadNotificationCount () {
      return this.$store.getters.unreadNotifications.length + this.$store.getters.unreadUpdates.length + this.$store.getters.unreadWebsiteScans.length
    },
    isAdmin () {
      return this.$store.state.user && (this.$store.state.user.roles.indexOf('Admin') > -1 || this.$store.state.user.roles.indexOf('LassoAdmin') > -1)
    },
    width () { return windowSize.width }
  },
  methods: {
    sortTabs () {
      const orders = new Map(this.allTabs.map((tab, i) => [ tab, i ]))

      const tabs = [ ...this.$store.state.tabs ].sort((a, b) => {
        const aScore = orders.get(a) ?? a.order ?? null
        const bScore = orders.get(b) ?? b.order ?? null
        if (aScore === bScore) return 0
        if (aScore === null) return 1
        if (bScore === null) return -1
        return aScore - bScore
      })

      for (const [ i, tab ] of tabs.entries()) {
        tab.order = i
      }

      this.allTabs = tabs
    },
    async loadAvailableOrganizations () {
      this.loadingAvailableOrgs = true
      await this.$store.dispatch('getAvailableOrgs')
      this.triggeredLoadAvailableOrgs = true
      this.loadingAvailableOrgs = false
    },
    updateListSortOrder (event) {
      if (event.to !== event.from) {
        // const oldIndex = event.relatedContext.index
        const futureIndex = event.draggedContext.futureIndex
        if (event.to.parentNode.classList.contains('dropdown-tabs')) {
          if (futureIndex === 0) return 1
        } else {
          const lastIndex = this.allTabs.length - 1
          if (futureIndex === lastIndex) return -1
        }
      }
    },
    newTab () {
      this.$store.dispatch('openNewTab', this.$router.resolve({ name: 'search' }).route)
    },
    setDropdownStyle () {
      if (this.mobileBehaviour) return
      const dropdownButtonEl = this.$refs.tabsDropdownButtonEl
      const dropdownDraggableEl = this.$refs.dropdownDraggable?.$el

      if (!dropdownButtonEl || !dropdownDraggableEl) return

      dropdownDraggableEl.style.transform = ''

      const dropdownButtonRect = dropdownButtonEl.getBoundingClientRect()
      const rect = dropdownDraggableEl.getBoundingClientRect()
      const viewport = getViewport()

      const padding = 16

      const spaceLeft = dropdownButtonRect.left - padding
      const spaceRight = viewport.right - dropdownButtonRect.right - padding

      const translateYValues = []
      const translateXValues = []

      translateYValues.push(`${(dropdownButtonRect.bottom - rect.top)}px`)

      const horizontalAlignment =
        spaceRight >= spaceLeft
          ? 'left'
          : 'right'

      switch (horizontalAlignment) {
        case 'left':
          translateXValues.push(`${dropdownButtonRect.left - rect.left}px`)
          break
        case 'right':
          translateXValues.push(`${dropdownButtonRect.right - rect.left}px`, '-100%')
          break
      }

      dropdownDraggableEl.style.transform = [
        ...translateXValues.map(value => `translateX(${value})`),
        ...translateYValues.map(value => `translateY(${value})`),
      ].join(' ')
    },
    onDropdownMouseLeave () {
      setTimeout(() => {
        if (this.tabDrag) return
        this.showDropdown = false
      }, 1000)
    },
    onDropdownHover () {
      this.showDropdown = true
    },
    updateTabsWrapperWidth (rect) {
      this.tabsWrapperWidth = rect.width
    },
    toggleSidebar () {
      EventBus.$emit('toggleSidebar')
    },
    openRouteInNewTab (appName) {
      this.$store.dispatch('findMatchingTab', tab => (
        tab.route.params?.appName === appName
      )).then(tab => {
        if (tab) {
          this.selectTab(tab)
        } else {
          this.$store.dispatch('openNewTab', this.$router.resolve({ name: 'app', params: { appName }, query: this.$route.query }).route)
        }
      })
    },
    selectTab (tab) {
      if (this.mobileBehaviour) this.showDropdown = false
      if (tab.id === this.activeTab.id) return
      this.$store.dispatch('selectTab', tab)
    },
    closeTab (tab) {
      const index = this.allTabs.indexOf(tab)

      if (this.activeTab === tab) {
        let newIndex = index + 1

        if (this.allTabs.length === 1) {
          this.newTab()
          this.sortTabs()
          newIndex = 1
        } else if (index === this.allTabs.length - 1) {
          newIndex = index - 1
        }

        this.selectTab(this.allTabs[newIndex])
      }

      const spliceIndex = this.$store.state.tabs.indexOf(tab)
      if (spliceIndex === -1) return
      tab.order = null
      this.$store.state.tabs.splice(spliceIndex, 1)
      this.sortTabs()
    },
    getTabIcon (route) {
      switch (route.name) {
        case 'list':
          return 'list'
        case 'news':
          return 'menu_book_outline_rounded'
        case 'search':
        case undefined:
          return 'search_outline_rounded'
        case 'app':
          if (route.params.appName) {
            const icon = getAppIcon(route.params.appName)
            const lassoId = route.params.lassoId

            if (typeof icon === 'string') return icon

            if (lassoId) {
              const entityType = lassoId.startsWith('CVR-1-') ? 'company' : 'person'
              return icon[entityType]
            }
            if (icon) {
              return icon.company
            }
          }
          break
        case 'entity':
          // eslint-disable-next-line no-case-declarations
          const lassoId = route.params.lassoId
          if (route.params.appName) {
            const icon = getAppIcon(route.params.appName)

            if (lassoId) {
              const entityType = lassoId.startsWith('CVR-1-') ? 'company' : 'person'
              return icon[entityType]
            }

            if (icon) {
              return icon.company
            }
          } else {
            if (lassoId) {
              if (lassoId.startsWith('CVR-1-')) {
                return 'work_outline_rounded'
              } else if (lassoId.startsWith('CVR-3-')) {
                return 'person_filled'
              } else if (lassoId.startsWith('CVR-2-')) {
                return 'question_mark_rounded'
              }
            }
          }
          return 'work_outline_rounded'
        default:
          if (route.params.appName) {
            switch (route.params.appName) {
              case 'lassosettings':
              case 'lassousersettings':
                return 'person_filled'
              default:
                break
            }
          }
          return 'work_outline_rounded'
      }
    },
    isTagTab (tab) {
      return tab.route?.name === 'list'
    },
    getTabTag (tab) {
      const tagId = tab.route?.params.listId
      if (!tagId) return null
      return this.$store.getters.tag(tagId)
    },
    getTabName (tab) {
      if (this.isTagTab(tab)) {
        return stripLeadingEmojiFromText(tab.name).trim()
      }
      return tab.name.trim()
    },
    signIn () {
      EventBus.$emit('modal', { modalType: 'confirm', confirmTitle: this.translate('sign-in-description'), confirmButtonText: this.translate('sign-in'), cancelButtonText: this.translate('cancel'), onConfirm: () => { signOut(this.$store.state.user, true) } })
    },
    signOut () {
      EventBus.$emit('modal', { modalType: 'confirm', confirmTitle: this.translate('sign-out-description'), confirmButtonText: this.translate('sign-out'), cancelButtonText: this.translate('cancel'), onConfirm: () => { signOut(this.$store.state.user, this.$store.state.readOnly) } })
    },
    loadMoreNotifications () {
      if (!this.$store.state.gettingNotifications && !this.$store.state.noMoreNotifications) {
        this.$store.dispatch('getMoreNotifications')
      }
    },
    openPortal () {
      window.location.href = window.location.href.replace(window.location.search, '')
    },
    onClickFollowed () {
      this.$store.dispatch('getTags').then(tags => {
        let followTag = tags.find(t => t.type === 'Company' && t.followTag)
        // router.replace({ name: 'list', params: { listId: followTag.id }, query: { ...from.query } })
        // this.$store.state.activeTab.pushRoute(this.$router.resolve({ name: 'list',  }).route)
        this.$store.dispatch('openNewTab', this.$router.resolve({ name: 'list', params: { listId: followTag.id }, query: this.$route.query }).route)
      })
    }
  },
  components: {
    Notifications,
    HelpWidget,
    Icon,
    IconButton,
    draggable,
    DropdownButton,
    DropdownMenuItem,
    DropdownMenuDivider,
    TagIcon,
  },
  created () {
    if (!this.anonymous && !this.$store.state.productAccess) {
      this.$store.dispatch('getNotifications')
    }
    EventBus.$on('tab:close', (tab) => {
      this.closeTab(tab)
    })

    this.$watch(
      () => this.tabsWrapperWidth / this.tabs.length,
      tabWidth => {
        if (tabWidth == 0) {
          this.hideTabIcon = false
          this.centerCloseIcon = false
        } else {
          this.hideTabIcon = tabWidth < (18 * 2) + (16 * 2) + 8
          this.centerCloseIcon = tabWidth < 18 + (16 * 2)
        }
      },
    )
  },
  mounted () {
    this.tabsWrapperEl = this.$refs.tabsWrapper?.$el ?? null
    window.addEventListener('resize', this.setDropdownStyle)
    this.$watch(() => this.tabsWrapperEl, (newEl, oldEl) => {
      if (oldEl) unwatchRect(oldEl, this.updateTabsWrapperWidth)
      if (newEl) watchRect(newEl, this.updateTabsWrapperWidth)
      else this.tabsWrapperWidth = 0
    })
  },
  updated () {
    this.tabsWrapperEl = this.$refs.tabsWrapper?.$el ?? null
  },
  beforeDestroy () {
    unwatchRect(this.tabsWrapperEl, this.updateTabsWrapperWidth)
  },
}
</script>

<template>
  <div
    id="toolbar"
    :class="{ 'narrow': isNarrow }"
  >
    <div
      v-show="$store.state.productAccess || ($store.getters.allowSidebar && isNarrow)"
      class="toolbar-buttons"
    >
      <IconButton
        v-show="$store.state.productAccess"
        icon="home_outline_rounded"
        size="normal"
        @click.stop="onClickFollowed"
      />

      <IconButton
        v-show="$store.getters.allowSidebar && isNarrow"
        icon="menu_outline_rounded"
        size="normal"
        @click.stop="toggleSidebar"
      />
    </div>

    <div
      class="tabs-wrapper"
      :class="{
        'has-dropdown': mobileBehaviour,
        'showing-dropdown': showDropdown,
        'is-dragging': tabDrag,
      }"
    >
      <div
        v-if="mobileBehaviour"
        :class="[
          'tab mobile is-active',
          (activeTab.route.params && activeTab.route.params.appName === 'lassousersettings') && 'peach-tab',
        ]"
        :title="activeTab.name"
        @mousedown.middle.prevent="() => {}"
        @click.middle.prevent="closeTab(activeTab)"
        @click.prevent="selectTab(activeTab)"
      >
        <TagIcon
          v-if="isTagTab(activeTab)"
          :tag="getTabTag(activeTab)"
          class="tab-icon"
        />

        <Icon
          v-else
          :icon="getTabIcon(activeTab.route)"
          size="small"
          class="tab-icon"
          style="color: inherit"
        />

        <span
          v-if="activeTab.name"
          class="tab-name"
          v-text="getTabName(activeTab)"
        />

        <IconButton
          v-if="!!allTabs.length"
          :icon="showDropdown ? 'expand_less' : 'expand_more'"
          ref="tabsDropdownButtonEl"
          size="small"
          @click.stop="showDropdown = !showDropdown"
        />

        <IconButton
          v-else
          icon="close"
          size="small"
          @click.stop="closeTab(activeTab)"
        />

        <div
          v-if="showDropdown"
          class="dropdown-tabs"
        >
          <div
            v-for="tab in allTabs"
            :key="tab.id"
            :title="tab.name"
            class="tab dropdown"
            :class="{'is-active': activeTab.id === tab.id}"
            @click="selectTab(tab)"
          >
            <TagIcon
              v-if="isTagTab(tab)"
              :tag="getTabTag(tab)"
              class="tab-icon"
            />

            <Icon
              v-else
              :icon="getTabIcon(tab.route)"
              size="small"
              class="tab-icon"
              style="color: inherit"
            />

            <span
              v-if="tab.name"
              class="tab-name"
              v-text="getTabName(tab)"
            />

            <IconButton
              icon="close"
              size="small"
              @click.stop="closeTab(tab)"
            />
          </div>
        </div>
      </div>

      <draggable
        v-if="!mobileBehaviour"
        v-model="allTabs"
        @start="tabDrag = true"
        @end="tabDrag = false; sortTabs()"
        @change="updateListSortOrder"
        v-bind="dragOptions"
        style="min-width: 0;"
      >
        <transition-group
          class="draggable-tabs"
          type="transition"
          ref="tabsWrapper"
          :name="!tabDrag ? 'flip-list' : null"
        >
          <div
            v-for="tab in allTabs"
            :key="tab.id"
            :class="{
              'is-active': activeTab && activeTab.id === tab.id,
              'peach-tab': tab.route.params && tab.route.params.appName === 'lassousersettings',
            }"
            class="tab normal draggable-tab"
            data-title="Faner"
            data-step="4"
            data-highlight-class="widen-to-plus"
            :data-intro="$store.getters.isFreemium
              ? 'Hold flere ting i gang samtidig og gå frem og tilbage mellem dem efter behov.'
              : 'Nu kan du have flere søgninger og værktøjer åbne på samme tid og nemt navigere mellem dem.'"
            @mouseenter="hoveredTab = tab"
            @mouseleave="hoveredTab = null"
            @mousedown.middle.prevent="() => {}"
            @click.middle.prevent="closeTab(tab)"
            @click="!tabDrag && selectTab(tab)"
          >
            <template v-if="!hideTabIcon">
              <TagIcon
                v-if="isTagTab(tab)"
                :tag="getTabTag(tab)"
                class="tab-icon"
              />

              <Icon
                v-else
                :icon="getTabIcon(tab.route)"
                size="small"
                class="tab-icon"
                style="color: inherit"
              />
            </template>

            <span
              v-if="tab.name"
              class="tab-name"
              v-text="getTabName(tab)"
            />

            <IconButton
              v-show="hoveredTab === tab || (activeTab && activeTab.id === tab.id)"
              icon="close_rounded"
              size="small"
              :style="(hideTabIcon && centerCloseIcon) ? {
                right: '0',
                left: '50%',
                transform: 'translateX(-50%)',
              } : {}"
              @click.stop.prevent="closeTab(tab)"
            />
          </div>
        </transition-group>
      </draggable>

      <div class="new-tab-button-wrapper">
        <IconButton
          icon="add_rounded"
          size="small"
          @click="newTab()"
        />
      </div>
    </div>

    <div class="toolbar-buttons">
      <transition
        v-if="!anonymous && !$store.state.productAccess"
        name="fade-scale"
      >
        <div
          v-if="($store.getters.notifications && $store.getters.notifications.length) || ($store.getters.updates && $store.getters.updates.length)"
          class="button right"
        >
          <DropdownButton
            :dropdownSpacing="8"
            preferHAlign="right"
            :borderRadiusEffect="false"
          >
            <template #default="{ onClick, isOpen }">
              <IconButton
                :class="[
                  'toolbar-icon-button',
                  (unreadNotificationCount > 0 || isOpen) && !stopAnimation && 'toolbar-icon-button--peach',
                  (unreadNotificationCount > 0 || isOpen) && 'toolbar-icon-button--peach',
                  unreadNotificationCount > 0 && !stopAnimation && 'toolbar-icon-button--animated',
                  hasActiveJobs && 'toolbar-icon-button--active',
                ]"
                :icon="unreadNotificationCount > 0 ? 'notifications_active_outline_rounded' : 'notification_outline_rounded'"
                size="normal"
                :buttonColor="unreadNotificationCount > 0 || isOpen ? 'peach' : undefined"
                @click="onClick"
              />
            </template>

            <template #dropdown-menu="{ close, isOpen }">
              <notifications
                :notifications="$store.getters.notifications"
                :noMore="$store.state.noMoreNotifications"
                @loadMore="loadMoreNotifications"
                :visible="isOpen"
                @click="close"
              />
            </template>
          </DropdownButton>
        </div>
      </transition>

      <transition
        v-if="!anonymous && !$store.state.productAccess && helpdeskAvailable"
        name="slide-fade"
      >
        <div class="button right">
          <HelpWidget />
        </div>
      </transition>

      <transition name="slide-fade">
        <div
          class="button right"
          v-show="!$store.state.productAccess"
        >
          <DropdownButton
            :dropdownSpacing="8"
            :borderRadiusEffect="false"
            :submenu-style="'dots'"
            preferHAlign="right"
            :menuItems="userDropdown"
          >
            <template #default="{ onClick, isOpen }">
              <IconButton
                :class="[
                  'toolbar-icon-button',
                  isOpen && 'toolbar-icon-button--peach',
                ]"
                icon="account_circle_outline_rounded"
                size="normal"
                :buttonColor="isOpen ? 'peach' : undefined"
                @click="onClick"
              />
            </template>

            <template #before-menu-items>
              <template v-if="direct || readOnly">
                <div
                  v-if="direct && readOnly"
                  style="font-size: 12px; padding: 0 16px"
                >
                  Du er logget ind anonymt med begrænsede beføjelser. Log ind med e-mail for at få se lister mm.
                </div>

                <div
                  v-else-if="direct"
                  style="font-size: 12px; padding: 0 16px"
                >
                  Du benytter en kompakt version af Lasso. Åbn portalen for at se dine lister mm.
                </div>

                <div
                  v-else-if="readOnly"
                  style="font-size: 12px; padding: 0 16px"
                >
                  Din bruger har begrænsede beføjelser, og kan ikke foretage ændringer i Lasso.
                </div>
              </template>

              <template v-else>
                <div
                  class="dropdown-user-profile-section"
                >
                  <svg
                    width="36"
                    height="36"
                    viewBox="0 0 30 30"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      d="M5.775 22.65C7.05 21.675 8.475 20.906 10.05 20.343C11.625 19.781 13.275 19.5 15 19.5C16.725 19.5 18.375 19.781 19.95 20.343C21.525 20.906 22.95 21.675 24.225 22.65C25.1 21.625 25.7815 20.4625 26.2695 19.1625C26.7565 17.8625 27 16.475 27 15C27 11.675 25.8315 8.8435 23.4945 6.5055C21.1565 4.1685 18.325 3 15 3C11.675 3 8.844 4.1685 6.507 6.5055C4.169 8.8435 3 11.675 3 15C3 16.475 3.244 17.8625 3.732 19.1625C4.219 20.4625 4.9 21.625 5.775 22.65ZM15 16.5C13.525 16.5 12.281 15.994 11.268 14.982C10.256 13.969 9.75 12.725 9.75 11.25C9.75 9.775 10.256 8.531 11.268 7.518C12.281 6.506 13.525 6 15 6C16.475 6 17.719 6.506 18.732 7.518C19.744 8.531 20.25 9.775 20.25 11.25C20.25 12.725 19.744 13.969 18.732 14.982C17.719 15.994 16.475 16.5 15 16.5ZM15 30C12.925 30 10.975 29.606 9.15 28.818C7.325 28.031 5.7375 26.9625 4.3875 25.6125C3.0375 24.2625 1.969 22.675 1.182 20.85C0.394 19.025 0 17.075 0 15C0 12.925 0.394 10.975 1.182 9.15C1.969 7.325 3.0375 5.7375 4.3875 4.3875C5.7375 3.0375 7.325 1.9685 9.15 1.1805C10.975 0.3935 12.925 0 15 0C17.075 0 19.025 0.3935 20.85 1.1805C22.675 1.9685 24.2625 3.0375 25.6125 4.3875C26.9625 5.7375 28.031 7.325 28.818 9.15C29.606 10.975 30 12.925 30 15C30 17.075 29.606 19.025 28.818 20.85C28.031 22.675 26.9625 24.2625 25.6125 25.6125C24.2625 26.9625 22.675 28.031 20.85 28.818C19.025 29.606 17.075 30 15 30Z"
                      fill="url(#paint0_linear_181_7018)"
                    />
                    <defs>
                      <linearGradient
                        id="paint0_linear_181_7018"
                        x1="2.25"
                        y1="3"
                        x2="28.5"
                        y2="24.75"
                        gradientUnits="userSpaceOnUse"
                      >
                        <stop stop-color="#FF8191" />
                        <stop
                          offset="1"
                          stop-color="#FFA07A"
                        />
                      </linearGradient>
                    </defs>
                  </svg>

                  <!-- <Icon icon="account_circle_rounded" size="large" style="color: #FFA07A" /> -->

                  <div style="display: flex; flex-direction: row; flex-wrap: wrap; justify-content: center; align-items: center;">
                    <span style="flex-basis: 100%; font-size: 14px; line-height: 20px; font-weight: 500; color: #262626">{{ user.name }}</span><br>
                    <span style="flex-basis: 100%; font-size: 12px; line-height: 20px; color: #262626">{{ user.organization.name }}</span>
                  </div>
                </div>
              </template>
              <DropdownMenuDivider />
            </template>
          </DropdownButton>
        </div>
      </transition>
    </div>
  </div>
</template>

<style lang="less">
  #toolbar {
    position: relative;
    z-index: 2;
    width: 100%;
    height: 56px;
    display: flex;
    align-items: flex-end;
    // box-shadow: inset 0 -24px 24px -24px rgba(0, 0, 0, 0.08);

    &.shadow {
      box-shadow: 0 1px 10px 1px rgba(50,50,50,.1);
      border-bottom: none;
    }

    &.narrow {
      height: 48px;

      .tabs-wrapper {
        .draggable-tabs {
          &, & > span {
            flex-grow: 1;
            flex-shrink: 0;
          }
        }

        .tab {
          .dropdown-tabs {
            top: 48px;
            left: 48px;
            right: 48px;
            max-height: calc(100% - 48px - 16px);
            overflow-y: auto;

            .tab {
              width: 100%;
              max-width: unset;
              min-width: unset;
            }
          }
        }
      }

      .toolbar-buttons {
        padding: 12px;
      }
    }

    /*-webkit-animation: swing-in-top-fwd 0.5s cubic-bezier(0.175, 0.885, 0.320, 1.275) 1s both;
    -moz-animation: swing-in-top-fwd 0.5s cubic-bezier(0.175, 0.885, 0.320, 1.275) 1s both;
    animation: swing-in-top-fwd 0.5s cubic-bezier(0.175, 0.885, 0.320, 1.275) 1s both;*/

    .tabs-wrapper {
      display: flex;
      align-items: flex-end;
      width: 0;
      height: 56px;
      overflow: hidden;
      flex: 1 1;
      padding-left: 38px;
      margin-left: -38px;

      &.has-dropdown {
        &.showing-dropdown { overflow: initial; }
        .draggable-tabs {
          .tab.ghost ~ .tab:last-child,
          .tab.ghost:last-child {
            display: none;
          }
        }
      }

      &.is-dragging {
        .tab {
          &:not(.is-active):hover {
            .icon-button {
              visibility: hidden !important;
            }
          }
        }
      }

      .draggable-tabs {
        display: flex;
        align-items: flex-end;
        position: relative;
        user-select: none;

        .tab {
          &::before, &::after {
            content: '';
            position: absolute;
            z-index: 1;
            width: 2px;
            height: calc(100% - 24px);
            background: #e2e2e2;
            border-radius: 2px;
          }

          &::before {
            left: -1px;
          }

          &::after {
            right: -1px;
          }

          &:first-child::before,
          &.is-active::before,
          &.is-active::after,
          &.is-active + .tab::before,
          &:not(:last-child)::after {
            opacity: 0;
          }
        }
      }

      .dropdown-tabs {
        position: fixed;
        z-index: 99999;
        top: -100vw;
        left: -100vw;
        // top: 100%;
        // right: 60px;
        background: white;
        box-shadow: 0 0 24px 0 rgba(0, 0, 0, 0.08);
        border-radius: 4px;
        overflow: hidden;
        border-top-left-radius: 0;
        border-top-right-radius: 0;

        .tab {
          border-radius: unset;
          background: unset;
          box-shadow: unset;

          &.is-active {
            box-shadow: none;
          }

          &.ghost:not(.is-active) {
            border: none;
          }

          &.ghost {
            box-shadow: 0 0 24px 0 rgba(0, 0, 0, 0.08);
          }
        }
      }

      .tab {
        display: flex;
        justify-content: space-between;
        align-items: center;
        height: 48px;
        width: 240px;
        min-width: 0;
        // max-width: 240px;
        padding: 0 16px;
        flex-grow: 0;
        flex-shrink: 1;
        border-top-left-radius: 8px;
        border-top-right-radius: 8px;
        position: relative;
        font-weight: 500;

        .lassox-portal__TagIcon {
          flex-shrink: 0;
        }

        .tab-name {
          flex-grow: 1;
          flex-shrink: 1;
          color: #262626;
          font-size: 14px;
          line-height: 16px;
          white-space: nowrap;
          overflow: hidden;
          // mask-image: linear-gradient(90deg, #000 0%, #000 calc(100% - 18px - 8px - 24px), transparent calc(100% - 18px - 8px));
          mask-image: linear-gradient(90deg, #000 0%, #000 calc(100% - 24px), transparent);
        }

        &.is-active,
        &:hover {
          .tab-name {
            mask-image: linear-gradient(90deg, #000 0%, #000 calc(100% - 18px - 8px - 24px), transparent calc(100% - 18px - 8px));
          }
        }

        &.mobile {
          z-index: unset;
        }

        &.flip-list-move {
          transition: transform 0.25s;
        }

        .tab-icon {
          margin-right: 12px;
        }

        .lassox-portal__IconButton {
          position: absolute;
          right: 16px;

          .md-icon {
            color: inherit;
          }
        }
        &.ghost:not(.is-active) {
          border: 1px solid rgba(177,177,177, 0.25);
          border-bottom: none;
        }

        &.tab-drag {
          opacity: 0 !important;
        }

        &:not(.ghost):hover {
          cursor: pointer;
          .icon-button {
            visibility: visible;
          }
        }

        &.is-active {
          box-shadow: 0 0 24px 0 rgba(0, 0, 0, 0.08);
          background: white;
          z-index: 2;

          .icon-button {
            visibility: visible;
          }
        }

        &.peach-tab.is-active {
          background: #fef2ed;
        }
      }

      .new-tab-button-wrapper {
        display: flex;
        justify-content: center;
        align-items: center;
        width: 48px;
        height: 48px;
        flex-shrink: 0;

        // .md-icon {
        //   color: #262626;
        // }
      }
    }

    .toolbar-buttons {
      display: flex;
      gap: 16px;
      padding: 12px 16px;
      margin: auto 0;

      .button {
        width: 24px;
        height: 24px;
        display: flex;
        align-items: center;
        justify-content: center;
        position: relative;

        .toolbar-notification-badge {
          position: absolute;
          bottom: 12px;
          left: 12px;
          height: 16px;
          min-width: 16px;
          line-height: 1;
          font-size: 10px;
          text-align: center;
          padding: 4px;
          z-index: 2;
          color: white;
          border-radius: 8px;
          background-color: #FF8E61;
          pointer-events: none;
        }

        .toolbar-icon-button {
          .md-icon {
            // color: #262626;
            transition: color 100ms;
          }

          // &:hover .md-icon,
          // &:focus .md-icon,
          // &:active .md-icon {
          //   color: #FFA07A;
          // }

          @keyframes bell-shake {
            0% {
              transform: rotate(-15deg);
            }
            4% {
              transform: rotate(15deg);
            }
            8%, 24% {
              transform: rotate(-18deg);
            }
            12%, 28% {
              transform: rotate(18deg);
            }
            16% {
              transform: rotate(-22deg);
            }
            20% {
              transform: rotate(22deg);
            }
            32% {
              transform: rotate(-12deg);
            }
            36% {
              transform: rotate(12deg);
            }
            40%, 100% {
              transform: rotate(0deg);
            }
          }

          &--animated .md-icon {
            animation: bell-shake 2s ease 2;
          }

          &--active .md-icon {
            animation: bell-shake 2s ease infinite;
          }

          &--peach .md-icon {
            color: #FFA07A;
          }
        }

        .dropdown.notifications-dropdown {
          .menu {
            left: unset;
            right: -47px;
          }
        }

        .notification-count {
          width: 18px;
          background: linear-gradient(270deg, #FF8191 0%, #FFA07A 77.08%);
          color: #fff;
          height: 18px;
          text-align: center;
          border-color: #fff;
          top: 5px;
          font-size: 10px;
          right: 5px;
          position: absolute;
          border-radius: 15px;
          border: 2px solid #fff;
        }

        &.menu-button {
          background-repeat: no-repeat;
          background-size: 24px;
          background-position: center;
          background-image: url(../assets/img/svg/menu.svg);

          i.fixed-padding {
            padding: 0 13.71px;
          }
        }

        &.notifications-button {
          background-repeat: no-repeat;
          background-size: 24px;
          background-position: center;
          // background-image: url(../assets/img/svg/globe.svg);
          // border-right: 1px solid #dfdfdf;

          // &.unread img {
          //   animation: swing 1.5s ease 2;
          // }

          // &.active img {
          //   animation: swing 1.5s ease infinite;
          // }
        }

        @keyframes swing {
          0% {
            transform: rotateZ(0);
          }
          10% {
            transform: rotateZ(25deg);
          }
          20% {
            transform: rotateZ(-15deg);
          }
          30% {
            transform: rotateZ(10deg);
          }
          40% {
            transform: rotateZ(0);
          }
          100% {
            transform: rotateZ(0);
          }
        }

        &.help-center-button {
          background-repeat: no-repeat;
          background-size: 24px;
          background-position: center;
          // background-image: url(../assets/img/svg/help-center.svg);
          border-right: 1px solid #dfdfdf;
        }

        &.home-button {
          border-right: 1px solid #dfdfdf;
          color: #777;

          i.fixed-padding {
            padding: 0 13.71px;
          }

          &:hover {
            color: #555;
          }
        }
      }
    }
  }

  .lassox-portal__DropdownMenu .dropdown-user-profile-section {
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 16px;
    height: 68px;
    padding: 0 16px;
    color: #616161;
  }

  .fade-scale-enter-active, .fade-scale-leave-active {
    transition: opacity 0.5s ease, transform 0.5s ease;
  }

  .fade-scale-enter, .fade-scale-leave-to {
    opacity: 0;
    transform: scale(0.7);
  }

  .slide-fade-enter-active {
    transition: all .2s ease-out;
    transition-delay: .2s;
  }

  .slide-fade-leave-active {
    transition: all .2s ease-out;
  }

  .slide-fade-enter, .slide-fade-leave-to
  /* .slide-fade-leave-active below version 2.1.8 */ {
    transform: translateX(300%);
  }
</style>
