<script lang="ts">
import { Vue, Component, Prop, Watch, Ref, Provide } from 'vue-property-decorator'
import { DropdownMenuItems, DropdownMenuItem } from '@/models/dropdown-menu-items'
import { watchRect, unwatchRect } from '@/util/watch-rect'
import DropdownMenuItemVue from './DropdownMenuItem.vue'
import DropdownMenuDividerVue from './DropdownMenuDivider.vue'
import { TooltipOptions } from '@/tooltips'

const normalizeItems = (items: DropdownMenuItems) => {
  const groups: DropdownMenuItem[][] = []

  let grouping = false
  let newGroup: DropdownMenuItem[] = []

  const startGrouping = () => {
    grouping = true
    newGroup = []
    groups.push(newGroup)
  }

  const stopGrouping = () => {
    grouping = false
    newGroup = []
  }

  for (const itemOrItems of items) {
    if (Array.isArray(itemOrItems)) {
      if (grouping) stopGrouping()
      startGrouping()
      for (const item of itemOrItems) {
        if (item) newGroup.push(item)
      }
      stopGrouping()
    } else {
      if (!grouping) startGrouping()
      const item = itemOrItems
      if (item) newGroup.push(item)
    }
  }

  return groups.filter(group => group.length)
}

interface StateMenu {
  id: string
  opener: HTMLElement | null
  items: DropdownMenuItems
}

interface StateMenuItem {
  id: string
  icon?: string
  label: string
  title?: string | TooltipOptions
  trailingIcon?: string
  disabled?: boolean
  submenu?: StateMenu
  closeMenuOnClick: boolean
  onClick?: ($event: any) => void
}

export type Opener = Vue | HTMLElement

@Component({
  components: {
    DropdownMenuItem: DropdownMenuItemVue,
    DropdownMenuDivider: DropdownMenuDividerVue,
  },
})

export default class DropdownMenu extends Vue {
  @Provide()
  get dropdownMenuVm () { return this }

  @Prop({ type: Array, default: () => [] })
  readonly items!: DropdownMenuItems

  @Prop({ type: Number, default: 0 })
  readonly dropdownSpacing!: number

  @Prop({ type: Object })
  readonly dropdownStyle?: Record<string, string>

  @Prop({ type: String })
  readonly hAlign?: 'left' | 'right'

  @Prop({ type: String })
  readonly vAlign?:  | 'above' | 'below'

  @Prop({ type: String })
  readonly preferHAlign?: 'left' | 'right'

  @Prop({ type: String })
  readonly preferVAlign?:  | 'above' | 'below'

  @Prop({ type: Boolean, default: true })
  readonly borderRadiusEffect!: boolean

  @Prop({ type: String, default: 'default' })
  readonly submenuStyle!: 'default' | 'dots'

  @Prop({ type: [ Vue, HTMLElement ] })
  readonly opener?: Opener

  @Prop({ type: Boolean, default: true })
  readonly closeOnFocusOut!: boolean

  @Prop({ type: Boolean, default: false })
  readonly closeOnLongMouseOut!: boolean

  @Prop({ type: Function })
  readonly close?: () => void

  @Ref()
  readonly boundsEl!: HTMLElement

  @Ref()
  readonly dropdownMenuEl!: HTMLElement

  finalItems: StateMenuItem[][] = []
  submenus: StateMenu[] = []

  openerEl: HTMLElement | null = null

  parentDropdownMenu: DropdownMenu | null = null
  childDropdownMenus = new Set<DropdownMenu>()

  openerRect: DOMRect | null = null
  boundsRect: DOMRect | null = null
  dropdownMenuRect: DOMRect | null = null

  isFocused = false
  isMousedOver = false
  longMouseOutPromise: Promise<void> | null = null

  ignoreFocusOut = false
  ignoreMouseOut = false

  get ancestorDropdownMenus () {
    const dropdownMenus: DropdownMenu[] = []
    if (this.parentDropdownMenu) dropdownMenus.push(this.parentDropdownMenu, ...this.parentDropdownMenu.ancestorDropdownMenus)
    return dropdownMenus
  }

  get dropdownMenuStyle () {
    const { opener, openerRect, boundsRect, dropdownMenuRect } = this

    if (!opener || !openerRect || !boundsRect || !dropdownMenuRect) return {
      visibility: 'hidden',
      pointerEvents: 'none',
    }

    const minWidth = Math.max(openerRect.width, 112)
    let maxWidth = 280
    let maxHeight: number
    const transforms = []

    let borderTopLeftRadius = 8
    let borderTopRightRadius = 8
    let borderBottomLeftRadius = 8
    let borderBottomRightRadius = 8

    if (opener instanceof DropdownMenuItemVue) {
      const spaceAbove = openerRect.bottom - boundsRect.top
      const spaceBelow = boundsRect.bottom - openerRect.top
      const spaceLeft = openerRect.left - boundsRect.left
      const spaceRight = boundsRect.right - openerRect.right

      let hAlign: 'left' | 'right' = 'right'
      let vAlign: 'above' | 'below' = 'below'

      maxWidth = Math.min(Math.max(spaceLeft, spaceRight), maxWidth)

      if (maxWidth > spaceRight && spaceLeft > spaceRight) {
        hAlign = 'left'
      }

      if (hAlign == 'left') {
        transforms.push(`translateX(${openerRect.left}px) translateX(-100%)`)
      } else {
        transforms.push(`translateX(${openerRect.right}px)`)
      }

      if (spaceBelow <= 280 && spaceAbove > spaceBelow) {
        vAlign = 'above'
      }

      if (this.borderRadiusEffect) {
        if (vAlign === 'above') {
          if (hAlign === 'left') {
            borderBottomLeftRadius = 0
          } else {
            borderBottomRightRadius = 0
          }
        } else {
          if (hAlign === 'left') {
            borderTopLeftRadius = 0
          } else {
            borderTopRightRadius = 0
          }
        }
      }

      if (vAlign == 'above') {
        maxHeight = spaceAbove
        transforms.push(`translateY(${openerRect.bottom + 8}px) translateY(-100%)`)
      } else {
        maxHeight = spaceBelow
        transforms.push(`translateY(${openerRect.top - 8}px)`)
      }
    } else {
      const spaceAbove = openerRect.top - boundsRect.top
      const spaceBelow = boundsRect.bottom - openerRect.bottom
      const spaceLeft = openerRect.right - boundsRect.left
      const spaceRight = boundsRect.right - openerRect.left

      let hAlign: 'left' | 'right' = this.preferHAlign ?? 'right'
      let vAlign: 'above' | 'below' = this.preferVAlign ?? 'below'

      maxWidth = Math.min(Math.max(spaceLeft, spaceRight), maxWidth)

      if (!this.hAlign) {
        if (this.preferHAlign === 'left') {
          if (maxWidth > spaceRight && spaceLeft > spaceRight) hAlign = 'right'
        } else {
          if (maxWidth > spaceLeft && spaceRight > spaceLeft) hAlign = 'left'
        }

        if (hAlign == 'left') {
          transforms.push(`translateX(${openerRect.left}px)`)
        } else {
          transforms.push(`translateX(${openerRect.right}px) translateX(-100%)`)
        }
      }

      if (!this.vAlign) {
        if (this.preferVAlign === 'below') {
          if (spaceBelow <= 280 && spaceAbove > spaceBelow) {
            vAlign = 'above'
          }
        } else {
          if (spaceAbove <= 280 && spaceBelow > spaceAbove) {
            vAlign = 'below'
          }
        }
      }

      if (this.borderRadiusEffect) {
        if (vAlign === 'above') {
          if (hAlign === 'left') {
            borderBottomLeftRadius = 0
          } else {
            borderBottomRightRadius = 0
          }
        } else {
          if (hAlign === 'left') {
            borderTopLeftRadius = 0
          } else {
            borderTopRightRadius = 0
          }
        }
      }

      if (vAlign == 'above') {
        maxHeight = spaceAbove
        transforms.push(`translateY(${openerRect.top - this.dropdownSpacing}px) translateY(-100%)`)
      } else {
        maxHeight = spaceBelow
        transforms.push(`translateY(${openerRect.bottom + this.dropdownSpacing}px)`)
      }
    }

    return {
      borderBottomRightRadius: `${borderBottomRightRadius}px`,
      borderBottomLeftRadius: `${borderBottomLeftRadius}px`,
      borderTopRightRadius: `${borderTopRightRadius}px`,
      borderTopLeftRadius: `${borderTopLeftRadius}px`,
      minWidth: `${minWidth}px`,
      maxWidth: `${maxWidth}px`,
      maxHeight: `${maxHeight}px`,
      transform: transforms.join(' '),
      ...(this.dropdownStyle ?? {}),
    }
  }

  mounted () {
    this.onOpenerChanged()
  }

  beforeDestroy () {
    const oldOpenerEl = this.openerEl
    this.openerEl = null
    this.onOpenerElChanged(this.openerEl, oldOpenerEl)
    this.close?.()
  }

  updateButtonRect (rect: DOMRect) {
    this.openerRect = rect
  }

  updateBoundsRect (rect: DOMRect) {
    this.boundsRect = rect
  }

  updateDropdownMenuRect (rect: DOMRect) {
    this.dropdownMenuRect = rect
  }

  onFocusIn () {
    this.isFocused = true
  }

  onFocusOut (event: FocusEvent) {
    if (this.ignoreFocusOut) return

    const newFocusEl = event.relatedTarget instanceof HTMLElement ? event.relatedTarget : null

    if (newFocusEl) {
      if (this.openerEl?.contains(newFocusEl)) return
      if (this.dropdownMenuEl.contains(newFocusEl)) return
    }

    this.isFocused = false

    if (!this.closeOnFocusOut || this.childDropdownMenus.size) return

    this.ignoreMouseOut = true
    this.close?.()

    for (const menu of this.ancestorDropdownMenus) {
      const isFocused = menu.dropdownMenuEl.contains(newFocusEl)

      if (!menu.close || !menu.closeOnFocusOut || isFocused) {
        if (!isFocused) menu.dropdownMenuEl?.focus()
        break
      }

      menu.close()
    }

    requestAnimationFrame(() => this.ignoreMouseOut = false)
  }

  onMouseOver () {
    this.isMousedOver = true
  }

  onMouseOut (event: MouseEvent) {
    if (this.ignoreMouseOut) return

    const newMouseOverEl = event.relatedTarget instanceof HTMLElement ? event.relatedTarget : null

    if (newMouseOverEl) {
      if (this.openerEl?.contains(newMouseOverEl)) return
      if (this.dropdownMenuEl.contains(newMouseOverEl)) return
    }

    this.isMousedOver = false

    if (!this.closeOnLongMouseOut || this.childDropdownMenus.size) return

    const promise = this.longMouseOutPromise = new Promise(resolve => setTimeout(resolve, 250))

    promise.then(() => {
      if (this.longMouseOutPromise !== promise) return
      this.longMouseOutPromise = null

      if (this.isMousedOver) return

      this.ignoreFocusOut = true
      this.close?.()

      for (const menu of this.ancestorDropdownMenus) {
        if (!menu.close || !menu.closeOnLongMouseOut || menu.isMousedOver) {
          if (!menu.isFocused) menu.dropdownMenuEl?.focus()
          break
        }

        menu.close()
      }

      requestAnimationFrame(() => this.ignoreFocusOut = false)
    })
  }

  @Watch('items', { immediate: true, deep: true })
  onItemsChanged () {
    this.finalItems = []
    this.submenus = []

    if (!this.items) return

    let submenuI = 0

    this.finalItems = normalizeItems(this.items).map((group, groupI) => {
      const groupId = `group-${groupI}`
      let hasIcon = false
      let hasTrailingIcon = false

      const items = group.map((item, itemI): StateMenuItem => {
        if (item.icon) hasIcon = true
        if (item.submenu && this.submenuStyle === 'default') hasTrailingIcon = true

        const submenu: StateMenu | undefined = item.submenu ? {
          id: `${submenuI++}`,
          opener: null,
          items: item.submenu,
        } : undefined

        if (submenu) this.submenus.push(submenu)

        return {
          id: `${groupId}-item-${itemI}`,
          icon: item.icon,
          label: item.label + (item.submenu && this.submenuStyle === 'dots' ? '...' : ''),
          title: item.title,
          disabled: item.disabled || (item.submenu && !item.submenu.length),
          trailingIcon: item.submenu && this.submenuStyle === 'default' ? 'arrow_right' : '',
          submenu,
          closeMenuOnClick: !item.submenu && (item.closeMenuOnClick ?? true),
          onClick: ($event) => item.onClick?.($event),
        }
      })

      if (hasIcon || hasTrailingIcon) {
        for (const item of items) {
          if (hasIcon && !item.icon) item.icon = ' '
          if (hasTrailingIcon && !item.trailingIcon) item.trailingIcon = ' '
        }
      }

      return items
    })
  }

  @Watch('opener')
  onOpenerChanged () {
    const el: Element | undefined = this.opener instanceof HTMLElement ? this.opener : this.opener?.$el
    this.openerEl = el instanceof HTMLElement ? el : null

    this.parentDropdownMenu?.childDropdownMenus.delete(this)

    this.parentDropdownMenu = this.opener instanceof DropdownMenuItemVue ? this.opener.dropdownMenuVm : null
    this.parentDropdownMenu?.childDropdownMenus.add(this)
  }

  @Watch('openerEl')
  onOpenerElChanged (openerEl: HTMLElement | null = null, oldOpenerEl: HTMLElement | null = null) {
    if (openerEl === oldOpenerEl) return

    if (oldOpenerEl) {
      unwatchRect(oldOpenerEl, this.updateButtonRect)
      oldOpenerEl.removeEventListener('focusin', this.onFocusIn)
      oldOpenerEl.removeEventListener('focusout', this.onFocusOut)
      oldOpenerEl.removeEventListener('mouseover', this.onMouseOver)
      oldOpenerEl.removeEventListener('mouseout', this.onMouseOut)
    }

    if (openerEl) {
      if (!oldOpenerEl) {
        watchRect(this.boundsEl, this.updateBoundsRect)
        watchRect(this.dropdownMenuEl, this.updateDropdownMenuRect)
        this.dropdownMenuEl.addEventListener('focusin', this.onFocusIn)
        this.dropdownMenuEl.addEventListener('focusout', this.onFocusOut)
        this.dropdownMenuEl.addEventListener('mouseover', this.onMouseOver)
        this.dropdownMenuEl.addEventListener('mouseout', this.onMouseOut)
      }

      watchRect(openerEl, this.updateButtonRect)
      openerEl.addEventListener('focusin', this.onFocusIn)
      openerEl.addEventListener('focusout', this.onFocusOut)
      openerEl.addEventListener('mouseover', this.onMouseOver)
      openerEl.addEventListener('mouseout', this.onMouseOut)

      this.$nextTick(() => this.dropdownMenuEl.focus())
    } else {
      unwatchRect(this.boundsEl, this.updateBoundsRect)
      unwatchRect(this.dropdownMenuEl, this.updateDropdownMenuRect)
      this.dropdownMenuEl.removeEventListener('focusin', this.onFocusIn)
      this.dropdownMenuEl.removeEventListener('focusout', this.onFocusOut)
      this.dropdownMenuEl.removeEventListener('mouseover', this.onMouseOver)
      this.dropdownMenuEl.removeEventListener('mouseout', this.onMouseOut)
    }
  }
}
</script>

<template>
  <div class="lassox-portal__DropdownMenu">
    <div
      ref="boundsEl"
      class="lassox-portal__DropdownMenu_bounds"
    />

    <div
      ref="dropdownMenuEl"
      class="lassox-portal__DropdownMenu_dropdown-menu"
      :class="{
        'lassox-portal__DropdownMenu_dropdown-menu-border-radius-effect': borderRadiusEffect
      }"
      tabindex="-1"
      :style="dropdownMenuStyle"
    >
      <slot name="before-menu-items" />

      <slot>
        <template v-if="finalItems">
          <template v-for="(items, groupI) in finalItems">
            <DropdownMenuDivider
              v-if="groupI"
              :key="`group-${groupI}-divider`"
            />

            <DropdownMenuItem
              v-for="item in items"
              :key="item.id"
              :ref="item.id"
              :icon="item.icon"
              :label="item.label"
              :title="item.title"
              :trailingIcon="item.trailingIcon"
              :disabled="item.disabled"
              :closeMenuOnClick="item.closeMenuOnClick"
              @longmouseover="() => {
                if (item.submenu) item.submenu.opener = $refs[item.id][0]
              }"
              @click="($event) => {
                if (item.submenu) item.submenu.opener = item.submenu.opener ? null : $refs[item.id][0]
                else if (item.onClick) item.onClick($event)
              }"
            />
          </template>
        </template>
      </slot>

      <slot name="after-menu-items" />
    </div>

    <DropdownMenu
      v-for="menu in submenus"
      :key="menu.id"
      :opener="menu.opener"
      :borderRadiusEffect="borderRadiusEffect"
      :close="() => menu.opener = null"
      :closeOnLongMouseOut="true"
      :items="menu.items"
    />
  </div>
</template>

<style lang="scss">
.lassox-portal__DropdownMenu {
  pointer-events: none;
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: 9999;

  &_bounds {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    z-index: -9999;
  }

  &_dropdown-menu {
    pointer-events: all;
    position: fixed;
    top: 0;
    left: 0;
    z-index: 9999;
    padding: 8px 0;
    box-sizing: border-box;
    background-color: white;
    border-radius: 8px;
    box-shadow: 0 0px 12px 0 rgba(black, 0.16);
    overflow-x: hidden;
    overflow-y: auto;

    &-border-radius-effect {
      box-shadow: inset 0 0 0 1px #B7B7B7, 0px 0px 24px rgba(0, 0, 0, 0.08);
    }

    &:focus {
      outline: none;
    }
  }
}
</style>
