<script>
import Toolbar from '@/components/Toolbar'
import Modal from '@/components/Modal'
import TagModal from '@/components/modals/TagModal'
import GenericNotificationModal from '@/components/modals/GenericNotificationModal'
import ConfirmModal from '@/components/modals/ConfirmModal'
import StoryModal from '@/components/modals/StoryModal'
import TagNameModal from '@/components/modals/TagNameModal'
import TermsModal from '@/components/modals/TermsModal'
import UserEmailModal from '@/components/modals/UserEmailModal'
import BookDemoModal from '@/components/modals/BookDemoModal'
import SwitchAccountModal from '@/components/modals/SwitchAccountModal'
import Dropdown from '@oldShared/lasso/vue-components/Dropdown'
import { orderBy, groupBy } from 'lodash'
import TabView from './components/TabView'
import { createTab, tabsById } from './types/Tab.ts'
import { isNavigationFailure, NavigationFailureType } from 'vue-router'
import DialogTransition from '@/components/DialogTransition.vue'
import { EventBus, Users, PEP } from '@/assets/js/helpers'
import './assets/css/animations.css'
import './assets/css/checkbox.css'
import appHistory from './history.ts'
import TooltipRoot from '@/components/TooltipRoot.vue'
import { store } from './assets/js/store'
import LassoSettings from './components/util/LassoSettings.ts'
import windowSize from '@/window-size.ts'
import LoadingSpinner from './components/LoadingSpinner.vue'
import Button from './components/Button.vue'
import 'intro.js/introjs.css'
import Dialog from '@/components/Dialog.vue'
import Icon from '@/components/Icon.vue'
import Onboarding from './Onboarding.vue'
import { showTabOverlay } from './util/overlay.ts'
import TrialOverlay from './components/overlays/TrialOverlay.vue'
import filters from './assets/js/filters'
import TrialExpiringOverlay from './components/overlays/TrialExpiringOverlay.vue'
import TrialExpiredOverlay from './components/overlays/TrialExpiredOverlay.vue'
import BrandingSurveyOverlay from './components/overlays/BrandingSurveyOverlay.vue'
import Deferred from './util/deferred.ts'
import { captureException } from '@sentry/browser'
import { mapState } from 'vuex'
import appStore from '@/stores/app'

/** @type {ReturnType<typeof Deferred<LassoSettings>>} */
const lassoSettingsDeferred = Deferred()
const initialTabCreatedDeferred = Deferred()

export default {
  name: 'App',
  data () {
    return {
      modalConfig: null,
      showModal: false,
      usersToImpersonate: null,
      gettingUsers: false,
      DialogTransition: DialogTransition,
      stopAnimationDuringTour: false,
    }
  },
  computed: {
    loading () { return this.$store.state.loading },
    loadingText () { return appStore.loadingMessage ?? 'Indlæser Lasso...' },
    size () { return windowSize.size },
    isMobile () { return windowSize.width < windowSize.sizes.md },
    isFreemium () { return this.$store.getters.isFreemium },
  },
  methods: {
    initialize: async function (force = false) {      
      // initialize the user by logging in, getting user data etc
      try {
        if (store.state.promises.initialize) await store.state.promises.initialize
      } catch (error) {
        if (error !== 'Lasso Unauthorized') {
          captureException(error)
        }
      }
      this.$store.state.loading = true

      if (this.$route.name !== 'search' && force) this.$router.replace({ name: 'search' })

      if (!store.state.direct
          && !store.getters.isFreemium
          && !store.state.user.organization.currentSolution.trial
          && !store.state.user.organization.termsAccepted
          && (store.state.user.roles.indexOf('Admin') > -1 || store.state.user.roles.indexOf('LassoAdmin') > -1)) {
        this.modalConfig = { modalType: 'terms', termsType: 'DPA' }
        this.showModal = true
      }

      setTimeout(() => {
        // This delay both allows the user to see what they came for originally, before being interrupted by an overlay and allow the TabView to mount and be ready for an display overlay event
        this.checkTrialExpiry()
      }, 1000)

      //#region Get PEP list
      // get latest PEP list, and make sure to report any errors as this is a critical feature that must work.
      if (store.state.user.organization.contentProviderSettings.find(cps => cps.contentProvider.uniqueName === 'pep')) {
        try {
          await store.commit('updatePEP', await PEP.get()) 
        } catch (error) {
          // we've seen cases were getting PEP list would fail. Log this properly here, by sending to Sentry.
          const newError = new Error("Failed to get PEP list: " + error.message)
          newError.stack += '\nCaused by: ' + error.stack
          captureException(newError)
        }
      }
      //#endregion
  
      this.$store.state.loading = false

      this.checkBrandingSurvey()
    },
    createInitialTab () {
      const tab = createTab({ store: this.$store, route: this.$route })
      document.title = tab.name
      this.$store.state.tabs.push(tab)
      this.$store.state.activeTab = tab
      history.replaceState({
        historyId: appHistory.id,
        tabId: tab.id,
      }, '', tab.route.fullPath)
      this.$nextTick(() => {
        initialTabCreatedDeferred.resolve()
      })
    },
    checkBrandingSurvey () {
      // ? This should be false. Set to true when testing.
      const forceShow = false

      Promise.all([ lassoSettingsDeferred.promise, initialTabCreatedDeferred.promise ])
        .then(([ lassoSettings ]) => {
          const { brandingBaselineSpring2024Survey } = lassoSettings.settings.portal

          if (!forceShow) {
            const startDate = new Date('2024-06-12T13:00:00Z')
            const endDate = new Date(startDate)
            endDate.setDate(endDate.getDate() + 7)

            // Only show within the specified date range
            const date = new Date()
            if (date < startDate || date > endDate) return

            // Only show for freemium
            if (!store.getters.isFreemium) return

            // Only show if the survey hasn't been shown before
            if (brandingBaselineSpring2024Survey.shown) return

            // Only show if at least 2 days have passed since first login
            const { firstTimeLogin } = store.state.user.settings
            const minimumDate = new Date(firstTimeLogin)
            minimumDate.setDate(minimumDate.getDate() + 2)
            if (Date.now() < minimumDate.getTime()) return
          }

          // Show the survey
          brandingBaselineSpring2024Survey.shown = true
          lassoSettings.updateSettings()
          showTabOverlay({ component: BrandingSurveyOverlay })
          EventBus.$emit('june:trackEvent', { event: 'Branding Baseline Spring 2024 survey shown' })
        })
    },
    onClick: function (event) {
      EventBus.$emit('app-click', event)
    },
    openHelpdesk: function (url) {
      EventBus.$emit('open-helpdesk', url)
    },
    onModalClose: function (modalConfig) {
      if (modalConfig.onCancel) modalConfig.onCancel()
      this.showModal = false
    },
    onToggleUsers () {
      if (!this.usersToImpersonate && !this.gettingUsers) {
        this.gettingUsers = true
        this.$store.dispatch('getOrganizationUsers').then(result => {
          const userGroups = groupBy(result.results, u => u.roles.some(r => [ 'Admin', 'LassoAdmin' ].indexOf(r) > -1) ? 'admin' : 'user')
          Object.keys(userGroups, key => userGroups[key] = orderBy(userGroups[key], 'name', 'asc'))
          this.usersToImpersonate = userGroups
          this.gettingUsers = false
        })
      }
    },
    checkTrialExpiry () {
      const toDate = this.$store.state.user.organization.currentSolution.toDate

      const today = new Date()
      today.setHours(0, 0, 0, 0)

      const isExpiring = !toDate ? false : new Date(toDate) > today
        && !(this.$store.state.user.organization.nextSolution
          && (this.$store.state.user.organization.nextSolution?.versionUniqueName !== 'lasso_freemium'
            && this.$store.state.user.organization.nextSolution?.versionUniqueName !== 'lasso_lite'))
      const daysToExpiry = filters.daysBetween(new Date(this.$store.state.user.organization.currentSolution.toDate), today)


      const showExpiring = isExpiring && daysToExpiry <= 3 && !this.$store.state.user.settings?.trial?.shownExpiringReminder
      const showExpired = this.isFreemium && !this.$store.state.user.organization?.canCreateTrial && !this.$store.state.user.settings?.trial?.shownExpiredReminder

      if (showExpiring) {
        showTabOverlay({ component: TrialExpiringOverlay })
        if (!this.$store.state.impersonating)
          Users.updateSettings({  trial: { shownExpiringReminder: true } })
      }
      else if (showExpired) {
        showTabOverlay({ component: TrialExpiredOverlay })
        if (!this.$store.state.impersonating)
          Users.updateSettings({ trial: { shownExpiredReminder: true } })
      }
    }
  },
  async created () {
    this.$router.onReady(() => {
      this.createInitialTab()
    })

    EventBus.$on('createInitialTab', this.createInitialTab)
    EventBus.$on('initialize', () => this.initialize(true))

    await this.initialize()

    addEventListener('popstate', event => {
      if (
        event.state
        && event.state.historyId === appHistory.id
        && typeof event.state.tabId === 'number'
      ) {
        const tab = tabsById.get(event.state.tabId)
        if (!tab) return

        tab.replaceRoute(this.$router.resolve({
          path: location.pathname,
          hash: location.hash,
          query: Object.fromEntries(new URLSearchParams(location.search).entries()),
        }).route)

        if (!this.$store.state.tabs.includes(tab)) {
          this.$store.state.tabs.push(tab)
        }

        this.$store.state.activeTab = tab
        return
      }

      const tab = createTab({ store: this.$store, route: this.$router.resolve({
        path: location.pathname,
        hash: location.hash,
        query: Object.fromEntries(new URLSearchParams(location.search).entries()),
      }).route })

      this.$store.state.tabs.push(tab)
      this.$store.state.activeTab = tab
    })

    // idk why this is needed, but it is ¯\_(ツ)_/¯
    this.$watch(() => this.$store.state.activeTab?.name, (_) => {
      if (!this.$store.state.activeTab) return
      // if ('App.vue ~ $watch ~ this.$store.state.activeTab?.name', name)
      document.title = this.$store.state.activeTab?.name
    }, { immediate: true })

    this.$watch(() => this.$store.state.activeTab?.route, route => {
      if (!route) return
      document.title = this.$store.state.activeTab.name
      if (this.modalConfig?.modalType !== 'terms') this.showModal = false
      this.$router.replace(route).catch(e => !isNavigationFailure(e, NavigationFailureType.duplicated))
    })

    // Branding baseline spring 2024 survey
    this.checkBrandingSurvey()
  },

  async mounted () {
    EventBus.$on('modal', config => {
      this.modalConfig = config
      this.showModal = true
    })
  },
  watch: {
    loading: function (loading) {
      if (loading) {
        this.initialize()
      }
    },
  },
  components: {
    Toolbar,
    Modal,
    TagModal,
    GenericNotificationModal,
    ConfirmModal,
    StoryModal,
    TagNameModal,
    TermsModal,
    Dropdown,
    UserEmailModal,
    TabView,
    TooltipRoot,
    BookDemoModal,
    SwitchAccountModal,
    LoadingSpinner,
    Button,
    Dialog,
    Icon,
    Onboarding,
    TrialOverlay,
    TrialExpiredOverlay,
    TrialExpiringOverlay
  }
}
</script>

<template>
  <div
    id="app"
    class="lassox-portal__App"
    :class="size"
    @click="onClick"
  >
    <div class="lassox-portal__App-inner">
      <transition name="fade">
        <div
          v-if="loading"
          class="app-loading"
        >
          <div class="app-loading-inner">
            <LoadingSpinner size="80" />
            <p style="margin-top: 50px; color: #FF8E61; font-size: 22px; font-weight: 500;" v-text="loadingText" />
          </div>
        </div>
      </transition>

      <template v-if="!loading">
        <router-view
          name="sidebar"
          v-if="$store.getters.allowSidebar && !$store.state.direct"
        />

        <div id="contentWrapper">
          <Toolbar
            v-if="!$store.state.direct || $route.query.search !== undefined"
            :anonymous="$store.state.direct"
            :stopAnimation="stopAnimationDuringTour"
          />

          <div
            id="impersonating"
            v-if="$store.state.impersonating && !$store.state.direct"
          >
            <span class="description">Du kontrollerer i øjeblikket denne bruger. Afgiv kontrol for at logge ind som dig selv.</span>

            <div>
              <!-- @opened="importerOpen = true" @closed="importerOpen = false;" -->
              <dropdown
                v-if="$store.state.user"
                left
                :minWidth="200"
                class="margin-right-1"
              >
                <a
                  slot="toggle"
                  @click="onToggleUsers"
                >{{ $store.state.user.name || 'Ikke navngivet' }}<i
                  class="fa fa-angle-down"
                  style="margin-left: 5px"
                /></a>

                <span
                  v-if="!usersToImpersonate || gettingUsers"
                  class="dropdown-description"
                >Henter brugere..</span>

                <div
                  v-else
                  style="max-height: 300px; overflow: auto"
                >
                  <div
                    v-for="(users, role, index) in usersToImpersonate"
                    :key="role"
                  >
                    <div
                      v-if="index > 0"
                      class="dropdown-divider"
                    />

                    <div class="dropdown-description">
                      {{ role === 'admin' ? 'Administratorer' : 'Brugere' }}
                    </div>

                    <div class="dropdown-divider" />

                    <a
                      @click="$store.dispatch('changeImpersonation', user)"
                      class="dropdown-item"
                      v-for="user in users"
                      :key="user.id"
                    >{{ user.name }}</a>
                  </div>
                </div>
              </dropdown>

              <a
                href="#"
                style="color: #fff; text-decoration: none"
                @click.prevent.stop="$store.dispatch('stopImpersonating')"
              >Afgiv kontrol</a>
            </div>
          </div>

          <div
            class="lassox-portal__App-tab-view-wrapper"
            :style="{
              borderTopLeftRadius: (isMobile || ($store.state.activeTab && $store.state.activeTab.order === 0)) ? '' : '6px',
            }"
          >
            <TabView
              v-for="tab in $store.state.tabs"
              :key="tab.id"
              :tab="tab"
            />
          </div>
        </div>
      </template>
    </div>

    <modal
      v-if="showModal"
      :config="modalConfig"
      @close="() => onModalClose(modalConfig)"
    >
      <switch-account-modal
        v-if="modalConfig.modalType === 'switchAccount'"
        :organizations="modalConfig.organizations"
        @close="() => onModalClose(modalConfig)"
      />
      <generic-notification-modal
        v-if="modalConfig.modalType === 'genericNotification'"
        :config="modalConfig"
        @close="() => onModalClose(modalConfig)"
      />
      <tag-modal
        v-else-if="modalConfig.modalType === 'tagManager'"
        :tags="$store.state.tags.filter((t) => t.type.toLowerCase() === modalConfig.tagType.toLowerCase())"
        :entities="modalConfig.entities"
        @close="() => onModalClose(modalConfig)"
        @done="changes => { modalConfig.onDone(changes); showModal = false }"
      />
      <confirm-modal
        v-else-if="modalConfig.modalType === 'confirm'"
        :confirmTitle="modalConfig.confirmTitle"
        :confirmDescription="modalConfig.confirmDescription"
        :confirmButtonText="modalConfig.confirmButtonText"
        :cancelButtonText="modalConfig.cancelButtonText"
        @cancel="() => onModalClose(modalConfig)"
        @confirm="modalConfig.onConfirm(); showModal = false"
        :config="modalConfig"
      />
      <story-modal
        v-else-if="modalConfig.modalType === 'story'"
        :story="modalConfig.story"
        :entity="modalConfig.entity"
        :config="modalConfig"
      />
      <tag-name-modal
        v-else-if="modalConfig.modalType === 'tagName'"
        :tag="modalConfig.tag"
        @cancel="showModal = false"
        @done="modalConfig.onDone(); showModal = false"
      />
      <terms-modal
        v-else-if="modalConfig.modalType === 'terms'"
        :termsType="modalConfig.termsType"
        @cancel="showModal = false"
        @done="showModal = false"
      />
      <user-email-modal
        v-else-if="modalConfig.modalType === 'userEmail'"
        @cancel="showModal = false"
        @done="email => { modalConfig.onDone(email); showModal = false }"
      />
    </modal>

    <notifications
      :max="3"
      position="bottom center"
      class="notifications"
      width="100%"
      animationName="notification-animation"
    >
      <template
        slot="body"
        slot-scope="props"
      >
        <div
          class="lasso-notification"
          :style="{ justifyContent: props.item.text ? '' : '' }"
        >
          <div
            v-if="true"
            class="notification-content"
          >
            <span
              v-if="props.item.title"
              class="title"
            >
              {{ props.item.title }}
            </span>
            <div
              class="text"
              v-if="props.item.text"
              v-html="props.item.text"
            />
          </div>
          <div
            v-if="props.item.type === 'actionable'"
            class="close"
          >
            <Button
              type="flat"
              label="OK"
              @click="props.close"
            />
            <!-- <IconButton class="close-icon" size="normal" icon="close_rounded" @click="props.close" /> -->
          </div>
        </div>
      </template>
    </notifications>

    <div class="lassox-portal__App-dialogs">
      <portal-target
        name="dialogs"
        multiple
        :transition="DialogTransition"
      />
    </div>

    <div class="lassox-portal__App-menus">
      <portal-target
        name="menus"
        multiple
      />
    </div>

    <TooltipRoot />
    <Onboarding
      v-if="$store.state.user"
      :portalLoading="loading"
      :isMobile="isMobile"
      @openHelpdesk="(url) => openHelpdesk(url)"
      @tourStarted="stopAnimationDuringTour = true"
      @tourStopped="stopAnimationDuringTour = false"
    />
  </div>
</template>

<style lang="less">
@import "@oldShared/lasso/style/constants/colors";
@import "@oldShared/lasso/style/spacing/margin";
@import "@oldShared/lasso/style/spacing/padding";
@import "@oldShared/lasso/style/base/typography";
@import "@oldShared/lasso/style/base/animation";
@import "@oldShared/lasso/style/base/tooltip";

* {
  scrollbar-width: auto;

  &::-webkit-scrollbar {
    width: 6px;
    height: 6px;
    background-color: rgba(0, 0, 0, 0.125);
  }

  &::-webkit-scrollbar-thumb {
    background-color: rgba(0, 0, 0, 0.25);
  }

  &::-webkit-scrollbar-thumb:hover {
    background-color: rgba(0, 0, 0, 0.5);
  }
}

html,
body {
  height: 100%;
  width: 100%;
  margin: 0;
  font-size: 14px;
}

h2 {
  font-size: 22px;
  font-weight: 500;
}

h4 {
  font-size: 17px;
  font-weight: 500;
}

.min-max-height {
  max-height: 100%;
  height: 100%;
}

a {
  color: #ffa07a;
  cursor: pointer;
  &:hover {
    color: #ffa07a;
  }
}

input {
  &:active, &:focus {
    outline: none;
  }
}

.input-group {
  label {
    font-size: 13px;
    font-weight: 500;
  }

  input {
    border: 1px solid #dfdfdf;
    border-radius: 2px;
    width: 100%;
    line-height: 30px;
    padding: 0 8px;

    &:active, &:focus {
      outline: none;
    }
  }

}

.custom-control {
  &.custom-radio {
    .custom-control-label::before {
      background-color: #fff;
      border: 1px solid #555;
    }
    .custom-control-input:checked~.custom-control-label::after {
      background-image: url("data:image/svg+xml;charset=utf8,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='-4 -4 8 8'%3E%3Ccircle r='4' fill='%23555'/%3E%3C/svg%3E");
    }
  }
  &.custom-checkbox {
    .custom-control-label::before {
      box-shadow: none;
    }
    .custom-control-input:focus {
      outline-width: 0;
      outline: none !important;
    }
    .custom-control-input:checked~.custom-control-label::before {
      background-color: #ffa07a;
      border: 1px solid #555;
    }
  }
}

.btn {
  border-radius: 32px;
  font-size: 14px;
  line-height: 20px;
  padding: 6px 20px;
  border: none;

  &:focus {
    box-shadow: none;
    outline: none;
  }

  &.btn-default {
    box-shadow: inset 0 0 0 1px #B7B7B7;
    background-color: #fff;
    &:hover {
      background-color: rgba(183, 183, 183, 0.20);
    }
    &:focus, &:active {
      // background-color: rgba(183, 183, 183, 0.50);
      background: rgba(183, 183, 183, 0.80);
    }
  }

  &.btn-primary {
    background-color: #FFA07A;

    &:hover {
      background-color: #FF8E61;
    }
    &:focus, &:active {
      background-color: #FF7138 !important;
      border: none !important;
      outline: none !important;
      box-shadow: none !important;
      // box-shadow: 0 0 0 0.2rem rgba(205, 75, 61, 0.5) !important;
      // background-color: #FFA07A !important;
      // border-color: #FFA07A !important;
    }
  }
}

.loader {
  border: 2px solid #f3f3f3;
  border-top: 2px solid @red-primary;
  border-radius: 50%;
  width: 15px;
  height: 15px;
  animation: spin .5s linear infinite;
}

.message {
  flex: 1 0;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  img {
    margin-bottom: 20px;
  }

    h3, h4 {
    margin: 0 0 10px 0;
  }

  p {
    color: #777;
    font-size: 15px;
    font-weight: 300;
    margin-bottom: 0;
  }
}

.copy-button {
  background-color: #fff;
  border: 1px solid #dfdfdf;
  font-style: normal;
  font-weight: 700;
  cursor: pointer;
  &:hover {
    background-color: #f8f8f8;
  }

  &.copy-button-sm {
    padding: 2px 6px;
    font-size: 9px;
    line-height: 9px;
  }
}

.filetype {
  cursor: pointer;
  border-radius: 2px;
  font-weight: 500;
  font-size: 7px;
  line-height: 7px;
  color: #fff;
  margin-right: 8px;
  padding: 3px 4px 2px 4px;
  position: relative;
  top: -3px;

  &.pdf { background-color: @peach-primary; }
  &.tiff { background-color: #94C11A; }
  &.xml { background-color: #004180; }

  .notification {
    width: 12px;
    background: @peach-primary;
    color: white;
    height: 12px;
    text-align: center;
    border-color: white;
    top: -7px;
    right: -7px;
    position: absolute;
    border-radius: 10px;
    border: 1px solid #fff;
  }
}

#app {
  background-color: #f9f9f9;
  width: 100%;
  height: 100%;
  min-height: 100%;
  display: flex;
  font-family: Roboto, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  overflow: hidden;

  .app-loading, .app-loading-inner {
    left: 0;
    right: 0;
    top: 0;
    bottom: 0;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-wrap: wrap;
    flex-direction: column;
    position: absolute;
    opacity: 1;
    z-index: 10;
    background: #fff;
  }

  .fade-leave-active {
    transition: background-color 0.5s;
    & > .app-loading-inner {
      transition: opacity 0.1s;
    }
  }
  .fade-leave-to {
    background-color: transparent;
    & > .app-loading-inner {
      opacity: 0;
    }
  }

  #contentWrapper {
    position: relative;
    z-index: 0;
    width: 0;
    flex-grow: 1;
    display: flex;
    flex-direction: column;
  }

  .vb {
    &.dragger-wide {
      >.vb-dragger {
        width: 15px;
      }
    }

    .vb-content {
      -webkit-overflow-scrolling: touch;
    }

    &.dark {
      &:hover {
        .vb-dragger-styler {
          background-color: #dfdfdf;
        }
      }

      &.vb-dragging-phantom {
        >.vb-dragger {
          >.vb-dragger-styler {
            background-color: #dfdfdf;
          }
        }
      }
    }

    &:hover {
      >.vb-dragger-styler {
        margin: 0px;
        height: 100%;
      }
    }

    >.vb-dragger {
      z-index: 5;
      width: 6px;
      right: 0;

      >.vb-dragger-styler {
        height: 100%;
        -webkit-backface-visibility: hidden;
        backface-visibility: hidden;
        -webkit-transform: rotate3d(0, 0, 0, 0);
        transform: rotate3d(0, 0, 0, 0);
        -webkit-transition: background-color 100ms ease-out,
          margin 100ms ease-out,
          height 100ms ease-out;
        transition: background-color 100ms ease-out,
          margin 100ms ease-out,
          height 100ms ease-out;
        background-color: rgba(255, 255, 255, 0);
      }
    }
  }

  .selector {
    text-align: center;
    // flex-basis: 30px;
    // flex-shrink: 0;
    // height: 34px;
    display: flex;
    align-items: center;
    justify-content: center;
    // position: relative;
    cursor: pointer;

    .check {
      border: 1px solid #dfdfdf;
      border-radius: 2px;
      // position: absolute;
      // top: 50%;
      // margin-top: -8px;
      height: 13px;
      width: 13px;
      background-color: #fff;
      display: flex;
      align-items: center;
      justify-content: center;
      // text-align: center;
      i {
        font-size: 10px;
        // position: absolute;
        // top: 50%;
        // margin-top: -5px;
        // left: 2px;
        // line-height: 13px;
        // vertical-align: text-top;
        display: none;
        color: #616161;
      }

      &.checked i {
        display: inline;
      }
    }
  }

  #impersonating {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    padding: 8px 15px;
    background-color: #EF4444;
    color: #fff;

    span.description {
      flex: 1 0 auto;
    }
  }

  .notifications {
    z-index: 9999;

    .notification-wrapper, .vue-notification-wrapper {
      margin-bottom: 16px;
      display: flex;
      justify-content: center;
      overflow: unset;
      pointer-events: none;

      &.notification-animation {
        transition: transform 200ms cubic-bezier(0.3, 0.0, 0.8, 0.15);

        &-move {
          transition: all 300ms ease-in;
        }

        &-enter,
        &-enter-active {
          transform: translateY(100vh);
        }
        &-enter-to {
          transform: translateY(0);
        }

        &-leave,
        &-leave-active {
          transition: opacity 200ms cubic-bezier(0.05, 0.7, 0.1, 1.0), transform 300ms cubic-bezier(0.3, 0.0, 0.8, 0.15);
          opacity: 1;
          transform: translateY(0);
        }

        &-leave-to {
          opacity: 0;
          transform: translateY(100vh);
        }
      }

      .lasso-notification {
        box-shadow: 0 5px 10px 1px rgba(50,50,50,.1);
        background-color: #353B3F;
        padding: 16px;
        min-width: 344px;
        max-width: 480px;
        // min-width: 344px;
        color: #fff;
        border-radius: 4px;
        display: flex;
        // flex-direction: column;
        position: relative;
        pointer-events: auto;

        .notification-content {
          display: flex;
          flex-wrap: wrap;
          // justify-content: center;
          align-items: center;
          flex-grow: 1;
          flex-shrink: 1;

          .title,
          .text {
            margin: -2px 0;
            font-size: 14px;
            line-height: 20px;
          }

          .title {
            font-weight: 500;
          }

          .title + .text {
            margin-top: 8px - 2px;
          }
        }

        .close {
          display: flex;
          justify-content: center;
          align-items: center;
          margin-left: 16px;
          opacity: 1;

          .lassox-portal__Button {
            min-width: auto;
            margin: -8px;

            .lassox-portal__BaseButton_label {
              color: #ffa07a;
              font-size: 14px;
              line-height: 20px;
            }
          }

          .close-icon {
            margin-left: auto;

            .md-icon {
              color: white;
            }
          }
        }

      }
    }
  }

  #list {
    #listHeader {
      .dropdown .menu {
        margin-top: 0px;
      }
    }
  }


  /* styling & utility classes for the updated 2024 design guide look, used in new sections of the portal */
  --dark-peach: #FF8E61;
}

.typography-title {
  color: black;
  font-size: 22px;
  line-height: 28px;
  font-weight: 600;
}

.typography-headline {
  font-size: 13px;
  line-height: 20px;
  font-weight: 500;
}

.typography-link {
  font-size: 13px;
  line-height: 20px;
  font-weight: 500;
  color: black;
  transition: color 0.2s;

  &:hover {
    text-decoration: none;
    color: var(--dark-peach);
  }
}
</style>
